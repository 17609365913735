import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0px ${theme.spacing(3)}px`,
    height: 35,
    boxShadow: theme.shadows[3],
  },
}));

export default function SearchThisAreaButton(props) {
  const classes = useStyles(props);
  const isValidSearchInput = useSelector(select.search.isValidSearchInput);
  const mapWasMovedByUser = useSelector(select.map.hasMoved);
  const mapCenter = useSelector(select.map.center);
  const hasActiveMapMarker = useSelector(select.results.hasActiveMapMarker);
  const searchDispatch = useSearchDispatchWithHistory();

  const handleSearchThisArea = useCallback(() => {
    searchDispatch(thunks.results.searchThisArea({ mapCenter }));
  }, [searchDispatch, mapCenter]);

  const showButton = isValidSearchInput && mapWasMovedByUser && !hasActiveMapMarker;

  return (
    <Slide in={showButton} direction="down">
      <Button
        onClick={handleSearchThisArea}
        variant="outlined"
        color="primary"
        disableElevation={false}
        classes={{ ...classes, outlined: 'white-background' }}
        {...props}
      >
        Search This Area
      </Button>
    </Slide>
  );
}
