import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';

function CheckboxInput({ name, CheckboxProps = {}, ...props }) {
  const [field, meta] = useField(name);

  const isError = Boolean(meta.touched && meta.error);
  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox {...CheckboxProps} checked={field.value} />}
        {...field}
        {...props}
      />
      {isError && <FormHelperText error={isError}>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  CheckboxProps: PropTypes.shape({}),
};

CheckboxInput.defaultProps = {
  CheckboxProps: {},
};

export default React.forwardRef((props, ref) => <CheckboxInput innerRef={ref} {...props} />);
