import React from 'react';
import { Typography } from '@material-ui/core';

export default function ConfirmDetailsText() {
  return (
    <Typography variant="inherit" component="strong">
      By submitting this form I certify that the above information is accurate to the best of my
      knowledge
    </Typography>
  );
}
