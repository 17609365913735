import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Link as ExternalLink } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import FocusTrap from 'focus-trap-react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';

import { select, actions } from 'store/toolkit';
import useFocusAnchors, { useFocusTo } from 'utils/FocusRefContext';
import useReturnFocus from 'hooks/useReturnFocus';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px ${theme.spacing(1)}px ${theme.spacing(
      1.5
    )}px`,
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(37.125),
    minHeight: theme.spacing(8.2175),
    maxWidth: '95vw',
    borderRadius: 11,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    '& p': {
      fontSize: '.75rem',
    },
  },
  iconButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    padding: 0,
  },
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    ratingInfoButton: {
      '&:focus': {
        boxShadow: `0 0 10px ${theme.palette.common.white}`,
      },
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    ratingInfoButton: {
      '&:focus': {
        forcedColorAdjust: 'none',
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: '.375rem',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popUp: {
    zIndex: 1600,
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
  },
  link: {
    fontSize: 13,
    marginTop: '10px',
  },
}));

export default function TooltipInfo() {
  const [arrowRef, setArrowRef] = useState(null);
  const dispatch = useDispatch();

  const title = useSelector(select.tooltip.getTitle);
  const message = useSelector(select.tooltip.getMessage);
  const link = useSelector(select.tooltip.getLink);
  const open = useSelector(select.tooltip.isOpen);
  const linkPresent = useSelector(select.tooltip.linkPresent);
  const renderHref = useSelector(select.tooltip.renderHref);

  const focusTo = useFocusTo();
  const focusAnchors = useFocusAnchors();
  const { ref: closeButtonRef, returnFocus: focusClose } = useReturnFocus();

  const classes = useStyles();

  const closeModal = useCallback(
    (e) => {
      e?.stopPropagation();
      dispatch(actions.tooltip.close());
      focusTo.tooltip();
      focusAnchors.tooltip = null;
      focusAnchors.tooltipAnchor = null;
    },
    [dispatch, focusTo, focusAnchors]
  );

  const handKeyDown = (e) => {
    e?.stopPropagation();
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  // anchor tooltip to tooltipAnchor if it's specified, otherwise use the tooltip returnFocus ref
  const anchorEl = useMemo(
    () => focusAnchors.tooltipAnchor?.current || focusAnchors.tooltip?.current,
    [focusAnchors.tooltipAnchor, focusAnchors.tooltip]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('scroll', closeModal);
      document.addEventListener('wheel', closeModal);
    } else {
      document.removeEventListener('wheel', closeModal);
      document.removeEventListener('scroll', closeModal);
    }

    return () => {
      document.removeEventListener('wheel', closeModal);
      document.removeEventListener('scroll', closeModal);
    };
  }, [open, closeModal]);

  useEffect(() => {
    // refocus the close button if open, anytime the content of the tooltip changes
    if (open) {
      focusClose();
    }
  }, [open, focusClose, focusAnchors.tooltip]);

  return (
    <div>
      <Popper
        id="tooltip-popper"
        open={open}
        anchorEl={anchorEl}
        placement="top"
        className={classes.popUp}
        modifiers={{
          arrow: {
            enabled: Boolean(arrowRef),
            element: arrowRef,
          },
        }}
      >
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            escapeDeactivates: false,
            onDeactivate: closeModal,
            preventScroll: true,
          }}
        >
          <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className={classes.paper} onClick={(e) => e.stopPropagation()}>
              <IconButton
                aria-label="close"
                onClick={closeModal}
                onKeyDown={handKeyDown}
                className={`${classes.iconButton} button-close-icon`}
                ref={closeButtonRef}
              >
                <CloseIcon
                  style={{
                    fontSize: '1rem',
                  }}
                />
              </IconButton>

              <Typography variant="subtitle2" component="div" style={{ marginBottom: 5 }}>
                {title}
              </Typography>

              {Boolean(message) && <Typography>{message}</Typography>}

              {linkPresent && !renderHref && (
                <Typography variant="subtitle2" component="div" style={{ marginBottom: 5 }}>
                  <Link className={classes.link} to={link.to} onClick={closeModal}>
                    {link.label}
                  </Link>
                </Typography>
              )}

              {renderHref && (
                <Typography variant="subtitle2" component="div" style={{ marginBottom: 5 }}>
                  <ExternalLink
                    className={classes.link}
                    rel="noopener noreferrer"
                    href={renderHref.to}
                    onClick={closeModal}
                    target="_blank"
                  >
                    {renderHref.label}
                  </ExternalLink>
                </Typography>
              )}
            </div>

            <span className={classes.arrow} ref={setArrowRef} />
          </div>
        </FocusTrap>
      </Popper>
    </div>
  );
}
