import { useEffect } from 'react';

/**
 * This hook will call the callback function whenever the provided ref.current is resized
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 * @param {ReactNode} ref
 * @param {function} callback
 */
const useResizeObserver = (ref, callback) => {
  useEffect(() => {
    if (!ref?.current) return;
    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // eslint-disable-line consistent-return
  }, [ref, callback]);
};

export default useResizeObserver;
