import React from 'react';
import clsx from 'clsx';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';

import Tooltip from 'components/Tooltip';
import useThemeColor from 'hooks/useThemeColor';
import useIconNode from 'hooks/useIconNode';

function useStyles(colorProp, isInverted) {
  const getClasses = makeStyles((theme) => {
    let backgroundColor = colorProp;
    let color = theme.palette.getContrastText(backgroundColor);
    let border;

    if (isInverted) {
      backgroundColor = theme.palette.common.white;
      color = colorProp;
      border = `1px solid ${colorProp}`;
    }

    return {
      pillRoot: {
        backgroundColor,
        color,
        border,
        display: 'inline-flex',
        alignItems: 'center',
        columnGap: 4,
        padding: '3px 14px',
        borderRadius: 25,
      },
      tooltipIcon: {
        margin: 0,
        color: 'inherit',
      },
      textRoot: {
        fontSize: '.875rem',
        fontWeight: 'bold',
        margin: '0px 2px',
      },
      iconContainer: {
        color: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
          fontSize: theme.iconSizes.small, // sets the icon size if it's an SvgIcon
          height: theme.iconSizes.small, // sets the icon size for img tags
          width: theme.iconSizes.small, // sets the icon size for img
        },
      },
    };
  });

  return getClasses();
}

function Pill({ TooltipProps, icon, iconUrl, iconAlt, label, color, inverted, additionalClass }) {
  const themeColor = useThemeColor(color);
  const classes = useStyles(themeColor, inverted);
  const { message: tooltipMessage, title: tooltipTitle, href, link } = TooltipProps || {};

  const iconNode = useIconNode(iconUrl || icon, iconAlt);

  const hasTooltip = Boolean(TooltipProps?.message);

  return (
    <Tooltip
      title={tooltipTitle || label}
      message={tooltipMessage}
      href={href}
      link={link}
      additionalClasses={{
        root: clsx(`${classes.pillRoot}`, {
          [additionalClass]: Boolean(additionalClass),
        }),
        icon: classes.tooltipIcon,
      }}
      deactivated={!hasTooltip}
    >
      {Boolean(iconNode) && <div className={classes.iconContainer}>{iconNode}</div>}
      <span className={classes.textRoot}>{label}</span>
    </Tooltip>
  );
}

export default Pill;

export const pillProps = {
  TooltipProps: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    }),
    additionalClasses: PropTypes.shape({
      root: PropTypes.string,
      icon: PropTypes.string,
      clickTarget: PropTypes.string,
    }),
    icon: PropTypes.string,
    deactivated: PropTypes.bool,
  }),
  icon: PropTypes.node,
  iconUrl: PropTypes.string,
  iconAlt: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.string, // can be passed as a hex value OR as a property of the theme.palette object. Example: "primary.main" will resolve to theme.palette.primary.main
  additionalClass: PropTypes.string,
  inverted: PropTypes.bool,
};

export const defaultPillProps = {
  TooltipProps: {},
  icon: null,
  iconUrl: '',
  iconAlt: '',
  color: 'primary.main',
  additionalClass: '',
  inverted: false,
};
Pill.propTypes = pillProps;
Pill.defaultProps = defaultPillProps;
