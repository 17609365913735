import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ChatNoResults from 'components/Modals/ChatModal/ChatNoResults';
import Providers from 'components/Modals/ChatModal/Providers';
import ProfileSkeleton from 'components/ModernExperience/LoadingSkeletons/ProfileSkeleton';
import ChatExpandedSearchAlert from 'components/Modals/ChatModal/ChatExpandedSearchAlert';
import SpecialtyButtons from 'components/Modals/ChatModal/SpecialtyButtons';
import PromotionBySpecialty from './Promotion/PromotionBySpecialty';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ChatSearchPanelContent({ search = {}, label }) {
  const locationName =
    search.city && search.stateAbbreviation
      ? `${search.city}, ${search.stateAbbreviation}`
      : 'your location';

  if (search.isLoading) return <ProfileSkeleton />;
  if (search.error) return <ChatNoResults locationName={locationName} searchName={label} error />;
  if (!search.results.length)
    return (
      <>
        <ChatNoResults locationName={locationName} searchName={label} />
        <SpecialtyButtons search={search} />
      </>
    );

  return (
    <>
      <PromotionBySpecialty
        specialtyId={search.specialtyId}
        subspecialtyId={search.subspecialtyId}
      />
      {search.isExpanded && (
        <ChatExpandedSearchAlert searchName={label} newRadius={search.radius} />
      )}
      <Providers search={search} />

      <SpecialtyButtons search={search} />
    </>
  );
}

export default function ChatSearchTabPanel({ search = {}, label, hidden, ...props }) {
  const classes = useStyles();
  if (hidden) return null;

  return (
    <Box role="tabpanel" className={classes.root} {...props}>
      <ChatSearchPanelContent search={search} label={label} />
    </Box>
  );
}
ChatSearchPanelContent.propTypes = {
  search: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string.isRequired,
};

ChatSearchTabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  ...ChatSearchPanelContent.propTypes,
};
