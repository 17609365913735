import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M9.35413 2.5C9.35413 2.5 9.51278 5.77516 11.7197 5.77516C14.7446 5.77516 13.5706 3.69866 16.0243 3.69866C19.6556 3.69866 22 7.20651 22 12.8332C22 18.4598 17.1736 21.3895 13.5565 21.3895C9.93936 21.3895 9.65732 18.8159 7.00617 18.8159C6.12128 18.8159 4.82743 18.9499 4.82743 21.4635H2C2 12.4736 11.3496 20.1415 11.3496 12.1104C11.3496 7.49559 6.84752 6.16297 6.84752 2.5"
      fill="#4379D3"
    />
    <path
      d="M12.6757 13.5167C12.1857 17.0809 10.1586 16.4992 9.48167 17.3806C8.6003 18.5264 11.3079 20 13.8109 20C16.314 20 20.1074 17.3171 20.1074 14.5461C20.1074 11.7751 18.662 11.2533 16.7336 11.2533C14.8051 11.2533 12.8696 12.0959 12.6757 13.5131V13.5167Z"
      fill="#D5EDFF"
    />
  </>,
  'Gastroenterology'
);
