import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/styles';
import Layers from '@material-ui/icons/Layers';

import { select } from 'store/toolkit';
import Attribute from '../../Attribute';

function PlaceAttributeList({ placeId }) {
  const networkContent = useSelector(select.place(placeId).networkContent);
  const tierDesignation = useSelector(select.place(placeId).tierDesignation);
  const { palette } = useTheme();

  if (!networkContent?.showNetworkStatus) return null;

  return (
    <>
      <Attribute
        isListItem={false}
        text={networkContent.networkText}
        icon={networkContent.getIcon(palette)}
        TypographyProps={{
          style: {
            fontWeight: 'bold',
            color: palette.success.dark,
          },
        }}
      />
      {tierDesignation && (
        <Attribute
          isListItem={false}
          text={tierDesignation.text}
          TooltipProps={{ message: tierDesignation.tooltipMessage }}
          icon={<Layers />}
        />
      )}
    </>
  );
}

PlaceAttributeList.propTypes = {
  placeId: PropTypes.string.isRequired,
};

export default React.memo(PlaceAttributeList);
