import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Paper, Box, Typography, Divider, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';

import GiftIcon from 'icons/Gift';

import { select } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > p, $imageContainer, .MuiAlert-root': { marginBottom: theme.spacing(1) },
  },
  icon: { marginRight: theme.spacing(1) },
  imageContainer: {
    '& > img': { height: '100%', [theme.breakpoints.down('md')]: { maxWidth: 400 } },
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 350,
  },
  grey: { backgroundColor: theme.palette.grey[100] },
}));

export default function RewardsPage() {
  const classes = useStyles();

  const isMdDown = useSelector(select.ui.isMdDown);
  const {
    description,
    eligibilityImageDesktop,
    eligibilityImageMobile,
    claimButtonImageDesktop,
    claimButtonImageMobile,
  } = useSelector(select.rewards.campaign) || {};
  const userIsEligible = useSelector(select.rewards.userIsEligible);

  useUpdateTitle('Rewards');

  const eligibilityImage = isMdDown ? eligibilityImageMobile : eligibilityImageDesktop;
  const claimButtonImage = isMdDown ? claimButtonImageMobile : claimButtonImageDesktop;

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h1" color="primary">
        <GiftIcon classes={{ root: classes.icon }} />
        Rewards Program Overview
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Get rewarded for visiting a high-quality doctor within your network.
      </Typography>
      {!userIsEligible && <Alert severity="info">You have already claimed this reward.</Alert>}

      {Boolean(description) && (
        <>
          <Typography variant="srOnly" component="h2">
            Reward Criteria
          </Typography>
          <Typography paragraph>{description}</Typography>
        </>
      )}

      {Boolean(eligibilityImage) && (
        <Box className={classes.imageContainer}>
          <img
            src={eligibilityImage}
            alt="a screenshot with highlighted elements for matching reward criteria"
          />
        </Box>
      )}

      <Typography variant="srOnly" component="h2">
        How to redeem
      </Typography>
      <Typography paragraph>
        Once you have selected an eligible provider, navigate to their profile by clicking their
        name or the details button. From there, find the rewards button located at the top of the
        profile and click it to start your reward redemption form.
      </Typography>

      {Boolean(claimButtonImage) && (
        <Paper elevation={0} className={`${classes.imageContainer} ${classes.grey}`}>
          <img
            src={claimButtonImage}
            alt="a screenshot highlighting the button used to claim the reward"
          />
        </Paper>
      )}

      <Typography paragraph>
        After you submit your form, you will receive an email from tangocard.com with instructions
        to download your digital gift card. Enjoy this token of our gratitude for selecting a
        high-quality provider!
      </Typography>
      <Divider />
      <Typography variant="srOnly" component="h2">
        Troubleshooting
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Still have an unanswered question? Fill out a support ticket and we will get back to you
        shortly.
      </Typography>
      <Typography>
        Click{' '}
        <Link
          component={RouterLink}
          to="/support"
          aria-label="click here to submit a support ticket form"
        >
          here
        </Link>{' '}
        to submit a support ticket form.
      </Typography>
    </Container>
  );
}
