import React from 'react';
import PropTypes, { SubspecialtyDataShape } from 'propTypes';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SUBSPECIALTY_TYPES } from 'utils/constants';
import { select } from 'store/toolkit';
import Volume from 'icons/Volume';
import ScoreIcon from 'components/ModernExperience/ScoreIcon';
import ScoreTierIcon from 'components/ModernExperience/ScoreTierIcon';

const useStyles = makeStyles((theme) => ({
  doubleIconContainer: {
    display: 'flex',
    '& > *:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  highVolumeIcon: {
    width: (props) => props.size * 1.15,
    height: (props) => props.size * 1.15,
    color: theme.palette.info.main,
  },
}));

export default function FocusAreaTypeIcon({ subspecialty = {}, containerProps, size, ...props }) {
  const { subspecialtyScoreTier, subspecialtyType, subspecialtyScore } = subspecialty;
  const style = { height: size, width: size, fontSize: size / 2 };
  const classes = useStyles({ size });
  const showScores = useSelector(select.featureFlags.showDrScore);
  const showEmboldRecommended = useSelector(select.featureFlags.showEmboldRecommended);

  if (subspecialtyType === SUBSPECIALTY_TYPES.HIGH_VOLUME) {
    return (
      <Volume className={classes.highVolumeIcon} titleAccess="Volume Based Measure" {...props} />
    );
  }

  if (subspecialtyType === SUBSPECIALTY_TYPES.SCORED) {
    if (showEmboldRecommended && showScores) {
      return (
        <Box className={classes.doubleIconContainer} {...containerProps}>
          <ScoreTierIcon tier={subspecialtyScoreTier} style={style} {...props} />
          <ScoreIcon
            tier={subspecialtyScoreTier}
            score={subspecialtyScore}
            style={style}
            {...props}
          />
        </Box>
      );
    }

    if (showEmboldRecommended)
      return <ScoreTierIcon tier={subspecialtyScoreTier} style={style} {...props} />;

    if (showScores)
      return (
        <ScoreIcon
          tier={subspecialtyScoreTier}
          score={subspecialtyScore}
          style={style}
          {...props}
        />
      );
  }

  return null;
}

FocusAreaTypeIcon.propTypes = {
  subspecialty: SubspecialtyDataShape.isRequired,
  size: PropTypes.number,
  containerProps: PropTypes.shape({}),
};

FocusAreaTypeIcon.defaultProps = {
  containerProps: {},
  size: 36,
};
