import * as Yup from 'yup';

import { transformToUsaE164 } from 'utils/utils';
import usStates from 'utils/usStates';

const DESCRIPTION_MAX_CHARS = 65535; // dup description-max-chars

const validationSchema = Yup.object({
  firstName: Yup.string('Enter your first name')
    .min(2, 'First Name must be at least 2 characters')
    .required('First Name is required'),
  lastName: Yup.string('Enter your last name')
    .min(2, 'Last Name must be at least 2 characters')
    .required('Last Name is required'),
  phone: Yup.string('Enter your phone number').test(
    'valid-e164',
    'Must be a valid phone number',
    (value) => {
      const formattedValue = transformToUsaE164(value);
      return formattedValue !== null;
    }
  ),
  email: Yup.string('Enter your email')
    .max(150, 'Email must be under 150 characters')
    .email('Please enter a valid email')
    .required('Email is required'),
  subject: Yup.string('Please enter a ticket description')
    .max(255, 'Subject must be 255 characters or less')
    .required('Subject is required'),
  description: Yup.string('Enter a description')
    .min(3, 'Must be at least 3 characters')
    .max(DESCRIPTION_MAX_CHARS, `Description must be less that ${DESCRIPTION_MAX_CHARS} characters`)
    .required('Description is required'),
  city: Yup.string('Enter a city'),
  state: Yup.string('Enter the state').oneOf(usStates, 'Must be a valid state'),
  zip: Yup.string('Enter a Zip').matches(/^\d{5}$/, 'Must be a 5 digit zip'), // from https://regexlib.com/REDetails.aspx?regexp_id=2
});

export default validationSchema;
