import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Language as WebsiteIcon,
  PhoneIphone,
  PictureAsPdf,
  Chat,
  Email,
  GetApp,
} from '@material-ui/icons';

/**
 * @typedef {object} BenefitExternalLink
 * @property {string} href
 * @property {Node} Icon Should be a Material UI icon
 * @property {string} label
 * @property {string?} altText altText for icon
 */

/** @returns {BenefitExternalLink[]} */
export function getBenefitExternalLinks(benefit) {
  const links = [];

  // phone number
  if (benefit.phoneNumber) {
    links.push({
      href: `tel:${benefit.phoneNumber}`,
      Icon: PhoneIphone,
      label: `${benefit.phoneNumber}`,
      analyticsAction: _analyticsActions.phoneClickFromPromotionProfile,
    });
  }

  // website
  if (benefit.website) {
    links.push({
      href: benefit.website,
      Icon: WebsiteIcon,
      label: 'Visit the website',
      analyticsAction: _analyticsActions.websiteClickFromPromotionProfile,
    });
  }
  // app link

  if (benefit.accessAppLink) {
    links.push({
      href: benefit.accessAppLink,
      Icon: GetApp,
      label: 'Download the app',
      analyticsAction: _analyticsActions.accessAppLinkFromPromotionProfile,
    });
  }
  // chat link
  if (benefit.accessChatLink) {
    links.push({
      href: benefit.accessChatLink,
      Icon: Chat,
      label: 'Chat now',
      analyticsAction: _analyticsActions.accessChatLinkFromPromotionProfile,
    });
  }
  // email link
  if (benefit.accessEmail) {
    links.push({
      href: `mailto:${benefit.accessEmail}`,
      Icon: Email,
      label: 'Email',
      analyticsAction: _analyticsActions.accessEmailFromPromotionProfile,
    });
  }

  // pdf link
  if (benefit.pdfLink) {
    links.push({
      href: benefit.pdfLink,
      Icon: PictureAsPdf,
      label: 'View the PDF',
      altText: 'PDF', // PDF icon has text inside, and should have alt text
      analyticsAction: _analyticsActions.accessPDFfromPromotionProfile,
    });
  }

  return links;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    rowGap: theme.spacing(1.5),
  },
  linkContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1.5),
  },
  link: {
    fontSize: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  buttonIcon: {
    fontSize: 28,
  },
}));

export default function BenefitExternalLinks({ benefit, additionalClass }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const externalLinks = useMemo(() => getBenefitExternalLinks(benefit), [benefit]);

  const handleAnalyticsAction = (analyticsAction) => {
    if (analyticsAction) dispatch(analyticsAction(benefit.programName));
  };

  if (!externalLinks.length) return null;

  return (
    <Grid classes={{ root: clsx(classes.root, { [additionalClass]: additionalClass }) }}>
      <Grid classes={{ root: classes.linkContainer }}>
        {externalLinks.map((link) => (
          <Link
            href={link.href}
            target="_blank"
            rel="noreferrer"
            key={link.label}
            classes={{ root: classes.link }}
            underline="hover"
            onClick={() => handleAnalyticsAction(link.analyticsAction)}
          >
            <link.Icon color="secondary" className={classes.buttonIcon} alt={link.altText || ''} />
            <span>{link.label}</span>
          </Link>
        ))}
      </Grid>
    </Grid>
  );
}

BenefitExternalLinks.propTypes = {
  benefit: PropTypes.shape({
    website: PropTypes.string,
    accessAppLink: PropTypes.string,
    accessChatLink: PropTypes.string,
    pdfLink: PropTypes.string,
    programName: PropTypes.string,
  }).isRequired,
  additionalClass: PropTypes.string,
};

BenefitExternalLinks.defaultProps = {
  additionalClass: undefined,
};
