import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import { select } from 'store/toolkit';

import NoDataIcon from './NoDataIcon';

export default function ProviderPhoneNumberCell({ providerId }) {
  const dispatch = useDispatch();
  const { phone } = useSelector(select.provider(providerId).closestLocation) || {};

  const handleLinkClick = useCallback(() => {
    dispatch(_analyticsActions.phoneClickFromCompareModal(providerId));
  }, [dispatch, providerId]);

  if (!phone) return <NoDataIcon />;

  return (
    <Link onClick={handleLinkClick} href={`tel:${phone}`}>
      {phone}
    </Link>
  );
}

ProviderPhoneNumberCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
