import emboldWatermark from 'assets/pwrd-emboldhealth-footer.svg';

// Print layout derived from this article - https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
// <div>
//   <table>
//     <tbody><tr><td>
//       <div class="profile-wrap">{profileContent}</div>
//     </td></tr></tbody>
//     <tfoot><tr><td>
//       <div class="footer-spacer"></div>
//     </td></tr></tfoot>
//   </table>
//   <div class="profile-footer">{watermark}</div>
// </div>
export default function formatPrintProfile(ref) {
  const PrintElem = document.createElement('div');
  const Table = document.createElement('table');
  Table.style.width = '100%';
  const Tbody = document.createElement('tbody');
  const Tr = document.createElement('tr');
  const Td = document.createElement('td');
  const clonedRef = ref.current.cloneNode(true);
  clonedRef.classList.add('profile-wrap');
  const footerSpacer = `<tfoot><tr><td><div class="footer-spacer"></div></td></tr></tfoot>`;
  Table.innerHTML = footerSpacer;
  Td.appendChild(clonedRef);
  Tr.appendChild(Td);
  Tbody.appendChild(Tr);
  Table.appendChild(Tbody);
  const watermark = `<div class="profile-footer"><img src="${emboldWatermark}" alt="powered by embold health" class="watermark"/></div>`;
  PrintElem.innerHTML = watermark;
  PrintElem.appendChild(Table);
  return PrintElem;
}
