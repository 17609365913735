import { createSlice } from '@reduxjs/toolkit';
import { PROVIDER_RESULT_TYPE, PLACE_RESULT_TYPE } from 'utils/constants';
import { SPECIALTIES_SLICE_NAME } from '../slicesNames';

const specialtiesSlice = createSlice({
  name: SPECIALTIES_SLICE_NAME,
  initialState: {
    specialties: {
      [PROVIDER_RESULT_TYPE]: {},
      [PLACE_RESULT_TYPE]: {},
    },
    subspecialties: {
      [PROVIDER_RESULT_TYPE]: {},
      [PLACE_RESULT_TYPE]: {},
    },
    loadingSpecialties: false,
    failedToLoadSpecialties: false,
  },
  reducers: {
    setSpecialties: (state, action) => ({
      ...state,
      specialties: { ...action.payload },
    }),
    setSubspecialties: (state, action) => ({
      ...state,
      subspecialties: { ...action.payload },
    }),
    setLoadingSpecialties: (state, action) => ({
      ...state,
      loadingSpecialties: action.payload,
    }),
    setFailedToLoadSpecialties: (state, action) => ({
      ...state,
      failedToLoadSpecialties: action.payload,
    }),
  },
});

export default specialtiesSlice;
export const specialtiesActions = specialtiesSlice.actions;
