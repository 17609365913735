/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { slug as selectClientSlug } from 'store/slices/config/selectClient';
import { currentSlug as selectNetworkSlug } from 'store/slices/config/selectNetworks';
import { axiosInstance as selectAxios } from 'store/slices/config/selectAxios';
import { PROMOTIONS_SLICE_NAME } from '../slicesNames';

export const fetchPromotions = createAsyncThunk(
  `${PROMOTIONS_SLICE_NAME}/fetchPromotions`,
  async (args, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    const clientSlug = selectClientSlug(state);
    const networkSlug = selectNetworkSlug(state);

    const axios = selectAxios(state);
    const url = `/promotions/?client_slug=${clientSlug}&network_slug=${networkSlug}`;

    const response = await axios.get(url);
    return response.data;
  }
);
