import React from 'react';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import PaginationController from 'components/PaginationController';
import CardDivider from './StyledWrappers/CardDivider';

const useStyles = makeStyles(() => ({
  clusterHeaderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonRoot: {
    padding: '0.25rem',
  },
  spaceEvenly: {
    flex: 1,
    textAlign: 'right',
  },
}));

function ResultCardMapViewClusterHeader({
  index,
  setIndex,
  length,
  onClick,
  accessibleButtonLabel,
}) {
  const classes = useStyles();
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);

  return (
    <>
      <div className={classes.clusterHeaderWrap}>
        {/* dummy div for spacing */}
        <div className={classes.spaceEvenly} />
        <div>
          <PaginationController
            index={index}
            length={length}
            label={wasPlaceSearch ? 'Place' : 'Provider'}
            setIndex={setIndex}
          />
        </div>
        <div className={classes.spaceEvenly}>
          <IconButton
            classes={{ root: classes.buttonRoot }}
            onClick={onClick}
            aria-label={
              accessibleButtonLabel ||
              `close ${wasPlaceSearch ? 'place' : 'provider'} cluster detail slider`
            }
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <CardDivider />
    </>
  );
}

export default ResultCardMapViewClusterHeader;

ResultCardMapViewClusterHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  accessibleButtonLabel: PropTypes.string,
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
};

ResultCardMapViewClusterHeader.defaultProps = {
  accessibleButtonLabel: '',
};
