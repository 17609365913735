import { createTheme, alpha, lighten } from '@material-ui/core/styles';

import { getDefaultClassOverrides } from 'themes/getTheme/defaultTheme';

// using createTheme here will populate other dynamic color values
// then we can use some of those dynamic values elsewhere in this file
const { palette: quantumPalette } = createTheme({
  palette: {
    contrastThreshold: 4.5, // AA a11y standards for text contrast ratio
    tonalOffset: 0.3, // AA a11y standards for focus contrast ratio (buttons)

    primary: {
      main: '#5a8200',
    },
    secondary: {
      main: '#222222',
      dark: lighten('#222222', 0.1), // setting this to a lighter version so some contrast exist between .main and .dark.
      light: lighten('#222222', 0.3),
    },
    info: {
      main: '#0055b3',
    },
    success: {
      main: '#00875a',
    },
    warning: {
      main: '#ffcd00',
    },
    error: {
      main: '#e05d5d',
    },

    text: {
      primary: '#222222',
      secondary: '#464646',
    },

    /* **** Custom Colors **** */
    focusIndicator: '#3e5b00',

    tertiary: {
      dark: '#3e5b00',
      main: '#5a8200',
      light: '#7b9b33',
      contrastText: '#ffffff',
    },

    scoreTier: {
      1: '#005e3e',
      2: '#222222',
      3: '#9c4141',
    },
  },
});

function getQuantumClassOverrides(palette = quantumPalette) {
  const defaultClassOverrides = getDefaultClassOverrides(palette);
  return {
    ...defaultClassOverrides,
    MuiTypography: {
      colorPrimary: {
        color: palette.secondary.main,
      },
      colorSecondary: { color: palette.primary.main },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: palette.secondary.main,
      },
      colorSecondary: {
        color: palette.primary.main,
      },
    },
    MuiButton: {
      ...defaultClassOverrides.MuiButton,

      root: {
        ...defaultClassOverrides.MuiButton.root,
        '&.chat-button': {
          background: `linear-gradient(65deg, ${palette.secondary.main}, ${palette.primary.main} 20%)`,
          borderColor: palette.secondary.main,
        },
      },

      containedSecondary: {
        ...defaultClassOverrides.MuiButton.containedSecondary,

        // there are some specific buttons for quantum that we want to override from containedSecondary to containedPrimary (accept cookies, and the result card details)
        // for any button in the app that is marked as secondary but needs to flip to primary, give it a className and add that className in the selector below
        '&.card-details-button, &.cookie-accept-button': {
          ...defaultClassOverrides.MuiButton.containedPrimary,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
        },
      },

      /* Quantum never uses an outlined button with a green color - set this to mimic outlinedSecondary */
      outlinedPrimary: {
        ...defaultClassOverrides.MuiButton.outlinedSecondary,
        borderColor: palette.secondary.main,
        color: palette.secondary.main,
        '&:hover': {
          // this block was copied from https://github.com/mui/material-ui/blob/v4.12.3/packages/material-ui/src/Button/Button.js
          // from there it was modified to use the secondary color instead of primary
          border: `1px solid ${palette.secondary.main}`,
          backgroundColor: alpha(palette.secondary.main, palette.action.hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}

const quantumThemeVariant = {
  palette: quantumPalette,
  typography: {},
  overrides: getQuantumClassOverrides(),
  props: {},
};

export default quantumThemeVariant;
