import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Button, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

import { select, actions, constants } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';

import CheckFilterOption from './CheckFilterOption';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  filterLabel: {
    color: theme.palette.grey[700],
    '&.Mui-focused': {
      color: theme.palette.grey[900],
    },
    fontSize: 12,
  },
  multiSelectGrid: {
    '@supports (display: grid)': {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
    },
  },
  showMoreButton: {
    display: 'block',
    '& .MuiButton-root': {
      fontWeight: 'heavy',
      fontSize: '0.8em',
      color: theme.palette.secondary.main,
    },
  },
}));

function LoadingSkeletons({ length = 2 }) {
  const list = Array.from({ length }, (_, i) => i); // generates an array with a length ex: 3 => [0, 1, 2]
  return list.map((el) => (
    <Skeleton key={el} height={30} width="90%" animation="wave" aria-label="options loading" />
  ));
}

export default function CheckFilterGroup({ filter: filterKey, hideAfter }) {
  const classes = useStyles();
  const [hasHiddenOptions, setHasHiddenOptions] = useState(Boolean(hideAfter));
  const dispatch = useDispatch();

  // filter values
  const filter = useSelector(select.filters.byKey(filterKey));
  const { options = [], value: filterValueArray = [] } = filter;
  const optionsLoading = useSelector(select.filters.optionsLoading);

  // updates the filters value when an option is checked
  const handleChange = useCallback(
    (evt) => {
      dispatch(actions.filters.handleFilterChange({ key: filterKey, value: evt.target.value }));
    },
    [dispatch, filterKey]
  );

  // determines if the option is checked or not based on values in the filter.value array
  const optionIsChecked = useCallback(
    (optionValue) => filterValueArray.includes(optionValue),
    [filterValueArray]
  );

  const optionCheckboxes = options.map((opt, i) => (
    <CheckFilterOption
      key={opt.label}
      label={opt.label}
      value={opt.value}
      checked={optionIsChecked(opt.value)}
      onChange={handleChange}
      autoFocus={i === hideAfter}
    />
  ));

  /* ************************ */
  /* *** Show More Button *** */
  /* ************************ */

  // only render the show more button when there is a hideAfter prop, and there are more options than the hideAfter prop
  // example: if only 3 options are present and hideAfter=4, don't show the ShowMoreButton
  const renderShowMoreButton = Boolean(hideAfter) && options.length > hideAfter;
  const showMoreButton = (
    <div className={classes.showMoreButton}>
      <Typography variant="srOnly" role="status">
        {!hasHiddenOptions && `showing ${options.length - hideAfter} additional ${filterKey}`}
      </Typography>
      <Button onClick={() => setHasHiddenOptions((prev) => !prev)}>
        {hasHiddenOptions ? 'Show More' : 'Show Less'}
      </Button>
    </div>
  );

  // determines how many options to show, based on the state of hasHiddenOptions and the hideAfter prop
  const numOptionsToShow = hasHiddenOptions ? hideAfter || options.length : options.length;

  // show skeletons for our dynamic filters when the options are loading
  const showLoadingSkeletons = filter.isDynamic && optionsLoading;

  /* *************************** */
  /* *** Handle null returns *** */
  /* *************************** */
  if (!filter) {
    logDevMessage(
      `Filter "${filterKey}" does not exist. Check the filter prop passed to CheckFilterGroup`
    );
  }

  if (filter.type !== constants.filters.ARRAY_FILTER_TYPE) {
    // handle a filter key being passed for incorrect filter type. Ex: This switch should not be used on an array filter type
    logDevMessage(
      `Attempted to render a CheckFilterGroup for a filter that is not of type ${constants.filters.ARRAY_FILTER_TYPE}`
    );
    return null;
  }

  if (filter.disabled) return null;

  // if options have completed loading but there is less than two options, then we should not show this filter group
  // example: a search by specialty for primary care will only have 1 specialty option - primary care, no need to show this only option
  if (!showLoadingSkeletons && options.length < 2) return null;

  /* ******************* */
  /* *** Main return *** */
  /* ******************* */
  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend" className={classes.filterLabel}>
        {filter.label}
      </FormLabel>
      <FormGroup className={classes.multiSelectGrid}>
        {showLoadingSkeletons ? (
          <LoadingSkeletons length={hideAfter} />
        ) : (
          optionCheckboxes.slice(0, numOptionsToShow)
        )}
      </FormGroup>
      {renderShowMoreButton && showMoreButton}
    </FormControl>
  );
}

CheckFilterGroup.propTypes = {
  filter: PropTypes.string.isRequired,
  hideAfter: PropTypes.number,
};

CheckFilterGroup.defaultProps = {
  hideAfter: undefined,
};
