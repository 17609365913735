import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';
import { Collapse, Box, Typography, Grid, ListItem, ButtonBase } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import SectionSubheader from './SectionSubheader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}`,
    display: 'block',
  },
  toggleAccordion: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '&:focus': { outlineOffset: 2 },
  },
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'inherit',
    },
    '& > :nth-child(2)': { marginLeft: 8 },
  },
  iconWrap: {
    padding: 0,
    marginLeft: 10,
  },
  childrenWrapper: { marginTop: theme.spacing(1) },
}));

// Utilize the plainTextTitle prop only when the title prop is a Node rather than a string
function Accordion({
  title,
  icon,
  children,
  isOpen,
  initialOpenState,
  ariaId,
  isListItem,
  iconOnlyTrigger,
  plainTextTitle,
  CollapseProps,
  onClick,
}) {
  const classes = useStyles();

  const [isExpandedLocalState, setIsExpandedLocalState] = useState(initialOpenState);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsExpandedLocalState(isOpen);
    }
  }, [isOpen, setIsExpandedLocalState]);

  const triggerId = `${ariaId}-trigger`;
  const contentId = `${ariaId}-content`;

  const isExpandable = Boolean(children);

  const handleClick = useCallback(() => {
    setIsExpandedLocalState((prev) => !prev);
    if (typeof onClick === 'function') {
      // onClick can be used to fire additional functionality or for managing externally controlled state
      onClick();
    }
  }, [onClick]);

  const triggerA11yProps = useMemo(
    () =>
      isExpandable
        ? {
            component: ButtonBase,
            'aria-expanded': isExpandedLocalState,
            'aria-controls': contentId,
            id: triggerId,
            'aria-label': `${iconOnlyTrigger ? plainTextTitle : title} details`,
            onClick: handleClick,
          }
        : {},
    [
      contentId,
      isExpandedLocalState,
      triggerId,
      title,
      isExpandable,
      plainTextTitle,
      iconOnlyTrigger,
      handleClick,
    ]
  );
  const contentA11yProps = useMemo(
    () =>
      isExpandable
        ? {
            id: contentId,
            'aria-labelledby': triggerId,
          }
        : {},
    [contentId, triggerId, isExpandable]
  );

  const Component = isListItem ? ListItem : Box;
  const ChildrenWrapper = typeof children === 'string' ? Typography : Box;

  return (
    <Component classes={{ root: classes.root }}>
      <Grid
        container
        classes={{ root: classes.toggleAccordion }}
        {...(!iconOnlyTrigger && triggerA11yProps)}
      >
        <Grid item classes={{ root: classes.titleGrid }}>
          {Boolean(icon) && icon}
          <SectionSubheader component="span" variant="subtitle1">
            {title}
          </SectionSubheader>
        </Grid>

        {isExpandable && (
          <Box className={classes.iconWrap} {...(iconOnlyTrigger && triggerA11yProps)}>
            {isExpandedLocalState ? <RemoveIcon /> : <AddIcon />}
          </Box>
        )}
      </Grid>
      {isExpandable && (
        <Collapse in={isExpandedLocalState} {...contentA11yProps} {...CollapseProps}>
          <ChildrenWrapper className={classes.childrenWrapper}>{children}</ChildrenWrapper>
        </Collapse>
      )}
    </Component>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  ariaId: PropTypes.string,
  isOpen: PropTypes.bool,
  icon: PropTypes.node,
  isListItem: PropTypes.bool,
  iconOnlyTrigger: PropTypes.bool,
  plainTextTitle: PropTypes.string,
  CollapseProps: PropTypes.shape({}), // https://v4.mui.com/api/collapse/#props
  onClick: PropTypes.func,
  initialOpenState: PropTypes.bool,
};

Accordion.defaultProps = {
  children: null,
  icon: null,
  isOpen: undefined,
  ariaId: '',
  isListItem: false,
  iconOnlyTrigger: false,
  plainTextTitle: '',
  CollapseProps: {},
  onClick: undefined,
  initialOpenState: false,
};
