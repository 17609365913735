import React from 'react';
import PropTypes from 'propTypes';
import ProfileSectionTitle from './ProfileSectionTitle';
import RoundedWrapper from '../ResultCards/StyledWrappers/RoundedWrapper';

function ProfileSection({ children, TitleProps }) {
  return (
    <RoundedWrapper grey padBottom>
      <ProfileSectionTitle {...TitleProps} />
      {children}
    </RoundedWrapper>
  );
}

export default ProfileSection;

ProfileSection.propTypes = {
  children: PropTypes.node.isRequired,
  TitleProps: PropTypes.shape({ ProfileSectionTitle }.propTypes).isRequired,
};
