import { createContext, useContext } from 'react';
import { logDevMessage } from 'utils/utils';

const ContactCardContext = createContext();

export const ContactCardContextProvider = ContactCardContext.Provider;

export default function useContactCardContext() {
  const context = useContext(ContactCardContext);

  if (!context)
    logDevMessage(
      'useContactCardContext found no context. Make sure it was used in a component with a parent ContactCardContextProvider'
    );

  return context;
}
