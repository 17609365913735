import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0px`,
    width: '100%',
    minWidth: 220,
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default function CostEstimator() {
  const classes = useStyles();
  const COST_ESTIMATOR_LINK = useSelector(select.featureFlags.costEstimatorLink);

  if (!COST_ESTIMATOR_LINK) return null;

  return (
    <Button
      href={COST_ESTIMATOR_LINK}
      target="_blank"
      rel="noreferrer"
      color="primary"
      variant="contained"
      classes={classes}
      startIcon={<MonetizationOnIcon />}
    >
      Cost Estimator Tool
    </Button>
  );
}
