import React from 'react';
import { Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function RewardsLoadingSkeleton() {
  return (
    <Container maxWidth="md">
      <Skeleton width="60%" height={75} />
      <Skeleton width="100%" height={400} />
      <Skeleton variant="rect" width="100%" height={300} />
    </Container>
  );
}
