import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';

import { actions } from 'store/toolkit';

function BreakpointWatcher({ width }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.ui.breakpointChanged(width));
  }, [width, dispatch]);

  return null;
}

BreakpointWatcher.propTypes = {
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired, // provided by withWidth HOC
};

export default withWidth()(BreakpointWatcher);
