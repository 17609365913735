import React, { useEffect } from 'react';
import PropTypes from 'propTypes/index';
import { _analyticsActions } from 'analytics/index';
import { useSelector, useDispatch } from 'react-redux';

import { select } from 'store/toolkit';
import ServiceInfoContent from './ServiceInfoContent';

export default function ServiceInfo({ serviceId, mobileView }) {
  const dispatch = useDispatch();
  const hasCostEstimator = useSelector(select.featureFlags.costEstimatorLink);
  const service = useSelector(select.services.byId(serviceId));

  useEffect(() => {
    if (service) {
      dispatch(_analyticsActions.serviceResultView(service.serviceName));
    }
  }, [dispatch, service]);

  if (!service) return null;

  return (
    <ServiceInfoContent
      service={service}
      mobileView={mobileView}
      fullWidthServiceDescription={!hasCostEstimator}
    />
  );
}

ServiceInfo.propTypes = {
  serviceId: PropTypes.number.isRequired,
  mobileView: PropTypes.bool,
};

ServiceInfo.defaultProps = {
  mobileView: false,
};
