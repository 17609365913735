import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function SupportPageSection({ title, className, ...props }) {
  const classes = useStyles();

  // Add id to section used in hash routing. If none is provided use the title but strip spaces
  const id = props.id || title?.replaceAll(' ', '') || null;

  return (
    <section className={`${classes.root} ${className}`} id={id}>
      {Boolean(title) && <Typography variant="h2">{title}</Typography>}
      {props.children}
    </section>
  );
}

SupportPageSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

SupportPageSection.defaultProps = {
  children: null,
  className: '',
  id: undefined,
  title: undefined,
};
