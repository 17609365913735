import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Divider, Grid } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { uniqueId } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'space-between',
    '& .MuiToggleButton-label': {
      color: theme.palette.grey[700],
    },
  },
  block: { display: 'block' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  flexCol: { display: 'flex', flexDirection: 'column' },
  justifyStart: { justifyContent: 'start' },
  justifyCenter: { justifyContent: 'center' },
  justifyEnd: { justifyContent: 'end' },
  justifyEvenly: { justifyContent: 'space-evenly' },
  justifyBetween: { justifyContent: 'space-between' },
  alignStart: { alignItems: 'start' },
  alignCenter: { alignItems: 'center' },
  alignEnd: { alignItems: 'end' },
  wrap: { flexWrap: 'wrap' },
  nowrap: { flexWrap: 'nowrap' },
  wrapReverse: { flexWrap: 'wrap-reverse' },
}));

export default function StyleguideCanvas({
  name = '',
  children,
  description,
  canvasStyle,
  display,
  align: defaultAlign,
  justify: defaultJustify,
  wrap,
  showContainerOptions,
  canvasProps,
}) {
  const classes = useStyles();
  const [canvasDisplay, setCanvasDisplay] = useState(display);
  const [justify, setJustify] = useState(defaultJustify);
  const [align, setAlign] = useState(defaultAlign);
  const id = name.replaceAll(' ', '-');

  const changeDisplay = useCallback((evt, value) => setCanvasDisplay(value), [setCanvasDisplay]);
  const changeJustify = useCallback((evt, value) => setJustify(value), [setJustify]);
  const changeAlign = useCallback((evt, value) => setAlign(value), [setAlign]);

  return (
    <section id={id} className={classes.root}>
      <Typography variant="h2">{name}</Typography>

      {Boolean(description) &&
        description
          .split('\n')
          .map((p) => <Typography key={`description-${uniqueId()}`}>{p}</Typography>)}

      <Divider />

      <Box
        className={clsx({
          [classes.block]: canvasDisplay === 'block',
          [classes.flexRow]: canvasDisplay === 'row',
          [classes.flexCol]: canvasDisplay === 'col',
          [classes.justifyStart]: justify === 'start',
          [classes.justifyCenter]: justify === 'center',
          [classes.justifyEnd]: justify === 'end',
          [classes.justifyEvenly]: justify === 'evenly',
          [classes.justifyBetween]: justify === 'between',
          [classes.alignStart]: align === 'start',
          [classes.alignCenter]: align === 'center',
          [classes.alignEnd]: align === 'end',
          [classes.wrap]: wrap === 'wrap',
          [classes.nowrap]: wrap === 'nowrap',
          [classes.wrapReverse]: wrap === 'reverse',
        })}
        {...canvasProps}
        style={canvasStyle}
      >
        {children}
      </Box>
      {showContainerOptions && (
        <>
          <Divider />
          <Typography variant="caption">Container Display:</Typography>
          <Grid container className={classes.toolbar} spacing={1}>
            <Grid item>
              <ToggleButtonGroup
                size="small"
                exclusive
                value={canvasDisplay}
                onChange={changeDisplay}
              >
                <ToggleButton value="block">Block</ToggleButton>
                <ToggleButton value="col">Flex Column</ToggleButton>
                <ToggleButton value="row">Flex Row</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {canvasDisplay !== 'block' && (
              <>
                <Grid item>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={justify}
                    onChange={changeJustify}
                  >
                    <ToggleButton value="start">Start</ToggleButton>
                    <ToggleButton value="center">Center</ToggleButton>
                    <ToggleButton value="end">End</ToggleButton>
                    <ToggleButton value="between">Space Between</ToggleButton>
                    <ToggleButton value="evenly">Space Evenly</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item>
                  <ToggleButtonGroup size="small" exclusive value={align} onChange={changeAlign}>
                    <ToggleButton value="start">Start</ToggleButton>
                    <ToggleButton value="center">Center</ToggleButton>
                    <ToggleButton value="end">End</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </section>
  );
}

StyleguideCanvas.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  canvasProps: PropTypes.shape({}),
  canvasStyle: PropTypes.shape({}),
  showContainerOptions: PropTypes.bool,
  display: PropTypes.oneOf(['block', 'row', 'col']),
  justify: PropTypes.oneOf(['center', 'start', 'end', 'evenly', 'between']),
  align: PropTypes.oneOf(['start', 'center', 'end']),
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'reverse']),
};

StyleguideCanvas.defaultProps = {
  description: '',
  canvasProps: {},
  canvasStyle: {},
  showContainerOptions: false,
  display: 'block',
  justify: 'center',
  align: 'center',
  wrap: 'wrap',
};
