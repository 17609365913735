import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import Check from '@material-ui/icons/CheckCircleOutlineOutlined';
import X from '@material-ui/icons/HighlightOff';

export default function CheckOrXIcon({ checked, ...props }) {
  const { palette } = useTheme();

  if (checked) return <Check htmlColor={palette.success.dark} {...props} />;
  return <X htmlColor={palette.error.main} {...props} />;
}

CheckOrXIcon.propTypes = {
  checked: PropTypes.bool.isRequired,
};
