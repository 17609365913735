import React from 'react';
import 'whatwg-fetch';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  profileDetailIcon: {
    height: 20,
    width: 20,
    margin: '1px 5px 0 0',
  },
  sectionGridContainer: {
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
}));

/* eslint-disable import/prefer-default-export */
/** @deprecated */
export function GridIconRow({ id, img, Icon, noMargin, children }) {
  const classes = useStyles();
  return (
    <Grid
      xs={12}
      item
      container
      alignItems="flex-start"
      className={!noMargin ? classes.sectionGridContainer : ''}
    >
      <Grid item style={{ textAlign: 'center' }}>
        {img && (
          <img src={img} className={`${classes.profileDetailIcon} profile-icon-${id}`} alt="" />
        )}
        {Icon && <Icon className={`${classes.profileDetailIcon} profile-icon-${id}`} />}
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
}

GridIconRow.defaultProps = {
  noMargin: undefined,
  img: null,
  Icon: null,
};

GridIconRow.propTypes = {
  id: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  img: PropTypes.string,
  noMargin: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.shape({}).isRequired,
};
