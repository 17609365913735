import { sample } from 'lodash';

import { distance } from 'utils/utils';

export const specialtyMatch = (promotions, specialtyId, subspecialtyId) => {
  const subSpecialtyMatches = [];
  const specialtyMatches = [];
  promotions.forEach((promotion) => {
    const hasSpecialtyMatch =
      promotion.specialties?.some((specialty) => specialty.specialtyId === specialtyId) &&
      promotion.subspecialties.length === 0;

    if (subspecialtyId) {
      const hasSubspecialtyMatch = promotion.subspecialties?.some(
        (subspecialty) => subspecialty.subspecialtyId === subspecialtyId
      );

      if (hasSubspecialtyMatch) {
        subSpecialtyMatches.push(promotion);
      }
    }

    if (hasSpecialtyMatch) {
      specialtyMatches.push(promotion);
    }
  });

  if (subSpecialtyMatches.length > 0) {
    return subSpecialtyMatches;
  }

  return specialtyMatches;
};

export const keepOnlyHighest = (arr, key) => {
  const sorted = arr.sort((a, b) => b[key] - a[key]);
  const highestValue = sorted[0][key];
  return sorted.filter((a) => a[key] === highestValue);
};

export const sortFilterByWeight = (promotions) => keepOnlyHighest(promotions, 'weight');

export const addDistanceValuesAndSort = (promotions, globalLat, globalLong) => {
  if (!globalLong || !globalLat) return promotions;

  return promotions.map((promotion) => {
    if (promotion.locations?.length === 0) return promotion;

    return {
      ...promotion,
      locations: promotion.locations
        .map((loc) => {
          const distanceInMiles = +distance(loc.latitude, loc.longitude, globalLat, globalLong);
          return {
            ...loc,
            ...(!Number.isNaN(distanceInMiles) && { distanceInMiles }),
          };
        })
        .sort((a, b) => a.distanceInMiles - b.distanceInMiles),
    };
  });
};

export const getClosestPromotionsWithinRadius = (promotions) => {
  // remove promotions with no locations and radius
  const withLocation = promotions.filter(
    (promotion) => promotion.locations.length > 0 && promotion.radius
  );
  // If there are no promotions with a location and radius, return all so we can move on to the next step
  if (withLocation.length === 0) {
    return promotions;
  }

  // Check to see if the users location is within the radius of each promotions first location (locations already ordered)
  const withinRadius = withLocation.filter(
    (promotion) => promotion.locations[0].distanceInMiles <= promotion.radius
  );
  // If the users location is not within the radius of any promotion locations, return all so we can move on to the next step
  if (withinRadius.length === 0) {
    return promotions;
  }
  if (withinRadius.length === 1) {
    return withinRadius;
  }
  // If there are multiple promotions within radius, sort by distance of first location (locations already ordered)
  withinRadius.sort((a, b) => a.locations[0].distanceInMiles - b.locations[0].distanceInMiles);
  const shortestDistance = withinRadius[0].locations[0].distanceInMiles;
  return withinRadius.filter(
    (promotion) => promotion.locations[0].distanceInMiles === shortestDistance
  );
};

/**
 * This function accepts an array of promotions and determines the single promotion in the array that best matches the criteria provided by the options.
 * @param {object[]} promotions An array of promotion/benefit objects
 * @param {object} options An object that optionally contains a specialtyId and/or subspecialtyId
 * @returns {object||null} Either returns the best matching promotion in the array or null
 */
export function findBestMatchingPromotion(promotions, options = {}) {
  // return if no promotions were passed
  if (!Array.isArray(promotions) || promotions.length === 0) {
    return null;
  }

  const { specialtyId, subspecialtyId } = options;

  // 1 & 2. subspecialty and specialty match
  const specialtyMatchPromotions = specialtyMatch(promotions, specialtyId, subspecialtyId);
  if (specialtyMatchPromotions.length === 0) {
    return null;
  }
  if (specialtyMatchPromotions.length === 1) {
    return specialtyMatchPromotions[0];
  }

  // 3. primary override
  const primaryOverride = specialtyMatchPromotions.find((promotion) => promotion.isPrimary);
  if (primaryOverride) {
    return primaryOverride;
  }

  // 4. radius and distance
  const sortedByDistance = getClosestPromotionsWithinRadius(specialtyMatchPromotions);
  if (sortedByDistance.length === 1) {
    return sortedByDistance[0];
  }

  // 5. weight
  const highestWeightedPromotions = sortFilterByWeight(sortedByDistance);
  if (highestWeightedPromotions.length === 1) {
    return highestWeightedPromotions[0];
  }

  // multiple promotions are equally close
  // 6. randomly choose one
  return sample(highestWeightedPromotions);
}
