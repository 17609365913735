export const allBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

export const xlDownBreakpoints = allBreakpoints;
export const lgDownBreakpoints = allBreakpoints.slice(0, 4);
export const mdDownBreakpoints = allBreakpoints.slice(0, 3);
export const smDownBreakpoints = allBreakpoints.slice(0, 2);
export const xsDownBreakpoints = allBreakpoints.slice(0, 1);

export const xlUpBreakpoints = allBreakpoints.slice(4);
export const lgUpBreakpoints = allBreakpoints.slice(3);
export const mdUpBreakpoints = allBreakpoints.slice(2);
export const smUpBreakpoints = allBreakpoints.slice(1);
export const xsUpBreakpoints = allBreakpoints;

export const MOBILE_CONDENSED_VIEW = 'MOBILE_CONDENSED';
export const MOBILE_EXPANDED_VIEW = 'MOBILE_EXPANDED';
export const DESKTOP_SMALL_VIEW = 'DESKTOP_SMALL';
export const DESKTOP_CONDENSED_VIEW = 'DESKTOP_CONDENSED';
export const DESKTOP_EXPANDED_VIEW = 'DESKTOP_EXPANDED';
export const PROVIDER_CARD_VIEW_OPTIONS = [
  MOBILE_CONDENSED_VIEW,
  MOBILE_EXPANDED_VIEW,
  DESKTOP_SMALL_VIEW,
  DESKTOP_CONDENSED_VIEW,
  DESKTOP_EXPANDED_VIEW,
];

export const PROFILE_MODAL_TYPES = {
  FEEDBACK: 'feedback',
  SHARE: 'share',
  CALL: 'call',
  COST: 'cost',
  SPECIALTIES: 'specialties',
  INFO: 'info',
  SERVICE_COST: 'service_cost',
  FOCUS_AREAS: 'focus_areas',
  AFFILIATIONS: 'affiliations',
};

export const VALID_PROFILE_MODALS = Object.values(PROFILE_MODAL_TYPES);
