import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useStartOver from 'hooks/useStartOver';
import useLogout from 'hooks/useLogout';
import { actions, select, thunks } from 'store/toolkit';
import useFilterOptionsQuery from 'hooks/filters/useFilterOptionsQuery';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { SUPPORT_ROUTE } from '../../navigation/navigationConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  buttonContainer: {
    maxWidth: 400,
    margin: `${theme.spacing(4)}px auto ${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    '& > *': { marginBottom: theme.spacing(1) },
    '& :last-child': { marginBottom: 0 },
  },
}));

export default function NoResults({ additionalClass }) {
  const classes = useStyles();
  const startOver = useStartOver();
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  const isChatEnabled = useSelector(select.featureFlags.enableChat);
  const suggestedExpandedRadius = useSelector(select.results.suggestedExpandedRadius);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const specialtyId = useSelector(select.results.specialtyId);
  const subspecialtyId = useSelector(select.results.subspecialtyId);
  const specialty = useSelector(select.specialties.bySpecialtyId(specialtyId));
  const isMdDown = useSelector(select.ui.isMdDown);
  const errorMessage = useSelector(select.results.error);
  const hideSupport = useSelector(select.featureFlags.hideSupport);
  const totalAppliedFilters = useSelector(select.results.totalAppliedFilters); // The total number of filters applied to the result state
  const baseParamPredictedResults = useSelector(select.filters.baseParamPredictedResults);
  const showNetworkLogout = useSelector(select.featureFlags.showNetworkLogoutButton);
  const isError = Boolean(errorMessage);
  const baseQueryString = useFilterOptionsQuery();
  const handleLogout = useLogout();

  const handleOpenChat = useCallback(() => {
    dispatch(actions.ui.openModal('chat'));
  }, [dispatch]);

  const handleSpecialtySearch = useCallback(() => {
    searchDispatch(thunks.results.breadcrumbSearch(specialty));
  }, [searchDispatch, specialty]);

  const handleExpandedRadiusSearch = useCallback(
    () => searchDispatch(thunks.results.expandedRadiusSearch()),
    [searchDispatch]
  );

  const handleAdjustFilters = useCallback(
    () => dispatch(actions.ui.openModal('filter')),
    [dispatch]
  );

  const handleStartOver = useCallback(() => startOver('/results'), [startOver]);

  useEffect(() => {
    if (isMdDown) {
      // when no results are returned, on mobile we should auto-hide the map. The show/hide map button will render null
      dispatch(actions.ui.setShowResultsMap(false));
    }
  }, [isMdDown, dispatch]);

  useEffect(() => {
    // get number of results without any filters applied IF any filters are applied
    if (totalAppliedFilters > 0) {
      dispatch(
        thunks.filters.fetchPredictedResultCount({
          queryString: baseQueryString,
          stripQueryParams: true,
        })
      );
    }
  }, [dispatch, totalAppliedFilters, baseQueryString]);

  const buttonProps = useMemo(() => ({ variant: 'outlined', color: 'secondary' }), []);

  const searchEntity = wasPlaceSearch ? 'places' : 'providers';
  const showExpandSearch = Boolean(suggestedExpandedRadius);
  const showExpandToSpecialty = Boolean(subspecialtyId) && specialty;
  const showAdjustFilterButton = Boolean(totalAppliedFilters > 0 && baseParamPredictedResults > 0);

  const title = isError ? 'Failed To Complete The Search' : 'No Results';
  const subtitle =
    errorMessage ||
    `We were not able to find any ${searchEntity} that matched your search criteria.`;
  const textColor = isError ? 'error' : 'primary';

  return (
    <div className={`${classes.root} ${additionalClass}`}>
      <Typography variant="h2" color={textColor}>
        {title}
      </Typography>
      <Typography color="primary">{subtitle}</Typography>

      {showExpandSearch && (
        <Typography color="primary">
          However, we did find some a little father out, within {suggestedExpandedRadius} miles.
        </Typography>
      )}

      <div className={classes.buttonContainer}>
        {showNetworkLogout && (
          <Button {...buttonProps} onClick={handleLogout}>
            Change Your Network
          </Button>
        )}
        {isError && !hideSupport && (
          <Button {...buttonProps} component={RouterLink} to={SUPPORT_ROUTE}>
            Contact Support
          </Button>
        )}
        {showAdjustFilterButton && (
          <Button {...buttonProps} onClick={handleAdjustFilters}>
            Update Filters ({totalAppliedFilters} Active)
          </Button>
        )}
        <Button {...buttonProps} onClick={handleStartOver}>
          Start Over
        </Button>

        {showExpandSearch && (
          <Button {...buttonProps} onClick={handleExpandedRadiusSearch}>
            Expand Search ({suggestedExpandedRadius} miles)
          </Button>
        )}

        {showExpandToSpecialty && (
          <Button {...buttonProps} onClick={handleSpecialtySearch}>
            Search for {specialty.specialtyName}
          </Button>
        )}

        {isChatEnabled && (
          <Button {...buttonProps} onClick={handleOpenChat}>
            Ask Our Virtual Assistant
          </Button>
        )}
      </div>

      {/* TODO - Include text and a link here for "How to use {appName}" - per figma: https://www.figma.com/file/lILPXsEKyEfnx3tHBimSHg/Provider-Guide-Modern-Experience?type=design&node-id=2601-1231&mode=design&t=Pkxtfhe3VWLr6edK-0 */}
    </div>
  );
}

NoResults.propTypes = {
  additionalClass: PropTypes.string,
};

NoResults.defaultProps = {
  additionalClass: '',
};
