import React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import ResultProfile from 'components/ModernExperience/Profile/ResultProfile';
import ShowMoreButton from 'components/ShowMoreButton';
import ProfileTabLabel from 'components/ModernExperience/Profile/ProfileTabLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(1),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: { marginTop: theme.spacing(2) },
}));

export default function CollapsingProfile({ wrapper: Wrapper, isOpen, id, onClick }) {
  const classes = useStyles();
  const result = useSelector(select.results.byId(id));
  const name = result.entityName || result.name;
  const profileDetailsId = `${name}-details`;

  return (
    <Wrapper className={classes.root}>
      <div className={classes.header}>
        <ProfileTabLabel id={id} />
        <ShowMoreButton
          showMoreText="Details"
          showLessText="Hide"
          isOpen={isOpen}
          onClick={() => onClick(id)}
          ariaControls={profileDetailsId}
          ariaLabel={name}
        />
      </div>

      <Collapse in={isOpen} unmountOnExit mountOnEnter id={profileDetailsId}>
        <div className={classes.spacer} />
        <ResultProfile id={id} />
      </Collapse>
    </Wrapper>
  );
}

CollapsingProfile.propTypes = {
  wrapper: PropTypes.elementType,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

CollapsingProfile.defaultProps = {
  wrapper: Box,
};
