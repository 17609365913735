import { configureStore } from '@reduxjs/toolkit';

import { getEnv } from 'utils/utils';
import { localStorageMiddleware } from './middleware/localStorage/localStorageMiddleware';

import specialtiesSlice from './slices/specialties/specialtiesSlice';
import promotionsSlice from './slices/promotions/promotionsSlice';
import filtersSlice from './slices/filters/filtersSlice';
import mapSlice from './slices/map/mapSlice';
import locationSlice from './slices/location/locationSlice';
import uiSlice from './slices/ui/uiSlice';
import resultsSlice from './slices/results/resultsSlice';
import searchSlice from './slices/search/searchSlice';
import configSlice from './slices/config/configSlice';
import tooltipSlice from './slices/tooltip/tooltipSlice';
import notificationsSlice from './slices/notifications/notificationsSlice';
import servicesSlice from './slices/services/servicesSlice';
import chatSlice from './slices/chat/chatSlice';
import bannerSlice from './slices/banner/bannerSlice';
import rewardsSlice from './slices/rewards/rewardsSlice';
import authSlice from './slices/auth/authSlice';
import costSlice from './slices/cost/costSlice';

import analyticsMiddleware from '../analytics/analyticsMiddleware';

const store = configureStore({
  reducer: {
    [specialtiesSlice.name]: specialtiesSlice.reducer,
    [tooltipSlice.name]: tooltipSlice.reducer,
    [promotionsSlice.name]: promotionsSlice.reducer,
    [filtersSlice.name]: filtersSlice.reducer,
    [mapSlice.name]: mapSlice.reducer,
    [locationSlice.name]: locationSlice.reducer,
    [uiSlice.name]: uiSlice.reducer,
    [resultsSlice.name]: resultsSlice.reducer,
    [searchSlice.name]: searchSlice.reducer,
    [configSlice.name]: configSlice.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [servicesSlice.name]: servicesSlice.reducer,
    [chatSlice.name]: chatSlice.reducer,
    [bannerSlice.name]: bannerSlice.reducer,
    [rewardsSlice.name]: rewardsSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [costSlice.name]: costSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    localStorageMiddleware.middleware, // middleware to affect localStorage
    analyticsMiddleware,
  ],
  devTools: getEnv() !== 'production',
});

export default store;
