import { select } from 'store/toolkit';

import { RESULT_VIEW } from './analyticsConstants';
import { getChatProviderAttributes, getCostEventData } from './analyticsUtils';
import selectResultAnalyticsAttributes from './selectAnalytics';

/* ********************************** */
/* *** 200-299 Result View Events *** */
/* ********************************** */

export default {
  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.profileView(providerId));
   */
  profileView: {
    code: 200,
    category: RESULT_VIEW,
    description: 'A user has viewed a results profile page. Can be a place or provider result.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Profile View`,
        eventLabel: `${resultType} View`,
      };
    },
    labelDescription: 'Profile View',
    actionDescription: '<Place|Provider> View',
  },

  compareView: {
    code: 201,
    category: RESULT_VIEW,
    description:
      'A user has opened the compare modal. We send a list of provider or place ids for the compare table results',
    getEventData: (state) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const profileIds = select.results.compareListIds(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        profileIds,
        eventAction: `Compare View`,
        eventLabel: `${resultType} Compare View`,
      };
    },
    labelDescription: 'Compare View',
    actionDescription: '<Place|Provider> Compare View',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.promotionResultView(promotion.programName));
   */
  promotionResultView: {
    code: 202,
    category: RESULT_VIEW,
    description:
      'A promotion has been rendered above the results list based on the search results. The user has not interacted with the promotion at this point.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Promote Result View`,
      eventLabel: `Promote Result View`,
    }),
    labelDescription: 'Promote Result View',
    actionDescription: 'Promote Result View',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.promotionProfileView(promotion.programName));
   */
  promotionProfileView: {
    code: 203,
    category: RESULT_VIEW,
    description:
      'A user has viewed a promotion profile. This can occur from expanding it in the results list.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Promote Profile View`,
      eventLabel: `Promote Profile View`,
    }),
    labelDescription: 'Promote Profile View',
    actionDescription: 'Promote Profile View',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.promotionProfileView(promotion.programName));
   */
  landingPagePromotionClick: {
    code: 204,
    category: RESULT_VIEW,
    description:
      'A user has clicked on a promotion on the landing page to view the promotion profile modal',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Promote Profile View`,
      eventLabel: `Promote Profile View`,
    }),
    labelDescription: 'Promote Profile View',
    actionDescription: 'Promote Profile View',
  },

  /**
   * This action accept a service serviceName string to send to the event.
   * @param {string} action Expected payload: Service serviceName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.serviceResultView(service.serviceName));
   */
  serviceResultView: {
    code: 205,
    category: RESULT_VIEW,
    description:
      'A service has been rendered above the results list based on the search results. The user has not interacted with the service at this point.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: 'Service Result View',
      eventLabel: 'Service Result View',
    }),
    labelDescription: 'Service Result View',
    actionDescription: 'Service Result View',
  },

  /**
   * This action accept a service serviceName string to send to the event.
   * @param {string} action Expected payload: Service serviceName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.serviceResultView(service.serviceName));
   */
  serviceProfileView: {
    code: 206,
    category: RESULT_VIEW,
    description:
      'A user has viewed a service profile. This can happen by expanding the service in the results list',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: 'Service Profile View',
      eventLabel: 'Service Profile View',
    }),
    labelDescription: 'Service Profile View',
    actionDescription: 'Service Profile View',
  },

  /**
   * This action expects an object with the location coordinates object of the chat and the provider object.
   * @param {object} action Expected payload: {coordinates: {latitude: <>, longitude: <>}, providerData: providerObject}
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.chatProfileView({coordinates: coords, providerData: provider}));
   */
  chatProfileView: {
    code: 207,
    category: RESULT_VIEW,
    description: 'A user has viewed a provider profile in the chat.',
    getEventData: (state, { payload = {} }) => {
      const providerAttributes = getChatProviderAttributes(payload);
      return {
        ...providerAttributes,
        eventAction: 'Profile View',
        eventLabel: 'Chat Provider View',
      };
    },
    labelDescription: 'Profile View',
    actionDescription: 'Chat Profile View',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.compareProfileView(providerId));
   */
  compareProfileView: {
    code: 208,
    category: RESULT_VIEW,
    description: 'Each result profile that is displayed in a compare table.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Profile View`,
        eventLabel: `${resultType} View`,
      };
    },
    labelDescription: 'Profile View',
    actionDescription: '<Place|Provider> View',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.mapMarkerView(markerProperties));
   */
  mapMarkerView: {
    code: 209,
    category: RESULT_VIEW,
    description: 'A user has viewed a result card on the map',
    getEventData: (state, { payload }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Map Result Card View`,
        eventLabel: `${resultType} View`,
      };
    },
    labelDescription: 'Map Result Card View',
    actionDescription: '<Place|Provider> View',
  },

  /**
   * This action expects a provider id to collect relevant attributes.
   * @param {string} action Expected payload: { npi, locationId, view, serviceId, data, userHasMetDeductible, isError }
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.providerServiceCostView(payload));
   */
  providerServiceCostView: {
    code: 210,
    category: RESULT_VIEW,
    description: 'A user has viewed a service cost table from a provider location',
    getEventData: getCostEventData,
    labelDescription: 'Provider Service Cost <Modal|Accordion> View',
    actionDescription: 'Provider Service Cost View',
  },
};
