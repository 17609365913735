import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M12.0079 17.9006L12.0047 13.8832C12.0047 13.4657 12.3425 13.1191 12.7689 13.1191C13.007 13.1191 13.2234 13.2341 13.3738 13.4135C13.6698 13.7746 14.1179 14.0038 14.615 14.003C15.4959 14.0022 16.2166 13.2816 16.2158 12.4017C16.2158 11.5145 15.4934 10.8011 14.6126 10.8019C14.1155 10.8019 13.6674 11.0328 13.3722 11.3875C13.2217 11.5749 13.0054 11.6826 12.7672 11.6834C12.3409 11.6834 12.0022 11.3448 12.0022 10.9194L11.999 6.90995L16.0163 6.90674C16.4346 6.90674 16.7813 6.56732 16.7805 6.14185C16.7805 5.90298 16.6655 5.68662 16.4837 5.53622C16.1233 5.24105 15.8916 4.8011 15.8916 4.296C15.8908 3.4161 16.6116 2.69465 17.4924 2.69385C18.3789 2.69304 19.0932 3.41369 19.094 4.29359C19.0948 4.79868 18.864 5.23944 18.5116 5.53461C18.3234 5.68582 18.2156 5.90217 18.2156 6.14024C18.2164 6.56652 18.5551 6.90432 18.9814 6.90432L22.9907 6.90111L22.9995 17.8918H13.3191V17.8998H12.0079V17.9006Z"
      fill="#FB8943"
    />
    <path
      d="M6.49972 13.6887C5.61245 13.6887 4.89812 14.403 4.89812 15.2901C4.89812 15.7879 5.12899 16.2351 5.48213 16.5231C5.66956 16.6743 5.77816 16.8906 5.77816 17.1287C5.77816 17.5542 5.43949 17.9 5.01395 17.9H1.00391V6.91016H5.01395C5.43949 6.91016 5.77816 7.24876 5.77816 7.67423C5.77816 7.91231 5.66956 8.12866 5.48213 8.27987C5.12899 8.57585 4.89812 9.02304 4.89812 9.52009C4.89812 10.4 5.61245 11.1214 6.49972 11.1214C7.387 11.1214 8.10133 10.4 8.10133 9.52009C8.10133 9.02223 7.87046 8.57504 7.51008 8.27987C7.32989 8.12866 7.21405 7.91231 7.21405 7.67423C7.21405 7.24876 7.55995 6.91016 7.97825 6.91016H11.9955V10.9196C11.9955 11.345 12.3342 11.6836 12.7597 11.6836C12.9979 11.6836 13.2142 11.5759 13.3655 11.3877C13.6615 11.0346 14.1007 10.8037 14.6059 10.8037C15.4859 10.8037 16.2075 11.518 16.2075 12.4051C16.2075 13.2922 15.4859 14.0064 14.6059 14.0064C14.1007 14.0064 13.6607 13.7756 13.3655 13.4153C13.2142 13.2351 12.9979 13.1193 12.7597 13.1193C12.3342 13.1193 11.9955 13.4651 11.9955 13.8834V17.9H7.97825C7.55995 17.9 7.21405 17.5542 7.21405 17.1287C7.21405 16.8906 7.32908 16.6743 7.51008 16.5231C7.87046 16.2343 8.10133 15.7871 8.10133 15.2901C8.10133 14.403 7.37976 13.6887 6.49972 13.6887Z"
      fill="#FFC75A"
    />
  </>,
  'Pediatrics'
);
