import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select, thunks } from 'store/toolkit';

import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { Breadcrumbs, LoadingStatusMessage } from './SearchBreadcrumbComponents';

function SearchBreadcrumbs() {
  const searchDispatch = useSearchDispatchWithHistory();
  const isSmDown = useSelector(select.ui.isSmDown);
  const showMap = useSelector(select.ui.showResultsMap);
  const visible = useSelector(select.results.listLength);
  const total = useSelector(select.results.count);
  const searchTerm = useSelector(select.results.searchText);
  const isLoading = useSelector(select.results.isLoading);
  const parentSpecialty = useSelector(select.results.parentSpecialty);

  const ariaHidden = useMemo(() => isSmDown && showMap, [isSmDown, showMap]);

  const onClick = useCallback(() => {
    searchDispatch(
      thunks.results.breadcrumbSearch({
        specialtyName: parentSpecialty?.specialtyName,
        specialtyId: parentSpecialty?.specialtyId,
      })
    );
  }, [searchDispatch, parentSpecialty]);

  if (isLoading) return <LoadingStatusMessage />;
  if (!searchTerm) return <div />; /* spacing div */

  return (
    <Breadcrumbs
      ariaHidden={ariaHidden}
      visible={visible}
      total={total}
      searchTerm={searchTerm}
      onClick={onClick}
      parentSpecialty={parentSpecialty}
    />
  );
}

export default SearchBreadcrumbs;
