import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <circle
      cx="12.2061"
      cy="12.2061"
      r="7.92388"
      transform="rotate(45 12.2061 12.2061)"
      fill="#D0DF00"
    />
    <path
      d="M21.3354 6.18261L18.0036 9.51439C17.8869 9.63106 17.858 9.80956 17.93 9.95418C18.3749 10.8503 18.1988 11.929 17.4858 12.642C16.5758 13.5521 15.1018 13.556 14.1966 12.6508C13.7866 12.2408 13.545 11.6932 13.5206 11.114C13.5108 10.9175 13.3535 10.7602 13.157 10.7504C12.5779 10.7261 12.0329 10.4871 11.6228 10.077C10.7176 9.17181 10.7216 7.69788 11.6316 6.78783C12.3394 6.08001 13.413 5.89872 14.3065 6.3411C14.4512 6.41312 14.6297 6.3842 14.7463 6.26753L18.0834 2.93056"
      fill="#8EC642"
    />
    <path
      d="M2.90171 18.1276L6.40943 14.6199C6.52611 14.5032 6.55503 14.3247 6.48301 14.1801C6.03805 13.284 6.21418 12.2053 6.92719 11.4922C7.83724 10.5822 9.31117 10.5783 10.2164 11.4835C10.6264 11.8935 10.868 12.4411 10.8924 13.0202C10.9022 13.2167 11.0595 13.374 11.256 13.3839C11.8351 13.4082 12.3801 13.6472 12.7902 14.0573C13.6954 14.9625 13.6914 16.4364 12.7814 17.3465C12.0736 18.0543 11 18.2356 10.1065 17.7932C9.96184 17.7212 9.78334 17.7501 9.66667 17.8668L6.15376 21.3797"
      fill="#8EC642"
    />
  </>,
  'OrthoJoint'
);
