import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M4.11044 12.1158L10.9033 18.9164C11.1701 19.1834 11.603 19.1834 11.8698 18.9164L18.6626 12.1158C20.5781 10.1983 20.2274 7.00055 17.9418 5.54371C15.1801 3.7833 11.5501 5.68528 11.4256 8.95803L11.3865 9.98386L11.3475 8.95803C11.223 5.68528 7.59303 3.7833 4.83125 5.54371C2.54572 7.00055 2.195 10.1983 4.11044 12.1158Z"
      fill="#F03D3D"
      stroke="#F03D3D"
      strokeWidth="1.53675"
    />
    <path
      d="M13.0846 14.2981L16.4886 17.6803C16.6495 17.8402 16.9093 17.8402 17.0702 17.6803L20.4742 14.2981C21.4566 13.322 21.2775 11.6862 20.1072 10.9458C18.7025 10.0572 16.8629 11.0247 16.7992 12.6857L16.7794 13.2031L16.7596 12.6857C16.6959 11.0247 14.8563 10.0572 13.4516 10.9458C12.2813 11.6862 12.1022 13.322 13.0846 14.2981Z"
      fill="#FF7B73"
      stroke="#FF7B73"
      strokeWidth="1.53675"
    />
  </>,
  'Cardiology'
);
