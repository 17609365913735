import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

export default function MedicalSchoolCell({ providerId }) {
  const { medicalSchool } = useSelector(select.provider(providerId).data) || {};

  if (!medicalSchool) return <NoDataIcon />;

  return medicalSchool;
}

MedicalSchoolCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
