import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import { uniqueId } from 'lodash';

import Attribute from '../Attribute';

export function getBenefitAttributeList(benefit = {}) {
  const list = [];

  // add three badge properties
  if (benefit.badge1Text) {
    list.push({ text: benefit.badge1Text, icon: benefit.badge1 });
  }
  if (benefit.badge2Text) {
    list.push({ text: benefit.badge2Text, icon: benefit.badge2 });
  }
  if (benefit.badge3Text) {
    list.push({ text: benefit.badge3Text, icon: benefit.badge3 });
  }

  // add telehealth attribute
  if (benefit.telehealthAvailable) {
    list.push({ text: 'Telehealth' });
  }

  return list;
}

export default function BenefitAttributeList({ benefit, ...props }) {
  const attributeList = useMemo(() => getBenefitAttributeList(benefit), [benefit]);

  if (!attributeList.length) return null;

  return (
    <List disablePadding {...props}>
      {attributeList.map((attribute) => (
        <Attribute key={uniqueId()} {...attribute} />
      ))}
    </List>
  );
}

BenefitAttributeList.propTypes = {
  benefit: PropTypes.shape({
    costText: PropTypes.string,
    costIcon: PropTypes.string,
    badge1Text: PropTypes.string,
    badge1: PropTypes.string,
    badge2Text: PropTypes.string,
    badge2: PropTypes.string,
    badge3Text: PropTypes.string,
    badge3: PropTypes.string,
    telehealthAvailable: PropTypes.bool,
  }).isRequired,
};
