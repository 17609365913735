import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { thunks, select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  caption: { marginTop: theme.spacing(1), textAlign: 'center' },
}));

export default function ChatProviderSearchTool() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isPerformingAllSearches = useSelector(select.chat.isPerformingAllSearches);

  const dispatchThunk = useCallback(() => dispatch(thunks.chat.performAllSearches()), [dispatch]);

  const buttonProps = { size: 'small', variant: 'contained' };
  return (
    <Grid container justifyContent="center">
      <Grid item component={Button} onClick={dispatchThunk} color="primary" {...buttonProps}>
        {isPerformingAllSearches && <CircularProgress size={12} />} Dispatch{' '}
        {thunks.chat.performAllSearches.typePrefix}
      </Grid>
      <Grid item component={Typography} variant="caption" className={classes.caption}>
        *This will perform a search using the values currently in state
      </Grid>
    </Grid>
  );
}
