import React from 'react';
import PropTypes from 'propTypes';
import { Skeleton as SkeletonRoot } from '@material-ui/lab';
import { Grid, makeStyles, withStyles } from '@material-ui/core';
import { DESKTOP_EXPANDED_VIEW, PROVIDER_CARD_VIEW_OPTIONS } from 'store/slices/ui/uiConstants';

import RoundedWrapper from '../ResultCards/StyledWrappers/RoundedWrapper';

const useStyles = makeStyles(() => ({
  mobileTopRow: {
    '& > div:first-child': {
      paddingRight: 10,
    },
  },
  bottomRow: {
    '& > div': {
      paddingLeft: 10,
      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end',
  },
  rowGap15: {
    rowGap: 15,
  },
  rowGap10: {
    rowGap: 10,
  },
  rowGap5: {
    rowGap: 5,
  },
  columnGap5: {
    columnGap: 5,
  },
}));

const Skeleton = withStyles(() => ({
  root: {
    maxWidth: '100%',
    borderRadius: 8,
  },
}))(SkeletonRoot);

export function ResultCardSkeletonDesktop({ view }) {
  const classes = useStyles();
  return (
    <RoundedWrapper>
      <Grid container direction="column" classes={{ root: classes.rowGap15 }}>
        {/* top row */}
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Skeleton variant="rect" width={200} height={20} />
          </Grid>
          <Grid
            item
            xs={6}
            classes={{ root: `${classes.flexRow} ${classes.justifyFlexEnd} ${classes.columnGap5}` }}
          >
            <Skeleton variant="rect" width={80} height={20} />
            <Skeleton variant="rect" width={80} height={20} />
          </Grid>
        </Grid>
        {/* bottom row */}
        <Grid container classes={{ root: classes.bottomRow }}>
          {/* left column */}
          <Grid
            item
            sm={view === DESKTOP_EXPANDED_VIEW ? 4 : 6}
            classes={{ root: `${classes.flexColumn} ${classes.rowGap15}` }}
          >
            <Skeleton variant="rect" width={320} height={40} />
            <Grid container direction="column" classes={{ root: classes.rowGap5 }}>
              <Skeleton variant="rect" width={115} height={20} />
              <Skeleton variant="rect" width={140} height={20} />
              <Skeleton variant="rect" width={190} height={20} />
            </Grid>
            <Skeleton variant="rect" width={80} height={20} />
          </Grid>
          {/* middle column */}
          <Grid
            item
            sm={view === DESKTOP_EXPANDED_VIEW ? 4 : 6}
            classes={{ root: `${classes.flexColumn} ${classes.rowGap10}` }}
          >
            <Skeleton variant="rect" width="100%" height={55} />
            <Skeleton variant="rect" width="100%" height={55} />
            <Skeleton variant="rect" width="100%" height={55} />
          </Grid>
          {view === DESKTOP_EXPANDED_VIEW && (
            <Grid item sm={4}>
              <Skeleton variant="rect" height={190} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </RoundedWrapper>
  );
}

ResultCardSkeletonDesktop.propTypes = {
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};

export function ResultCardSkeletonMobile() {
  const classes = useStyles();
  return (
    <RoundedWrapper>
      <Grid container direction="column" classes={{ root: classes.rowGap15 }}>
        {/* top row */}
        <Grid container classes={{ root: classes.mobileTopRow }}>
          <Grid item xs={9} classes={{ root: `${classes.flexColumn} ${classes.rowGap15}` }}>
            <Skeleton variant="rect" width={100} height={20} />
            <Skeleton variant="rect" width={235} height={40} />
            <Grid container direction="column" classes={{ root: classes.rowGap5 }}>
              <Skeleton variant="rect" width={115} height={20} />
              <Skeleton variant="rect" width={140} height={20} />
              <Skeleton variant="rect" width={190} height={20} />
            </Grid>
          </Grid>
          <Grid xs={3} item classes={{ root: `${classes.flexColumn} ${classes.rowGap5}` }}>
            <Skeleton variant="rect" width={80} height={20} />
            <Skeleton variant="rect" width={80} height={20} />
          </Grid>
        </Grid>
        <Grid classes={{ root: `${classes.flexColumn} ${classes.rowGap10}` }}>
          <Skeleton variant="rect" width="100%" height={55} />
          <Skeleton variant="rect" width="100%" height={55} />
          <Skeleton variant="rect" width="100%" height={55} />
        </Grid>
      </Grid>
    </RoundedWrapper>
  );
}
