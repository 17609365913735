import React from 'react';
import { FilterMenuProps } from 'propTypes/index';
import { Typography, Chip, FormGroup } from '@material-ui/core';

import SwitchFilter from 'components/Results/Filters/SwitchFilter';
import RadiusSlider from './RadiusSlider';

function PlaceFilterMenu({ classes, chipOpen, handleCloseChip }) {
  return (
    <>
      <FormGroup row component="fieldset">
        <Typography component="legend" variant="srOnly">
          Facility Filters
        </Typography>
        <SwitchFilter filter="featuredFacility" />
      </FormGroup>

      {/* @TODO duplicate code RadiusSlider with label and chip */}
      <div>
        <Typography className={classes.distanceLabel} component="strong">
          Distance in Miles{' '}
          {chipOpen && ': ' && (
            <Chip
              className={classes.chip}
              label="CUSTOM"
              aria-label="Custom search area"
              onDelete={handleCloseChip}
            />
          )}
        </Typography>
        <div className={classes.sliderContainer}>
          <RadiusSlider chipOpen={chipOpen} />
        </div>
      </div>
    </>
  );
}

PlaceFilterMenu.propTypes = FilterMenuProps;

export default PlaceFilterMenu;
