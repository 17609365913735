import React from 'react';
import PropTypes from 'propTypes/index';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import ErrorIcon from '@material-ui/icons/Error';

function SharePageError({ classes }) {
  const shareType = useSelector(select.results.shareType) || 'result';
  const errorMessage = useSelector(select.results.error);

  return (
    <div className={`${classes.root} ${classes.errorPage}`}>
      <ErrorIcon className={classes.errorIcon} />
      <Typography variant="h2">Results could not be loaded</Typography>

      {/* This error message stored in Redux is a user facing message set in the reducer (resultsSlice.jsx) based on the response status code */}
      <Typography>{errorMessage}</Typography>

      <Typography>This {shareType.toLowerCase()} may not be in your network</Typography>
      <Link component={RouterLink} to="/">
        Log In and start a new search
      </Link>
    </div>
  );
}
SharePageError.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    errorPage: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
  }).isRequired,
};

export default SharePageError;
