import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions, thunks } from 'store/toolkit';

import { useProviderDetailsToString } from 'hooks/Provider';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Typography,
  Grid,
  List,
  TextField,
  IconButton,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ReplayIcon from '@mui/icons-material/Replay';

import Modal from 'components/Modals/Modal';
import ChatMessage from 'components/Modals/ChatModal/ChatMessage';
import ActionButtons from 'components/Modals/ChatModal/ActionButtons';
import ChatFeedback from 'components/Modals/ChatModal/Feedback/ChatFeedback';
import ChatDevTools from 'components/Modals/ChatModal/DevTools/ChatDevTools';
import {
  RESTART,
  DISMISS_EMERGENCY,
  SEARCH_IN_PG,
  START_OVER,
  TRY_AGAIN,
  RETRY,
  USER,
  ASSISTANT,
  CONVERSATION_STARTER,
  CONVERSATION_STARTER_WHITELABELED,
} from 'store/slices/chat/chatConstants';
import ChatHeader from 'components/Modals/ChatModal/ChatHeader';
import ChatSearchTabs from 'components/Modals/ChatModal/ChatSearchTabs';
import PromotionById from './Promotion/PromotionById';
import ChatAcknowledgementForm from './ChatTerms/ChatAcknowledgementForm';
import ChatLoading from './ChatLoading';
import ChatConversationContainer from './ChatConversationContainer';

const useStyles = makeStyles((theme) => ({
  modalStyles: {
    '& [data-reach-dialog-content]': {
      [theme.breakpoints.up('md')]: {
        width: 900,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& [data-reach-dialog-content]': {
        '& .modal-content-wrapper': {
          flex: 1,
          padding: theme.spacing(2),
        },
      },
    },
  },
  textInput: {
    width: '100%',
    marginBottom: '10px',
    '& textarea': {
      lineHeight: 1.5,
    },
    '& fieldset': {
      borderRadius: 15,
    },
  },
  messageList: {
    width: '100%',
  },
  inputOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
}));

function ChatModal() {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const showChatModal = useSelector(select.ui.chatModalOpen);
  const enableChatFeedback = useSelector(select.featureFlags.enableChatFeedback);
  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);
  const dispatch = useDispatch();

  const enableDevTools = useSelector(select.chat.devTools);
  const conversation = useSelector(select.chat.conversation);
  const errorMessage = useSelector(select.chat.errorMessage);
  const searchParameters = useSelector(select.chat.searchParameters);
  const actionButtonKeys = useSelector(select.chat.actionButtonKeys);
  const termsAccepted = useSelector(select.chat.termsAccepted);
  const feedbackSuccess = useSelector(select.chat.feedbackSuccess);
  const chatKey = useSelector(select.chat.chatKey);
  const promotionId = useSelector(select.chat.promotionId);
  const endChat = useSelector(select.chat.endChat);
  const searchesHaveBeenPerformed = useSelector(select.chat.searchesHaveBeenPerformed);
  const requests = useSelector(select.chat.requestsWithResults);
  const sendingChatMessage = useSelector(select.chat.sendingChatMessage);
  const appName = useSelector(select.content.appName);

  const [pendingPromise, setPendingPromise] = useState(null);
  const [inputText, setInputText] = useState('');
  const [showDismissDisclaimer, setShowDismissDisclaimer] = useState(false);
  const listEndRef = useRef(null);
  const chatInputRef = useRef(null);
  const classes = useStyles();
  // @TODO: use selector TECH-3695
  const providerDetailsToString = useProviderDetailsToString();

  const [isTermsError, setIsTermsError] = useState(false);
  const handleInputClick = useCallback(() => {
    setIsTermsError(!termsAccepted);
  }, [termsAccepted]);

  const closeChat = useCallback(() => {
    dispatch(actions.ui.closeModal('chat'));
  }, [dispatch]);

  const handleChange = ({ target: { value } }) => {
    setInputText(value);
  };

  const handleClearChat = useCallback(
    (e) => {
      pendingPromise?.abort();
      e.preventDefault();
      setInputText('');
      setIsTermsError(false);
      dispatch(actions.chat.resetChat());
    },
    [dispatch, pendingPromise]
  );

  const addUserMessage = () => {
    setInputText('');
    const userMessage = {
      role: USER,
      content: inputText,
    };
    dispatch(actions.chat.addMessageToConversation(userMessage));
  };

  // conditionally chain thunks askEva > analyzeChat > performAllSearches > chatExpandSearch
  const searchAndExpand = useCallback(async () => {
    const performAllSearchesPromise = dispatch(thunks.chat.performAllSearches());
    setPendingPromise(performAllSearchesPromise);
    performAllSearchesPromise.unwrap().catch(() => {}); // error handled in performAllSearches.rejected;
  }, [dispatch]);

  // conditionally chain thunks askEva > analyzeChat > performAllSearches
  const analyzeChat = useCallback(async () => {
    const analyzeChatPromise = dispatch(thunks.chat.analyzeChat());
    setPendingPromise(analyzeChatPromise);
    analyzeChatPromise
      .unwrap()
      .then(({ end, isEmergency }) => {
        // It is possible to return end = false or isEmergency = true from the analyze-chat endpoint in which case we want to continue the conversation
        if (end && !isEmergency) {
          searchAndExpand();
        }
      })
      .catch(() => {}); // error handled in analyzeChat.rejected
  }, [dispatch, searchAndExpand]);

  // conditionally chain thunks askEva > analyzeChat
  const submitConversationToChat = useCallback(async () => {
    const askEvaPromise = dispatch(thunks.chat.askEva());
    setPendingPromise(askEvaPromise);
    askEvaPromise
      .unwrap()
      .then(({ end, isEmergency }) => {
        if (end && !isEmergency) {
          analyzeChat();
        }
      })
      .catch(() => {}); // error handled in askEva.rejected
  }, [dispatch, analyzeChat]);

  const overrideEmergency = useCallback(async () => {
    const overrideMessage = {
      role: USER,
      content: 'Dismiss and continue',
    };
    await dispatch(actions.chat.setDismissEmergency(true));
    await dispatch(actions.chat.addMessageToConversation(overrideMessage));
    await dispatch(thunks.chat.askEva());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (!inputText.length) return;
    if (!termsAccepted) return;

    addUserMessage();
    submitConversationToChat();
  };

  const actionButtonsMap = useMemo(
    () => ({
      [RESTART]: {
        label: 'Restart Chat',
        callback: handleClearChat,
      },
      [DISMISS_EMERGENCY]: {
        label: 'Dismiss and continue',
        callback: overrideEmergency,
      },
      [START_OVER]: {
        label: 'Start over',
        callback: handleClearChat,
      },
      [SEARCH_IN_PG]: {
        label: `Search In ${appName}`,
        callback: (e) => {
          handleClearChat(e);
          closeChat();
        },
      },
      [TRY_AGAIN]: {
        label: 'Try again',
        callback: () => {
          dispatch(actions.chat.removeMessageFromConversation());
          chatInputRef.current?.focus();
          dispatch(actions.chat.resetErrorState());
        },
      },
      [RETRY]: {
        label: 'Retry',
        callback: () => {
          dispatch(actions.chat.resetErrorState());
          submitConversationToChat();
        },
      },
    }),
    [closeChat, handleClearChat, dispatch, submitConversationToChat, overrideEmergency, appName]
  );

  const actionButtons = useMemo(
    () => actionButtonKeys.map((key) => actionButtonsMap[key]),
    [actionButtonKeys, actionButtonsMap]
  );

  const scrollToBottom = useCallback(() => {
    if (showChatModal && listEndRef?.current && termsAccepted)
      listEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [listEndRef, showChatModal, termsAccepted]);

  useEffect(() => {
    if (actionButtonKeys.includes(DISMISS_EMERGENCY)) {
      setShowDismissDisclaimer(true);
    } else {
      setShowDismissDisclaimer(false);
    }
  }, [actionButtonKeys]);

  /**
   * Scroll to most recent chat message (or search results) when:
   * - new message added
   * - search is performed
   * - action buttons are added
   *
   * Also focus on input if:
   * - desktop
   * - action buttons and search results are not present
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToBottom();
      if (!searchesHaveBeenPerformed && chatInputRef?.current && !smDown && termsAccepted) {
        chatInputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    smDown,
    conversation.length,
    searchesHaveBeenPerformed,
    actionButtons.length,
    scrollToBottom,
    termsAccepted,
  ]);

  const getConversationAsString = useCallback(() => {
    let convo = 'Conversation:\n';
    conversation.forEach((message) => {
      convo += `Role: ${message.role}\nMessage: ${message.content}\n\n`;
    });
    if (searchesHaveBeenPerformed) {
      const filteredSpecialties = searchParameters?.specialties?.filter(
        (specialty) =>
          !searchParameters?.subspecialties?.find(
            (subspecialty) => subspecialty.specialtyId === specialty.specialtyId
          )
      );

      if (filteredSpecialties.length > 0) {
        convo += '\n\nSpecialties Searched:\n';
        filteredSpecialties.forEach((specialty) => {
          convo += `${specialty.specialtyName}\n`;
        });
      }

      if (searchParameters?.subspecialties?.length > 0) {
        convo += '\n\nSubspecialties Searched:\n';
        searchParameters.subspecialties.forEach((subspecialty) => {
          convo += `${subspecialty.subspecialtyName}\n`;
        });
      }

      convo += '\n\nProviders:\n';
      // eslint-disable-next-line no-restricted-syntax
      for (const request of requests) {
        convo += `${request.subspecialtyName || request.specialtyName}:\n`;
        // eslint-disable-next-line no-loop-func
        request.results.forEach((provider, index) => {
          convo += `${index + 1}.\n${providerDetailsToString(provider)}\n\n`;
        });
      }

      convo += `Explore In ${appName}:\n`;
      if (searchParameters?.specialties?.length > 0) {
        convo += '\nSpecialties:\n';
        searchParameters.specialties.forEach((specialty) => {
          convo += `${specialty.specialtyName}\n`;
        });
      }

      if (searchParameters?.subspecialties?.length > 0) {
        convo += '\nSubspecialties:\n';
        searchParameters.subspecialties.forEach((subspecialty) => {
          convo += `${subspecialty.subspecialtyName}\n`;
        });
      }
    }
    return convo;
  }, [
    conversation,
    requests,
    searchParameters,
    providerDetailsToString,
    searchesHaveBeenPerformed,
    appName,
  ]);

  const conversationStarter = useMemo(
    () => (isWhitelabeled ? CONVERSATION_STARTER_WHITELABELED : CONVERSATION_STARTER),
    [isWhitelabeled]
  );

  const a11yMessage = useMemo(() => {
    const messageChain = [conversationStarter, ...conversation];
    const message = messageChain[messageChain.length - 1];
    if (!termsAccepted) return '';
    return `${message.role === ASSISTANT ? 'Virtual Assistant' : 'You'} said: ${message.content}`;
  }, [conversation, termsAccepted, conversationStarter]);

  const disableInput = useMemo(
    () => sendingChatMessage || endChat || !termsAccepted,
    [endChat, sendingChatMessage, termsAccepted]
  );
  const disableSendButton = useMemo(
    () => sendingChatMessage || endChat || !termsAccepted || !inputText.length,
    [endChat, inputText.length, sendingChatMessage, termsAccepted]
  );

  return (
    <Modal
      handleClose={closeChat}
      open={showChatModal}
      ariaId="chat-modal"
      customStyles={classes.modalStyles}
      maxWidth="md"
      fullScreen={smDown}
    >
      <ChatHeader onReset={handleClearChat} getConversationAsString={getConversationAsString} />
      <ChatConversationContainer tabIndex={0} aria-label="Virtual Assistant conversation">
        <Collapse in={!termsAccepted}>
          <ChatAcknowledgementForm isError={isTermsError} setIsError={setIsTermsError} />
        </Collapse>
        <List className={classes.messageList}>
          <ChatMessage message={conversationStarter} />
          {conversation.map((message, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ChatMessage key={i} message={message} />
          ))}
          {errorMessage && <ChatMessage message={errorMessage} error />}
        </List>
        <div ref={listEndRef} />
        {promotionId && <PromotionById id={promotionId} />}

        <ChatLoading scrollToBottom={scrollToBottom} />

        {searchesHaveBeenPerformed && <ChatSearchTabs />}
        <ActionButtons actionButtons={actionButtons} />
        {showDismissDisclaimer && (
          <Grid style={{ marginLeft: 16 }}>
            <Typography>
              By clicking “Dismiss and continue”, you acknowledge that you have been alerted to a
              potential emergency situation based on your message and are choosing to continue with
              your conversation.
            </Typography>
          </Grid>
        )}

        {endChat && enableChatFeedback && (
          <ChatFeedback
            chatKey={chatKey}
            chatLog={getConversationAsString()}
            success={feedbackSuccess}
          />
        )}
        <Typography variant="srOnly" aria-live="polite">
          {a11yMessage}
        </Typography>
      </ChatConversationContainer>
      {/* Input field */}
      <Grid style={{ position: 'relative' }}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        {!termsAccepted && <div className={classes.inputOverlay} onClick={handleInputClick} />}
        <TextField
          id="chat-message"
          multiline
          minRows={1}
          maxRows={3}
          margin="dense"
          variant="outlined"
          size="small"
          className={classes.textInput}
          value={inputText}
          placeholder={searchesHaveBeenPerformed ? 'Start new chat' : 'Enter your message..'}
          onChange={handleChange}
          disabled={disableInput}
          inputRef={chatInputRef}
          onKeyDown={(e) => {
            // On Enter
            if (e.keyCode === 13) {
              handleSubmit(e);
            }
          }}
          InputProps={{
            inputProps: {
              maxLength: 150,
            },
            endAdornment: searchesHaveBeenPerformed ? (
              <IconButton
                aria-label="Start new chat"
                variant="contained"
                onClick={handleClearChat}
                style={{
                  display: 'block',
                  padding: 0,
                }}
              >
                <ReplayIcon size="small" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="Send message"
                disabled={disableSendButton}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                style={{
                  display: 'block',
                  padding: 0,
                }}
              >
                <SendIcon size="small" />
              </IconButton>
            ),
          }}
        />
      </Grid>
      {enableDevTools && <ChatDevTools />}
    </Modal>
  );
}

export default ChatModal;
