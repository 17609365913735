import React from 'react';
import { createSvgIcon } from '@material-ui/core';

const color1 = '#F05B5A';
const color2 = '#2B95BF';
const color3 = '#C5C3BB';
const color4 = '#F0C417';
const color5 = '#965BA5';
const color6 = '#49BA6F';

export default createSvgIcon(
  <>
    <path
      d="M20.6401 4.20865C20.6362 4.74341 20.5592 5.26263 20.3025 5.7414C20.1368 6.05037 19.9028 6.28644 19.56 6.39476C19.4965 6.41487 19.4913 6.43521 19.5294 6.48686C19.6393 6.63609 19.7177 6.7988 19.7303 6.98802C19.7547 7.35618 19.5418 7.58174 19.2634 7.76708C19.1619 7.8345 19.0548 7.89368 18.9398 7.93505C18.8646 7.96201 18.8463 8.00246 18.861 8.08153C18.8941 8.26253 18.8943 8.44558 18.8411 8.62406C18.7348 8.98011 18.467 9.13277 18.123 9.18556C18.0668 9.19424 18.0086 9.20681 17.953 9.20064C17.8404 9.1883 17.8095 9.22441 17.8068 9.34233C17.7985 9.70455 17.6018 9.95365 17.2898 10.117C16.971 10.2843 16.6214 10.3291 16.2708 10.3216C15.5217 10.3051 14.7829 10.2048 14.0868 9.91114C13.4229 9.63096 12.9153 9.15676 12.5124 8.56556C12.4848 8.52511 12.4628 8.48055 12.4631 8.43438C12.464 8.06531 12.4585 7.70058 12.4585 7.32762C12.4585 7.24466 12.4699 7.17153 12.5085 7.0984C13.1695 5.85132 14.2081 5.12505 15.5898 4.89355C16.4669 4.74661 17.3513 4.64286 18.2179 4.43558C18.5728 4.3508 18.9149 4.23013 19.2584 4.10444C19.6924 3.94562 19.946 3.65264 20.1071 3.24403C20.1451 3.14759 20.1869 3.02213 20.3121 3.02533C20.4367 3.02853 20.4698 3.1549 20.5057 3.2534C20.6181 3.56169 20.6424 3.88323 20.6403 4.20865H20.6401Z"
      fill={color1}
    />
    <path
      d="M3.35996 4.20865C3.36384 4.74341 3.44086 5.26263 3.69749 5.7414C3.86318 6.05037 4.09719 6.28644 4.43999 6.39476C4.50352 6.41487 4.50877 6.43521 4.47061 6.48686C4.36069 6.63609 4.2823 6.7988 4.26973 6.98802C4.24528 7.35618 4.45827 7.58174 4.73662 7.76708C4.83808 7.8345 4.94526 7.89368 5.06021 7.93505C5.1354 7.96201 5.15368 8.00246 5.13906 8.08153C5.10592 8.26253 5.10569 8.44558 5.15894 8.62406C5.2652 8.98011 5.53304 9.13277 5.87698 9.18556C5.93319 9.19424 5.99147 9.20681 6.047 9.20064C6.15967 9.1883 6.19052 9.22441 6.19326 9.34233C6.20149 9.70455 6.39825 9.95365 6.71019 10.117C7.02899 10.2843 7.37864 10.3291 7.72921 10.3216C8.47832 10.3051 9.21716 10.2048 9.91326 9.91114C10.5771 9.63096 11.0847 9.15676 11.4876 8.56556C11.5152 8.52511 11.5372 8.48055 11.537 8.43438C11.536 8.06531 11.5415 7.70058 11.5415 7.32762C11.5415 7.24466 11.5301 7.17153 11.4915 7.0984C10.8306 5.85132 9.79191 5.12505 8.41022 4.89355C7.53313 4.74661 6.64872 4.64286 5.78214 4.43558C5.42723 4.3508 5.08512 4.23013 4.74164 4.10444C4.30767 3.94562 4.054 3.65264 3.89289 3.24403C3.85495 3.14759 3.81313 3.02213 3.6879 3.02533C3.56335 3.02853 3.53021 3.1549 3.49433 3.2534C3.38235 3.56169 3.35813 3.88346 3.35996 4.20865Z"
      fill={color2}
    />
    <path
      d="M12.2074 14.9991V16.3923C12.087 16.5161 11.9443 16.6407 11.7944 16.7682V15.2896C11.9105 15.1875 12.0504 15.0912 12.2074 14.9991Z"
      fill={color3}
    />
    <path
      d="M12.2074 17.7899V18.8677C12.0949 18.9406 11.9569 19.0171 11.7944 19.1076V18.1307C11.9121 18.0214 12.0554 17.909 12.2074 17.7899Z"
      fill={color3}
    />
    <path
      d="M11.7944 20.2297C11.9089 20.147 12.0449 20.0606 12.2074 19.9717V21.2144C12.2074 21.3285 12.1151 21.4208 12.001 21.4208C11.887 21.4208 11.7944 21.3285 11.7944 21.2144V20.2297Z"
      fill={color3}
    />
    <path
      d="M13.5964 4.46668C13.5964 5.27613 12.9904 5.94412 12.2074 6.04125V13.6556C12.0726 13.7315 11.933 13.8154 11.7945 13.9061V6.03896C11.02 5.93407 10.4229 5.27019 10.4229 4.46668C10.4229 3.5905 11.1333 2.88 12.0095 2.88C12.8857 2.88 13.5964 3.5905 13.5964 4.46668Z"
      fill={color3}
    />
    <path
      d="M12.3005 19.9304C12.3752 19.8557 12.6883 19.7231 12.7468 19.6982C12.8521 19.653 12.9682 19.653 13.1554 19.9919C13.3426 20.3308 13.3602 20.3068 13.4555 21.276C13.4749 21.4728 13.2166 21.2017 12.8716 20.792C12.5265 20.3822 12.1924 20.0387 12.3005 19.9306V19.9304Z"
      fill={color1}
    />
    <path
      d="M10.8421 19.9916C10.6549 20.3302 10.6373 20.3065 10.542 21.2757C10.5226 21.4724 10.7945 21.2126 11.1259 20.7916C11.2673 20.612 11.4447 20.1207 12.687 19.5786C13.9293 19.0365 13.3001 18.0251 12.9128 17.5794C12.7633 17.4074 12.7354 17.3934 12.6945 17.4245C12.6577 17.4526 12.2804 17.6967 12.2644 17.7997C12.2484 17.9028 12.2644 18.0203 12.3773 18.1268C12.4902 18.2333 12.5745 18.4271 12.5547 18.6108C12.5208 18.9271 12.1579 19.0722 11.5288 19.4335C10.8996 19.7948 10.8421 19.9916 10.8421 19.9916Z"
      fill={color4}
    />
    <path
      d="M11.1777 19.4943C11.3007 19.4006 11.6793 19.1895 11.7278 19.17C11.7762 19.1506 11.7552 19.104 11.6197 18.9797C11.4842 18.8554 11.3258 18.6159 11.5697 18.2313C11.9305 17.6618 13.4059 17.0683 13.4146 15.9583C13.4189 15.0554 12.9559 14.62 12.8382 14.6699C12.654 14.748 12.4655 14.8481 12.3974 14.8913C12.2872 14.9612 12.2194 14.9692 12.2941 15.1107C12.3558 15.2275 12.556 15.3952 12.556 15.9414C12.556 16.7737 10.5879 17.4492 10.5879 18.6234C10.5879 19.1266 11.1361 19.5256 11.1777 19.4939V19.4943Z"
      fill={color2}
    />
    <path
      d="M15.6895 12.5002C15.6895 12.5222 15.6886 12.5441 15.687 12.5656C15.602 13.6516 13.4088 14.1191 12.2074 14.8251C12.0504 14.9172 11.9106 15.0134 11.7945 15.1155C11.6039 15.283 11.4775 15.4665 11.4455 15.6747C11.44 15.7092 11.4373 15.7444 11.4373 15.7803C11.4373 15.8162 11.4387 15.8491 11.4412 15.8813C11.464 16.1853 11.5819 16.3665 11.6576 16.473C11.6784 16.502 11.6948 16.5361 11.7074 16.5715C11.7264 16.6247 11.7367 16.6805 11.7389 16.7255C11.7412 16.7705 11.7241 16.8135 11.6914 16.8439C11.5895 16.9396 11.4448 17.0445 11.3431 17.127C11.2286 17.22 11.227 17.1926 11.1308 17.1035C11.1308 17.1035 10.6132 16.6695 10.5753 15.7837C10.5734 15.7483 10.5728 15.712 10.5728 15.675C10.5728 15.643 10.5744 15.6112 10.5778 15.5797C10.6361 15.0125 11.2035 14.4964 11.7945 14.1084C11.933 14.0177 12.0726 13.9338 12.2074 13.8579C12.3729 13.7647 12.5308 13.6838 12.6697 13.6166C13.3583 13.2839 14.6273 12.8501 14.8638 12.5213C14.8888 12.4865 14.9022 12.4529 14.9022 12.4207C14.9022 12.3807 14.8732 12.3471 14.8225 12.3181C14.5629 12.17 13.7363 12.1497 13.3649 12.0674C12.9773 11.9815 12.5539 11.5489 12.4958 11.1361C12.4913 11.1046 12.489 11.0731 12.489 11.042C12.489 11.0031 12.4938 10.965 12.5031 10.9275C12.5987 10.5399 13.1656 10.2289 13.7539 10.2289C14.3748 10.2289 15.5938 11.018 15.6843 12.3432C15.6877 12.3949 15.6895 12.4472 15.6895 12.5002Z"
      fill={color5}
    />
    <path
      d="M11.3386 13.6166C10.5772 13.2489 9.10613 12.7573 9.10613 12.4207C9.10613 12.1755 10.1999 12.1659 10.6434 12.0674C11.0607 11.9748 11.5194 11.4808 11.5194 11.042C11.5194 10.6032 10.8999 10.2289 10.2547 10.2289C9.60958 10.2289 8.31885 11.0806 8.31885 12.5002C8.31885 13.7478 10.8112 14.4231 10.9079 14.4457C11.1014 14.4909 11.1697 14.3899 11.2573 14.3189C11.6149 14.0291 11.7374 14.0163 11.6789 13.8543C11.646 13.7633 11.3386 13.6166 11.3386 13.6166Z"
      fill={color6}
    />
  </>,
  'OutcareColored'
);
