import * as Yup from 'yup';

import usStates from 'utils/usStates';
import { CHECKBOX_ERROR_TEXT } from '../Login/errorText';

export const SELECTED_PROVIDER = 'provider-name';
export const PROVIDER_NPI = 'provider-npi';
export const VISITED_TIME_FRAME = 'visited-time-frame';
export const VISITED_TIME_FRAME_OPTIONS = [
  { value: 'previous-year', label: "I've seen this provider in the last 12 months" },
  { value: 'future', label: 'I plan to visit this provider in the future' },
];
export const DATE_OF_VISIT = 'date-of-visit';
export const FIRST_NAME = 'first-name';
export const LAST_NAME = 'last-name';
export const PREFERRED_EMAIL = 'preferred-email';
export const CONFIRM_EMAIL = 'confirm-email';
export const CITY = 'city';
export const STATE = 'state';
export const ZIP = 'zip';
export const CONFIRM_DETAILS_CHECKBOX = 'confirm-details';

const validationSchema = Yup.object({
  [SELECTED_PROVIDER]: Yup.string().required('This field is required'), // this field is not editable and automatically filled
  [PROVIDER_NPI]: Yup.number('Must be a valid number').required('This field is required'), // this field is not editable and automatically filled
  [VISITED_TIME_FRAME]: Yup.string().required('This field is required'),
  [DATE_OF_VISIT]: Yup.string().required('This field is required'),
  [FIRST_NAME]: Yup.string('Enter your first name')
    .min(2, 'First Name must be at least 2 characters')
    .required('First Name is required'),
  [LAST_NAME]: Yup.string('Enter your last name')
    .min(2, 'Last Name must be at least 2 characters')
    .required('Last Name is required'),
  [PREFERRED_EMAIL]: Yup.string('Enter your email')
    .max(150, 'Email must be under 150 characters')
    .email('Please enter a valid email')
    .required('Email is required'),
  [CONFIRM_EMAIL]: Yup.string('Enter your last name')
    .oneOf([Yup.ref(PREFERRED_EMAIL)], "Emails don't match")
    .required('Email confirmation is required'),
  [CITY]: Yup.string('Enter a city'),
  [STATE]: Yup.string('Enter the state').oneOf(usStates, 'Must be a valid state'),
  [ZIP]: Yup.string('Enter a Zip').matches(/^\d{5}$/, 'Must be a 5 digit zip'), // from https://regexlib.com/REDetails.aspx?regexp_id=2
  [CONFIRM_DETAILS_CHECKBOX]: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
});

export default validationSchema;
