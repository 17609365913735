import { createSlice } from '@reduxjs/toolkit';

import { AUTH_SLICE_NAME } from '../slicesNames';
import { validLoginCookie } from '../config/configSlice';
import * as appThunks from '../../appThunks';

const initialState = {
  isLoggedIn: validLoginCookie,
};

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(appThunks.userLoggedIn.fulfilled, (state) => {
        state.isLoggedIn = true;
      })
      .addCase(appThunks.userLoggedIn.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(appThunks.userLoggedOut.fulfilled, (state) => {
        state.isLoggedIn = false;
      });
  },
});

export default authSlice;
