/* eslint-disable no-alert */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import useUpdateTitle from 'hooks/useUpdateTitle';

import ResponsiveIconButton from 'components/ResponsiveIconButton';
import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function CustomComponentStyleguide() {
  const classes = useStyles();

  useUpdateTitle('Custom Component Styleguide', { hidePrefix: true });

  return (
    <div className={classes.root}>
      <StyleguideCanvas
        name="ResponsiveIconButton"
        description={`This component renders an MUI Button if the iconOnly prop is false. 
          
          If iconOnly is truthy it renders an IconButton that uses either the startIcon or endIcon. The text provided as children will serve as the aria-label for the IconButton. 
          
          Props that should only be applied to the Button should be passed via MuiButtonProps.
          Props that should only be applied to the IconButton should be passed via MuiIconButtonProps

          All other props are forwarded to both.`}
      >
        {['primary', 'secondary', undefined].map((color) => (
          <React.Fragment key={color || '-'}>
            <ResponsiveIconButton startIcon={<AccountCircleIcon />} color={color}>
              Responsive Icon Button
            </ResponsiveIconButton>
            <ResponsiveIconButton iconOnly startIcon={<AccountCircleIcon />} color={color}>
              Responsive Icon Button
            </ResponsiveIconButton>
          </React.Fragment>
        ))}
        <br />
        {['contained', 'outlined'].map((variant) => (
          <React.Fragment key={variant || '-'}>
            <ResponsiveIconButton
              startIcon={<AccountCircleIcon />}
              color="primary"
              MuiButtonProps={{ variant }}
            >
              Responsive Icon Button
            </ResponsiveIconButton>
            <ResponsiveIconButton
              iconOnly
              startIcon={<AccountCircleIcon />}
              color="primary"
              MuiButtonProps={{ variant }}
            >
              Responsive Icon Button
            </ResponsiveIconButton>
          </React.Fragment>
        ))}
      </StyleguideCanvas>
    </div>
  );
}
