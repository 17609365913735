import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import ProviderSearchModal from 'components/ProviderSearch/ProviderSearchModal';
import { select, actions } from 'store/toolkit';
import { getPlaceholderText } from './ProviderAutocomplete';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    [`& fieldset`]: {
      borderRadius: '20px 0 0 20px',
    },
  },
  rightBorderRadius: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  startIcon: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[700],
    height: 22,
  },
  input: {
    fontSize: '1rem',
    '& ::placeholder': {
      opacity: 1,
      color: theme.palette.grey[600],
      fontSize: '.875rem',
    },
  },
}));

function ProviderSearchDummy({ showLabel, rightBorderRadius }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mdDown = useSelector(select.ui.isMdDown);
  const searchType = useSelector(select.search.type);
  const searchInput = useSelector(select.search.text);

  const placeholderText = useMemo(() => getPlaceholderText(searchType), [searchType]);

  return (
    <>
      {showLabel && (
        <Typography
          component="label"
          htmlFor="provider-search-dummy"
          style={{ display: 'inline-block', margin: '0.5rem 0 0' }}
        >
          What are you looking for?
        </Typography>
      )}

      <TextField
        id="provider-search-dummy"
        placeholder={placeholderText}
        value={searchInput}
        variant="outlined"
        margin="dense"
        disabled={!searchType}
        onClick={
          mdDown
            ? () => {
                dispatch(actions.ui.openModal('providerSearch'));
              }
            : undefined
        }
        onChange={
          mdDown
            ? ({ target: { value } }) => {
                dispatch(actions.ui.openModal('providerSearch'));
                dispatch(actions.search.handleTextInput({ value }));
              }
            : undefined
        }
        InputProps={{
          classes: { root: classes.input },
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SearchIcon className={classes.startIcon} />
            </InputAdornment>
          ),
        }}
        className={`${classes.textField}${
          rightBorderRadius ? ` ${classes.rightBorderRadius}` : ''
        }`}
        style={{ marginTop: showLabel ? 0 : 20 }}
      />

      <ProviderSearchModal />
    </>
  );
}

ProviderSearchDummy.propTypes = {
  showLabel: PropTypes.bool,
  rightBorderRadius: PropTypes.bool,
};

ProviderSearchDummy.defaultProps = {
  showLabel: false,
  rightBorderRadius: true,
};

export default ProviderSearchDummy;
