import { createAsyncThunk } from '@reduxjs/toolkit';

import { serviceUrl as selectServiceUrl } from 'store/fusionServices/selectFusion';
import { axiosInstance } from '../config/selectAxios';
import { SERVICES_SLICE_NAME } from '../slicesNames';

// delete this disable if more exports are added
// eslint-disable-next-line import/prefer-default-export
export const fetchService = createAsyncThunk(
  `${SERVICES_SLICE_NAME}/fetchCurrent`,
  async (args, thunkApi) => {
    const { getState } = thunkApi;

    const state = getState();
    const axios = axiosInstance(state);

    // get service url
    const url = selectServiceUrl(state);
    const response = await axios.get(url);

    return response.data;
  }
);
