import { createSelector } from '@reduxjs/toolkit';

import { COST_SLICE_NAME } from '../slicesNames';
import * as selectResults from '../results/selectResults';

export const slice = (state) => state[COST_SLICE_NAME];

export function byKey(key) {
  return createSelector([slice], (costSlice) => costSlice[key]);
}

export function currentServiceByKey(key) {
  return createSelector([slice, selectResults.serviceId], (costSlice, currentServiceId) => {
    if (!currentServiceId) return null;
    return costSlice[key]?.data?.filter(({ serviceId }) => serviceId === currentServiceId);
  });
}
