import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

/*
    This component is an extension of the React Router <Link />
    Rather than just changing the location to the new "to" route, it additionally sets the "background"
    state to the current location. Therefore keeping the original location underneath the modal
 */
function LinkToModal({ to, ...props }) {
  const location = useLocation(); // current location
  return (
    <Link to={{ pathname: to, state: { background: location } }} {...props}>
      {props.children}
    </Link>
  );
}

LinkToModal.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

LinkToModal.defaultProps = {
  children: undefined,
};

export default React.forwardRef((props, ref) => <LinkToModal innerRef={ref} {...props} />);
