import React from 'react';
import PropTypes from 'propTypes';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { openInNewTab } from 'utils/utils';
import DetailsButton from '../DetailsButton';

function PlaceDetailsButton({ placeId, accessibleLabel, buttonClasses }) {
  const profileDetailsLink = useSelector(select.results.profileDetailsLink(placeId));

  const handlePlaceDetailsClick = () => {
    openInNewTab(profileDetailsLink);
  };
  return (
    <DetailsButton
      onClick={handlePlaceDetailsClick}
      accessibleLabel={accessibleLabel}
      buttonClasses={buttonClasses}
    />
  );
}

export default React.memo(PlaceDetailsButton);

PlaceDetailsButton.propTypes = {
  placeId: PropTypes.string.isRequired,
  accessibleLabel: PropTypes.string,
  buttonClasses: PropTypes.string,
};

PlaceDetailsButton.defaultProps = {
  accessibleLabel: '',
  buttonClasses: '',
};
