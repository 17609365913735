import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import CompareTable from './CompareTable';

export default function SplitCompareTableForPrint({ resultIds = [], header, ...props }) {
  if (resultIds.length <= 5)
    return (
      <>
        {header}
        <CompareTable resultIds={resultIds} {...props} />
      </>
    );

  const startingSecondHalfIndex = Math.ceil(resultIds.length / 2); // odd array of 7 elements => midpoint of 3.5 => round up to 4. Second half will start at index 4
  const firstHalfArray = resultIds.slice(0, startingSecondHalfIndex);
  const secondHalfArray = resultIds.slice(startingSecondHalfIndex);
  return (
    <>
      {header}
      <CompareTable resultIds={firstHalfArray} {...props} />
      <Typography align="center">
        1 - {firstHalfArray.length} of {resultIds.length}
      </Typography>
      <div className="pagebreak" />
      {header}
      <CompareTable resultIds={secondHalfArray} {...props} />
      <Typography align="center">
        {startingSecondHalfIndex + 1} - {resultIds.length} of {resultIds.length}
      </Typography>
    </>
  );
}

SplitCompareTableForPrint.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.node,
};

SplitCompareTableForPrint.defaultProps = {
  header: null,
};
