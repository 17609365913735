import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

export default function HealthAndWellness(props) {
  const { palette } = useTheme();
  const starColor = palette.success.main;

  return (
    <SvgIcon viewBox="0 -50 144 144" {...props}>
      <path
        d="M17.0919 1.96867C17.4494 1.1938 18.5506 1.19379 18.9081 1.96866L22.844 10.5018C22.9897 10.8176 23.289 11.0351 23.6343 11.076L32.9661 12.1824C33.8135 12.2829 34.1538 13.3303 33.5273 13.9097L26.6281 20.2899C26.3728 20.526 26.2584 20.8778 26.3262 21.2189L28.1576 30.4359C28.3239 31.2728 27.433 31.9202 26.6883 31.5034L18.4884 26.9134C18.185 26.7435 17.815 26.7435 17.5116 26.9134L9.31165 31.5034C8.56704 31.9202 7.67608 31.2728 7.84238 30.4359L9.67378 21.2189C9.74156 20.8778 9.62724 20.526 9.37191 20.2899L2.47269 13.9097C1.84619 13.3303 2.18651 12.2829 3.0339 12.1824L12.3657 11.076C12.711 11.0351 13.0103 10.8176 13.156 10.5018L17.0919 1.96867Z"
        fill={starColor}
      />
      <path
        d="M53.0919 1.96867C53.4494 1.1938 54.5506 1.19379 54.9081 1.96866L58.844 10.5018C58.9897 10.8176 59.289 11.0351 59.6343 11.076L68.9661 12.1824C69.8135 12.2829 70.1538 13.3303 69.5273 13.9097L62.6281 20.2899C62.3728 20.526 62.2584 20.8778 62.3262 21.2189L64.1576 30.4359C64.3239 31.2728 63.433 31.9202 62.6883 31.5034L54.4884 26.9134C54.185 26.7435 53.815 26.7435 53.5116 26.9134L45.3117 31.5034C44.567 31.9202 43.6761 31.2728 43.8424 30.4359L45.6738 21.2189C45.7416 20.8778 45.6272 20.526 45.3719 20.2899L38.4727 13.9097C37.8462 13.3303 38.1865 12.2829 39.0339 12.1824L48.3657 11.076C48.711 11.0351 49.0103 10.8176 49.156 10.5018L53.0919 1.96867Z"
        fill={starColor}
      />
      <path
        d="M89.0919 1.96867C89.4494 1.1938 90.5506 1.19379 90.9081 1.96866L94.844 10.5018C94.9897 10.8176 95.289 11.0351 95.6343 11.076L104.966 12.1824C105.813 12.2829 106.154 13.3303 105.527 13.9097L98.6281 20.2899C98.3728 20.526 98.2584 20.8778 98.3262 21.2189L100.158 30.4359C100.324 31.2728 99.433 31.9202 98.6883 31.5034L90.4884 26.9134C90.185 26.7435 89.815 26.7435 89.5116 26.9134L81.3117 31.5034C80.567 31.9202 79.6761 31.2728 79.8424 30.4359L81.6738 21.2189C81.7416 20.8778 81.6272 20.526 81.3719 20.2899L74.4727 13.9097C73.8462 13.3303 74.1865 12.2829 75.0339 12.1824L84.3657 11.076C84.711 11.0351 85.0103 10.8176 85.156 10.5018L89.0919 1.96867Z"
        fill={starColor}
      />
      <path
        d="M126 2.38751L129.936 10.9206C130.227 11.5523 130.826 11.9871 131.517 12.069L140.848 13.1755L133.949 19.5557C133.438 20.0279 133.21 20.7316 133.345 21.4138L135.177 30.6308L126.977 26.0408C126.37 25.7011 125.63 25.7011 125.023 26.0408L116.823 30.6308L118.655 21.4138C118.79 20.7316 118.562 20.0279 118.051 19.5557L111.152 13.1755L120.483 12.069C121.174 11.9871 121.773 11.5523 122.064 10.9206L126 2.38751Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
