import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import PlaceProfilePage from './PlaceProfile/PlaceProfilePage';
import ProviderProfilePage from './ProviderProfile/ProviderProfilePage';
import ProfileError from './ProfileError';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ProfilePage() {
  const { profileType, id } = useParams();
  const classes = useStyles();

  const getProfileContent = (type) => {
    switch (type) {
      case 'place':
        return <PlaceProfilePage id={id} />;
      case 'provider':
        return <ProviderProfilePage id={id} />;
      default:
        return (
          <ProfileError
            heading="No Results"
            text="We were unable to find any results that matched your search
      criteria. Please try a new search."
            buttons={['newsearch']}
          />
        );
    }
  };

  return <div className={classes.root}>{getProfileContent(profileType)}</div>;
}
