import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';

import { select, actions } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[700],
  },
  label: {
    whiteSpace: 'nowrap',
  },
  outlined: {
    minHeight: 45,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    transition: 'all 0.5s',
  },
  iconMapIsHidden: {
    transform: 'rotate(-180deg)',
  },
}));

function ShowMapButton({ isMobile = false, ...props }) {
  const { icon, iconMapIsHidden, ...buttonClasses } = useStyles(props);
  const dispatch = useDispatch();
  const mapIsOpen = useSelector(select.ui.showResultsMap);
  const hasActiveMapMarker = useSelector(select.results.hasActiveMapMarker);
  const returnedNoResults = useSelector(select.results.returnedNoResults);

  const toggleMap = useCallback(() => dispatch(actions.ui.toggleShowResultsMap()), [dispatch]);

  const commonProps = useMemo(
    () => ({
      onClick: toggleMap,
      classes: { ...buttonClasses, outlined: `white-background ${buttonClasses.outlined}` },
      'aria-expanded': mapIsOpen,
      'aria-controls': 'map-container',
    }),
    [toggleMap, buttonClasses, mapIsOpen]
  );

  if (isMobile) {
    if (returnedNoResults || hasActiveMapMarker) return null;
    return (
      <Button
        variant="outlined"
        color="primary"
        startIcon={mapIsOpen ? <ListIcon /> : <MapIcon />}
        {...commonProps}
        {...props}
      >
        {mapIsOpen ? 'Show List' : 'Show Map'}
      </Button>
    );
  }

  return (
    <Button
      startIcon={<KeyboardTabIcon className={`${icon} ${mapIsOpen ? '' : iconMapIsHidden}`} />}
      {...commonProps}
      {...props}
    >
      {mapIsOpen ? 'Hide Map' : 'Show Map'}
    </Button>
  );
}

ShowMapButton.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

ShowMapButton.defaultProps = {
  className: undefined,
  isMobile: false,
};

export default React.memo(ShowMapButton);
