/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ErrorIcon from '@material-ui/icons/Error';

import { uniq } from 'lodash';
import { _analyticsActions } from 'analytics';

import Modal from 'components/Modals/Modal';
import {
  isValidEmail,
  isValidPhoneNumber,
  checkInvalidTextField,
  determineResultType,
} from 'utils/utils';
import { actions, select } from 'store/toolkit';

import { PROVIDER_RESULT_TYPE } from 'utils/constants';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  formLabel: {
    fontSize: '0.75rem',
  },
  addButton: {
    marginTop: '.4rem',
    marginBottom: '.7rem',
  },
  checkmark: {
    width: 50,
    height: 50,
  },
}));

function ShareProvider({ profileData }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  // axios instance
  const axios = useSelector(select.axios.axiosInstance);

  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);
  const locationInput = useSelector(select.location.locationInput);
  const apiUrl = useSelector(select.config.apiUrl);
  const { latitude, longitude } = useSelector(select.location.latLong);

  const networkSlug = useSelector(select.networks.currentSlug);

  const [numFields, setNumFields] = useState(1);
  const [inputValues, setInputValues] = useState([]);
  const [message, setMessage] = useState('');
  const [fromName, setFromName] = useState('');
  const [consent, setConsent] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [invalidSentFrom, setInvalidSentFrom] = useState(false);
  const [sentFromError, setSentFromError] = useState('');

  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const resultType = determineResultType(profileData[0]);

  let title = resultType === PROVIDER_RESULT_TYPE ? 'Share Provider' : 'Share Facility';
  if (profileData.length > 1) {
    title = resultType === PROVIDER_RESULT_TYPE ? `${title}s` : title.replace('y', 'ies');
  }
  title += `- ${profileData.map(
    (prof) => ` ${resultType === PROVIDER_RESULT_TYPE ? prof.entityName : prof.name}`
  )}`;

  const previouslyAddedRecipientFieldRef = useRef();
  const MaxFields = 10;

  const handleAddField = (e) => {
    e.preventDefault();
    if (numFields !== MaxFields) {
      setNumFields(numFields + 1);
    }
  };

  const handleRemoveField = (e) => {
    e.preventDefault();
    setNumFields(numFields - 1);
    previouslyAddedRecipientFieldRef.current.focus();
  };

  const validInput = (i) => {
    if (invalidInputs.indexOf(i) > -1) {
      const updatedInvalidInputs = invalidInputs;
      updatedInvalidInputs.splice(invalidInputs.indexOf(i), 1);
      setInvalidInputs(updatedInvalidInputs);
    }
  };

  const isInvalidInput = (i) => {
    if (invalidInputs.indexOf(i) === -1) {
      setInvalidInputs([...invalidInputs, i]);
    }
  };

  const validateInput = (i) => {
    if (inputValues[i]) {
      if (inputValues[i].type !== 'email' && inputValues[i].type !== 'phone') {
        isInvalidInput(i);
      } else {
        validInput(i);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const handleTextInput = (i, value) => {
    validInput(i);

    const newInputValues = inputValues.slice();
    let type;
    let formattedVal = value;

    if (isValidEmail(value)) {
      type = 'email';
    } else if (isValidPhoneNumber(value)) {
      type = 'phone';

      formattedVal = value.replace('(', '').replace(')', '').replaceAll('-', '');

      if (formattedVal.length === 10 && formattedVal.charAt(0) !== '1') {
        formattedVal = `+1${formattedVal}`;
        return handleTextInput(i, formattedVal);
      }
      if (formattedVal.length === 10) {
        formattedVal = `+${formattedVal}`;
        return handleTextInput(i, formattedVal);
      }
      if (formattedVal.length === 11) {
        formattedVal = `+${formattedVal}`;
        return handleTextInput(i, formattedVal);
      }
    }

    newInputValues[i] = {
      value: formattedVal,
      type,
    };
    setInputValues(newInputValues);
  };

  const handleClearInput = (i) => {
    const newInputValues = inputValues.slice();
    newInputValues[i] = {
      value: '',
      type: '',
    };
    setInputValues(newInputValues);
  };

  const handleChangeMessage = ({ target: { value } }) => {
    if (error) {
      setError('');
    }
    setMessage(value);
  };

  const handleCheckMessage = ({ target: { value } }) => {
    const errorMessage = checkInvalidTextField(value);
    setMessageError(errorMessage);
    setInvalidMessage(Boolean(errorMessage));
  };

  const handleChangeSenderName = ({ target: { value } }) => {
    if (error) {
      setError('');
    }
    setFromName(value);
  };

  const handleCheckSentFrom = ({ target: { value } }) => {
    const errorMessage = checkInvalidTextField(value);
    setSentFromError(errorMessage);
    setInvalidSentFrom(Boolean(errorMessage));
  };

  const handleCheckConsent = ({ target: { checked } }) => {
    setConsent(checked);
  };

  const shareSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSending(false); // hide intersitial
      setSuccess(false); // reset interstitial text and image
      setNumFields(1); // reset share fields
      setInputValues([]); // reset share fields
      dispatch(actions.ui.closeProfileModal({ promptFeedback: true })); // close share modal, conditionally open feedback modal
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newProfileData = profileData.map(
      // use destructuring to not include the suggest, or the hospitalGradeUrl properties
      ({ suggest, hospitalGradeUrl, ...rest }) => ({ ...rest })
    );

    const payload = {
      phone_numbers: uniq(
        inputValues.filter((input) => input.type === 'phone').map((input) => input.value)
      ),
      email_addresses: uniq(
        inputValues.filter((input) => input.type === 'email').map((input) => input.value)
      ),
      message,
      from_name: fromName,
      location: `${latitude},${longitude}`,
      location_input: locationInput,
      network_slug: networkSlug || 'NO_NETWORK_SLUG',
      entityIds: profileData.map((prof) =>
        resultType === PROVIDER_RESULT_TYPE ? prof.entityId : prof.id
      ),
      entity_type: resultType,
      entity_data: newProfileData,
      is_new_share: true,
      subspecialty_id:
        activeSubspecialtyId && activeSubspecialtyId > 0 ? activeSubspecialtyId : null,
    };
    if (payload.email_addresses.length || payload.phone_numbers.length) {
      setSending(true);
      setError('');

      const url = `${apiUrl}/share/`;

      axios
        .post(url, payload)
        .then((response) => {
          if (!response.status || response.status !== 202) {
            setSending(false);
            setError('There was a problem processing your request');
          } else {
            shareSuccess();
          }
        })
        .catch((err) => {
          let errorMessage = 'There was a problem processing your request';
          Sentry.captureException(err);
          if (err.response?.status === 400) {
            if (err.response?.data?.email_addresses) {
              const emailErrorIndexes = Object.keys(err.response.data.email_addresses).map((key) =>
                parseInt(key, 10)
              );
              setInvalidInputs([...invalidInputs, ...emailErrorIndexes]);
            }

            if (err.response?.data?.phone_numbers) {
              const phoneNumberErrorIndexes = Object.keys(err.response.data.phone_numbers).map(
                (key) => parseInt(key, 10)
              );
              setInvalidInputs([...invalidInputs, ...phoneNumberErrorIndexes]);
            }

            if (err.response?.data?.message) {
              setInvalidMessage(true);
              setMessageError(err.response.data.message);
            }
          }

          if (err.response?.status === 502) {
            if (err.response?.data) {
              errorMessage = err.response?.data;
            }
          }
          setError(errorMessage);
          setSending(false);
        });

      profileData.forEach((result) => {
        const resultId = result.entityId || result.id;
        dispatch(_analyticsActions.sharedProfile(resultId));
      });
    }
  };

  const textFields = [];
  for (let i = 0; i < numFields; i += 1) {
    // eslint-disable-next-line no-shadow
    const invalidInput = invalidInputs.indexOf(i) > -1;
    const inputValue = inputValues[i];
    const helperSRText = `${i + 1} of ${numFields}`;

    textFields.push(
      <div
        key={i}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <TextField
          id={`recipient-input-${i}`}
          label={`Phone number or email${i === 0 ? ' (required)' : `: recipient ${i + 1}`}`}
          margin="dense"
          variant="outlined"
          autoComplete="on"
          style={{ flex: 1 }}
          autoFocus
          value={inputValue ? inputValue.value : ''}
          onChange={(e) => handleTextInput(i, e.target.value)}
          onBlur={() => validateInput(i)}
          error={invalidInput}
          helperText={
            invalidInput
              ? 'Please enter a valid email address (example@mail.com) or 10 digit phone number'
              : ''
          }
          InputProps={{
            startAdornment: (
              <div
                style={{
                  width: '3rem',
                  padding: '0.25rem 0 0 0.5rem',
                }}
              >
                {inputValue ? (
                  <>
                    {inputValue.type === 'email' && (
                      <EmailIcon className={`${classes.successIcon} share-icon-success`} />
                    )}
                    {inputValue.type === 'phone' && (
                      <PhoneIcon className={`${classes.successIcon} share-icon-success`} />
                    )}
                    {inputValue.type !== 'email' && inputValues[i].type !== 'phone' && (
                      <ErrorIcon className={`${classes.errorIcon} share-icon-error`} />
                    )}
                  </>
                ) : null}
              </div>
            ),
            endAdornment: inputValue?.value?.length ? (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClearInput(i)}>
                  <CloseIcon alt="" />
                  <Typography variant="srOnly">
                    Clear recipient input {helperSRText} {inputValue.value}.
                  </Typography>
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            maxLength: 255,
            ref: i === numFields - 2 ? previouslyAddedRecipientFieldRef : undefined,
          }}
        />
        <div style={{ width: '3rem', marginTop: 4 }}>
          {i !== 0 && i === numFields - 1 ? (
            <IconButton onClick={handleRemoveField}>
              <CloseIcon alt="" />
              <Typography variant="srOnly">Remove recipient {helperSRText}</Typography>
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <>
      <Modal
        open
        handleClose={() => dispatch(actions.ui.closeProfileModal())}
        title={title}
        fullWidth
        ariaId="share-provider"
        fullScreen={smDown}
        hasDescription
      >
        <Container component="form" maxWidth="sm" style={{ padding: smDown ? 20 : 0 }}>
          <Typography id="share-provider-description" paragraph>
            Send this {resultType === PROVIDER_RESULT_TYPE ? resultType : 'facility'}'s profile to
            yourself or share it with someone else.
          </Typography>

          {textFields}

          {numFields !== MaxFields && (
            <Button onClick={handleAddField} startIcon={<AddIcon />} className={classes.addButton}>
              Add another recipient
            </Button>
          )}
          {inputValues.filter((input) => input.type === 'email').length > 0 && (
            <TextField
              id="share-message"
              label="Add a message"
              multiline
              value={message}
              minRows="3"
              onChange={handleChangeMessage}
              margin="dense"
              variant="outlined"
              autoComplete="on"
              error={invalidMessage}
              helperText={messageError}
              onBlur={handleCheckMessage}
              style={{
                width: '92%',
                margin: '0.5rem 0 1rem',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 1024,
              }}
            />
          )}
          <TextField
            id="share-sender"
            label="Sent from (your name)"
            margin="dense"
            value={fromName}
            onChange={handleChangeSenderName}
            onBlur={handleCheckSentFrom}
            variant="outlined"
            autoComplete="name"
            error={invalidSentFrom}
            helperText={sentFromError}
            style={{ flex: 1, width: '92%' }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 255,
            }}
          />
          <br />
          <FormControlLabel
            control={<Checkbox onChange={handleCheckConsent} value="consent" checked={consent} />}
            label="I have obtained permission to send to the recipient(s) above. (required)"
            classes={{
              label: classes.formLabel,
            }}
            style={{ marginLeft: 0 }}
          />

          {!!error && (
            <Typography align="center" color="error">
              {error}
            </Typography>
          )}

          <Button
            disabled={
              invalidMessage ||
              invalidSentFrom ||
              inputValues.length === 0 ||
              (inputValues.filter((input) => input.type === 'email').length === 0 &&
                inputValues.filter((input) => input.type === 'phone').length === 0) ||
              !consent ||
              sending
            }
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            style={{
              display: 'block',
              margin: '1rem auto 20px',
            }}
          >
            Send
          </Button>
        </Container>
      </Modal>

      <Modal open={sending} ariaId="share-success" showX={false} hasDescription={false}>
        <div style={{ textAlign: 'center', padding: '8px 24px' }}>
          <Typography id="share-success-title" style={{ paddingBottom: 10 }}>
            {success ? 'Sent!' : 'Sending...'}
          </Typography>
          {success ? (
            <CheckOrXIcon checked className={classes.checkmark} />
          ) : (
            <CircularProgress size={50} thickness={2} />
          )}
        </div>
      </Modal>
    </>
  );
}

ShareProvider.defaultProps = {
  profileData: PropTypes.arrayOf(PropTypes.shape({})),
};

ShareProvider.propTypes = {
  profileData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ShareProvider;
