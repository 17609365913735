import React from 'react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'propTypes/index';
import { Typography } from '@material-ui/core';
import ProfileSkeleton from 'components/ModernExperience/LoadingSkeletons/ProfileSkeleton';

function SharePageSkeleton({ classes }) {
  const animation = 'wave';
  return (
    <div className={classes.root}>
      <Skeleton animation={animation}>
        {/* This text does not actually render but it gives the Skeleton a height and width to match this dummy text */}
        <Typography variant="h2" animation={animation}>
          XX Providers Were Shared With You
        </Typography>
      </Skeleton>
      <ProfileSkeleton animation={animation} />
    </div>
  );
}

SharePageSkeleton.propTypes = {
  classes: PropTypes.shape({ root: PropTypes.string.isRequired }).isRequired,
};

export default SharePageSkeleton;
