import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M17.5 13.2271C17.5 16.4599 14.8793 19.0806 11.6465 19.0806C8.41365 19.0806 5.79293 16.4599 5.79293 13.2271C5.79293 9.99425 8.41365 7.37354 11.6465 7.37354C14.8793 7.37354 17.5 9.99425 17.5 13.2271Z"
      fill="#AB5EDA"
    />
    <path
      d="M17.4997 7C17.4997 7.37098 17.1989 7.67172 16.828 7.67172C16.457 7.67172 16.1562 7.37098 16.1562 7C16.1562 6.62902 16.457 6.32828 16.828 6.32828C17.1989 6.32828 17.4997 6.62902 17.4997 7Z"
      fill="#E992FF"
    />
    <circle cx="7.46957" cy="19.9442" r="0.671717" fill="#E992FF" />
    <circle cx="5.16693" cy="6.89398" r="1.43939" fill="#E992FF" />
    <path
      d="M21.9996 18.1214C21.9996 19.3933 20.9685 20.4244 19.6966 20.4244C18.4247 20.4244 17.3936 19.3933 17.3936 18.1214C17.3936 16.8495 18.4247 15.8184 19.6966 15.8184C20.9685 15.8184 21.9996 16.8495 21.9996 18.1214Z"
      fill="#E992FF"
    />
    <circle cx="10.636" cy="4.30303" r="2.30303" fill="#E992FF" />
    <circle cx="11.5964" cy="21.5759" r="1.34343" fill="#E992FF" />
    <path
      d="M21.0003 11.0202C21.0003 11.7622 20.3989 12.3636 19.6569 12.3636C18.915 12.3636 18.3135 11.7622 18.3135 11.0202C18.3135 10.2782 18.915 9.67676 19.6569 9.67676C20.3989 9.67676 21.0003 10.2782 21.0003 11.0202Z"
      fill="#E992FF"
    />
    <circle cx="3.63173" cy="16.4896" r="1.05556" fill="#E992FF" />
    <circle cx="3.05556" cy="11.6918" r="1.05556" fill="#E992FF" />
  </>,
  'AllergyTest'
);
