import React from 'react';
import clsx from 'clsx';
import PropTypes, { RefType } from 'propTypes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(3),
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    padding: theme.spacing(2),
    width: '100%',
  },
  grey: {
    backgroundColor: theme.palette.grey[100],
    borderWidth: 0,
    borderRadius: theme.spacing(1.5),
  },
  padBottom: {
    marginBottom: 25,
  },
}));

function RoundedWrapper({ children, additionalStyles, grey, padBottom, innerRef }) {
  const classes = useStyles();
  return (
    <section
      className={clsx(classes.root, {
        [additionalStyles]: additionalStyles,
        [classes.grey]: grey,
        [classes.padBottom]: padBottom,
      })}
      ref={innerRef}
    >
      {children}
    </section>
  );
}

export default React.forwardRef((props, ref) => <RoundedWrapper {...props} innerRef={ref} />);

RoundedWrapper.propTypes = {
  innerRef: RefType,
  grey: PropTypes.bool,
  padBottom: PropTypes.bool,
  additionalStyles: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

RoundedWrapper.defaultProps = {
  grey: false,
  padBottom: false,
  additionalStyles: '',
  innerRef: undefined,
};
