import React from 'react';
import PropTypes from 'propTypes';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  specialty: {
    fontSize: '1.25rem',
  },
  title: {
    margin: 0,
  },
}));

function ProviderProfileBasicInfo({ providerId }) {
  const classes = useStyles();
  const { specialty } = useSelector(select.provider(providerId).data);
  const title = useSelector(select.provider(providerId).fullNameAndTitle);

  return (
    <div>
      <Typography variant="h2" classes={{ root: classes.title }}>
        {title}
      </Typography>
      <Typography component="span" classes={{ root: classes.specialty }}>
        {specialty}
      </Typography>
    </div>
  );
}

export default ProviderProfileBasicInfo;

ProviderProfileBasicInfo.propTypes = {
  providerId: PropTypes.string.isRequired,
};
