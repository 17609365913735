import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Modal from 'components/Modals/Modal';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    margin: '25px 0 20px',
  },
  text: {
    margin: '0 1.5rem 0 0',
  },
});

/** @deprecated */
export default function BadgeModal({ open, handleClose, returnFocus, title = '', text, children }) {
  const classes = useStyles();
  const id = title.toLowerCase().replaceAll(' ', '-');

  // if no text prop has been passed, render the children
  const renderChildren = !text;

  return (
    <Modal open={open} handleClose={handleClose} exitFunction={returnFocus} ariaId={id}>
      <div id={id} className={classes.root}>
        {renderChildren ? (
          children
        ) : (
          <>
            <Typography variant="h3" id={`${id}-title`}>
              {title}
            </Typography>
            <Typography classes={{ root: classes.text }}>{text}</Typography>
          </>
        )}
      </div>
    </Modal>
  );
}

BadgeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  returnFocus: PropTypes.func.isRequired,
  children: PropTypes.node,
};

BadgeModal.defaultProps = {
  children: undefined,
  text: undefined,
};
