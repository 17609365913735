import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'propTypes/index';

import TreatmentCategoryRow from './TreatmentCategoryRow';

const useStyles = makeStyles((theme) => ({
  tableBody: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'none',
    },
    '& > *:not(:last-child)': {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}));

export default function ServiceCostRow({ service }) {
  const classes = useStyles();

  return (
    <TableBody classes={{ root: classes.tableBody }}>
      {service.treatmentCategories.map((category) => (
        <TreatmentCategoryRow
          key={`${category.treatmentCategoryCode} - ${category.benefitFeatureSubtitle}`}
          treatmentCategory={category}
        />
      ))}
    </TableBody>
  );
}

ServiceCostRow.propTypes = {
  service: PropTypes.shape({
    treatmentCategories: PropTypes.arrayOf(
      PropTypes.shape({
        treatmentCategoryCode: PropTypes.string,
        treatmentCategoryName: PropTypes.string,
        memberCost: PropTypes.number,
        serviceId: PropTypes.number,
      })
    ),
  }).isRequired,
};
