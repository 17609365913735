/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { logDevMessage } from 'utils/utils';
import {
  CARE_CATEGORIES,
  PLACES_BY_IDS,
  PLACE_RESULT_TYPE,
  PROVIDERS_BY_IDS,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';
import { ENDPOINTS, SEARCH_LIMIT, SEARCH_METHODS, PARAMS } from './fusionConstants';
import * as selectSearch from '../slices/search/selectSearch';
import * as selectNetworks from '../slices/config/selectNetworks';
import * as selectFilters from '../slices/filters/selectFilters';
import * as selectLocation from '../slices/location/selectLocation';
import * as selectMap from '../slices/map/selectMap';
import { apiUrl as selectApiUrl } from '../slices/config/selectConfig';

/* ***************************************** */
/* ************ search method ************** */
/* ***************************************** */
export const searchMethod = createSelector(
  [
    selectSearch.type,
    selectSearch.serviceType,
    selectSearch.isSingleProviderSearch,
    selectSearch.isSinglePlaceSearch,
    selectSearch.isPlaceByExactNameSearch,
  ],
  (
    searchType,
    serviceType,
    isSingleProviderSearch,
    isSinglePlaceSearch,
    isPlaceByExactNameSearch
  ) => {
    switch (searchType) {
      case CARE_CATEGORIES.PROVIDER_SPECIALTY:
        return SEARCH_METHODS.PROVIDERS_BY_SPECIALTY;
      case CARE_CATEGORIES.PROVIDER_NAME:
        if (isSingleProviderSearch) return SEARCH_METHODS.PROVIDER_BY_ENTITY_ID;
        return SEARCH_METHODS.PROVIDERS_BY_NAME;
      case CARE_CATEGORIES.FACILITY_TYPE:
        return SEARCH_METHODS.PLACES_BY_TYPE;
      case CARE_CATEGORIES.FACILITY_NAME:
        if (isSinglePlaceSearch) return SEARCH_METHODS.PLACE_BY_ID;
        if (isPlaceByExactNameSearch) return SEARCH_METHODS.PLACE_BY_NAME_EXACT;
        return SEARCH_METHODS.PLACES_BY_LOOSE_NAME;
      case CARE_CATEGORIES.SERVICE:
        if (serviceType === PROVIDER_RESULT_TYPE) return SEARCH_METHODS.PROVIDERS_BY_SERVICE;
        if (serviceType === PLACE_RESULT_TYPE) return SEARCH_METHODS.PLACES_BY_SERVICE;
        return undefined;
      case PROVIDERS_BY_IDS:
        return SEARCH_METHODS.PROVIDERS_BY_ENTITY_IDS;
      case PLACES_BY_IDS:
        return SEARCH_METHODS.PLACES_BY_ID;
      default:
        return undefined;
    }
  }
);

/* ***************************************** */
/* *******+******* endpoint **************** */
/* ***************************************** */
export const endpoint = createSelector(
  [searchMethod, selectSearch.entityId],
  (searchMethod, entityId) => {
    switch (searchMethod) {
      // Providers
      case SEARCH_METHODS.PROVIDERS_BY_SPECIALTY:
      case SEARCH_METHODS.PROVIDERS_BY_NAME:
      case SEARCH_METHODS.PROVIDERS_BY_SERVICE:
      case SEARCH_METHODS.PROVIDERS_BY_ENTITY_IDS:
        return ENDPOINTS.PROVIDERS;
      case SEARCH_METHODS.PROVIDER_BY_ENTITY_ID:
        return `${ENDPOINTS.PROVIDERS}/${encodeURIComponent(entityId)}`;

      // Places
      case SEARCH_METHODS.PLACES_BY_TYPE:
      case SEARCH_METHODS.PLACES_BY_LOOSE_NAME:
      case SEARCH_METHODS.PLACE_BY_NAME_EXACT:
      case SEARCH_METHODS.PLACES_BY_SERVICE:
      case SEARCH_METHODS.PLACES_BY_ID:
        return ENDPOINTS.PLACES;
      case SEARCH_METHODS.PLACE_BY_ID:
        return `${ENDPOINTS.PLACES}/${encodeURIComponent(entityId)}`;
      default:
        return undefined;
    }
  }
);

/* ***************************************** */
/* ************* query params ************** */
/* ***************************************** */

/* Below is a selector for every potential query param sent to fusion.
 * Each selector should either return a valid query param pair: example networkSlug=bcbsarkansas
 * or and empty string if there should be no query param
 * */

export const networkParam = createSelector([selectNetworks.currentSlug], (currentSlug) => {
  if (!currentSlug) return '';
  return `${PARAMS.NETWORK_SLUG}=${encodeURIComponent(currentSlug)}`;
});

export const limitParam = () => `${PARAMS.LIMIT}=${SEARCH_LIMIT}`; // Currently we don't ever change the limit value (the results per page). In the future we may, and would just need to change this function to a selector.
export const offsetParam = () => `${PARAMS.OFFSET}=0`; // Currently we only start at an offset of 0. Fetches for next pages use the prebuilt url from the response. We never explicitly set a modified offset.

export const orderingParam = createSelector(
  [endpoint, selectFilters.providerSort, selectFilters.placeSort],
  (endpoint, providerSort, placeSort) => {
    switch (endpoint) {
      case ENDPOINTS.PROVIDERS:
        return `${PARAMS.ORDERING}=${providerSort}`;
      case ENDPOINTS.PLACES:
        return `${PARAMS.ORDERING}=${placeSort}`;
      default:
        return '';
    }
  }
);

export const locationParam = createSelector(
  [selectLocation.latLong],
  (coords) => `${PARAMS.LOCATION}=${coords.latitude},${coords.longitude}`
);

/** This returns a string containing the location param, and either the radius or bounding box param */
export const locationAndAreaParams = createSelector(
  [
    locationParam,
    selectFilters.radius,
    selectSearch.isBoundingBoxSearch,
    selectMap.boundsAsQueryParams,
  ],
  (locationParam, radius, isBoundingBoxSearch, boundsAsQueryParams) => {
    if (isBoundingBoxSearch) {
      const boundsParam = `${PARAMS.BOUNDING_BOX}=${boundsAsQueryParams}`;
      return `${locationParam}&${boundsParam}`;
    }

    return `${locationParam}&${PARAMS.RADIUS}=${radius}mi`;
  }
);

export const qParam = createSelector([selectSearch.text], (searchText) => {
  if (!searchText) return '';
  return `${PARAMS.Q}=${encodeURIComponent(searchText)}`;
});

export const nameExactParam = createSelector([selectSearch.text], (searchText) => {
  if (!searchText) return '';
  return `${PARAMS.NAME_EXACT}=${encodeURIComponent(searchText)}`;
});

export const specialtyIdParam = createSelector([selectSearch.specialtyId], (specialtyId) => {
  if (!specialtyId) return '';
  return `${PARAMS.SPECIALTY_ID}=${specialtyId}`;
});

export const subspecialtyIdParam = createSelector(
  [selectSearch.subspecialtyId],
  (subspecialtyId) => {
    if (!subspecialtyId || subspecialtyId < 1) return '';
    return `${PARAMS.SUBSPECIALTY_ID}=${subspecialtyId}`;
  }
);

export const serviceIdParam = createSelector([selectSearch.serviceId], (serviceId) => {
  if (!serviceId) return '';
  return `${PARAMS.SERVICE_ID}=${serviceId}`;
});

export const providerFilterParams = selectFilters.providerFiltersAsQueryParams;
export const placeFilterParams = selectFilters.placeFiltersAsQueryParams;

export const stateParam = createSelector(
  [selectLocation.locationComponents],
  (locationComponents = {}) => {
    const { state = null } = locationComponents;

    if (!state) return '';
    return `${PARAMS.STATE}=${encodeURIComponent(state)}`;
  }
);

export const npiInParam = createSelector([selectSearch.entityIds], (entityIds) => {
  if (!entityIds || entityIds.length === 0) return '';
  return `${PARAMS.NPI_IN}=${entityIds.map(encodeURIComponent).join(',')}`;
});

export const idInParam = createSelector([selectSearch.entityIds], (entityIds) => {
  if (!entityIds || entityIds.length === 0) return '';
  return `${PARAMS.ID_IN}=${entityIds.map(encodeURIComponent).join(',')}`;
});

/* ***************************************** */
/* ***************** urls ****************** */
/* ***************************************** */

export function joinParams(paramsArray = []) {
  // filter out falsy values like empty strings or undefined, then join remaining params with "&"
  return paramsArray.filter((param) => Boolean(param)).join('&');
}

/**
 * Use this function to quickly compose a search URL. It will automatically create you a selector
 * with the baseUrl & endpoint. You just need to determine which queryParam selectors to include in
 * the URL you are composing.
 * @param {Function[]} includedQueryParams An array of query param selectors
 * @returns {Function} Returns the selector built with createSelector
 */
export function composeUrlSelector(includedQueryParams = []) {
  return createSelector(
    [selectApiUrl, endpoint, networkParam, ...includedQueryParams], // every url uses an base url, endpoint, and network queryparam
    (baseUrl, endpoint, ...queryParams) => {
      // verify that there is a base url
      if (!baseUrl) {
        logDevMessage('Missing baseUrl in composeUrlSelector');
        return '';
      }

      // verify that there is a an endpoint
      if (!endpoint) {
        logDevMessage('Missing endpoint in composeUrlSelector');
        return '';
      }

      // verify that there is a network slug, first element in query params should always be network
      if (!queryParams[0]) {
        logDevMessage('Missing network slug in composeUrlSelector');
        return '';
      }

      const querystring = joinParams(queryParams);
      return `${baseUrl}${endpoint}/?${querystring}`;
    }
  );
}

export const providerByEntityIdUrl = composeUrlSelector([]); // this search type uses location params, otherwise we might end up with a provider's location greater than 250 miles away showing
export const placeByIdUrl = composeUrlSelector([]); // this search type does not use any other query params

export const providersByNameUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  qParam,
  providerFilterParams,
]);

export const providersBySpecialtyUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  specialtyIdParam,
  subspecialtyIdParam,
  providerFilterParams,
]);

export const providersByServiceUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  serviceIdParam,
  providerFilterParams,
]);

export const placesByServiceUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  serviceIdParam,
  placeFilterParams,
]);

export const placesByTypeUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  specialtyIdParam,
  subspecialtyIdParam,
  placeFilterParams,
]);

export const placesByLooseNameUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  qParam,
  placeFilterParams,
]);

export const placesByNameExactUrl = composeUrlSelector([
  limitParam,
  orderingParam,
  locationAndAreaParams,
  nameExactParam,
  placeFilterParams,
]);

export const providersByEntityIdsUrl = composeUrlSelector([npiInParam]);
export const placesByIdsUrl = composeUrlSelector([idInParam]);

/* ***************************************** */
/* ****** generic search url selector ****** */
/* ***************************************** */

export const searchUrl = createSelector(
  [
    searchMethod,
    providerByEntityIdUrl,
    providersByEntityIdsUrl,
    providersByNameUrl,
    providersBySpecialtyUrl,
    providersByServiceUrl,
    placesByServiceUrl,
    placesByTypeUrl,
    placesByNameExactUrl,
    placesByLooseNameUrl,
    placeByIdUrl,
    placesByIdsUrl,
  ],
  (
    searchMethod,
    providerByEntityIdUrl,
    providersByEntityIdsUrl,
    providersByNameUrl,
    providersBySpecialtyUrl,
    providersByServiceUrl,
    placesByServiceUrl,
    placesByTypeUrl,
    placesByNameExactUrl,
    placesByLooseNameUrl,
    placeByIdUrl,
    placesByIdsUrl
  ) => {
    switch (searchMethod) {
      case SEARCH_METHODS.PROVIDER_BY_ENTITY_ID:
        return providerByEntityIdUrl;
      case SEARCH_METHODS.PROVIDERS_BY_ENTITY_IDS:
        return providersByEntityIdsUrl;
      case SEARCH_METHODS.PROVIDERS_BY_NAME:
        return providersByNameUrl;
      case SEARCH_METHODS.PROVIDERS_BY_SPECIALTY:
        return providersBySpecialtyUrl;
      case SEARCH_METHODS.PROVIDERS_BY_SERVICE:
        return providersByServiceUrl;
      case SEARCH_METHODS.PLACES_BY_SERVICE:
        return placesByServiceUrl;
      case SEARCH_METHODS.PLACES_BY_TYPE:
        return placesByTypeUrl;
      case SEARCH_METHODS.PLACES_BY_LOOSE_NAME:
        return placesByLooseNameUrl;
      case SEARCH_METHODS.PLACE_BY_NAME_EXACT:
        return placesByNameExactUrl;
      case SEARCH_METHODS.PLACE_BY_ID:
        return placeByIdUrl;
      case SEARCH_METHODS.PLACES_BY_ID:
        return placesByIdsUrl;
      default:
        // because this selector gets called inside an async thunk, this Error message gets handled by the resultsSlice reducer and is subsequently displayed to the user.
        throw new Error(
          'Invalid search method. Please select a menu option for "What are you searching for?"'
        );
    }
  }
);

export const serviceUrl = createSelector(
  [selectApiUrl, serviceIdParam, networkParam, stateParam],
  (baseUrl, serviceId, network, state) => {
    if (!serviceId || !network || !state) {
      return '';
    }

    return `${baseUrl}${ENDPOINTS.SERVICE}/?${joinParams([serviceId, network, state])}`;
  }
);

/** This selector takes the calculated searchUrl and changes the endpoint to the corresponding "closest-x-location" endpoint. Then removes the radius & bounding box params. */
export const expandSearchUrl = createSelector([searchUrl], (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  let path = urlObj.pathname;
  // replace endpoint
  if (path.endsWith(ENDPOINTS.PROVIDERS)) {
    path = path.replace(ENDPOINTS.PROVIDERS, ENDPOINTS.CLOSEST_PROVIDERS);
  } else if (path.endsWith(ENDPOINTS.PLACES)) {
    path = path.replace(ENDPOINTS.PLACES, ENDPOINTS.CLOSEST_PLACES);
  }
  urlObj.pathname = path;

  // remove radius
  if (params.has(PARAMS.RADIUS)) params.delete(PARAMS.RADIUS);

  // remove bounding box
  if (params.has(PARAMS.BOUNDING_BOX)) params.delete(PARAMS.BOUNDING_BOX);

  // add replace original params with new params
  urlObj.search = params.toString();
  return urlObj.toString();
});
