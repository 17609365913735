import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import { List, ListItem, ListItemText, Link as MuiLink } from '@material-ui/core';

import ChevronRight from '@material-ui/icons/ChevronRight';

import { actions, select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  linkRoot: {
    padding: '0 4px',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 12,
      margin: '3px 0 0 3px',
    },
  },
  footerNav: {
    maxWidth: '80%',
    textAlign: 'left',
    display: 'flex',
    paddingBottom: '10px',
  },
  listRoot: {
    margin: '0rem 0',
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
    minWidth: '50%',
  },
  listItemRoot: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: 'auto',
      padding: '0px 14px',
      borderLeft: `1px solid ${theme.palette.text.secondary}`,
    },
    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
  listItemTextRoot: {
    fontSize: '.875rem',
  },
}));

/**
 * ---- Adding links to the footer ----
 *
 * 1. External links - use the extended Mui Link component
 *       <Link>...</Link>
 * 2. Internal route - use react-router Link component and pass Mui Link for styles
 *       <Link component={RouterLink}>...</Link>
 * 3. Action button - use Mui Link component and pass "button" for semantics
 *       <Link component="button">...</Link>
 */

function Link(props) {
  return <MuiLink {...props} underline="hover" color="primary" />;
}

function FooterNav() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showDisclaimer = useSelector(select.featureFlags.showDisclaimer);
  const loadingClientConfig = useSelector(select.config.isLoading);
  const isAuthenticated = useSelector(select.auth.isLoggedIn);

  const schedulePhoneNumber = useSelector(select.content.schedulePhoneNumber);

  const handleFeedback = () => {
    dispatch(actions.ui.openProfileModal('feedback'));
  };
  const handleCall = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${schedulePhoneNumber}`;
  };

  return (
    <nav aria-label="Footer navigation" className={classes.footerNav}>
      <List classes={{ root: classes.listRoot }}>
        <ListItem classes={{ root: classes.listItemRoot }}>
          <Link
            to="/privacy"
            component={RouterLink}
            classes={{
              root: classes.linkRoot,
            }}
          >
            <ListItemText
              classes={{ root: classes.listItemTextRoot }}
              primary="Privacy Statement"
            />
            <ChevronRight />
          </Link>
        </ListItem>

        <ListItem classes={{ root: classes.listItemRoot }}>
          <Link
            to="/terms"
            component={RouterLink}
            classes={{
              root: classes.linkRoot,
            }}
          >
            <ListItemText classes={{ root: classes.listItemTextRoot }} primary="Terms of Use" />
            <ChevronRight />
          </Link>
        </ListItem>

        {showDisclaimer && (
          <ListItem classes={{ root: classes.listItemRoot }}>
            <Link
              to="/disclaimer"
              component={RouterLink}
              classes={{
                root: classes.linkRoot,
              }}
            >
              <ListItemText classes={{ root: classes.listItemTextRoot }} primary="Disclaimer" />
              <ChevronRight />
            </Link>
          </ListItem>
        )}

        {!loadingClientConfig && schedulePhoneNumber && isAuthenticated && (
          <ListItem classes={{ root: classes.listItemRoot }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              onClick={handleCall}
              classes={{
                root: classes.linkRoot,
              }}
            >
              <ListItemText
                classes={{ root: classes.listItemTextRoot }}
                primary={`Need Help? ${schedulePhoneNumber}`}
              />
              <ChevronRight />
            </Link>
          </ListItem>
        )}
        <ListItem classes={{ root: classes.listItemRoot }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={handleFeedback}
            component="button"
            classes={{
              root: classes.linkRoot,
            }}
          >
            <ListItemText classes={{ root: classes.listItemTextRoot }} primary="Submit Feedback" />
            <ChevronRight />
          </Link>
        </ListItem>
      </List>
    </nav>
  );
}

export default FooterNav;
