import { useCallback } from 'react';

import { transformToUsaE164 } from 'utils/utils';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

function buildBodyFromFormValues(formValues, departmentId) {
  const { firstName, lastName, phone, email, city, state, zip, subject, description } = formValues;

  const formattedPhone = transformToUsaE164(phone);

  // set required fields
  const body = {
    departmentId,
    contact: {
      firstName,
      lastName,
    },
    email,
    subject,
    description,
  };

  // set optional fields when available
  if (formattedPhone) body.phone = formattedPhone;
  if (city || state || zip) body.cf = {};
  if (city) body.cf.cf_city_state_zip_code = city;
  if (state) body.cf.cf_state = state;
  if (zip) body.cf.cf_zip = zip;
  return body;
}

export default function useSubmitSupportForm({ errorStatus, successStatus }) {
  const departmentId = useSelector(select.content.zohoDepartmentId);

  // axios instance
  const axios = useSelector(select.axios.axiosInstance);

  const handleSubmit = useCallback(
    async (formValues, formikTools) => {
      const postBody = buildBodyFromFormValues(formValues, departmentId);

      try {
        await axios.post('/support/', postBody);
        formikTools.setStatus(successStatus);
      } catch (err) {
        formikTools.setStatus(errorStatus);
        if (err.response.status === 400) {
          // bad request, check for invalid field
          const { data = {} } = err.response;
          if (data.phone) formikTools.setFieldError('phone', data.phone);
          if (data.email) formikTools.setFieldError('email', data.email);
          if (data.description) formikTools.setFieldError('description', data.description);
        }
      }
    },
    [axios, departmentId, errorStatus, successStatus]
  );

  return handleSubmit;
}
