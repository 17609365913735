/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { UI_SLICE_NAME } from '../slicesNames';
import * as uiConstants from './uiConstants';

// Basic selectors
/** @returns {boolean} */
export const appErrorModalOpen = (state) => state[UI_SLICE_NAME].modals.appError;

/** @returns {boolean} */
export const locationModalOpen = (state) => state[UI_SLICE_NAME].modals.location;

/** @returns {boolean} */
export const providerSearchModalOpen = (state) => state[UI_SLICE_NAME].modals.providerSearch;

/** @returns {boolean} */
export const updateRegionModalOpen = (state) => state[UI_SLICE_NAME].modals.updateRegion;

/** @returns {boolean} */
export const highQualityModalOpen = (state) => state[UI_SLICE_NAME].modals.highQuality;

/** @returns {boolean} */
export const ClientConfigDevToolsModalOpen = (state) =>
  state[UI_SLICE_NAME].modals.ClientConfigDevTools;

/** @returns {boolean} */
export const showCookieBanner = (state) => state[UI_SLICE_NAME].showCookieBanner;

/** @returns {boolean} */
export const userHasMetOutOfPocketMaximum = (state) =>
  state[UI_SLICE_NAME].userHasMetOutOfPocketMaximum;

/** @returns {boolean} */
export const chatModalOpen = (state) => state[UI_SLICE_NAME].modals.chat;

/** @returns {boolean} */
export const filterMenuOpen = (state) => state[UI_SLICE_NAME].modals.filter;

/** @returns {boolean} */
export const isCssEnabled = (state) => state[UI_SLICE_NAME].isCssEnabled;

/** @returns {(string|null)} */
export const returnFocusToKey = (state) => state[UI_SLICE_NAME].returnFocusToKey;

export const showResultsMap = (state) => state[UI_SLICE_NAME].showResultsMap;

/** Selects the profileModal object containing the type & data. Additionally adds an isOpen property to the object.
 * @returns {Object} { type: string, data: any, isOpen: boolean }
 */
export const profileModal = createSelector(
  [(state) => state[UI_SLICE_NAME].profileModal],
  (profileModalObject) => ({
    ...profileModalObject,
    isOpen: Boolean(profileModalObject.type),
  })
);

export const breakpoint = (state) => state[UI_SLICE_NAME].breakpoint;

/**
 * Breakpoints: https://v4.mui.com/customization/breakpoints/#default-breakpoints
 * lgDown - desktop
 * mdDown - laptop (<1200px)
 * smDown - tablet (<900px)
 * xsDown - mobile (<600px)
 */
export const isXs = createSelector([breakpoint], (breakpoint) => breakpoint === 'xs');
export const isSm = createSelector([breakpoint], (breakpoint) => breakpoint === 'sm');
export const isMd = createSelector([breakpoint], (breakpoint) => breakpoint === 'md');
export const isLg = createSelector([breakpoint], (breakpoint) => breakpoint === 'lg');
export const isXsDown = createSelector([breakpoint], (breakpoint) =>
  uiConstants.xsDownBreakpoints.includes(breakpoint)
);
export const isSmDown = createSelector([breakpoint], (breakpoint) =>
  uiConstants.smDownBreakpoints.includes(breakpoint)
);
export const isMdDown = createSelector([breakpoint], (breakpoint) =>
  uiConstants.mdDownBreakpoints.includes(breakpoint)
);
export const isLgDown = createSelector([breakpoint], (breakpoint) =>
  uiConstants.lgDownBreakpoints.includes(breakpoint)
);

const {
  DESKTOP_CONDENSED_VIEW,
  DESKTOP_EXPANDED_VIEW,
  DESKTOP_SMALL_VIEW,
  MOBILE_CONDENSED_VIEW,
  MOBILE_EXPANDED_VIEW,
} = uiConstants;
export const cardView = createSelector(
  [isSmDown, isMdDown, showResultsMap],
  (isSmDown, isMdDown, mapIsOpen) => {
    // screens under 600px
    if (isSmDown) {
      if (mapIsOpen) {
        return MOBILE_CONDENSED_VIEW;
      }
      return MOBILE_EXPANDED_VIEW;
    }

    // screens between 960 and 1280px
    if (isMdDown) {
      return DESKTOP_SMALL_VIEW;
    }

    // screens over 1280px
    if (mapIsOpen) {
      return DESKTOP_CONDENSED_VIEW;
    }

    return DESKTOP_EXPANDED_VIEW;
  }
);
