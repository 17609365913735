import React, { useCallback, useEffect } from 'react';
import { MOBILE_CONDENSED_VIEW } from 'store/slices/ui/uiConstants';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import { makeStyles } from '@material-ui/styles';
import { _analyticsActions } from 'analytics/index';

import { ProviderResultCardMobileContent } from '../ProviderCard/ProviderResultCard';
import RoundedWrapper from '../StyledWrappers/RoundedWrapper';
import ResultCardMapViewHeader from '../ResultCardMapViewHeader';
import { PlaceResultCardMobileContent } from '../PlaceCard/PlaceResultCard';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    background: 'white',
  },
}));

export default function ActiveResultCard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const selectedId = useSelector(select.results.selectedId);
  const handleClose = useCallback(() => dispatch(actions.results.clearActiveResults()), [dispatch]);

  useEffect(() => {
    dispatch(_analyticsActions.mapMarkerView(selectedId));
  }, [selectedId, dispatch]);

  return (
    <RoundedWrapper additionalStyles={classes.card}>
      <ResultCardMapViewHeader onClick={handleClose} />
      {wasPlaceSearch ? (
        <PlaceResultCardMobileContent view={MOBILE_CONDENSED_VIEW} placeId={selectedId} />
      ) : (
        <ProviderResultCardMobileContent view={MOBILE_CONDENSED_VIEW} providerId={selectedId} />
      )}
    </RoundedWrapper>
  );
}
