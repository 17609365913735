import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ResultProfile from 'components/ModernExperience/Profile/ResultProfile';
import ProfileTabLabel from 'components/ModernExperience/Profile/ProfileTabLabel';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    maxWidth: 754, // this value matches the max width of the ProviderProfile and the PlaceProfile
    marginBottom: theme.spacing(1),
  },
  tabIndicator: {
    display: 'none', // hide the built in MUI sliding indicator
  },
  tabsFlexContainer: {
    '& > *': {
      // select all children of the flex container (all children should be tabs)
      flex: 1, // apply equal flex so each tab is the same width and cover the entire container
      maxWidth: 'none', // remove the max width that's inherited so the full container space is taken up
      minWidth: 150, // setting a min width allows tabs to wrap when they get too small
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // on xs screens stack the tabs vertically
    },
  },
  tabRoot: {
    // this is the tab root (a button)
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius * 3,
    margin: theme.spacing(0.5),
  },
  tabLabel: {
    width: '100%',
  },
  tabSelected: {
    borderColor: theme.palette.text.secondary,
  },
}));

function ResultTab({ id, ...props }) {
  const classes = useStyles();

  return (
    <Tab
      label={<ProfileTabLabel id={id} additionalClass={classes.tabLabel} />}
      classes={{
        root: classes.tabRoot,
        selected: classes.tabSelected,
      }}
      {...props}
    />
  );
}

ResultTab.propTypes = {
  id: PropTypes.string.isRequired,
};

export default function ResultProfileTabs({ ids = [] }) {
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = useCallback(
    (evt, newIndex) => setCurrentTabIndex(newIndex),
    [setCurrentTabIndex]
  );

  if (!Array.isArray(ids) || ids.length < 1) return null;

  const currentId = ids[currentTabIndex];
  return (
    <div>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabIndicator,
        }}
      >
        {ids.map((id) => (
          <ResultTab id={id} key={id} />
        ))}
      </Tabs>

      <ResultProfile id={currentId} />
    </div>
  );
}

ResultProfileTabs.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};
