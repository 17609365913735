import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Modal from 'components/Modals/Modal';
import UpdateNetworkForm from 'components/Forms/NetworkSelects/UpdateNetworkForm';
import { CHOOSE_NETWORK_TEXT } from 'utils/constants';
import { actions, select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    zIndex: 2,
    padding: '20px 10px 30px',
    [theme.breakpoints.up('md')]: {
      minWidth: 450,
    },
  },
  headerText: {
    margin: `${theme.spacing(3)}px auto`,
    maxWidth: 360,
    textAlign: 'center',
  },
}));

function UpdateRegionModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateRegionModalOpen = useSelector(select.ui.updateRegionModalOpen);
  const isSmDown = useSelector(select.ui.isSmDown);

  const handleClose = () => {
    dispatch(actions.ui.closeModal('updateRegion'));
  };

  return (
    <Modal
      open={updateRegionModalOpen}
      ariaId="update-region-modal"
      handleClose={handleClose}
      fullScreen={isSmDown}
    >
      <Container component="section" className={`${classes.container}`}>
        <Typography
          variant="h2"
          id="update-region-modal-title"
          classes={{ root: classes.headerText }}
          color="primary"
        >
          {CHOOSE_NETWORK_TEXT}
        </Typography>
        <UpdateNetworkForm regionModalOpen />
      </Container>
    </Modal>
  );
}

export default UpdateRegionModal;
