import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SearchThisAreaButton from './SearchThisAreaButton';
import ZoomControls from './ZoomControls';

const useStyles = makeStyles({
  searchThisAreaButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  zoomControlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function MapController(props) {
  const classes = useStyles();

  return (
    <Grid container {...props}>
      {/* Empty grid item to keep 3 column spacing equal */}
      <Grid item xs={2} />

      <Grid item xs={8} className={classes.searchThisAreaButtonContainer}>
        <SearchThisAreaButton />
      </Grid>

      <Grid item xs={2} className={classes.zoomControlContainer}>
        <ZoomControls />
      </Grid>
    </Grid>
  );
}

export default React.memo(MapController);
