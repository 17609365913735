import React from 'react';
import { List, ListItem } from '@material-ui/core';
import PropTypes from 'propTypes/index';

import { PROVIDER_CARD_VIEW_OPTIONS } from 'store/slices/ui/uiConstants';
import ResultCard from './ResultCards/ResultCard';

export default function ResultCardList({ resultIds, view }) {
  return (
    <List style={{ flex: '1 0 0px', padding: 0 }}>
      {resultIds.map((resultId, i) => (
        <ListItem data-testid={`result-card-${i}`} disableGutters key={resultId}>
          <ResultCard resultId={resultId} view={view} />
        </ListItem>
      ))}
    </List>
  );
}

ResultCardList.propTypes = {
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
