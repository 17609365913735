import { DEFAULT_LOCATIONS, PASSWORD } from 'utils/constants';
import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

const token = 'Token 5cfe5ed5bb450ca927646d5622f6efe9ebf0f74b';

const qualityForumConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'qualityforum',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'qualityforum',
  EMBOLD_CLIENT: 'qualityforum',
  EMBOLD_CLIENT_SLUG: 'qualityforum',

  // toggles
  SHOW_COST: false,
  SHOW_DISCLAIMER: false,
  SHOW_DR_SCORE: false,

  // login
  LOGIN_NETWORK_NAME: 'qualityForumUserId',
  SHOW_REGION_SELECTOR: false,
  LOGIN_COOKIE_AUTH_REGEX: ['QualityForum2024|qualityforum'],

  // other
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Dallas },

  // content
  DISPLAY_NAME: 'Quality Forum',
  SCHEDULE_PHONE_NUMBER: '',
  HEADER_LOGO: emboldLogo,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  FORM_VARIANT: PASSWORD,
  ADD_ICON: false,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE:
    'Quality Forum and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  NO_WELCOME_LINK: false,
};

export default qualityForumConfig;
