import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import {
  CITY,
  DATE_OF_VISIT,
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_EMAIL,
  PROVIDER_NPI,
  STATE,
  ZIP,
} from './redeemRewardForm.schema';

function buildBodyFromFormValues(formValues) {
  const body = {
    email: formValues[PREFERRED_EMAIL],
    member_first_name: formValues[FIRST_NAME],
    member_last_name: formValues[LAST_NAME],
    date: formValues[DATE_OF_VISIT],
    provider_npi: formValues[PROVIDER_NPI],
  };

  if (formValues[CITY]) body.city = formValues[CITY];
  if (formValues[STATE]) body.state = formValues[STATE];
  if (formValues[ZIP]) body.zipcode = formValues[ZIP];

  return body;
}

export default function useSubmitRedeemRewardForm({
  errorStatus,
  successStatus,
  setSubmissionError,
}) {
  // axios instance
  const axios = useSelector(select.axios.axiosInstance);
  const departmentId = useSelector(select.content.zohoDepartmentId);
  const networkSlug = useSelector(select.networks.currentSlug);

  const handleSubmit = useCallback(
    async (formValues, formikTools) => {
      const postBody = buildBodyFromFormValues(formValues);
      // attach additional required fields not provided by the form
      postBody.department_id = departmentId;
      postBody.network_slug = networkSlug;

      try {
        await axios.post('/rewards-campaign/redeem/', postBody);
        formikTools.setStatus(successStatus);
      } catch (err) {
        const { response = {} } = err;
        const { status } = response;

        // handle user facing message for specific error codes
        let userMessage;
        if (status === 409) {
          // for 409's provide a more detailed message for the user, example: Max number of submissions reached for campaign
          const { detail } = response.data || {};
          userMessage = detail;
        }

        if (!userMessage)
          userMessage =
            'Looks like there was an issue handling your request. Please refresh your page or try again later.';

        setSubmissionError(userMessage);
        formikTools.setStatus(errorStatus);
      }
    },
    [errorStatus, successStatus, axios, networkSlug, departmentId, setSubmissionError]
  );

  return handleSubmit;
}
