import { createSlice } from '@reduxjs/toolkit';

import { getEnv, getLocalStorageExpire } from 'utils/utils';
import { handleNetworkChange } from 'store/appActions';
import { userLoggedIn, userLoggedOut } from 'store/appThunks';
import { LOGIN_NETWORK_NAME_VERSION } from 'utils/constants';
import { CONFIG_SLICE_NAME } from '../slicesNames';
import defaultConfig from './localConfigurations/defaultConfig';
import configThunks from './configThunks';
import getClientConfig from './localConfigurations/getClientConfig';

const localClientConfig = getClientConfig();
const combinedLocalConfig = { ...defaultConfig, ...localClientConfig };

const loginRegex = new RegExp(...combinedLocalConfig.LOGIN_COOKIE_AUTH_REGEX);
const cookieValue = getLocalStorageExpire(
  combinedLocalConfig.LOGIN_NETWORK_NAME,
  LOGIN_NETWORK_NAME_VERSION
);
export const validLoginCookie = loginRegex.test(cookieValue);

let localStorageNetworkSlug = null;
if (combinedLocalConfig.LOGIN_NETWORK_NAME === 'msftUserId') {
  const memberId = validLoginCookie ? cookieValue : null;
  localStorageNetworkSlug = validLoginCookie ? memberId?.substring(0, 3)?.toLowerCase() : null;
} else {
  localStorageNetworkSlug = validLoginCookie ? cookieValue : null;
}

const initialState = {
  isLoading: false,
  error: null,
  localClientToUse: localStorage.getItem('localClientToUse') || 'walmart',
  currentNetworkSlug: localStorageNetworkSlug,
  values: {
    ...combinedLocalConfig,
  },
};

// setup local, testing and staging specifics
const env = getEnv();
if (env === 'local' || env === 'testing') {
  // change this value for api url
  if (env === 'local') {
    initialState.values.API_URL =
      localStorage.getItem('localApiUrlToUse') || 'https://fusion.dev.emboldhealth.com/api/pg';

    initialState.values.API_TOKEN =
      localStorage.getItem('localApiTokenToUse') || initialState.values.API_TOKEN;
  } else {
    initialState.values.API_URL =
      process.env.REACT_APP_API_URL || 'https://fusion.dev.emboldhealth.com/api/pg';
  }
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;

  if (process.env.REACT_APP_API_TOKEN) {
    // change this block for api token
    initialState.values.API_TOKEN = process.env.REACT_APP_API_TOKEN;
  }
} else if (env === 'staging') {
  initialState.values.API_URL = 'https://fusion.staging.emboldhealth.com/api/pg';
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
}

const configSlice = createSlice({
  name: CONFIG_SLICE_NAME,
  initialState,
  reducers: {
    setNetworkSlug(state, action) {
      state.currentNetworkSlug = action.payload;
    },
    overrideValue(state, action) {
      if (process.env.NODE_ENV === 'development') {
        state.values = { ...state.values, ...action.payload };
      }
    },
    setLocalDevClientConfig(state, action) {
      const { selectedClientConfig, urlToken, apiURL, apiToken } = action.payload;

      if (selectedClientConfig !== '') {
        localStorage.setItem('localClientToUse', selectedClientConfig);
      }

      if (apiURL !== '') {
        localStorage.setItem('localApiUrlToUse', apiURL);
      } else {
        state.values.API_URL =
          process.env.REACT_APP_API_URL || 'https://fusion.dev.emboldhealth.com/api/pg';
        localStorage.setItem('localApiUrlToUse', state.values.API_URL);
      }

      if (apiToken !== '') {
        localStorage.removeItem('localApiTokenToUse');
        localStorage.setItem('localApiTokenToUse', apiToken);
      }

      if (urlToken !== '') {
        window.location.href += '?token='.concat(urlToken);
      } else {
        window.location.reload();
      }
    },
    clearLocalDevConfig() {
      localStorage.clear();
      window.location.reload();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(configThunks.fetchClientConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(configThunks.fetchClientConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.values = { ...state.values, ...action.payload };

        const availableNetworks = Object.keys(state.values.REGION_SELECTOR_CODES);
        const mpiNetwork = localStorage.getItem('mpiNetwork');

        // if current slug (from previous session) is invalid, clear it
        if (!availableNetworks.includes(state.currentNetworkSlug)) {
          state.currentNetworkSlug = null;
        }

        if (state.values.HAS_PORTAL_INTEGRATION && mpiNetwork) {
          if (availableNetworks.includes(mpiNetwork)) {
            // hide region selector when SSO is used
            state.currentNetworkSlug = mpiNetwork;
            state.values.SHOW_REGION_SELECTOR = false;
          } else {
            // @TODO: what to do if the SSO network from a previous session is invalid?
          }
        }
      })
      .addCase(configThunks.fetchClientConfig.rejected, (state, action) => {
        state.isLoading = false;
        // error message could be passed with rejectWithValue which puts the message in the action.payload
        // OR errors can be thrown which puts them in action.error
        state.error = action.payload || action.error?.message || 'Unknown Error';
      });

    builder.addCase(handleNetworkChange, (state, action) => {
      const networkSlug = action.payload;
      state.currentNetworkSlug = networkSlug;
    });

    builder
      .addCase(userLoggedIn.fulfilled, (state, action) => {
        const { networkSlug } = action.payload;
        state.currentNetworkSlug = networkSlug;
      })
      .addCase(userLoggedOut.fulfilled, (state) => {
        state.currentNetworkSlug = null;
      });
  },
});

export default configSlice;
export const { setNetworkSlug, setLocalDevClientConfig, clearLocalDevConfig } = configSlice.actions;
