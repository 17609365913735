import React from 'react';
import PropTypes from 'propTypes';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';
import { PROVIDER_CARD_VIEW_OPTIONS } from 'store/slices/ui/uiConstants';

import ProviderResultCard from './ProviderCard/ProviderResultCard';
import PlaceResultCard from './PlaceCard/PlaceResultCard';

const useStyles = makeStyles(() => ({
  cardWrapper: {
    width: '100%',
  },
}));

export default function ResultCard({ resultId, view }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wasProviderSearch = useSelector(select.results.wasProviderSearch);

  return (
    <div
      onMouseEnter={() => dispatch(actions.results.setHoveredResult(resultId))}
      onMouseLeave={() => dispatch(actions.results.clearHoveredResult())}
      className={classes.cardWrapper}
      aria-labelledby={`result-title-${resultId}`} // screen reader to identify group by text inside ResultTitle component
    >
      {wasProviderSearch ? (
        <ProviderResultCard providerId={resultId} view={view} />
      ) : (
        <PlaceResultCard placeId={resultId} view={view} />
      )}
    </div>
  );
}

ResultCard.propTypes = {
  resultId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};
