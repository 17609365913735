import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles(() => ({
  loading: {
    clipPath: 'inset(0 100% 0 0)',
    animation: '$loadingDots 1s steps(4) infinite',
  },
  '@keyframes loadingDots': {
    to: {
      'clip-path': 'inset(0 -34% 0 0)',
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export function MapLoadingModal({ text }) {
  const classes = useStyles();

  return (
    <div style={{ overflow: 'hidden', textAlign: 'center', margin: '10px 20px' }}>
      <EmboldLoadingSpinner size={40} />
      <Typography id="loading-dialog-title">
        {text}
        <span className={classes.loading} aria-hidden="true">
          ...
        </span>
      </Typography>
    </div>
  );
}

MapLoadingModal.propTypes = {
  text: PropTypes.string,
};

MapLoadingModal.defaultProps = {
  text: 'Map loading',
};
