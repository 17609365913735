import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Toolbar, TextField, MenuItem, Button } from '@material-ui/core';

import {
  CallOutlined,
  SchoolOutlined,
  BookOutlined,
  OpenInBrowserOutlined,
  EmailOutlined,
  DirectionsOutlined,
  RecordVoiceOverOutlined,
  AccessTimeOutlined,
  AccessibleForwardOutlined,
  Search,
  NearMe,
  NearMeOutlined,
  CheckCircleOutlined,
  HighlightOff,
  MonetizationOnOutlined,
  MonetizationOn,
  LocalHospitalOutlined,
  GradeRounded,
  InfoOutlined,
  VerifiedUser,
  Error as ErrorIcon,
  Language,
} from '@material-ui/icons';

import * as EmboldIcons from 'icons';
import * as ColoredIcons from 'icons/colored';

import { makeStyles } from '@material-ui/styles';
import StyleguideCanvas from 'components/Pages/StyleGuide/StyleguideCanvas';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import HospitalGradeIcon from 'icons/dynamic/HospitalGradeIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'center',
    columnGap: theme.spacing(2),
  },
  iconContainer: {
    margin: theme.spacing(1),
    borderRadius: 4,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

const inputVariant = 'outlined';

const muiExamples = [
  CallOutlined,
  SchoolOutlined,
  BookOutlined,
  OpenInBrowserOutlined,
  EmailOutlined,
  DirectionsOutlined,
  RecordVoiceOverOutlined,
  AccessTimeOutlined,
  AccessibleForwardOutlined,
  Search,
  NearMe,
  NearMeOutlined,
  CheckCircleOutlined,
  HighlightOff,
  MonetizationOnOutlined,
  MonetizationOn,
  LocalHospitalOutlined,
  GradeRounded,
  InfoOutlined,
  VerifiedUser,
  ErrorIcon,
  Language,
];

const emboldIcons = Object.values(EmboldIcons);
const coloredIcons = Object.values(ColoredIcons);

function getIconName(icon) {
  if (process.env.NODE_ENV === 'production') return ''; // component names do NOT exist in production builds

  if (typeof icon === 'function') {
    return icon.name; // functional react component
  }
  return icon.type.render.displayName.slice(0, -4); // class based component
}

export default function IconsStyleguide() {
  const classes = useStyles();
  const [color, setColor] = useState('inherit');
  const [fontSize, setFontSize] = useState('medium');
  const [htmlColor, setHtmlColor] = useState(undefined);

  const handleColorChange = useCallback((evt) => setColor(evt.target.value), [setColor]);
  const handleFontChange = useCallback((evt) => setFontSize(evt.target.value), [setFontSize]);
  const handleHtmlColorChange = useCallback(
    (evt) => {
      setHtmlColor(evt.target.value || undefined);
    },
    [setHtmlColor]
  );

  const iconProps = useMemo(() => ({ color, fontSize, htmlColor }), [color, fontSize, htmlColor]);

  return (
    <Box className={classes.root}>
      <Typography variant="h2" align="center">
        Icons
      </Typography>

      <Toolbar className={classes.toolbar}>
        <TextField
          select
          label="color"
          variant={inputVariant}
          value={color}
          onChange={handleColorChange}
        >
          {['inherit', 'primary', 'secondary', 'error', 'disabled', 'action'].map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="fontSize"
          variant={inputVariant}
          value={fontSize}
          onChange={handleFontChange}
        >
          {['default', 'inherit', 'large', 'medium', 'small'].map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="htmlColor"
          placeholder="#f7f7f7 or darkblue"
          variant={inputVariant}
          value={htmlColor}
          onChange={handleHtmlColorChange}
        />
      </Toolbar>

      <StyleguideCanvas
        name="MUI Icon Examples"
        display="row"
        description={`
        These are some of the preferred icons used throughout the app from the MUI library.
        The full library can be found here: https://v4.mui.com/components/material-icons/#material-icons

        Click icon to copy import code
      `}
      >
        {muiExamples.map((Icon) => (
          <Button
            key={getIconName(Icon)}
            className={classes.iconContainer}
            onClick={() =>
              navigator.clipboard.writeText(
                `import ${getIconName(Icon)}Icon from '@material-ui/icons/${getIconName(Icon)}';`
              )
            }
          >
            <Icon {...iconProps} />
            <Typography component="span" variant="caption">
              {getIconName(Icon)}
            </Typography>
          </Button>
        ))}
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Embold Custom Icons"
        display="row"
        description={`
        These custom Embold Icons follow the same conventions as the MUI icons. They accept all of the same props.
      `}
      >
        {emboldIcons.map((Icon) => (
          <Button
            key={getIconName(Icon)}
            className={classes.iconContainer}
            onClick={() =>
              navigator.clipboard.writeText(
                `import ${getIconName(Icon)}Icon from 'icons/${getIconName(Icon)}';`
              )
            }
          >
            <Icon {...iconProps} />
            <Typography component="span" variant="caption">
              {getIconName(Icon)}
            </Typography>
          </Button>
        ))}
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Embold Colored Icons"
        display="row"
        description={`
        These icons are the same as the Embold custom icons, but some or all of the icon's paths have predefined colors that cannot be changed via props.
      `}
      >
        {coloredIcons.map((Icon) => (
          <Button
            key={getIconName(Icon)}
            className={classes.iconContainer}
            onClick={() =>
              navigator.clipboard.writeText(
                `import ${getIconName(Icon)}Icon from 'icons/colored/${getIconName(Icon)}';`
              )
            }
          >
            <Icon {...iconProps} />
            <Typography component="span" variant="caption">
              {getIconName(Icon)}
            </Typography>
          </Button>
        ))}
      </StyleguideCanvas>

      <Typography variant="h2">Custom Icons With Additional Props:</Typography>
      <br />

      <StyleguideCanvas
        name="CheckOrXIcon"
        justify="center"
        display="row"
        canvasStyle={{ columnGap: 16 }}
        description={`
        This component accepts a "checked" prop to determine which icon it renders. Additionally it dynamically sets the htmlColor prop.
        It extends all of the props that other icons accept.
        `}
      >
        <div>
          <CheckOrXIcon checked={false} fontSize={fontSize} color={color} />
          <pre>{`checked={false}`}</pre>
        </div>
        <div>
          <CheckOrXIcon checked fontSize={fontSize} color={color} />
          <pre>{`checked={true}`}</pre>
        </div>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="HospitalGradeIcon"
        canvasStyle={{ fontSize: 60 }}
        display="row"
        justify="evenly"
      >
        {['a', 'b', 'c', 'd', 'f', 'ng', undefined].map((grade) => (
          <div style={{ display: 'flex', columnGap: 8, margin: 8 }} key={grade || 'empty'}>
            <HospitalGradeIcon grade={grade} {...iconProps} />
            <HospitalGradeIcon grade={grade} lastUpdated="Spring 2024" {...iconProps} />
            <HospitalGradeIcon grade={grade} lastUpdated="Summer 2024" {...iconProps} />
            <HospitalGradeIcon grade={grade} lastUpdated="Fall 2024" {...iconProps} />
            <HospitalGradeIcon grade={grade} lastUpdated="Winter 2024" {...iconProps} />
          </div>
        ))}
      </StyleguideCanvas>

      <StyleguideCanvas name="Loading Spinner" display="column" justify="evenly">
        <Typography variant="h3">Generic spinner with label</Typography>
        <LoadingSpinner loading />
        <Typography variant="h3">Embold loading spinners</Typography>
        <EmboldLoadingSpinner size={18} />
        <EmboldLoadingSpinner size={35} />
        <EmboldLoadingSpinner size={50} />
        <hr />
        <EmboldLoadingSpinner size={18} speed={0.5} />
        <EmboldLoadingSpinner size={35} />
        <EmboldLoadingSpinner size={50} speed={5} />
      </StyleguideCanvas>
    </Box>
  );
}

IconsStyleguide.propTypes = {};

IconsStyleguide.defaultProps = {};
