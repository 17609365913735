import { createSlice } from '@reduxjs/toolkit';

import { BANNER_COLORS, TEXT_BANNER_VARIANT, VALID_BANNER_VARIANTS } from './bannerConstants';
import { BANNER_SLICE_NAME } from '../slicesNames';

const initialState = {
  showBanner: false,
  variant: TEXT_BANNER_VARIANT,
  color: undefined,
  text: '',
};

const bannerSlice = createSlice({
  name: BANNER_SLICE_NAME,
  initialState,
  reducers: {
    showBanner(state, action) {
      const { variant = 'text', text, color } = action.payload;

      if (VALID_BANNER_VARIANTS.includes(variant)) {
        state.variant = variant;
        state.text = text;
        state.showBanner = true;

        if (BANNER_COLORS.includes(color)) {
          state.color = color;
        } else {
          state.color = undefined;
        }
      }
    },
    hideBanner(state) {
      state.showBanner = false;
    },
  },
});

export default bannerSlice;
export const { showBanner, hideBanner } = bannerSlice.actions;
