import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';

import HospitalGradeIcon from 'icons/dynamic/HospitalGradeIcon';

import Metric from 'components/ModernExperience/Metric';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    '&>*': { width: '100%', height: '100%' },
  },
}));

export default function HospitalSafetyGrade({ grade, lastUpdated }) {
  const classes = useStyles();
  if (!grade || !lastUpdated) return null;

  return (
    <Metric
      icon={<HospitalGradeIcon grade={grade} lastUpdated={lastUpdated} />}
      iconClass={classes.iconContainer}
      title={`Hospital Safety Grade: ${grade}`}
      TooltipProps={{
        message:
          'The Hospital Safety Grade assigns an ‘A’, ‘B’, ‘C’, ‘D’ or ‘F’ grade to nearly 3,000 hospitals across the country based on their performance in preventing medical errors, injuries, accidents, infections and other harms to patients in their care.',
      }}
    >
      {lastUpdated}
    </Metric>
  );
}

HospitalSafetyGrade.propTypes = {
  grade: PropTypes.string,
  lastUpdated: PropTypes.string,
};

HospitalSafetyGrade.defaultProps = {
  grade: undefined,
  lastUpdated: undefined,
};
