import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';

import useUpdateTitle from 'hooks/useUpdateTitle';
import useUrlProfileSearch from 'hooks/queryString/useUrlProfileSearch';
import { PLACE_TYPE } from 'utils/constants';

import ProfileSkeleton from '../../LoadingSkeletons/ProfileSkeleton';
import PlaceProfile from './PlaceProfile';
import ProfileError from '../ProfileError';

export default function PlaceProfilePage({ id }) {
  const resultIds = useSelector(select.results.idList);
  const placeData = useSelector(select.place(resultIds[0]).data);

  useUpdateTitle(placeData?.name || 'Facility Profile', { hidePrefix: true });

  const { error, fetching } = useUrlProfileSearch({ id, searchType: PLACE_TYPE });

  if (error || (!fetching && resultIds?.length === 0))
    return (
      <ProfileError
        heading="No Results"
        text="We were unable to find a facility that matched your search
      criteria. Here are a couple of solutions that might be able to help."
        buttons={['newsearch', 'refresh']}
      />
    );

  return fetching ? <ProfileSkeleton isPlaceProfile /> : <PlaceProfile placeId={resultIds[0]} />;
}

PlaceProfilePage.propTypes = {
  id: PropTypes.string.isRequired,
};
