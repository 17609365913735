import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  SCORE_COLORS_BY_TIER,
  SCORE_TIER_HIGH,
  SCORE_TIER_LOW,
  SCORE_TIER_MIDDLE,
  VALID_SCORE_TIERS,
} from 'utils/constants';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  [SCORE_TIER_HIGH]: {
    backgroundColor: get(theme.palette, SCORE_COLORS_BY_TIER[1]),
  },
  [SCORE_TIER_MIDDLE]: {
    backgroundColor: get(theme.palette, SCORE_COLORS_BY_TIER[2]),
  },
  [SCORE_TIER_LOW]: {
    backgroundColor: get(theme.palette, SCORE_COLORS_BY_TIER[3]),
  },
}));

export default function ScoreIcon({ score, tier, additionalClass, ...props }) {
  const classes = useStyles();

  const className = clsx(classes[tier], {
    [additionalClass]: Boolean(additionalClass),
  });

  return (
    <Avatar className={className} aria-label={`score of ${score}. scale of 70 to 100`} {...props}>
      {score}
    </Avatar>
  );
}

ScoreIcon.propTypes = {
  score: PropTypes.number.isRequired,
  tier: PropTypes.oneOf(VALID_SCORE_TIERS).isRequired,
  additionalClass: PropTypes.string,
};

ScoreIcon.defaultProps = {
  additionalClass: undefined,
};
