import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { select } from 'store/toolkit';
import SubmitButton from '../SubmitButton';
import CheckboxInput from '../CheckboxInput';
import AgreeToTermsLabel from './AgreeToTermsLabel';
import { CHECKBOX_ERROR_TEXT, NETWORK_SELECT_ERROR_TEXT } from './errorText';
import SSONetworkController from './SSONetworkController';

const initialValues = {
  networkSlug: '',
  termsChecked: false,
};
export default function NetworkSelectLogin({ classes, onSubmit }) {
  const validNetworkSlugs = useSelector(select.networks.availableSlugs);

  const validationSchema = Yup.object({
    networkSlug: Yup.string()
      .oneOf(validNetworkSlugs, NETWORK_SELECT_ERROR_TEXT) // validates that this is a valid network for this config
      .required(NETWORK_SELECT_ERROR_TEXT),
    termsChecked: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form className={classes.root}>
        <SSONetworkController name="networkSlug" />
        <CheckboxInput
          name="termsChecked"
          label={<AgreeToTermsLabel />}
          className={classes.termsAgreement}
        />
        <SubmitButton className={classes.submitButton}>Continue</SubmitButton>
      </Form>
    </Formik>
  );
}
NetworkSelectLogin.propTypes = {
  classes: PropTypes.shape({
    submitButton: PropTypes.string,
    root: PropTypes.string,
    termsAgreement: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
