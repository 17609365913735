import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonGroup, Button, Collapse, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import { actions, select } from 'store/toolkit';

const BUTTON_PADDING = 2;
const TRANSITION_TIME = 0.3; // in seconds
const COLLAPSE_TIMEOUT = { enter: TRANSITION_TIME * 800, exit: TRANSITION_TIME * 1200 };
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    transition: `${TRANSITION_TIME}s ease`,
    opacity: 1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  rootHidden: {
    opacity: 0,
  },
  groupedContained: {
    padding: `${theme.spacing(BUTTON_PADDING / 1.5)}px ${theme.spacing(BUTTON_PADDING)}px`,
  },
  compareButtonRoot: {
    flex: 1,
  },
}));

export default function CompareButton(props) {
  const classes = useStyles();
  const enableCompareModal = useSelector(select.results.enableCompareModal);

  const dispatch = useDispatch();

  const history = useHistory();

  const label = useSelector(select.results.compareButtonText);

  const clearCompare = useCallback(() => dispatch(actions.results.clearCompareList()), [dispatch]);
  const openCompare = useCallback(() => {
    history.replace({ ...history.location, pathname: '/results/compare' }); // update path but maintain query params
  }, [history]);

  return (
    <Box {...props}>
      <Collapse in={enableCompareModal} timeout={COLLAPSE_TIMEOUT}>
        <ButtonGroup
          color="primary"
          variant="contained"
          aria-label="compare controls"
          classes={{
            root: clsx(classes.root, { [classes.rootHidden]: !enableCompareModal }),
            groupedContained: classes.groupedContained,
          }}
        >
          <Button onClick={openCompare} classes={{ root: classes.compareButtonRoot }}>
            {/* The reason for conditionally rendering the label, is because when the compare list changes from 2 to 0, we don't want to see "Compare 0 Places" during it's short transition out */}
            {enableCompareModal && label}
          </Button>
          <Button onClick={clearCompare} aria-label="clear compare list">
            <CloseIcon />
          </Button>
        </ButtonGroup>
      </Collapse>
    </Box>
  );
}
