import React from 'react';
import clsx from 'clsx';
import PropTypes from 'propTypes';

import { Grid, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { MAP_ANIMATION_TRANSITION } from 'components/Results/ResultsPage';
import {
  DESKTOP_EXPANDED_VIEW,
  MOBILE_CONDENSED_VIEW,
  MOBILE_EXPANDED_VIEW,
  PROVIDER_CARD_VIEW_OPTIONS,
  DESKTOP_SMALL_VIEW,
  DESKTOP_CONDENSED_VIEW,
} from 'store/slices/ui/uiConstants';
import ProviderContactCard from './ProviderContactCard';
import ProviderMetricList from './ProviderMetricList';
import ProviderDirectionsButton from './ProviderDirectionsButton';
import RoundedWrapper from '../StyledWrappers/RoundedWrapper';
import ProviderAttributeList from './ProviderAttributeList';
import CompareCheckbox from '../CompareCheckbox';
import ProviderDetailsButton from './ProviderDetailsButton';
import PillList from './PillList';
import CallButton from '../CallButton';
import ProviderNameAndSpecialty from './ProviderNameAndSpecialty';

const useStyles = makeStyles((theme) => ({
  // BASE STYLES
  cardHeader: {
    paddingBottom: 10,
  },
  headerGroup: {
    display: 'flex',
    columnGap: 8,
  },
  pill: {
    alignSelf: 'center',
  },
  wrapAttributes: {
    paddingTop: theme.spacing(1),
  },
  contactCardWrapper: {
    width: '100%',
    minWidth: 500,
  },
  firstColumn: {
    flex: 1,
  },
  secondColumn: {
    flex: 1.25,
    marginLeft: 15,
    transition: MAP_ANIMATION_TRANSITION,
  },
  thirdColumn: {
    transition: MAP_ANIMATION_TRANSITION,
    overflow: 'hidden',
  },
  pullUp: {
    marginTop: -40,
  },
  // DESKTOP_EXPANDED
  secondColumnExpanded: {
    marginRight: 15,
  },
  thirdColumnExpanded: {
    flex: 1.8,
  },
  // DESKTOP_CONDENSED
  thirdColumnCondensed: {
    flex: 0,
    width: 0,
    transform: 'translateX(15px)',
  },
  // MOBILE VIEWS
  mobileWrapperColumn: {
    rowGap: 12,
  },
  mobilePill: {
    alignSelf: 'flex-start',
    margin: '2px 4px 2px 0',
  },
  mobileHeaderButton: {
    paddingRight: 0,
  },
  mobileLabelText: {
    fontSize: '.75rem',
  },
  mobileCheckbox: {
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '& svg': {
      fontSize: '1rem',
    },
  },
  mobileWrapAttributes: {
    paddingBottom: 0,
    paddingTop: theme.spacing(1.5),
  },
  mobileDirectionsPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  // Wrapper
  reducedPadding: {
    background: 'white',
    padding: '.5rem',
  },

  isHovered: {
    borderColor: theme.palette.text.secondary,
  },
  mobilePillListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

function ProviderResultCard({ providerId, view }) {
  const isMobileView = view === MOBILE_CONDENSED_VIEW || view === MOBILE_EXPANDED_VIEW;
  const hasPillList = useSelector(select.provider(providerId).pillList)?.length > 0;

  if (isMobileView) return <ProviderResultCardMobile providerId={providerId} view={view} />;
  return (
    <ProviderResultCardDesktop providerId={providerId} view={view} hasPillList={hasPillList} />
  );
}

export default ProviderResultCard;

const ResultCardSharedProps = {
  providerId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};
ProviderResultCard.propTypes = ResultCardSharedProps;

function ProviderResultCardDesktop({ view, providerId, hasPillList }) {
  const classes = useStyles();
  const hoveredIdEqualsIdProp = useSelector(select.results.hoveredIdEqualsIdProp(providerId));

  return (
    <RoundedWrapper additionalStyles={hoveredIdEqualsIdProp ? classes.isHovered : null}>
      {/*  card header */}
      <Grid container justifyContent="space-between" className={classes.cardHeader}>
        <Grid className={classes.headerGroup}>
          <PillList providerId={providerId} pillClassName={classes.pill} />
        </Grid>
        <Grid className={classes.headerGroup}>
          <CompareCheckbox resultId={providerId} />
          <ProviderDetailsButton providerId={providerId} />
        </Grid>
      </Grid>

      {/*  card content grid */}
      <Grid container>
        {/*  left grid */}
        <Grid
          className={clsx(classes.firstColumn, {
            [classes.pullUp]: !hasPillList,
          })}
        >
          <ProviderNameAndSpecialty providerId={providerId} />
          <div className={classes.wrapAttributes}>
            <ProviderAttributeList providerId={providerId} />
          </div>
          <ProviderDirectionsButton providerId={providerId} />
        </Grid>
        {/*  center grid */}
        <Grid
          item
          className={clsx(classes.secondColumn, {
            [classes.secondColumnExpanded]: view === DESKTOP_EXPANDED_VIEW,
          })}
        >
          <ProviderMetricList providerId={providerId} />
        </Grid>
        {/*  right grid */}
        {view !== DESKTOP_SMALL_VIEW && (
          <Grid
            item
            className={clsx(classes.thirdColumn, {
              [classes.thirdColumnCondensed]: view === DESKTOP_CONDENSED_VIEW,
              [classes.thirdColumnExpanded]: view === DESKTOP_EXPANDED_VIEW,
            })}
          >
            <Collapse in={view === DESKTOP_EXPANDED_VIEW} timeout={700}>
              <div
                className={classes.contactCardWrapper}
                aria-hidden={view !== DESKTOP_EXPANDED_VIEW}
              >
                <ProviderContactCard providerId={providerId} />
              </div>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </RoundedWrapper>
  );
}

ProviderResultCardDesktop.propTypes = {
  ...ResultCardSharedProps,
  hasPillList: PropTypes.bool.isRequired,
};

function ProviderResultCardMobile({ view, providerId }) {
  const classes = useStyles();
  return (
    <RoundedWrapper additionalStyles={classes.reducedPadding}>
      <ProviderResultCardMobileContent view={view} providerId={providerId} />
    </RoundedWrapper>
  );
}

export function ProviderResultCardMobileContent({ view, providerId }) {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.mobileWrapperColumn}>
      {/* row 1 */}
      <Grid container justifyContent="space-between">
        {/*  grid left (details) */}
        <Grid item xs={8}>
          <Grid className={classes.mobilePillListContainer}>
            <PillList providerId={providerId} pillClassName={classes.mobilePill} />
          </Grid>
          <ProviderNameAndSpecialty providerId={providerId} />
          <div className={classes.mobileWrapAttributes}>
            <ProviderAttributeList providerId={providerId} />
          </div>
        </Grid>
        {/*  grid right (buttons) */}
        <Grid item xs={4} container direction="column" justifyContent="space-between">
          <Grid container direction="column" alignItems="flex-end">
            <ProviderDetailsButton
              providerId={providerId}
              buttonClasses={`${classes.mobileLabelText} ${classes.mobileHeaderButton}`}
            />
            {view !== MOBILE_CONDENSED_VIEW && (
              <CompareCheckbox
                resultId={providerId}
                labelClasses={classes.mobileLabelText}
                checkboxRootClasses={classes.mobileCheckbox}
              />
            )}
          </Grid>
          <Grid container direction="column" alignItems="flex-end">
            <CallButton providerId={providerId} />
            <ProviderDirectionsButton
              providerId={providerId}
              additionalClasses={classes.mobileDirectionsPadding}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* row 2 */}
      {view === MOBILE_EXPANDED_VIEW && (
        <Grid>
          <ProviderMetricList providerId={providerId} MetricProps={{ headingLevel: 'h3' }} />
        </Grid>
      )}
    </Grid>
  );
}

ProviderResultCardMobile.propTypes = ProviderResultCard.propTypes;
ProviderResultCardMobile.defaultProps = ProviderResultCard.defaultProps;

ProviderResultCardMobileContent.propTypes = ProviderResultCard.propTypes;
ProviderResultCardMobileContent.defaultProps = ProviderResultCard.defaultProps;
