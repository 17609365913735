import React, { useCallback, useMemo, useState } from 'react';
import PropTypes, { RefType } from 'propTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Tooltip } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';
import EventIcon from '@material-ui/icons/Event';
import GiftIcon from 'icons/Gift';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import ResponsiveIconButton from 'components/ResponsiveIconButton';
import formatPrintProfile from 'utils/formatPrintProfile';
import useReturnFocus from 'hooks/useReturnFocus';
import useFocusAnchors from 'utils/FocusRefContext';
import { actions, select } from 'store/toolkit';
import { isValidUrl } from 'utils/utils';
import { _analyticsActions } from 'analytics/index';

const SPINNER_SIZE = 16;
const useStyles = makeStyles((theme) => ({
  container: {
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonRoot: {
    width: 125,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    padding: theme.spacing(1),
  },
}));

function ProviderProfileHeaderButtons({ providerId, printRef }) {
  const classes = useStyles();
  const focusAnchors = useFocusAnchors();
  const providerData = useSelector(select.provider(providerId).data);
  const showShareButton = useSelector(select.featureFlags.showShare);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);
  const hasActiveReward = Boolean(useSelector(select.rewards.hasActiveCampaign));
  const userIsEligibleForReward = useSelector(select.rewards.userIsEligible);
  const isXs = useSelector(select.ui.isXs);

  const dispatch = useDispatch();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();
  const { ref: printButtonRef, returnFocus: focusPrintButton } = useReturnFocus();

  const [isPrinting, setIsPrinting] = useState(false);

  const { pathname } = useLocation();

  const isSharePage = pathname.startsWith('/share/');

  const handleCall = () => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: providerData }));
  };

  const handleShare = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [providerData] }));
  };

  const handleScheduleEvent = () =>
    dispatch(_analyticsActions.scheduleClickFromNeedHelpButton(providerData.entityId));

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    focusPrintButton();
  }, [focusPrintButton]);

  const printProfile = useReactToPrint({
    content: () => formatPrintProfile(printRef),
    documentTitle: providerData.entityName || 'Embold Health Provider',
    onBeforeGetContent: () => setIsPrinting(true),
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = useCallback(() => {
    setIsPrinting(true);
    dispatch(_analyticsActions.printProfile(providerId));
    // need to wait for profile accordion animations to finish before ingesting content
    setTimeout(printProfile, 470);
  }, [dispatch, printProfile, providerId]);

  const buttonProps = useMemo(
    () => ({
      MuiButtonProps: { variant: 'outlined' },
      color: 'primary',
      iconOnly: isXs,
      classes: { root: classes.buttonRoot },
    }),
    [classes, isXs]
  );

  const schedulingButton = isValidUrl(providerData.schedulingUrl) ? (
    // some providers have a schedulingUrl property which is the preferred method for scheduling
    <ResponsiveIconButton
      {...buttonProps}
      href={providerData.schedulingUrl}
      onClick={handleScheduleEvent}
      target="_blank"
      rel="noreferrer"
      startIcon={<EventIcon />}
    >
      Schedule
    </ResponsiveIconButton>
  ) : (
    // when providers do NOT have a schedulingUrl open the call modal for scheduling through their network support
    <ResponsiveIconButton
      startIcon={<HelpOutlineIcon />}
      {...buttonProps}
      onClick={handleCall}
      ref={callButtonRef}
    >
      Need Help?
    </ResponsiveIconButton>
  );

  const showRewardButton = hasActiveReward && providerData.showRewards; // show the button only if the provider is eligible for the active reward
  const disableRewardButton = showRewardButton && !userIsEligibleForReward; // only enable the button if the user is eligible to claim the reward

  const tooltipMessage = disableRewardButton
    ? 'You have already submitted a reward redemption form'
    : 'Get rewarded for seeing a high-quality doctor. Click for more details.';

  const rewardsButton = (
    <ResponsiveIconButton
      {...buttonProps}
      startIcon={<GiftIcon />}
      disabled={disableRewardButton}
      component={RouterLink}
      to={`/rewards/redeem/${providerData.npi}`}
    >
      Rewards
    </ResponsiveIconButton>
  );

  return (
    <Grid container classes={{ root: classes.container }}>
      {showRewardButton && (
        <Grid item>
          <Tooltip title={tooltipMessage} arrow placement="top">
            {disableRewardButton ? (
              /* Important: The span is necessary for using a Tooltip on a disabled element: https://v4.mui.com/components/tooltips/#disabled-elements  */
              /* The span will receive the ref from Tooltip and allow it to be shown on hover */
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              <span style={{ display: 'block' }} tabIndex={0}>
                {rewardsButton}
              </span>
            ) : (
              /* When the button is enabled, the button itself will receive the ref from Tooltip */
              rewardsButton
            )}
          </Tooltip>
        </Grid>
      )}

      <Grid item>
        <ResponsiveIconButton
          {...buttonProps}
          onClick={handlePrint}
          ref={printButtonRef}
          startIcon={
            isPrinting ? (
              <CircularProgress thickness={3} size={SPINNER_SIZE} aria-label="" />
            ) : (
              <PrintIcon />
            )
          }
        >
          {isPrinting ? 'Printing' : 'Print'}
        </ResponsiveIconButton>
      </Grid>

      {!isSharePage && (
        <>
          {showShareButton && (
            <Grid item>
              <ResponsiveIconButton
                {...buttonProps}
                onClick={handleShare}
                ref={shareButtonRef}
                startIcon={<ShareIcon />}
              >
                Share
              </ResponsiveIconButton>
            </Grid>
          )}

          {showScheduleButton && <Grid item>{schedulingButton}</Grid>}
        </>
      )}
    </Grid>
  );
}

export default ProviderProfileHeaderButtons;

ProviderProfileHeaderButtons.propTypes = {
  providerId: PropTypes.string.isRequired,
  printRef: RefType.isRequired,
};
