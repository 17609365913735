import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutocompleteInput({ name, ...props }) {
  const [field, , helpers] = useField(name);

  const handleChange = useCallback(
    (evt, value) => {
      helpers.setValue(value);
    },
    [helpers]
  );

  return (
    <Autocomplete
      name={name}
      value={field.value}
      {...field}
      id={props.id || field.name}
      onChange={handleChange}
      {...props}
    />
  );
}

AutocompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

AutocompleteInput.defaultProps = {
  id: undefined,
};
