import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';

export default function SubmitButton({ children = 'Submit', ...props }) {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      type="submit"
      startIcon={isSubmitting && <CircularProgress size="1rem" />}
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      {...props}
    >
      {children}
    </Button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node,
};

SubmitButton.defaultProps = {
  children: 'Submit',
};
