import React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, Typography } from '@material-ui/core/';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  treatmentCategoryCell: {
    borderBottom: `none`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  tableCellCost: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `none`,
  },
  benefitSubtitle: {
    color: theme.palette.grey[600],
  },
}));

export default function TreatmentCategoryRow({ treatmentCategory }) {
  const classes = useStyles();
  const userHasMetOutOfPocketMaximum = useSelector(select.ui.userHasMetOutOfPocketMaximum);
  const planPays = userHasMetOutOfPocketMaximum
    ? treatmentCategory.totalCost
    : treatmentCategory.planCost;
  const youPay = userHasMetOutOfPocketMaximum ? 0 : treatmentCategory.memberCost;

  return (
    <TableRow key={treatmentCategory.treatmentCategoryCode}>
      <TableCell className={classes.treatmentCategoryCell} component="th" scope="row">
        {treatmentCategory.benefitFeatureSubtitle && (
          <Typography className={classes.benefitSubtitle}>
            {treatmentCategory.benefitFeatureSubtitle}
          </Typography>
        )}
        <Typography>{treatmentCategory.treatmentCategoryName}</Typography>
      </TableCell>
      <TableCell className={classes.tableCellCost} align="center">
        <Typography style={{ fontWeight: 'bold' }} component="h4">
          ${planPays}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCellCost} align="center">
        <Typography style={{ fontWeight: 'bold' }} component="h4">
          ${youPay}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

TreatmentCategoryRow.propTypes = {
  treatmentCategory: PropTypes.shape({
    treatmentCategoryCode: PropTypes.string,
    treatmentCategoryName: PropTypes.string,
    memberCost: PropTypes.number,
    planCost: PropTypes.number,
    totalCost: PropTypes.number,
    serviceId: PropTypes.number,
    benefitFeatureSubtitle: PropTypes.string,
  }).isRequired,
};
