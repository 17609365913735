import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { CHAT_BUTTON_Z_INDEX } from 'utils/constants';
import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

import { VirtualAssistant } from 'icons/index';

const ICON_WIDTH = 65;
const SPACE_BETWEEN_ICON_AND_BUTTON = 10;
const TEXT_WIDTH = 140;
const BUTTON_PADDING_RIGHT = 22;
const BORDER_WIDTH = 2;

const StyledButton = withStyles((theme) => ({
  root: {
    transition: 'transform 400ms ease',
    paddingLeft: 0,
    paddingRight: BUTTON_PADDING_RIGHT,
    justifyContent: 'flex-start',

    // color
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 30%)`,
    border: `${BORDER_WIDTH}px solid ${theme.palette.primary.main}`,

    // position
    width: 2 * (ICON_WIDTH + TEXT_WIDTH + SPACE_BETWEEN_ICON_AND_BUTTON), // oversize the button to give it extra space off-screen
    position: 'fixed',
    top: '50%',
    left: `calc(100% - ${ICON_WIDTH}px)`,
    zIndex: CHAT_BUTTON_Z_INDEX,
    '&:hover, &.Mui-focusVisible': {
      transform: `translateX(-${TEXT_WIDTH + BUTTON_PADDING_RIGHT}px)`,
    },

    // border
    borderRadius: theme.shape.borderRadius * 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,

    '@media only screen and (max-height: 560px)': {
      // on vertically short windows, switch to absolute positioning so that it's positioned inside the parent <main>
      // this avoids reflow/overflow issues
      position: 'absolute',
      top: 215,
    },
  },
  label: {
    textAlign: 'left',
    fontSize: theme.typography.h6.fontSize,
    '& :nth-child(2)': {
      // setting a width for the text inside the button label
      width: TEXT_WIDTH,
    },
  },
  startIcon: {
    margin: 0,
    paddingRight: SPACE_BETWEEN_ICON_AND_BUTTON,
    width: ICON_WIDTH,
  },
  iconSizeLarge: {
    '& > *:first-child': { fontSize: '3rem', marginLeft: 'auto' },
  },
}))(Button);

export default function ChatButton() {
  const dispatch = useDispatch();
  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);

  const openChat = useCallback(() => {
    dispatch(actions.ui.openModal('chat'));
    dispatch(_analyticsActions.chatModalOpened());
  }, [dispatch]);

  const label = isWhitelabeled ? (
    <>
      Search using <br />
      Virtual Assistant
    </>
  ) : (
    "Search using Embold's Virtual Assistant"
  );

  return (
    <StyledButton
      variant="contained"
      color="secondary"
      disableElevation={false}
      onClick={openChat}
      size="large"
      classes={{ root: 'chat-button' }} // this chat-button class is used for theme overrides
      startIcon={<VirtualAssistant fontSize="inherit" />}
    >
      <span>{label}</span>
    </StyledButton>
  );
}
