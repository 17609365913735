import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

export default function CostCell({ providerId }) {
  const classes = useStyles();
  const costText = useSelector(select.provider(providerId).costText);

  if (!costText) return <NoDataIcon />;

  return (
    <Typography component="span" color="primary" classes={classes}>
      {costText}
    </Typography>
  );
}

CostCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
