import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles(() => ({
  networkAlert: {
    maxWidth: 754,
    width: '100%',
  },
}));

function NetworkNotice({ notice, type, isPlural }) {
  const classes = useStyles();

  if (notice === 'info') {
    return null;
    // @TODO: when it's approved by client relations - show this (or similar) UI
    // <Alert classes={{ root: classes.networkAlert }} severity="info">
    //   Always confirm that the provider accepts your health plan.{' '}
    //   <Link component={RouterLink} to="/terms#reliance-on-information-posted">
    //     More info
    //   </Link>
    // </Alert>
  }

  if (notice === 'mismatch') {
    return (
      <Alert classes={{ root: classes.networkAlert }} severity="warning">
        {isPlural
          ? `These ${type?.toLowerCase()}s were  shared from a different health plan than yours`
          : `This ${type?.toLowerCase()} was  shared from a different health plan than yours`}
      </Alert>
    );
  }

  return null;
}

NetworkNotice.propTypes = {
  notice: PropTypes.oneOf(['', 'info', 'mismatch']),
  type: PropTypes.string.isRequired,
  isPlural: PropTypes.bool.isRequired,
};

NetworkNotice.defaultProps = {
  notice: '',
};

export default NetworkNotice;
