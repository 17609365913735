const testThemeVariant = {
  // overrides to be applied
  palette: {
    primary: { main: '#990000' },
  },
  typography: {
    fontFamily: 'Comic Sans',
  },
  overrides: {
    MuiDivider: {
      root: {
        color: 'red',
      },
    },
  },
  props: {
    MuiDivider: {
      absolute: true,
    },
  },
  // overrides to be ignored
  breakpoints: {
    values: {
      sm: 500,
    },
  },
  direction: 'rtl',
  mixins: {
    toolbar: {
      minHeight: 120,
    },
  },
  shadows: [0, '1px', '2px'],
  spacing: {
    mui: false,
  },
  shape: {
    borderRadius: 10,
  },
  transitions: {
    duration: {
      short: 125,
    },
  },
  zIndex: {
    tooltip: 5000,
  },
  iconSizes: {
    large: '5rem',
  },
};

export default testThemeVariant;
