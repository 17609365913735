import React from 'react';
import { useSelector } from 'react-redux';

import SectionSpecializations from 'components/ModernExperience/Profile/ProviderProfile/SectionSpecializations';
import { logDevMessage } from 'utils/utils';
import { select } from 'store/toolkit';
import { SUBSPECIALTY_SECTION } from 'utils/constants';
import ProfileSectionModal from '../ProfileSectionModal';

export default function FocusAreasModal(props) {
  const { data: profileData } = useSelector(select.ui.profileModal);

  const { id } = profileData || {};

  if (!id) {
    logDevMessage('Missing id property in the uiSlice.profileModal.data object');
    return null;
  }

  return (
    <ProfileSectionModal title={SUBSPECIALTY_SECTION.title} {...props}>
      <SectionSpecializations providerId={id} />
    </ProfileSectionModal>
  );
}
