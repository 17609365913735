import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { actions } from 'store/toolkit';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  zoomButtons: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[700],
    width: 30,
    height: 30,
    boxShadow: theme.shadows[5],
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },

  topButton: {
    marginBottom: theme.spacing(2),
  },
}));

function ZoomControls({ className }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const zoomIn = useCallback(() => dispatch(actions.map.zoomIn()), [dispatch]);
  const zoomOut = useCallback(() => dispatch(actions.map.zoomOut()), [dispatch]);

  return (
    <div className={clsx(classes.container, { [className]: className })}>
      <IconButton
        className={`${classes.zoomButtons} ${classes.topButton}`}
        onClick={zoomIn}
        title="Zoom In"
      >
        <Typography variant="srOnly">Zoom into map</Typography>
        <AddIcon />
      </IconButton>

      <IconButton className={classes.zoomButtons} onClick={zoomOut} title="Zoom Out">
        <Typography variant="srOnly">Zoom out of map</Typography>
        <RemoveIcon />
      </IconButton>
    </div>
  );
}
ZoomControls.propTypes = {
  className: PropTypes.string,
};

ZoomControls.defaultProps = {
  className: undefined,
};

export default ZoomControls;
