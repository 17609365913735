/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '1rem', // ensures no auto-zoom on iOS safari
    },
    '& .MuiInputAdornment-root': { padding: '0px 8px', color: theme.palette.grey[700] },
    '& ::placeholder': {
      color: theme.palette.grey[700],
      opacity: 1, // By default firefox and chrome will decrease the opacity, causing a11y violations unless we specify this
    },
  },
  adornment: {
    fontWeight: 'bold',
    letterSpacing: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
}));

export default function PhoneInput({ label, name, className, ...props }) {
  // useField will look for a 'name' property in props to determine which field in the Formik context to control.
  // It will return an array with a field object and a meta object
  // field contains the name, onChange, onBlur amd value to be passed to our input
  // meta contains the the field error and touched values for determining if there is an error to be shown on the field
  const [field, meta, helpers] = useField(name);
  const classes = useStyles();

  // in this custom handleChange, we will force the phone format into (555) 123-4567
  const handleChange = useCallback(
    (evt) => {
      let value = evt.target.value || '';

      // strip non-numbers
      value = value.replace(/\D/g, '');

      if (value.length > 6) {
        value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
      } else if (value.length > 3) {
        value = `(${value.substring(0, 3)}) ${value.substring(3)}`;
      } else {
        value = `${value}`;
      }

      helpers.setValue(value);
    },
    [helpers]
  );

  return (
    <TextField
      className={`${classes.root} ${className}`}
      label={label}
      variant="outlined"
      autoComplete="tel"
      type="tel"
      inputProps={{
        maxLength: 14,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography className={classes.adornment} component="span">
              +1
            </Typography>
          </InputAdornment>
        ),
      }}
      placeholder="(555) 123-4567"
      // display as error if the field has an error, and has been touched by the user
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      // spread the formik field props for control
      id={field.name}
      {...field}
      onChange={handleChange}
      // pass any additional input or MUI props needed
      {...props}
    />
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PhoneInput.defaultProps = {
  className: '',
};
