import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FocusAreaTypeIcon from 'components/ModernExperience/FocusAreaTypeIcon';
import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(1),
    padding: 0,
    '&:not(:last-child)': { paddingBottom: theme.spacing(1) },
  },
}));

export default function FocusAreasCell({ providerId }) {
  const classes = useStyles();
  const subspecialties = useSelector(select.provider(providerId).sortedSubspecialties);

  if (!subspecialties.length) return <NoDataIcon />;

  return (
    <List>
      {subspecialties.map((ss) => (
        <ListItem key={`${providerId}-ss-${ss.subspecialtyId}`} classes={classes}>
          <FocusAreaTypeIcon subspecialty={ss} size={22} />
          <ListItemText primaryTypographyProps={{ variant: 'caption' }}>
            {ss.subspecialtyName}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

FocusAreasCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
