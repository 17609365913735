/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, SOCT, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import soctLoginLogo from 'assets/soct_logo.svg';
import soctHeaderLogo from 'assets/soct_header_logo.png';

const apiTokens = {
  dev: 'Token 7aa95f2dd343a8084d6d3203f143d83bbca28149',
  staging: 'Token 321527ba2c48504d40befa570180680db1e18566',
  prod: 'Token 50522284abbed9c05b2a2f676aa3953883c35b41',
};

const token = selectToken(apiTokens);

const soctConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'carecompass',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: SOCT,
  EMBOLD_CLIENT: SOCT,
  EMBOLD_CLIENT_SLUG: SOCT,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: [
    '^(standardaccess|primarycareaccess|expandedaccess|qualityfirstselectaccess|preferredaccessoutofarea)$',
  ],

  // other
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Hartford },
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'soctUserId',

  // content
  DISPLAY_NAME: 'State of Connecticut',
  SCHEDULE_PHONE_NUMBER: '833-740-3258',
  HEADER_LOGO: soctHeaderLogo,
  WELCOME_MESSAGE:
    'State of Connecticut and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: soctLoginLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default soctConfig;
