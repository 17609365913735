import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'app';

function createAppAction(actionName) {
  return createAction(`${PREFIX}/${actionName}`);
}

/** Used to handle generic errors caught by the ErrorBoundary component */
export const catchAppError = createAppAction('catchAppError');

/** Used in the start over function for resetting certain state after an error has occurred */
export const startOver = createAppAction('startOver');

/** Used to change the network and close the updateRegion modal */
export const handleNetworkChange = createAppAction('handleNetworkChange');

/** Used to update entire Redux store from url params */
export const updateStoreFromUrl = createAppAction('updateStoreFromUrl');

/** A user has clicked "clear" all on the search form */
export const clearSearchForm = createAppAction('clearSearchForm');

/** A user has clicked one of the marker icons on the map */
export const mapLocationClicked = createAppAction('mapLocationClicked');

/** Used to show all results in a cluster */
export const showClusterResults = createAppAction('showClusterResults');

/** A user has clicked an empty area on the map, not clicking any icons or markers */
export const mapClicked = createAppAction('mapClicked');

/** A screen reader text element on the map has been focused */
export const mapScreenReaderLocationFocused = createAppAction('mapScreenReaderLocationFocused');
