import React from 'react';
import { useSelector } from 'react-redux';
import {
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapFeature,
} from 'react-azure-maps';

import { select } from 'store/toolkit';

/** This component renders a dot to be shown on the map representing the location from the location slice */
export default function UserLocationMapLayer() {
  const { latitude, longitude } = useSelector(select.location.latLong);

  return (
    <AzureMapDataSourceProvider id="location-marker-provider">
      <AzureMapLayerProvider
        id="location-marker-layer"
        type="SymbolLayer"
        options={{ iconOptions: { image: 'pin-round-blue' } }}
      />
      <AzureMapFeature
        type="Point"
        id="location-marker"
        variant="shape"
        // initial value
        coordinate={[longitude, latitude]}
        // Ensures that marker will move when value from redux changes
        setCoords={[longitude, latitude]}
      />
    </AzureMapDataSourceProvider>
  );
}
