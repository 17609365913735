/* eslint-disable */
/**
 * Sample provider array
 * Location: Dallas, TX
 * Search type: Specialty
 * Search input: Primary Care
 * Date: 12/6/2023
 */
export default [
  {
    specialty: 'Primary Care',
    gender: 'Male',
    yearsExperience: 18,
    entityName: 'Huy Nguyen',
    curatedOut: false,
    scoreCareJourneyDiagnosing: 3,
    outcareCompetent: false,
    title: 'MD',
    titleArray: ['MD'],
    subspecialties: [
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyScore: 99,
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyScore: 99,
        subspecialtyId: 25,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyScore: 79,
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Pediatrics',
        subspecialtyId: 28,
      },
    ],
    scoringOrder: 100,
    overallPercentile: 0.9982134699821472,
    groupAffiliations: [],
    medicalSchool: 'Ross University',
    scoreCareJourneyOutcomes: 3,
    diagnosingPercentile: 0.8774005174636841,
    languagesArray: ['Vietnamese', 'English'],
    qualityMetricValue: 78.46005961744123,
    firstName: 'Huy',
    outcareUrl: '',
    benefitDecrease: false,
    languages: 'Vietnamese',
    healthPlan: '',
    npi: 1194986208,
    appropriatenessMetricValue: 85.43703428597175,
    hospitalAffiliations: [],
    regionId: 1,
    lastName: 'Nguyen',
    performanceMetricValue: 80.88471271295145,
    scoreCareJourneyTreatment: 3,
    laymansSubspecialties: [
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Pediatrics',
        subspecialtyId: 28,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyId: 25,
      },
    ],
    scoreCareJourneyCost: 2,
    suggest: [
      {
        input: ['nguyen TXB1', 'huy nguyen TXB1'],
        contexts: {
          loc: [
            {
              lon: -96.682339,
              lat: 32.917209,
            },
          ],
        },
      },
    ],
    entityId: '1194986208',
    overallMetricValue: 80.07845221137448,
    boardCertified: true,
    outcomesPercentile: 0.8966181874275208,
    emboldEvaluated: true,
    specialtyId: 5,
    acceptingNewPatients: true,
    scoreOverallQuality: 100,
    treatmentPercentile: 0.9557405114173889,
    telehealthAvailable: false,
    outcareCertified: false,
    region: 'Dallas / Fort Worth',
    highPerforming: true,
    costMetricValue: 78.57333993549001,
    places: [
      {
        city: 'Garland',
        address1: '3465 W Walnut St',
        address2: 'Ste 225',
        isWheelchairAccessible: true,
        zipcode: '75042',
        productIds: ['TXB1'],
        phone: '972-272-7816',
        acceptingNewPatients: true,
        telehealthAvailable: false,
        name: 'Famicare Clinic PA',
        specialization: '',
        location: {
          lon: -96.682339,
          lat: 32.917209,
        },
        state: 'TX',
        fax: '972-276-8137',
        latitude: 32.917209,
        longitude: -96.682339,
      },
    ],
    closestVisibleLocation: {
      city: 'Garland',
      address1: '3465 W Walnut St',
      address2: 'Ste 225',
      isWheelchairAccessible: true,
      zipcode: '75042',
      productIds: ['TXB1'],
      phone: '972-272-7816',
      acceptingNewPatients: true,
      name: 'Famicare Clinic PA',
      specialization: '',
      location: {
        lon: -96.682339,
        lat: 32.917209,
      },
      state: 'TX',
      fax: '972-276-8137',
      latitude: 32.917209,
      longitude: -96.682339,
    },
  },
  {
    specialty: 'Primary Care',
    gender: 'Male',
    yearsExperience: 26,
    entityName: 'Anilkumar Patel',
    curatedOut: false,
    scoreCareJourneyDiagnosing: 0,
    outcareCompetent: false,
    title: 'MD',
    titleArray: ['MD'],
    subspecialties: [
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyScore: 100,
        subspecialtyId: 26,
      },
    ],
    scoringOrder: 100,
    overallPercentile: 0.9982134699821472,
    groupAffiliations: [],
    medicalSchool: 'Baroda Medical College',
    scoreCareJourneyOutcomes: 3,
    qualityMetricValue: 95.390337967454,
    firstName: 'Anilkumar',
    outcareUrl: '',
    benefitDecrease: false,
    healthPlan: '',
    npi: 1538188941,
    appropriatenessMetricValue: 68.20824136150374,
    hospitalAffiliations: [],
    regionId: 1,
    lastName: 'Patel',
    performanceMetricValue: 93.00524664713261,
    scoreCareJourneyTreatment: 3,
    laymansSubspecialties: [
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyId: 26,
      },
    ],
    scoreCareJourneyCost: 2,
    suggest: [
      {
        input: ['patel TXB1', 'anilkumar patel TXB1'],
        contexts: {
          loc: [
            {
              lon: -96.85238,
              lat: 33.030827,
            },
            {
              lon: -96.796102,
              lat: 33.0647,
            },
            {
              lon: -96.748094,
              lat: 33.019203,
            },
            {
              lon: -96.773417,
              lat: 33.056095,
            },
            {
              lon: -96.752945,
              lat: 32.997919,
            },
            {
              lon: -96.765682,
              lat: 33.019394,
            },
            {
              lon: -96.865803,
              lat: 33.044897,
            },
          ],
        },
      },
    ],
    entityId: '1538188941',
    overallMetricValue: 88.86182537972945,
    boardCertified: true,
    outcomesPercentile: 0.9980732202529907,
    emboldEvaluated: true,
    specialtyId: 5,
    acceptingNewPatients: true,
    scoreOverallQuality: 100,
    treatmentPercentile: 0.9557405114173889,
    telehealthAvailable: false,
    outcareCertified: false,
    region: 'Dallas / Fort Worth',
    highPerforming: true,
    costMetricValue: 70.54466214784591,
    places: [
      {
        zipcode: '75080',
        productIds: ['TXB1'],
        city: 'Richardson',
        phone: '972-398-5700',
        address1: '3351 Waterview Pkwy',
        address2: '',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        telehealthAvailable: false,
        specialization: '',
        location: {
          lon: -96.752945,
          lat: 32.997919,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 32.997919,
        longitude: -96.752945,
      },
      {
        zipcode: '75075',
        productIds: ['TXB1'],
        city: 'Plano',
        phone: '972-596-6800',
        address1: '3901 W 15th St',
        address2: '',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        specialization: '',
        location: {
          lon: -96.765682,
          lat: 33.019394,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 33.019394,
        longitude: -96.765682,
      },
      {
        zipcode: '75075',
        productIds: ['TXB1'],
        city: 'Plano',
        phone: '972-612-9000',
        address1: '2800 W 15th St',
        address2: '',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        specialization: '',
        location: {
          lon: -96.748094,
          lat: 33.019203,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 33.019203,
        longitude: -96.748094,
      },
      {
        zipcode: '75093',
        productIds: ['TXB1'],
        city: 'Plano',
        phone: '972-899-5510',
        address1: '2301 Marsh Ln',
        address2: 'Ste 200',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        specialization: '',
        location: {
          lon: -96.85238,
          lat: 33.030827,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 33.030827,
        longitude: -96.85238,
      },
      {
        zipcode: '75010',
        productIds: ['TXB1'],
        city: 'Carrollton',
        phone: '469-892-1400',
        address1: '2329 Parker Rd',
        address2: '',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        specialization: '',
        location: {
          lon: -96.865803,
          lat: 33.044897,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 33.044897,
        longitude: -96.865803,
      },
      {
        city: 'Plano',
        address1: '4116 W Spring Creek Pkwy',
        address2: 'Ste 40C',
        isWheelchairAccessible: false,
        zipcode: '75024',
        productIds: ['TXB1'],
        phone: '469-893-5000',
        acceptingNewPatients: true,
        name: 'Anilkumar Patel MDPA',
        specialization: '',
        location: {
          lon: -96.773417,
          lat: 33.056095,
        },
        state: 'TX',
        fax: '469-893-5555',
        latitude: 33.056095,
        longitude: -96.773417,
      },
      {
        zipcode: '75024',
        productIds: ['TXB1'],
        city: 'Plano',
        phone: '214-473-8822',
        address1: '6800 Preston Rd',
        address2: '',
        acceptingNewPatients: false,
        name: 'Questcare Hospitalists PLLC',
        specialization: '',
        location: {
          lon: -96.796102,
          lat: 33.0647,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 33.0647,
        longitude: -96.796102,
      },
    ],
    closestVisibleLocation: {
      zipcode: '75080',
      productIds: ['TXB1'],
      city: 'Richardson',
      phone: '972-398-5700',
      address1: '3351 Waterview Pkwy',
      address2: '',
      acceptingNewPatients: false,
      name: 'Questcare Hospitalists PLLC',
      specialization: '',
      location: {
        lon: -96.752945,
        lat: 32.997919,
      },
      state: 'TX',
      isWheelchairAccessible: false,
      latitude: 32.997919,
      longitude: -96.752945,
    },
  },
  {
    specialty: 'Primary Care',
    gender: 'Male',
    yearsExperience: 26,
    entityName: 'Hung Dang',
    curatedOut: false,
    scoreCareJourneyDiagnosing: 3,
    outcareCompetent: false,
    title: 'MD',
    titleArray: ['MD'],
    subspecialties: [
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyScore: 87,
        subspecialtyId: 25,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyScore: 88,
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyScore: 99,
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Pediatrics',
        subspecialtyId: 28,
      },
    ],
    scoringOrder: 100,
    overallPercentile: 0.9982134699821472,
    groupAffiliations: [],
    medicalSchool: 'University Of Texas Medical Branch',
    scoreCareJourneyOutcomes: 3,
    diagnosingPercentile: 0.9959287047386169,
    languagesArray: ['Vietnamese'],
    qualityMetricValue: 76.62081074298179,
    firstName: 'Hung',
    outcareUrl: '',
    benefitDecrease: false,
    languages: 'Vietnamese',
    healthPlan: '',
    npi: 1376541656,
    appropriatenessMetricValue: 92.33334302818257,
    hospitalAffiliations: [],
    regionId: 1,
    lastName: 'Dang',
    performanceMetricValue: 83.21499098801607,
    scoreCareJourneyTreatment: 3,
    laymansSubspecialties: [
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Pediatrics',
        subspecialtyId: 28,
      },
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyId: 25,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
    ],
    scoreCareJourneyCost: 2,
    suggest: [
      {
        input: ['dang TXB1', 'hung dang TXB1'],
        contexts: {
          loc: [
            {
              lon: -97.115004,
              lat: 32.695557,
            },
            {
              lon: -97.288319,
              lat: 32.782657,
            },
          ],
        },
      },
    ],
    entityId: '1376541656',
    overallMetricValue: 78.86080161856623,
    boardCertified: true,
    outcomesPercentile: 0.9520297646522522,
    emboldEvaluated: true,
    specialtyId: 5,
    acceptingNewPatients: true,
    scoreOverallQuality: 100,
    treatmentPercentile: 0.9557405114173889,
    telehealthAvailable: false,
    outcareCertified: false,
    region: 'Dallas / Fort Worth',
    highPerforming: true,
    costMetricValue: 61.62106779034457,
    places: [
      {
        city: 'Arlington',
        address1: '3020 Matlock Rd',
        address2: 'Ste 200',
        isWheelchairAccessible: true,
        zipcode: '76015',
        productIds: ['TXB1'],
        phone: '817-468-5252',
        acceptingNewPatients: true,
        telehealthAvailable: false,
        name: 'Hung T Dang MD PA',
        specialization: '',
        location: {
          lon: -97.115004,
          lat: 32.695557,
        },
        state: 'TX',
        fax: '817-468-5257',
        latitude: 32.695557,
        longitude: -97.115004,
      },
    ],
    closestVisibleLocation: {
      city: 'Arlington',
      address1: '3020 Matlock Rd',
      address2: 'Ste 200',
      isWheelchairAccessible: true,
      zipcode: '76015',
      productIds: ['TXB1'],
      phone: '817-468-5252',
      acceptingNewPatients: true,
      name: 'Hung T Dang MD PA',
      specialization: '',
      location: {
        lon: -97.115004,
        lat: 32.695557,
      },
      state: 'TX',
      fax: '817-468-5257',
      latitude: 32.695557,
      longitude: -97.115004,
    },
  },
  {
    specialty: 'Primary Care',
    gender: 'Male',
    yearsExperience: 43,
    entityName: 'Cesar Albarracin',
    curatedOut: false,
    scoreCareJourneyDiagnosing: 0,
    outcareCompetent: false,
    title: 'MD',
    titleArray: ['MD'],
    subspecialties: [
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyScore: 100,
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
    ],
    scoringOrder: 100,
    overallPercentile: 0.9982134699821472,
    groupAffiliations: [],
    medicalSchool: 'Colegio Mayor De Nuestra Senoradel',
    scoreCareJourneyOutcomes: 3,
    languagesArray: ['Spanish'],
    qualityMetricValue: 96.03405702213973,
    firstName: 'Cesar',
    outcareUrl: '',
    benefitDecrease: false,
    languages: 'Spanish',
    healthPlan: '',
    npi: 1457331456,
    appropriatenessMetricValue: 68.48960201199552,
    hospitalAffiliations: [],
    regionId: 1,
    lastName: 'Albarracin',
    performanceMetricValue: 91.60951156449694,
    scoreCareJourneyTreatment: 3,
    laymansSubspecialties: [
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyId: 26,
      },
    ],
    scoreCareJourneyCost: 2,
    suggest: [
      {
        input: ['albarracin TXB1', 'cesar albarracin TXB1'],
        contexts: {
          loc: [
            {
              lon: -96.937643,
              lat: 32.456644,
            },
          ],
        },
      },
    ],
    entityId: '1457331456',
    overallMetricValue: 79.53076135750631,
    boardCertified: true,
    outcomesPercentile: 0.9762142300605774,
    emboldEvaluated: true,
    specialtyId: 5,
    acceptingNewPatients: true,
    scoreOverallQuality: 100,
    treatmentPercentile: 0.9557405114173889,
    telehealthAvailable: false,
    outcareCertified: false,
    region: 'Dallas / Fort Worth',
    highPerforming: true,
    costMetricValue: 27.09766438256405,
    places: [
      {
        city: 'Midlothian',
        address1: '4440 E Highway 287',
        address2: '',
        isWheelchairAccessible: false,
        zipcode: '76065',
        productIds: ['TXB1'],
        phone: '972-723-5590',
        acceptingNewPatients: true,
        telehealthAvailable: false,
        name: 'The Medical Clinic of North Texa',
        specialization: '',
        location: {
          lon: -96.937643,
          lat: 32.456644,
        },
        state: 'TX',
        fax: '972-723-5592',
        latitude: 32.456644,
        longitude: -96.937643,
      },
    ],
    closestVisibleLocation: {
      city: 'Midlothian',
      address1: '4440 E Highway 287',
      address2: '',
      isWheelchairAccessible: false,
      zipcode: '76065',
      productIds: ['TXB1'],
      phone: '972-723-5590',
      acceptingNewPatients: true,
      name: 'The Medical Clinic of North Texa',
      specialization: '',
      location: {
        lon: -96.937643,
        lat: 32.456644,
      },
      state: 'TX',
      fax: '972-723-5592',
      latitude: 32.456644,
      longitude: -96.937643,
    },
  },
  {
    specialty: 'Primary Care',
    gender: 'Male',
    yearsExperience: 13,
    entityName: 'Kevin Fite',
    curatedOut: false,
    scoreCareJourneyDiagnosing: 2,
    outcareCompetent: false,
    title: 'MD',
    titleArray: ['MD'],
    subspecialties: [
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyScore: 99,
        subspecialtyId: 26,
      },
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyScore: 90,
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyScore: 100,
        subspecialtyId: 25,
      },
    ],
    scoringOrder: 99,
    overallPercentile: 0.9820022583007812,
    groupAffiliations: [],
    medicalSchool: 'Columbia Conroe Medical Center  Houston',
    scoreCareJourneyOutcomes: 2,
    diagnosingPercentile: 0.7353664040565491,
    languagesArray: ['Spanish'],
    qualityMetricValue: 73.06256720797928,
    firstName: 'Kevin',
    outcareUrl: '',
    benefitDecrease: false,
    languages: 'Spanish',
    healthPlan: '',
    npi: 1730372491,
    appropriatenessMetricValue: 74.44922645620451,
    hospitalAffiliations: [],
    regionId: 35,
    lastName: 'Fite',
    performanceMetricValue: 72.73801322114082,
    scoreCareJourneyTreatment: 3,
    laymansSubspecialties: [
      {
        subspecialtyName: 'Geriatrics',
        subspecialtyId: 27,
      },
      {
        subspecialtyName: 'Behavioral Health',
        subspecialtyId: 25,
      },
      {
        subspecialtyName: 'Diabetes and High Blood Pressure',
        subspecialtyId: 26,
      },
    ],
    scoreCareJourneyCost: 2,
    suggest: [
      {
        input: ['fite TXB1', 'kevin fite TXB1'],
        contexts: {
          loc: [
            {
              lon: -96.799609,
              lat: 32.785618,
            },
            {
              lon: -97.704252,
              lat: 30.411457,
            },
          ],
        },
      },
    ],
    entityId: '1730372491',
    overallMetricValue: 65.87927561089296,
    boardCertified: true,
    outcomesPercentile: 0.7218789458274841,
    emboldEvaluated: true,
    specialtyId: 5,
    acceptingNewPatients: true,
    scoreOverallQuality: 99,
    treatmentPercentile: 0.9557405114173889,
    telehealthAvailable: false,
    outcareCertified: false,
    region: 'Austin',
    highPerforming: true,
    costMetricValue: 42.12325346062072,
    places: [
      {
        zipcode: '75201',
        productIds: ['TXB1'],
        city: 'Dallas',
        phone: '617-513-4838',
        address1: '750 N Saint Paul St',
        address2: 'Ste 250',
        acceptingNewPatients: true,
        name: 'Broad Health PA',
        telehealthAvailable: false,
        specialization: '',
        location: {
          lon: -96.799609,
          lat: 32.785618,
        },
        state: 'TX',
        isWheelchairAccessible: false,
        latitude: 32.785618,
        longitude: -96.799609,
      },
    ],
    closestVisibleLocation: {
      zipcode: '75201',
      productIds: ['TXB1'],
      city: 'Dallas',
      phone: '617-513-4838',
      address1: '750 N Saint Paul St',
      address2: 'Ste 250',
      acceptingNewPatients: true,
      name: 'Broad Health PA',
      specialization: '',
      location: {
        lon: -96.799609,
        lat: 32.785618,
      },
      state: 'TX',
      isWheelchairAccessible: false,
      latitude: 32.785618,
      longitude: -96.799609,
    },
  },
];
