import { useMemo } from 'react';
import { useTheme } from '@material-ui/core';

import { PLACE_TEMPLATE_NAME } from 'components/ModernExperience/Map/loadCustomAtlasIconTemplates';

export default function useImageSprites() {
  const { palette } = useTheme();

  return useMemo(
    () => [
      // https://learn.microsoft.com/en-us/azure/azure-maps/how-to-use-image-templates-web-sdk#list-of-image-templates
      // https://github.com/Azure/react-azure-maps/blob/master/src/components/AzureMap/useCreateSprites.tsx
      // as the map initializes, we can create custom sprites using the built in azure templates
      // or any custom templates from loadCustomAtlasIconTemplates.js
      {
        id: 'marker-primary',
        templateName: 'marker',
        color: palette.primary.main, // marker fill
        secondaryColor: palette.primary.main, // marker border
      },
      {
        id: 'marker-secondary',
        templateName: 'marker',
        color: palette.secondary.main, // marker fill
        secondaryColor: palette.secondary.main, // marker border
      },
      {
        id: 'place-primary',
        templateName: PLACE_TEMPLATE_NAME,
        color: palette.primary.main, // hospital path lines
        secondaryColor: palette.primary.contrastText, // hospital fill
      },
      {
        id: 'place-secondary',
        templateName: PLACE_TEMPLATE_NAME,
        color: palette.secondary.main, // marker fill
        secondaryColor: palette.secondary.contrastText, // marker border
      },
    ],
    [palette]
  );
}
