import React from 'react';
import PropTypes from 'propTypes';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import GroupIcon from '@material-ui/icons/Group';

import ProfileSection from '../ProfileSection';
import AffiliationsList from '../AffiliationsList';

function SectionGroupAffiliation({ providerId }) {
  const { groupAffiliations } = useSelector(select.provider(providerId).sortedAffiliations);

  if (!groupAffiliations || groupAffiliations?.length === 0) return null;
  return (
    <ProfileSection TitleProps={{ title: 'Group Affiliations' }}>
      <AffiliationsList affiliations={groupAffiliations} icon={<GroupIcon />} />
    </ProfileSection>
  );
}

export default SectionGroupAffiliation;

SectionGroupAffiliation.propTypes = {
  providerId: PropTypes.string.isRequired,
};
