import React from 'react';
import PropTypes from 'propTypes';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import Place from 'icons/Place';

import ProfileSection from '../ProfileSection';
import AffiliationsList from '../AffiliationsList';

function SectionHospitalAffiliations({ providerId }) {
  const { hospitalAffiliations } = useSelector(select.provider(providerId).sortedAffiliations);

  if (!hospitalAffiliations || hospitalAffiliations?.length === 0) return null;
  return (
    <ProfileSection TitleProps={{ title: 'Hospital Affiliations' }}>
      <AffiliationsList affiliations={hospitalAffiliations} icon={<Place />} />
    </ProfileSection>
  );
}

export default SectionHospitalAffiliations;

SectionHospitalAffiliations.propTypes = {
  providerId: PropTypes.string.isRequired,
};
