import * as Sentry from '@sentry/react';

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';
import { PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';

export default function useFetchSpecialties() {
  const dispatch = useDispatch();
  const configIsLoading = useSelector(select.config.isLoading);
  const axios = useSelector(select.axios.axiosInstance);

  const fetchSpecialties = () => {
    dispatch(actions.specialties.setLoadingSpecialties(true));
    axios('/specialties/')
      .then((res) => {
        if (res.data && res.data.length) {
          const typeMap = {
            P: PROVIDER_RESULT_TYPE,
            F: PLACE_RESULT_TYPE,
          };
          const specialtiesMap = {
            [PROVIDER_RESULT_TYPE]: {},
            [PLACE_RESULT_TYPE]: {},
          };
          const subspecialtiesMap = {
            [PROVIDER_RESULT_TYPE]: {},
            [PLACE_RESULT_TYPE]: {},
          };
          res.data.forEach((specialty) => {
            specialtiesMap[typeMap[specialty.type]][specialty.specialtyId] = specialty;
            if (specialty.subspecialties.length > 0) {
              specialty.subspecialties.forEach((subspecialty) => {
                // eslint-disable-next-line no-param-reassign
                subspecialty.parentSpecialtyId = specialty.specialtyId;
                subspecialtiesMap[typeMap[specialty.type]][subspecialty.subspecialtyId] =
                  subspecialty;
              });
            }
          });
          dispatch(actions.specialties.setSpecialties(specialtiesMap));
          dispatch(actions.specialties.setSubspecialties(subspecialtiesMap));
          dispatch(actions.specialties.setFailedToLoadSpecialties(false));
        }
      })
      .catch((error) => {
        dispatch(actions.specialties.setFailedToLoadSpecialties(true));
        Sentry.captureException(error);
      })
      .finally(() => {
        dispatch(actions.specialties.setLoadingSpecialties(false));
      });
  };

  useEffect(() => {
    if (!configIsLoading) {
      fetchSpecialties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading]);
}
