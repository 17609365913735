import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { makeStyles } from '@material-ui/styles';

import SortMenu from 'components/Results/Filters/SortMenu';
import FilterMenu from 'components/Results/Filters/FilterMenu';
import MobileSearchButton from 'components/Results/MobileSearchButton';

import SearchForm from './SearchForm';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    columnGap: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem 0 0',
  },
  fullWidthContainer: {
    display: 'block',
  },
  searchFormWrap: {
    width: '70%',
  },
  searchFormWrapMobile: {
    width: '100%',
  },
  sortFilterWrap: {
    padding: '6px 0px',
    zIndex: 3,
    display: 'flex',
    columnGap: theme.spacing(1),
    justifyContent: 'flex-end',
    flex: 1,
  },
}));

function SearchBar({ mobileView }) {
  const classes = useStyles();
  const isSmDown = useSelector(select.ui.isSmDown);
  const fullWidth = useMemo(() => mobileView || isSmDown, [isSmDown, mobileView]);

  return (
    <div className={clsx(classes.flexContainer, { [classes.fullWidthContainer]: fullWidth })}>
      <div className={clsx(classes.searchFormWrap, { [classes.searchFormWrapMobile]: fullWidth })}>
        {mobileView ? <MobileSearchButton /> : <SearchForm mobileView={false} autoSearch />}
      </div>
      <div className={classes.sortFilterWrap} aria-label="sort and filter controls">
        <SortMenu />
        <FilterMenu />
      </div>
    </div>
  );
}

export default React.memo(SearchBar);

/**
 * mobileView (boolean) - if true, use the compact "MobileSearchButton", else use the horizontal search form
 */
SearchBar.propTypes = {
  mobileView: PropTypes.bool.isRequired,
};
