import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { actions, select } from 'store/toolkit';
import ProviderNameAndSpecialty from 'components/ModernExperience/ResultCards/ProviderCard/ProviderNameAndSpecialty';
import DetailsButton from 'components/ModernExperience/ResultCards/DetailsButton';
import { openInNewTab } from 'utils/utils';
import Modal from './Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .modal-content-wrapper': {
      padding: `${theme.spacing(5)}px 0px`, // close button height
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  },
  titleRow: {
    padding: `0px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
  },
}));

export default function ProfileSectionModal({ title, children, ...props }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { data: modalData } = useSelector(select.ui.profileModal);
  const { id } = modalData || {};
  const profileDetailsLink = useSelector(select.results.profileDetailsLink(id));
  const { entityName } = useSelector(select.results.byId(id)) || {};
  const smDown = useSelector(select.ui.isXsDown);

  const handleProviderDetailsClick = () => {
    openInNewTab(profileDetailsLink);
  };

  const handleClose = useCallback(() => {
    dispatch(actions.ui.closeProfileModal());
  }, [dispatch]);

  if (!id || !entityName) return null;

  return (
    <Modal
      handleClose={handleClose}
      ariaId="profile-section-modal"
      customStyles={classes.root}
      fullScreen={smDown}
      fullWidth
      maxWidth="md"
      TitleProps={{ variant: 'srOnly', component: 'h2' }}
      title={`${entityName}: ${title}`}
      {...props}
    >
      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems={smDown ? 'flex-end' : 'flex-start'}
        className={classes.titleRow}
      >
        <Grid item>
          <ProviderNameAndSpecialty providerId={id} />
        </Grid>
        <Grid item>
          <DetailsButton onClick={handleProviderDetailsClick} accessibleName={entityName} />
        </Grid>
      </Grid>
      {children}
    </Modal>
  );
}

ProfileSectionModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
