import { useRef, useCallback } from 'react';

/**
 * @typedef {Object} Ref
 * @property {Any?} current
 */

/**
 * @typedef {Function} ReturnFocus
 * @returns {Ref|false} Returns the ref if focus was successfully called, or false if not.
 */

/**
 * @typedef {Object} ReturnFocusHook
 * @property {Ref} ref
 * @property {ReturnFocus} returnFocus
 */

/**
 * This hook is used for setting a ref, and then using a function to return focus to that ref. Example - A button that
 * opens a modal. The button receives the ref, and return focus is called when the modal closes to return focus
 * to the button.
 * @returns {ReturnFocusHook} returns a ref to return focus to, and a function to call to return the focus
 */
export default function useReturnFocus() {
  const ref = useRef(null);

  const returnFocus = useCallback(() => {
    if (ref?.current) {
      ref.current.focus();
      return ref;
    }
    return false;
  }, [ref]);

  return { ref, returnFocus };
}
