/* eslint-disable no-shadow */
import { isEmpty } from 'lodash';

import { REWARDS_SLICE_NAME } from 'store/slices/slicesNames';

export const isLoading = (state) => state[REWARDS_SLICE_NAME].isLoading;
export const error = (state) => state[REWARDS_SLICE_NAME].error;
export const campaign = (state) => state[REWARDS_SLICE_NAME].campaign;

export const hasActiveCampaign = (state) => {
  const { campaign } = state[REWARDS_SLICE_NAME];
  if (typeof campaign !== 'object') return false;
  return !isEmpty(campaign);
};

export const userIsEligible = (state) => {
  const hasRedeemed = state[REWARDS_SLICE_NAME].campaign?.hasRedeemed;
  if (typeof hasRedeemed !== 'boolean') return false;
  return !hasRedeemed;
};
