import { ENSEMBLE_PROVIDER_LOOKUP, ENSEMBLE_FORM } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import ensembleConfig from './ensembleConfig';

const apiTokens = {
  dev: 'Token ea0cf433992a401f3046984b45fd229ee8d90f4e',
  staging: 'Token fa3c270d74500943b2db0de25b309bb6265ca45a',
  prod: 'Token b06018a0bb7aa813dedddb071db7db5cda58c48a',
};

const token = selectToken(apiTokens);

const ensembleProviderLookupConfig = {
  // copy base level ensemble config
  ...ensembleConfig,
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'ensemblehp-providerlookup',

  FORM_VARIANT: ENSEMBLE_FORM,
  SHOW_REGION_SELECTOR: false,

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: ENSEMBLE_PROVIDER_LOOKUP,
  EMBOLD_CLIENT: ENSEMBLE_PROVIDER_LOOKUP,
  EMBOLD_CLIENT_SLUG: ENSEMBLE_PROVIDER_LOOKUP,

  // toggles
  CLOSED_ACCESS: false,

  // other
  LOGIN_NETWORK_NAME: 'ensembleProviderLookupUserId',

  // content
  WELCOME_MESSAGE: `If you are already in a plan please login through Quantum [HERE](https://ensemblehp.quantum-health.com/ "Log in at Quantum.com") to view quality information about providers and other benefits that may be available to you`,
};

export default ensembleProviderLookupConfig;
