import { createSlice } from '@reduxjs/toolkit';

import { createProviderCostKey } from './costUtils';
import { COST_SLICE_NAME } from '../slicesNames';
import { fetchProviderCost } from './costThunks';

const initialState = {};

const costSlice = createSlice({
  name: COST_SLICE_NAME,
  initialState,
  reducers: {
    invalidateKey(state, action) {
      const { npi, locationId } = action.payload;
      const key = createProviderCostKey({ npi, locationId });
      state[key] = undefined;
    },
    clear() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProviderCost.pending, (state, action) => {
        const { meta } = action;
        const { npi, locationId } = meta.arg;
        const key = createProviderCostKey({ npi, locationId });

        state[key] = {
          isLoading: true,
          error: null,
          data: null,
        };
      })
      .addCase(fetchProviderCost.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { npi, locationId } = meta.arg;
        const key = createProviderCostKey({ npi, locationId });

        state[key] = {
          isLoading: false,
          error: null,
          data: payload,
        };
      })
      .addCase(fetchProviderCost.rejected, (state, action) => {
        const { meta } = action;
        const { npi, locationId } = meta.arg;
        const key = createProviderCostKey({ npi, locationId });

        state[key] = {
          isLoading: false,
          error: 'Failed to retrieve cost information',
          data: null,
        };
      });
  },
});

export default costSlice;
export const { invalidateKey, clear } = costSlice.actions;
