import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export default withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    borderRadius: theme.shape.borderRadius * 4,
    overflowY: 'auto',
    transition: 'height .2s ease',
    position: 'relative',
    maxHeight: '66vh',
    minHeight: 100,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      maxHeight: 'none',
      flex: '1 0 1px', // grow to fill the space between the chat header and text input, but do not exceed that space
    },
  },
}))(Box);
