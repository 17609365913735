/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box, Divider, Typography, useTheme, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { startCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      '& > .MuiBox-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },
  },
}));

function ColoredBox({ color, label, small, contrastText }) {
  const { palette } = useTheme();

  let textColor = contrastText;
  if (!textColor) {
    try {
      textColor = palette.getContrastText(color);
    } catch (e) {
      console.log(label, 'failed to convert color', color);
      textColor = '#000';
    }
  }

  return (
    <Box
      style={{
        backgroundColor: color,
        color: textColor,
        height: small ? 100 : 125,
        width: small ? 100 : 125,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid #f1f1f1`,
        padding: 4,
      }}
    >
      <Typography component="span" align="center" variant="caption">
        {color}
      </Typography>
      <Typography component="strong" variant="h3" align="center">
        {label}
      </Typography>
      <Typography component="span" align="center" variant="caption">
        Text: {textColor}
      </Typography>
    </Box>
  );
}

function ColorGroup({ group, label }) {
  const colorBoxes = useMemo(() => {
    if (!group || typeof group === 'function' || typeof group === 'number') return null;

    if (typeof group === 'string') return <ColoredBox color={group} label="" />; // single colors

    if (group.main && group.dark && group.light)
      // main theme colors
      return (
        <>
          <ColoredBox color={group.light} label="Light" small />
          <ColoredBox color={group.main} label="Main" contrastText={group.contrastText} />
          <ColoredBox color={group.dark} label="Dark" small />
        </>
      );

    return Object.keys(group).map((key) => <ColoredBox key={key} color={group[key]} label={key} />);
  }, [group]);

  if (!colorBoxes) return null;

  return (
    <Paper>
      <Typography component="strong" variant="h4">
        {startCase(label)}
      </Typography>
      <Box>{colorBoxes}</Box>
    </Paper>
  );
}

export default function ColorsStyleguide() {
  const classes = useStyles();
  const { palette } = useTheme();

  const {
    primary,
    secondary,
    info,
    success,
    warning,
    error,
    type,
    text,
    action,
    divider,
    scoreTier,
    background,
    common,
    tertiary,
    ...rest
  } = palette;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} component={Typography} variant="h2">
        MUI Component Colors
      </Grid>
      <Grid item component={ColorGroup} group={primary} label="Primary" />
      <Grid item component={ColorGroup} group={secondary} label="Secondary" />
      <Grid item component={ColorGroup} group={info} label="Info" />
      <Grid item component={ColorGroup} group={success} label="success" />
      <Grid item component={ColorGroup} group={warning} label="warning" />
      <Grid item component={ColorGroup} group={error} label="error" />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} component={Typography} variant="h2">
        Other Colors
      </Grid>
      <Grid item component={ColorGroup} group={background} label="background" />
      <Grid item component={ColorGroup} group={common} label="common" />
      <Grid item component={ColorGroup} group={scoreTier} label="scoreTier" />
      <Grid item component={ColorGroup} group={tertiary} label="tertiary" />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} component={Typography} variant="h2">
        Explicit Colors
      </Grid>
      {Object.keys(rest).map((key) => (
        <Grid item component={ColorGroup} key={key} group={palette[key]} label={key} />
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} component={Typography} variant="h2">
        Text Colors
      </Grid>
      {Object.keys(text).map((key) => (
        <Typography key={key} component="strong" variant="h3" style={{ color: text[key] }}>
          {startCase(key)}
        </Typography>
      ))}
    </Grid>
  );
}
