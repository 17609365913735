import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import LoginPage from 'components/Pages/LoginPage';

import { select } from 'store/toolkit';

import { LOGIN_ROUTE } from '../../navigation/navigationConstants';
/**
 * This component extends the standard <Route /> component from React Router.
 * When authenticated, a user may visit the routes as normal.
 * When unauthenticated the user will be redirected, but the intended route will be stored in the Router's state.
 * See example at https://v5.reactrouter.com/web/example/auth-workflow
 * @TODO: TECH-4023 revisit authorization / authentication handling
 */
export default function AuthProtectedRoute({ render, ...props }) {
  const isAuthenticated = useSelector(select.auth.isLoggedIn);

  return (
    <Route
      path={props.path}
      {...props}
      render={(routeProps) =>
        isAuthenticated ? (
          render(routeProps)
        ) : (
          <Redirect
            to={{ pathname: LOGIN_ROUTE, state: { from: routeProps.location } }}
            component={LoginPage}
          />
        )
      }
    />
  );
}

AuthProtectedRoute.propTypes = {
  render: PropTypes.func.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
