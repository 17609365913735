import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import Modal from './Modal';

/**
 * This HOC is used to wrap page components to be displayed as a modal.
 * @param {JSX.Element} Component This is the component to be rendered within a modal
 * @param {string} title This is the string to set the document title to when the modal is opened
 * @param {string = "sm"} maxWidth This is passed to the <Modal /> component as a prop
 * @returns A React functional component
 */

export default function withModalTemplate(Component, modalProps = {}) {
  function ReturnComponent(props) {
    const history = useHistory();
    const isXsDown = useSelector(select.ui.isXsDown);

    return (
      <Modal open handleClose={history.goBack} fullScreen={isXsDown} {...modalProps}>
        <Component {...props} />
      </Modal>
    );
  }

  return ReturnComponent;
}
