import { DEFAULT_LOCATIONS, POLK, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import polkLogo from 'assets/polk-icon-only.svg';
import polkFullLogo from 'assets/polk-full.png';

const apiTokens = {
  dev: 'Token b436afd7ec0e592afc47d5ccd2b2dd9d90bcba50',
  staging: 'Token e85a85fbb2a772a152b055fb83184a44c4594268',
  prod: 'Token f8c8f063bbb77b10da354cd9dc046a13114577d3',
};

const token = selectToken(apiTokens);

const polkConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'polk',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'polk',
  EMBOLD_CLIENT: POLK,
  EMBOLD_CLIENT_SLUG: 'polk',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Lakeland },
  LOGIN_NETWORK_NAME: 'polkUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['^(polkcountymember|bcbscustomerservice|eversideclinics)$'],

  // content
  DISPLAY_NAME: 'Polk',
  UPDATE_REGION_TEXT: 'Who am I?',
  HEADER_LOGO: polkLogo,
  SUPPORT_LINK: 'https://polksupport.emboldhealth.com/portal/',
  FORM_VARIANT: DROPDOWN,
  SHOW_CONTACT_BCBS_ROUTE: true,
  SCHEDULE_COPY: `Connecting you to the Blue Cross Blue Shield customer service to assist with scheduling. Click 'Call Now' to continue.`,
  SCHEDULE_PHONE_NUMBER: '855-630-6824',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Polk County Public Schools and Embold Health have partnered to bring you Embold’s Provider
  Guide, your guide to quickly and easily finding the doctors in your network and area that
  have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: polkFullLogo,
  NO_WELCOME_LINK: true,
};

export default polkConfig;
