import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';

function RadioGroupInput({
  name,
  label,
  options,
  FormControlProps,
  FormLabelProps,
  FormControlLabelProps,
  RadioProps,
  ...props
}) {
  const [field, meta] = useField(name);

  const isError = Boolean(meta.touched && meta.error);
  return (
    <FormControl {...FormControlProps} component="fieldset">
      <FormLabel {...FormLabelProps} component="legend">
        {label}
      </FormLabel>
      <RadioGroup {...field} {...props} name={name}>
        {options.map((opt) => (
          <FormControlLabel
            {...FormControlLabelProps}
            key={opt.value}
            value={opt.value}
            control={<Radio {...RadioProps} />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
      {isError && <FormHelperText error={isError}>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

RadioGroupInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  FormControlProps: PropTypes.shape(),
  FormLabelProps: PropTypes.shape(),
  FormControlLabelProps: PropTypes.shape(),
  RadioProps: PropTypes.shape(),
};

RadioGroupInput.defaultProps = {
  FormControlProps: {},
  FormLabelProps: {},
  FormControlLabelProps: {},
  RadioProps: {},
};

export default React.forwardRef((props, ref) => <RadioGroupInput innerRef={ref} {...props} />);
