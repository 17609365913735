import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes, { ChildrenType } from 'propTypes';
import { useDispatch } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CloseIcon from '@material-ui/icons/Close';

import { actions } from 'store/toolkit';
import { BANNER_COLORS } from 'store/slices/banner/bannerConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    '& button': {
      color: 'inherit',
    },
    background: ({ color }) =>
      `linear-gradient(45deg, ${theme.palette[color].dark}, ${theme.palette[color].main} 50%)`,
    color: ({ color }) => theme.palette[color].contrastText,
    '& .Mui-focusVisible': {
      outlineColor: ({ color }) => theme.palette[color].contrastText,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
    display: 'block',
  },
}));

export default function Banner({
  children,
  hideCloseButton,
  color,
  additionalClass,
  onClose,
  Icon,
  contentContainerProps,
  ...props
}) {
  const classes = useStyles({ color });

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(actions.banner.hideBanner());
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <Grid
      container
      alignItems="center"
      className={clsx(classes.root, { [additionalClass]: additionalClass })}
      {...props}
    >
      {!hideCloseButton && <Grid item xs={1} />}

      <Grid
        item
        xs={hideCloseButton ? 12 : 10}
        className={classes.contentContainer}
        {...contentContainerProps}
      >
        {children}
      </Grid>

      {!hideCloseButton && (
        <Grid item xs={1}>
          <IconButton
            aria-label="dismiss banner"
            size="small"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Icon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

Banner.propTypes = {
  hideCloseButton: PropTypes.bool,
  children: ChildrenType.isRequired,
  color: PropTypes.oneOf(BANNER_COLORS),
  additionalClass: PropTypes.string,
  onClose: PropTypes.func,
  Icon: PropTypes.elementType,
  contentContainerProps: PropTypes.shape({}),
};

Banner.defaultProps = {
  hideCloseButton: false,
  color: 'primary',
  additionalClass: undefined,
  onClose: undefined,
  Icon: CloseIcon,
  contentContainerProps: {},
};
