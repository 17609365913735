import React from 'react';
import PropTypes from 'propTypes';

import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

function Span({ classes, children }) {
  return (
    <Typography classes={classes} component="span">
      {children}
    </Typography>
  );
}

/**
 * A styled wrapper for provider specialty text - "Modern Experience Larger Provider Cards"
 */
const ProviderSpecialty = withStyles(() => ({
  root: {
    fontSize: '1rem',
  },
}))(Span);

export default ProviderSpecialty;

Span.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Span.defaultProps = {
  classes: {},
};
