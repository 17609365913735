import { select, actions, thunks } from 'store/toolkit';
import {
  USER_LOGIN,
  RETURNING_USER_SESSION,
  NETWORK_SLUG_UPDATE,
  CHAT_TERMS_ACCEPTED,
} from './analyticsConstants';

/* *************************** */
/* *** 0-99 Session Events *** */
/* *************************** */

export default {
  userLogin: {
    code: 1,
    category: USER_LOGIN,
    description: 'A user logged in through the login form',
    getEventData: (state) => {
      const formVariant = select.client.loginFormVariant(state);
      const client = select.client.name(state);
      return {
        eventLabel: `Login form submission ${formVariant}`,
        eventAction: `Visit ${client} PG`,
      };
    },
    labelDescription: 'Login for submission <login type>',
    actionDescription: 'Visit <client> PG',
    triggers: [thunks.app.userLoggedIn.fulfilled],
  },

  returningUserLogin: {
    code: 2,
    category: RETURNING_USER_SESSION,
    description:
      "A user who logged in previously opened the app and doesn't not need to be re-authenticated. This could also be caused by refreshing the page or opening a profile in a new tab",
    getEventData: (state) => {
      const client = select.client.name(state);
      return {
        eventAction: `Visit ${client} PG`,
        eventLabel: 'Cookied Login',
      };
    },
    labelDescription: 'Visit <client> PG',
    actionDescription: 'Cookied Login',
  },

  changedNetworkSlug: {
    code: 3,
    category: NETWORK_SLUG_UPDATE,
    description: 'A user changed their network slug',
    getEventData: () => ({
      eventAction: 'Update Network Slug',
      eventLabel: 'Update Network Slug',
    }),
    labelDescription: 'Update Network Slug',
    actionDescription: 'Update Network Slug',
    triggers: [actions.app.handleNetworkChange],
  },

  chatTermsAccepted: {
    code: 4,
    category: CHAT_TERMS_ACCEPTED,
    description: ' A user accepted the chat terms and privacy statements',
    getEventData: (state) => {
      const scenarioId = select.chat.scenarioId(state);
      return {
        eventAction: 'Chat Terms Accepted',
        eventLabel: 'Chat Terms Accepted',
        ...(scenarioId ? { scenarioId } : {}),
      };
    },
    labelDescription: 'Chat Terms Accepted',
    actionDescription: 'Chat Terms Accepted',
    triggers: [actions.chat.acceptTerms],
  },
};
