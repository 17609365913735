import { createAsyncThunk } from '@reduxjs/toolkit';

import { SEARCH_SLICE_NAME } from '../slicesNames';
import { search as selectResultsSearch } from '../results/selectResults';
import { overwriteSlice } from './searchSlice';

// more exports will be added, remove eslint disable when they are
/* eslint-disable import/prefer-default-export */
export const updateSearchFromResults = createAsyncThunk(
  `${SEARCH_SLICE_NAME}/updateFromResults`,
  async (args, thunkApi) => {
    const { getState, dispatch } = thunkApi;
    const state = getState();

    const resultsSearchValues = selectResultsSearch(state);

    dispatch(overwriteSlice(resultsSearchValues));
  }
);
