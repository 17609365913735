import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { PromotionProfileModal } from 'components/Pages/PromotionProfilePage';

export default function ModalRouter() {
  const location = useLocation();

  // Setup modal routes see https://v5.reactrouter.com/web/example/modal-gallery for example
  // This piece of state is set when one of the
  // links to modals are clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the previous route in the background, behind
  // the modal.
  const background = location.state && location.state.background;

  // if no background is present, then there should be no modal component shown
  if (!background) return null;

  // IMPORTANT: Any route that exists in this Switch should also exist in the main Switch in App.jsx
  // If not, a user could reach this route as a modal (By clicking a <LinkToModal />), bookmark it, and revisit
  // the bookmark and they would end up hitting a route that doesn't exist and be redirected to root
  return (
    <Switch location={location}>
      <Route path="/promotion/:id" exact render={(props) => <PromotionProfileModal {...props} />} />
    </Switch>
  );
}
