import React from 'react';
import PropTypes from 'propTypes';

import DetailIconList from './DetailIconList';

function AffiliationsList({ affiliations, icon }) {
  const items = affiliations.map(({ label, isPrimary }) => {
    const prefix = isPrimary ? <strong>Primary Affiliation: </strong> : '';
    return {
      text: (
        <>
          {prefix}
          {label}
        </>
      ),
      icon,
    };
  });

  return <DetailIconList items={items} />;
}

export default AffiliationsList;

AffiliationsList.propTypes = {
  affiliations: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, isPrimary: PropTypes.bool })
  ),
  icon: PropTypes.node,
};

AffiliationsList.defaultProps = {
  affiliations: [],
  icon: null,
};
