import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

import { select } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';
import { DROPDOWN, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import DropdownNetworkSelector from './DropdownNetworkSelector';
import AutocompleteNetworkSelect from './AutocompleteNetworkSelect';

export default function NetworkSelector({ name, onChange }) {
  const configLoading = useSelector(select.config.isLoading);
  const label = useSelector(select.content.regionSelectorText);
  const variant = useSelector(select.client.loginFormVariant);
  const networkInputVariant = useSelector(select.networks.networkInputVariant);
  const updateRegionModalOpen = useSelector(select.ui.updateRegionModalOpen);
  const location = useLocation();

  // network selectors are dependant on having the list of networks from the config json
  // if the config has not loaded, we should never render the selector
  if (configLoading)
    return <Skeleton width="100%" height={25} aria-label="network options are loading" />;

  switch (location.pathname === '/login' ? variant : networkInputVariant) {
    case DROPDOWN:
      return (
        <DropdownNetworkSelector
          name={name}
          label={label}
          onChange={onChange}
          submitOnChange={location?.pathname !== '/login' && !updateRegionModalOpen}
        />
      );
    case DROPDOWN_AUTOCOMPLETE:
      return (
        <AutocompleteNetworkSelect
          name={name}
          label={label}
          onChange={onChange}
          submitOnChange={location?.pathname !== '/login' && !updateRegionModalOpen}
        />
      );
    default:
      logDevMessage('Unhandled variant in Network Selector');
      return null;
  }
}

NetworkSelector.defaultProps = {
  onChange: false,
};

NetworkSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.bool,
};
