import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useSearchDispatchWithHistory = (path = '/results') => {
  const dispatch = useDispatch();
  const history = useHistory();

  return useCallback(
    (arg) => {
      dispatch(arg);
      history.push(path);
    },
    [path, history, dispatch]
  );
};

export default useSearchDispatchWithHistory;
