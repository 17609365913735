import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `${theme.spacing(1)}px ${theme.spacing(6)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 600,
  },
  icon: { color: theme.palette.warning.main, marginRight: theme.spacing(2) },
}));

export default function ChatExpandedSearchAlert({ searchName, newRadius }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography>
        We were unable to find a <strong>{searchName}</strong> provider matching your search
        criteria. However, we were able to find providers within <strong>{newRadius} miles</strong>
      </Typography>
    </Box>
  );
}

ChatExpandedSearchAlert.propTypes = {
  searchName: PropTypes.string.isRequired,
  newRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
