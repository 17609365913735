/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import { Grid, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { actions, select } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';
import StickySidebar from './StickySidebar';
import { LAYOUT_CONTAINED, LAYOUT_SIDEBAR } from './constants';

const useStyles = makeStyles((theme) => ({
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    goBackButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
  contentMargin: {
    paddingTop: '1rem',
  },
  padColumns: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  container: {
    paddingTop: 40,
    marginBottom: '2rem',
  },
  goBackBar: {
    position: 'fixed',
    width: '100%',
    height: '2.5rem',
    zIndex: 2,
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const PageTemplate = (WrappedComponent, title, layout = LAYOUT_CONTAINED, Sidebar = () => {}) => {
  function TemplateWrapper(props) {
    const { history, location } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const enableChat = useSelector(select.featureFlags.enableChat);

    const smoothScrollToTarget = useCallback((targetId) => {
      const target = document.getElementById(targetId.substring(1));
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    useEffect(() => {
      smoothScrollToTarget(location.hash);
    }, [location.hash, enableChat, smoothScrollToTarget]);

    const lastLocation = useLastLocation();
    const handleBack = () => {
      if (lastLocation === null) {
        history.push('/');
      } else {
        if (
          enableChat &&
          location.hash === '#beta-testing' &&
          (lastLocation.pathname === '/results' || lastLocation.pathname === '/')
        ) {
          dispatch(actions.ui.openModal('chat'));
        }
        history.goBack();
      }
    };

    function PaddedContent() {
      return (
        <div className={classes.contentMargin}>
          <WrappedComponent {...props} />
        </div>
      );
    }

    const containerProps = {
      maxWidth: 'xl',
      className: `page-template-container ${classes.container}`,
    };

    useUpdateTitle(title);

    return (
      <section>
        <div className={classes.goBackBar}>
          <Button
            onClick={handleBack}
            className={classes.goBackButton}
            color="primary"
            startIcon={<LeftArrowIcon />}
          >
            Go Back
          </Button>
        </div>

        {layout === LAYOUT_SIDEBAR ? (
          <Container {...containerProps} maxWidth="xl">
            <Grid container className={classes.contentMargin}>
              {!smDown && <Grid item xs={12} md={1} />}
              <Grid item xs={12} md={7} classes={{ root: classes.padColumns }}>
                <WrappedComponent {...props} />
              </Grid>
              {!smDown && <Grid item xs={12} md={1} />}
              <Grid item xs={12} md={3} classes={{ root: classes.padColumns }}>
                <StickySidebar smDown={smDown}>
                  <Sidebar />
                </StickySidebar>
              </Grid>
            </Grid>
          </Container>
        ) : layout === LAYOUT_CONTAINED ? (
          <Container {...containerProps}>{PaddedContent()}</Container>
        ) : (
          <>{PaddedContent()}</>
        )}
      </section>
    );
  }

  TemplateWrapper.propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      goBack: PropTypes.func,
    }).isRequired,
  };

  return TemplateWrapper;
};

export default PageTemplate;
