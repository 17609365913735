/* eslint-disable react/destructuring-assignment, react/prop-types */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import { actions } from 'store/toolkit';

class ErrorBoundary extends Component {
  componentDidCatch(error) {
    // send error to sentry
    Sentry.captureException(error);

    // redirect to homepage with URL params stripped out
    this.props.history.push('/');

    // reset to initialState

    this.props.handleErrorCatch();
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleErrorCatch: () => dispatch(actions.app.catchAppError()),
});

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));
