import { isValidCoords } from 'utils/mapUtils';
import { logDevMessage } from 'utils/utils';
import { AVERAGE_CARD_HEIGHT, FLOATING_CARD_BOTTOM } from './mapConstants';

/**
 * @typedef {Object} latLongPair
 * @property {number} latitude The total number of results from a query
 * @property {number} longitude The total number of results from a query
 */

/**
 * @typedef {Object} boundsPair
 * @property {ne} latLongPair The total number of results from a query
 * @property {sw} latLongPair The total number of results from a query
 */

/**
 * This function takes the existing map bounds and returns a new set of bounds that
 * is re-framed around a set of coordinates, such that the coordinates are centered
 * horizontally, and placed vertically in the center of the *remaining* map space once
 * the area covered by the active result card or slider is taken into account.
 *
 * Center horizontally by taking the width of the original map in "degrees", then
 * creating a new pair of longitudal bounds that preserve this width, but centered
 * around the longitude value of the given coords.
 *
 * Center vertically by creating a new bounds-pair where the top and bottom latitude
 * are shifted by the same value in order to center the given coord in the remaining space.
 * See docs for 'getRelativeCenter' for more details.
 *
 * @param {boundsPair} bounds
 * @param {latLongPair} coords
 * @param {relativeCenter} number Represents the height from the bottom of the map that will mark the center of the visible map space
 * @returns {boundsPair}
 */
export function shiftBoundsForCoords({ bounds, coords, relativeCenter = 0.8 }) {
  const { latitude: markerLat, longitude: markerLng } = coords;
  const { sw, ne } = bounds;

  // center horizonally
  const boundsWidth = ne.longitude - sw.longitude;
  const boundsWidthHalf = boundsWidth / 2;
  const leftLongitude = markerLng - boundsWidthHalf;
  const rightLongitude = markerLng + boundsWidthHalf;
  // center vertically (relative to space already taken by result card)
  const boundsHeight = ne.latitude - sw.latitude;
  const padMarker = boundsHeight * (1 - relativeCenter); // desired "top padding"
  const distanceFromMarkerToTopBounds = ne.latitude - markerLat; // initial "top padding"
  const verticalShift = distanceFromMarkerToTopBounds - padMarker; // adjustment to acheive desired padding

  return {
    ne: {
      latitude: ne.latitude - verticalShift,
      longitude: rightLongitude,
    },
    sw: {
      latitude: sw.latitude - verticalShift,
      longitude: leftLongitude,
    },
  };
}

/**
 * Accepts a boundsPair object (pre-validated) and returns a boundsPair object
 * that is zoomed to 95% of the original size. It does so by cropping 2.5% of the
 * total viewport width and height from each corner.
 * @param {boundsPair} bounds
 * @returns {boundsPair}
 */
export const zoomBounds = (bounds) => {
  const { sw, ne } = bounds;
  const boundsWidth = ne.longitude - sw.longitude;
  const boundsLength = ne.longitude - sw.longitude;

  const widthPad = boundsWidth * 0.025;
  const lengthPad = boundsLength * 0.025;

  return {
    sw: {
      latitude: sw.latitude + widthPad,
      longitude: sw.longitude + lengthPad,
    },
    ne: {
      latitude: ne.latitude - widthPad,
      longitude: ne.longitude - lengthPad,
    },
  };
};

/**
 * Accepts the Azure maps object returned from evt.map.getCamera()
 * Returns a structured mapBounds object. Default to null
 * @param {number[]} bounds
 * @returns {boundsPair}
 */
export const getMapBounds = (mapCamera) => {
  if (!mapCamera) {
    logDevMessage('Cannot get map bounds - Azure maps camera object is not defined');
    return null;
  }
  const { bounds } = mapCamera;
  if (!bounds) return null;
  if (!isValidCoords(bounds[1], bounds[0]) || !isValidCoords(bounds[3], bounds[2])) return null;
  return {
    sw: {
      longitude: bounds[0],
      latitude: bounds[1],
    },
    ne: {
      longitude: bounds[2],
      latitude: bounds[3],
    },
  };
};

/**
 * The 'relativeCenter' represents the height from the bottom of the map that will mark
 * the center of the visible map space. For instance, if a result card is covering the
 * bottom 40% of the map, the remaining open map space will be 60%, so the relative center
 * will be .7, which is the distance from the bottom of the map to the middle of the open 60%
 *
 * @param {number} mapHeight height of map in pixels
 * @param {number} coveredAreaHeight height of result card in pixels
 * @returns {number} value between 0 and 1
 */
export const getRelativeCenter = (mapHeight, coveredAreaHeight) => {
  if (!mapHeight || !coveredAreaHeight) return null;
  const coveredHeightRatio = coveredAreaHeight / mapHeight;
  const openHeightRatio = 1 - coveredHeightRatio;
  const proportionalCenterOfOpenMapArea = coveredHeightRatio + openHeightRatio / 2;
  // this function should return a value between 0 and 1, otherwise default to null
  if (proportionalCenterOfOpenMapArea > 1 || proportionalCenterOfOpenMapArea < 0) return null;
  return proportionalCenterOfOpenMapArea;
};

/**
 * Performs a series of computations to appropriately frame an active result.
 *
 * @param {boundsPair} bounds
 * @param {number} latitude
 * @param {number} longitude
 * @param {number} mapHeight height in pixels
 * @returns {boundsPair}
 */
export const reframeBoundsToActiveMarker = ({ bounds, latitude, longitude, mapHeight }) => {
  const offsetHeight = AVERAGE_CARD_HEIGHT + FLOATING_CARD_BOTTOM;
  /**
   * get new bounds that center the marker in the remaining map
   * space accounting for the height of the floating result card
   * */
  const relativeCenter = getRelativeCenter(mapHeight, offsetHeight);
  const shiftedBounds = shiftBoundsForCoords({
    bounds,
    coords: { latitude, longitude },
    relativeCenter,
  });

  /**
   * zoom in 2.5%. Otherwise the "fly" animation at the same zoom level can
   * re-combine broken clusters and result in a confusing map state
   * */
  const { ne, sw } = zoomBounds(shiftedBounds);

  // check results for validity
  if (!isValidCoords(ne.latitude, ne.longitude) || !isValidCoords(sw.latitude, sw.longitude)) {
    logDevMessage('Reframe bounds calculations failure');
    return null;
  }
  return { ne, sw };
};
