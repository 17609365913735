import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATIONS_SLICE_NAME } from '../slicesNames';
import * as notificationsConstants from './notificationsConstants';

const initialState = {
  isOpen: false,
  duration: null,
  message: null,
  severity: notificationsConstants.INFO,
  returnFocusKey: null,
};

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {
    create(state, action) {
      const {
        message,
        severity = notificationsConstants.INFO,
        duration = null,
        returnFocusKey = null,
      } = action.payload;

      state.isOpen = true;
      state.message = message;
      state.duration = duration;
      state.returnFocusKey = returnFocusKey;
      if (notificationsConstants.VALID_SEVERITIES.includes(severity)) {
        state.severity = severity;
      }
    },
    hide(state) {
      state.isOpen = false;
    },
    pause(state) {
      state.duration = null;
    },
    clear() {
      return initialState;
    },
  },
});

export default notificationsSlice;
export const { create, hide, clear, pause } = notificationsSlice.actions;
