import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Tooltip, { TooltipPropsShape } from 'components/Tooltip';
import useIconNode from 'hooks/useIconNode';

const useStyles = makeStyles(() => ({
  titleGrid: {
    alignItems: 'center',
    columnGap: 6,
  },
  title: {
    margin: 0,
    fontWeight: 700,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': { height: '1rem' },
    '& > svg': { fontSize: '1.5rem' },
  },
  tooltip: {
    marginLeft: '.35rem',
  },
}));

function ProfileSectionTitle({ title, icon, iconAltText, TooltipProps, additionalStyles }) {
  const classes = useStyles();
  const { title: tooltipTitle, message: tooltipText } = TooltipProps;

  const iconEl = useIconNode(icon, iconAltText);

  const hasTooltip = Boolean(tooltipText);

  return (
    <Grid container classes={{ root: classes.titleGrid }}>
      {iconEl && <div className={classes.icon}>{iconEl}</div>}
      <Tooltip
        title={tooltipTitle}
        message={tooltipText}
        additionalClasses={{ icon: classes.tooltip }}
        deactivated={!hasTooltip}
      >
        <Typography classes={{ root: `${classes.title} ${additionalStyles}` }} variant="h3">
          {title}
        </Typography>
      </Tooltip>
    </Grid>
  );
}

export default ProfileSectionTitle;

ProfileSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconAltText: PropTypes.string,
  TooltipProps: TooltipPropsShape,
  additionalStyles: PropTypes.string,
};

ProfileSectionTitle.defaultProps = {
  icon: undefined,
  iconAltText: undefined,
  TooltipProps: {},
  additionalStyles: '',
};
