import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Modal from 'components/Modals/Modal';
import { DEFAULT_SUPPORT_MAILBOX } from 'utils/constants';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

function ClientConfigErrorModal() {
  const clientConfigError = useSelector(select.config.error);

  return (
    <Modal
      open={Boolean(clientConfigError)}
      handleClose={() => window.location.reload()}
      ariaId="app-error-modal"
      title="Something went wrong"
      showX={false}
      errorModal
    >
      <Typography paragraph>
        Sorry for the inconvenience, this doesn&apos;t normally happen. Refresh the page below to
        try again, or if the issue persists contact our support team.
      </Typography>

      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          style={{ marginBottom: 20 }}
        >
          Try Again
        </Button>
      </div>

      <div style={{ textAlign: 'center' }}>
        <Button
          color="primary"
          href={`mailto:${DEFAULT_SUPPORT_MAILBOX}`}
          style={{ marginBottom: 20 }}
        >
          Contact Support
        </Button>
      </div>
    </Modal>
  );
}

export default ClientConfigErrorModal;
