import { useMemo } from 'react';
import { useTheme } from '@material-ui/styles';
import { get } from 'lodash';

/**
 *
 * @param {string} color A string path to match on the palette object
 * @returns {string|undefined}
 *
 * @example
 * const primaryColor = useThemeColor('primary.main'); // returns theme.palette.primary.main
 * const customKeyColor = useThemeColor('customKey'); // returns theme.palette.customKey
 * const hexColor = useThemeColor('#fff'); // returns "#fff"
 */
export default function useThemeColor(color) {
  const theme = useTheme();

  return useMemo(() => {
    if (!color || typeof color !== 'string') return undefined;

    if (color.startsWith('#')) return color; // string is already a hex value

    return get(theme.palette, color);
  }, [theme, color]);
}
