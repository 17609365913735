import React from 'react';
import PropTypes from 'propTypes';

import { makeStyles, withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Skeleton as SkeletonRoot } from '@material-ui/lab';

import RoundedWrapper from 'components/ModernExperience/ResultCards/StyledWrappers/RoundedWrapper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 754,
    paddingBottom: 0,
  },
  padBottom: {
    marginBottom: 25,
  },
  buttonContainer: {
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '40%',
    },
  },
  basicInfoGridCell: {
    flexGrow: 1,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      marginBottom: 20,
      borderRight: 'none',
    },
  },
  attributesGridCell: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: 20,
    flexDirection: 'column',
    rowGap: 10,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
}));

const Skeleton = withStyles(() => ({
  root: {
    maxWidth: '100%',
    borderRadius: 8,
  },
}))(SkeletonRoot);

function ProfileSkeleton({ isPlaceProfile, animation }) {
  const classes = useStyles();

  return (
    <RoundedWrapper additionalStyles={classes.wrapper}>
      <Skeleton
        variant="rect"
        height={32}
        className={classes.buttonContainer}
        animation={animation}
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Grid container classes={{ root: classes.padBottom }}>
            <Grid item classes={{ root: classes.basicInfoGridCell }}>
              <Skeleton variant="rect" height={28} width={200} animation={animation} />
              <Skeleton variant="rect" height={25} width={100} animation={animation} />
              <Skeleton
                variant="rect"
                height={100}
                width={isPlaceProfile ? '50%' : '100%'}
                animation={animation}
              />
            </Grid>
            {!isPlaceProfile && (
              <Grid item classes={{ root: classes.attributesGridCell }}>
                <Skeleton variant="rect" height={20} width={250} animation={animation} />
                <Skeleton variant="rect" height={18} width={150} animation={animation} />
                <Skeleton variant="rect" height={18} width={175} animation={animation} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item classes={{ root: classes.padBottom }}>
          <Skeleton variant="rect" height={300} animation={animation} />
        </Grid>
        {!isPlaceProfile && (
          <Grid item classes={{ root: classes.padBottom }}>
            <Skeleton variant="rect" height={250} animation={animation} />
          </Grid>
        )}
      </Grid>
    </RoundedWrapper>
  );
}

export default ProfileSkeleton;

ProfileSkeleton.propTypes = {
  isPlaceProfile: PropTypes.bool,
  animation: PropTypes.oneOf(['wave', 'pulse', false]),
};

ProfileSkeleton.defaultProps = {
  isPlaceProfile: false,
  animation: 'pulse',
};
