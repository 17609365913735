import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { Formik } from 'formik';

import validationSchema, {
  CITY,
  CONFIRM_DETAILS_CHECKBOX,
  CONFIRM_EMAIL,
  DATE_OF_VISIT,
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_EMAIL,
  PROVIDER_NPI,
  SELECTED_PROVIDER,
  STATE,
  VISITED_TIME_FRAME,
  VISITED_TIME_FRAME_OPTIONS,
  ZIP,
} from './redeemRewardForm.schema';
import useSubmitRedeemRewardForm from './useSubmitRedeemRewardForm';
import RedeemRewardConfirmation from './RedeemRewardConfirmation';
import RedeemRewardsFormFields from './RedeemRewardFormFields';

const ERROR_STATUS = 'error';
const SUCCESS_STATUS = 'success';

const useOverlayStyles = makeStyles((theme) => ({
  wrapper: { position: 'relative ' },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    borderRadius: 10,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  overlayLoading: {
    background: `${theme.palette.grey[200]}80`, // 50% opacity
  },
}));

export default function RedeemRewardForm({ providerNameAndTitle, npi, disabled, loading }) {
  const classes = useOverlayStyles();
  const [submissionError, setSubmissionError] = useState(''); // stores the user display error message

  const initialValues = {
    [SELECTED_PROVIDER]: providerNameAndTitle || '',
    [PROVIDER_NPI]: npi, // hidden
    [VISITED_TIME_FRAME]: VISITED_TIME_FRAME_OPTIONS[0].value,
    [DATE_OF_VISIT]: '',
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [PREFERRED_EMAIL]: '',
    [CONFIRM_EMAIL]: '',
    [CITY]: '',
    [STATE]: '',
    [ZIP]: '',
    [CONFIRM_DETAILS_CHECKBOX]: false,
  };

  const handleSubmit = useSubmitRedeemRewardForm({
    errorStatus: ERROR_STATUS,
    successStatus: SUCCESS_STATUS,
    setSubmissionError,
  });

  return (
    <Box classes={{ root: classes.wrapper }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ status }) => {
          switch (status) {
            case SUCCESS_STATUS:
              return <RedeemRewardConfirmation />;
            case ERROR_STATUS:
              return <RedeemRewardConfirmation error message={submissionError} />;
            default:
              return (
                <RedeemRewardsFormFields
                  providerNameAndTitle={providerNameAndTitle}
                  disabled={disabled || loading}
                />
              );
          }
        }}
      </Formik>
      <Box
        classes={{
          root: clsx({ [classes.overlay]: disabled || loading, [classes.overlayLoading]: loading }),
        }}
      >
        {loading && <CircularProgress />}
      </Box>
    </Box>
  );
}

RedeemRewardForm.propTypes = {
  providerNameAndTitle: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  npi: PropTypes.number.isRequired,
};
RedeemRewardForm.defaultProps = {
  providerNameAndTitle: '',
};
