import { createSlice } from '@reduxjs/toolkit';

import { getEnv } from 'utils/utils';
import { REWARDS_SLICE_NAME } from 'store/slices/slicesNames';

import * as rewardsThunks from './rewardsThunks';

const initialState = {
  isLoading: false,
  error: null,
  campaign: null,
};

const rewardsSlice = createSlice({
  name: REWARDS_SLICE_NAME,
  initialState,
  reducers: {
    overrideCampaign(state, action) {
      if (getEnv() !== 'production') {
        state.campaign = action.payload || null;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(rewardsThunks.fetchCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(rewardsThunks.fetchCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || 'Unknown error fetching the rewards campaign';
        state.campaign = null;
      })
      .addCase(rewardsThunks.fetchCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaign = action.payload;
      });
  },
});

export default rewardsSlice;
export const { overrideCampaign } = rewardsSlice.actions;
