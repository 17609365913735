import React from 'react';
import PropTypes from 'propTypes';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CardDivider from './StyledWrappers/CardDivider';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    padding: '0.25rem',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function ResultCardMapViewHeader({ onClick, accessibleButtonLabel }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.buttonWrap}>
        <IconButton
          classes={{ root: classes.buttonRoot }}
          onClick={onClick}
          aria-label={accessibleButtonLabel || 'close result detail card'}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <CardDivider />
    </>
  );
}

export default ResultCardMapViewHeader;

ResultCardMapViewHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  accessibleButtonLabel: PropTypes.string,
};

ResultCardMapViewHeader.defaultProps = {
  accessibleButtonLabel: '',
};
