import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';

import emboldWatermark from 'assets/pwrd-emboldhealth-footer.svg';
import { logDevMessage } from 'utils/utils';

/**
 * @example
 * <PrintButton
 *   printComponent={<MyComponentToPrint propA="a" />}
 *   variant="outlined"
 *   options={{
 *     onBeforePrint: () => console.log('run before printing'),
 *     onAfterPrint: () => console.log('run after printing')
 *   }}
 * >
 *  Print
 * </PrintButton>
 *
 * @example
 * <PrintButton
 *   printComponent={<MyComponentToPrint propA="a" />}
 *   component={IconButton}
 *   pageMargin=".25in"
 * >
 *  <MyIcon />
 * </PrintButton>
 *
 */
export default function PrintButton({
  children,
  pageMargin,
  component: Component,
  printComponent,
  options,
  includeWatermark,
  ...props
}) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    ...options,
  });

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (props.onClick) {
      logDevMessage(
        'Invalid prop onClick was passed to <PrintButton />. To add callbacks to print function pass them in the options prop such as onBeforePrint or onAfterPrint. See all available options at: https://www.npmjs.com/package/react-to-print#api ',
        'warn'
      );
    }
  }, [props]);

  return (
    <>
      {/* "Component" is the button component. Default value is the Mui <Button />. This is the only component that gets displayed. */}
      <Component {...props} onClick={handlePrint}>
        {children}
      </Component>

      {/* printComponent will not be displayed, only printed */}
      <div style={{ display: 'none' }}>
        <div style={{ padding: pageMargin }} ref={printRef}>
          {printComponent}

          {includeWatermark && (
            <div className="profile-footer">
              <img src={emboldWatermark} alt="powered by embold health" className="watermark" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

PrintButton.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
  printComponent: PropTypes.element.isRequired,
  pageMargin: PropTypes.string,
  options: PropTypes.shape({}), // see available options here: https://www.npmjs.com/package/react-to-print#api
  includeWatermark: PropTypes.bool,
};

PrintButton.defaultProps = {
  pageMargin: '.5in',
  component: Button,
  options: {},
  includeWatermark: false,
};
