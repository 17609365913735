/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import { SPECIALTIES_SLICE_NAME } from '../slicesNames';

export const specialties = (state) => state[SPECIALTIES_SLICE_NAME].specialties;

export const subspecialties = (state) => state[SPECIALTIES_SLICE_NAME].subspecialties;

export const placeSpecialties = (state) =>
  state[SPECIALTIES_SLICE_NAME].specialties?.[PLACE_RESULT_TYPE];

export const providerSpecialties = (state) =>
  state[SPECIALTIES_SLICE_NAME].specialties?.[PROVIDER_RESULT_TYPE];

export const providerSubspecialties = (state) =>
  state[SPECIALTIES_SLICE_NAME].subspecialties?.[PROVIDER_RESULT_TYPE];

export const loadingSpecialties = (state) => state[SPECIALTIES_SLICE_NAME].loadingSpecialties;

export const failedToLoadSpecialties = (state) =>
  state[SPECIALTIES_SLICE_NAME].failedToLoadSpecialties;

export function bySpecialtyId(id) {
  return createSelector(
    [providerSpecialties, placeSpecialties],
    (providerSpecialties, placeSpecialties) => {
      if (id === undefined) return undefined;
      if (id >= 300 && id < 400) {
        // must be a place specialty
        return placeSpecialties[id];
      }

      return providerSpecialties[id];
    }
  );
}
