import React, { useRef } from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';
import MapSection from 'components/ModernExperience/MapSection';
import ResultsSection from 'components/ModernExperience/ResultsSection';
import ShowMapButton from 'components/ModernExperience/ShowMapButton';
import SearchBar from 'components/ModernExperience/SearchBar';
import SearchBreadcrumbs from 'components/ModernExperience/Breadcrumbs/SearchBreadcrumbs';
import useUrlSearch from 'hooks/queryString/useUrlSearch';
import CompareModal from 'components/ModernExperience/CompareTable/CompareModal';
import useResponsiveMobileMapHeight from 'hooks/useResponsiveMobileMapHeight';

const FOCUS_OUTLINE_WIDTH = 2;
const MINIMUM_DESKTOP_CARD_WIDTH = 750;
const MAP_TRANSITION_TIME = 0.7;
export const MAP_ANIMATION_TRANSITION = `${MAP_TRANSITION_TIME}s ease`;
/*
 * The styles in this component should be responsible for positioning and relationship between the children components on this page.
 * Styles that relate specifically to the look of a child component should be applied within the child component, not here.
 */
const useStyles = makeStyles((theme) => {
  // breakpoints
  const desktopOnly = theme.breakpoints.up('lg');
  const mobileOnly = theme.breakpoints.down('md');

  // calculated sizes
  const pageHorizontalPadding = theme.spacing(3);
  const spaceBetweenResultsAndMap = theme.spacing(1);
  const desktopNonMapSpace =
    pageHorizontalPadding * 2 + MINIMUM_DESKTOP_CARD_WIDTH + spaceBetweenResultsAndMap; // this is all of the horizontal pixels that are NOT the map. It is used to calculate the map width on desktop

  return {
    root: {
      width: '100%',
      minHeight: 500,
      flex: '1 1px', // grows the page to the available space between header/footer but does not grow past that
      overflowY: 'auto',
      padding: `${theme.spacing(1)}px ${pageHorizontalPadding}px`,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        paddingBottom: 0,
      },
    },
    searchContainer: {
      width: '100%',
    },
    searchContentContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    listAndMapContainer: {
      flex: '1 1px', // grow to fill the space between the search car/breadcrumbs bar and the bottom of the page, but do not grow pase the bottom of the page
      overflow: 'hidden', // we don't want to push the footer down on this page
      display: 'flex',
      justifyContent: 'space-between',
      [desktopOnly]: {
        flexDirection: 'row',
      },
      [mobileOnly]: {
        flexDirection: 'column',
      },
    },
    resultsSection: {
      overflow: 'auto',
      [desktopOnly]: {
        flex: 0,
        transition: MAP_ANIMATION_TRANSITION,
        marginRight: spaceBetweenResultsAndMap,
        minWidth: MINIMUM_DESKTOP_CARD_WIDTH, // this value should reflect the minimum width required to cleanly display the result cards
      },
      [mobileOnly]: {
        height: '100%',
      },
    },
    resultsFullWidth: {
      minWidth: '100%',
      marginRight: 0,
    },
    mapWrap: {
      transition: MAP_ANIMATION_TRANSITION,
      overflow: 'hidden',
      padding: FOCUS_OUTLINE_WIDTH,
      [desktopOnly]: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      },
      [mobileOnly]: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.speedDial - 1, // 1 step below the show map button
      },
    },
    mapWrapHidden: {
      visibility: 'hidden',
      [desktopOnly]: {
        width: 0,
      },
      [mobileOnly]: {
        height: 0,
      },
    },
    mapSection: {
      [desktopOnly]: {
        /* The reason for calculating a width for the map is so that the map itself stays the same size, even when transitioned off screen. This prevents the map from having to resize itself during the transition. */
        width: `calc(100vw - ${desktopNonMapSpace}px - 10px)`,
        margin: `auto`,
        flex: 1,
      },
      [mobileOnly]: {
        width: `calc(100vw - ${2 * FOCUS_OUTLINE_WIDTH}px)`,
      },
    },
    breadcrumbsAndMapButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      [mobileOnly]: {
        paddingTop: theme.spacing(1),
      },
    },
    showMapButton: {
      [desktopOnly]: {
        marginRight: theme.spacing(2),
      },
      [mobileOnly]: {
        position: 'absolute',
        zIndex: theme.zIndex.speedDial,
        bottom: theme.spacing(1),
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 150,
      },
    },
  };
});

/**
 * Dynamically recreate the mapClasses as the container height changes. Kept in a separate hook to limit re-making the main "classes" object
 * @param {number|null} containerHeight - container height of the results section, used to set the container height of the map section
 * @returns - object with one class within
 */
const useMapStyles = (containerHeight) =>
  makeStyles((theme) => {
    const FALLBACK_MAP_HEIGHT = `calc(100vh - 185px - ${FOCUS_OUTLINE_WIDTH}px)`; // full height but not covering the search bar and header. 185px is the approximate height of the header + search bar.
    const mobileOnly = theme.breakpoints.down('md');
    const height = containerHeight || FALLBACK_MAP_HEIGHT;

    return {
      mobileHeight: {
        [mobileOnly]: {
          height,
        },
      },
    };
  });

function ResultsPage() {
  const classes = useStyles();
  const isXsDown = useSelector(select.ui.isXsDown);
  const isMdDown = useSelector(select.ui.isMdDown);
  const showMap = useSelector(select.ui.showResultsMap);
  const enableCompareModal = useSelector(select.results.enableCompareModal);

  const searchContentContainerRef = useRef(null);
  const containerHeight = useResponsiveMobileMapHeight(searchContentContainerRef);
  const mapStyles = useMapStyles(containerHeight)();

  useUpdateTitle('Results');
  useUrlSearch();

  return (
    <div className={classes.root}>
      <SearchBar mobileView={isXsDown} />

      <div className={classes.searchContentContainer} ref={searchContentContainerRef}>
        <div
          data-testid="breadcrumbs-show-map-bar"
          className={classes.breadcrumbsAndMapButtonContainer}
        >
          <SearchBreadcrumbs />
          <ShowMapButton className={classes.showMapButton} isMobile={isMdDown} />
        </div>

        <div className={classes.listAndMapContainer}>
          <ResultsSection
            additionalClass={clsx(classes.resultsSection, { [classes.resultsFullWidth]: !showMap })}
          />
          <div
            className={clsx(classes.mapWrap, {
              [classes.mapWrapHidden]: !showMap,
              [mapStyles.mobileHeight]: showMap,
            })}
            data-testid="search-results-map"
          >
            <div className="screenReaderText">
              <h2>Map Section</h2>
            </div>
            <MapSection
              className={`${classes.mapSection} ${mapStyles.mobileHeight}`}
              containerHeight={containerHeight}
            />
          </div>
        </div>
      </div>

      {enableCompareModal && <Route path="*/compare" component={CompareModal} />}
    </div>
  );
}

export default React.memo(ResultsPage);
