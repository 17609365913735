import React from 'react';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';

import useStartOver from 'hooks/useStartOver';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(1.5),
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  button: {
    marginBottom: theme.spacing(1),
  },
}));

function ProfileError({ heading, text, buttons }) {
  const classes = useStyles();
  const startOver = useStartOver();

  return (
    <Grid container className={classes.container}>
      <Typography component="h1" color="primary" classes={{ root: classes.title }}>
        {heading}
      </Typography>
      <Typography paragraph align="center">
        {text}
      </Typography>
      {buttons.indexOf('refresh') > -1 && (
        <Button
          onClick={() => window.location.reload()}
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Refresh Page
        </Button>
      )}
      {buttons.indexOf('newsearch') > -1 && (
        <Button onClick={() => startOver()} variant="outlined" color="primary">
          Try a New Search
        </Button>
      )}
    </Grid>
  );
}

export default ProfileError;

ProfileError.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['refresh', 'newsearch'])),
};

ProfileError.defaultProps = {
  buttons: [],
};
