import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@material-ui/core';

import getTheme from './getTheme/index';

export default function ThemeProvider({ children }) {
  const clientName = useSelector(select.client.name);
  const theme = getTheme(clientName);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: null,
};
