import React from 'react';
import PropTypes from 'propTypes/index';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';

import { select, actions } from 'store/toolkit';
import Modal from 'components/Modals/Modal';
import LocationAutocomplete from 'components/LocationSearch/LocationAutocomplete';
import { useFocusTo } from 'utils/FocusRefContext';

const useStyles = makeStyles(() => ({
  backButton: {
    position: 'absolute',
    left: 0,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
    minHeight: '60px',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
  },
}));

function LocationModal({ autoSearch, submitSearch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationModalOpen = useSelector(select.ui.locationModalOpen);
  const focusTo = useFocusTo();

  return (
    <Modal
      fullScreen
      showMobileMenu
      open={locationModalOpen}
      showX={false}
      ariaId="location-modal"
      exitFunction={focusTo.locationDummy}
    >
      <div
        style={{
          overflowY: 'hidden',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <div className={classes.headerContainer}>
          <Typography
            component="div"
            color="primary"
            id="location-modal-title"
            classes={{ root: classes.header }}
          >
            Location
          </Typography>
          <Button
            className={classes.backButton}
            color="primary"
            aria-label="go back"
            onClick={() => {
              dispatch(actions.ui.closeModal('location'));
            }}
            startIcon={<LeftArrowIcon />}
          >
            Back
          </Button>
        </div>

        <LocationAutocomplete
          mobileView
          autoFocus
          autoSearch={autoSearch}
          submitSearch={submitSearch}
        />
      </div>
    </Modal>
  );
}

export default LocationModal;

LocationModal.propTypes = {
  submitSearch: PropTypes.func.isRequired,
  autoSearch: PropTypes.bool.isRequired,
};
