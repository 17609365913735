import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  spinner: {
    margin: '1rem auto 0',
  },
}));

function LoadingSpinner({ loading }) {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Typography aria-live="assertive">Loading...</Typography>

      <CircularProgress size={50} thickness={2} aria-label="" classes={{ root: classes.spinner }} />
    </Grid>
  );
}

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

LoadingSpinner.defaultProps = {};

export default LoadingSpinner;
