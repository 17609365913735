import { useDispatch, useSelector } from 'react-redux';
import { formatGeolocate, validateLatLong } from 'utils/utils';
import actions from 'store/actions';
import { useFocusAnchorKeys } from 'utils/FocusRefContext';
import { select } from 'store/toolkit';

const useGeolocation = () => {
  const dispatch = useDispatch();
  const focusAnchorKeys = useFocusAnchorKeys();
  const azureMaps = useSelector(select.tokens.azureMaps);

  const handleGeoError = (verbose) => {
    dispatch(actions.location.handleGeocodeError(verbose));
    dispatch(
      actions.notifications.create({
        message: "There was an error getting your browser's location.",
        severity: 'warning',
        duration: 10000,
        returnFocusKey: focusAnchorKeys.locationInput,
      })
    );
  };
  // the first argument is needed to avoid a toast alert when pg loads and a user has their location disabled
  const getBrowserLocation = async (locationButtonClicked = false, verbose = false) => {
    if (locationButtonClicked) {
      dispatch(actions.location.startGetGeolocation());
      if (!navigator.geolocation) {
        console.error('Browser does not have geolocation support!');

        handleGeoError();
      } else {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            const { latitude, longitude } = location.coords;

            if (validateLatLong(latitude, longitude)) {
              fetch(
                `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${azureMaps}&api-version=1.0&query=${latitude},${longitude}`
              )
                .then((res) => res.json())
                .then(
                  (res) => {
                    const formattedResponse = formatGeolocate(res);
                    if (!formattedResponse) {
                      handleGeoError(verbose);
                    } else {
                      dispatch(actions.map.moveCenterTo({ latitude, longitude }));
                      dispatch(actions.location.closeLocationModal());
                      dispatch(
                        actions.location.updateLocationComponentsFromGeocodeResponse({
                          locationInput: formattedResponse.fullAddress,
                          latLong: { latitude, longitude },
                          locationComponents: {
                            city: formattedResponse.city,
                            zip: formattedResponse.zip,
                            state: formattedResponse.state,
                          },
                        })
                      );
                    }
                  },
                  (error) => {
                    console.error(error);
                    handleGeoError(verbose);
                  }
                );
            } else {
              handleGeoError(verbose);
            }
          },
          (error) => {
            console.error(error);
            // if we cannot get location, set to resolved as true so initial search text works
            if (locationButtonClicked) {
              dispatch(
                actions.location.handleGeocodeError({
                  error:
                    'Embold does not have permission to show your location. Please check your browser settings.',
                })
              );
              dispatch(
                actions.notifications.create({
                  message:
                    'Embold does not have permission to show your location. Please check your browser settings.',
                  severity: 'warning',
                  duration: 10000,
                  returnFocusKey: focusAnchorKeys.locationInput,
                })
              );
            }
          },
          {
            timeout: 5000,
            maximumAge: 300000, // Cache location for 5 minutes to speed up subsequent calls
          }
        );
      }
    }
  };

  return { getBrowserLocation };
};

export default useGeolocation;
