/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo, useEffect } from 'react';
import { useFocusTo } from 'utils/FocusRefContext';
import { useFormikContext } from 'formik';
import ScreenReaderErrors from '../ScreenReaderErrors';

export default function LoginFormErrors() {
  const { errors, isValidating } = useFormikContext();
  const focusTo = useFocusTo();

  const errorFocusMap = useMemo(
    () => ({
      networkSlug: focusTo.prefixField,
      id: focusTo.idField,
      suffix: focusTo.suffixField,
      termsChecked: focusTo.termsChecked,
      memberError: focusTo.prefixField,
    }),
    [focusTo]
  );

  const focusOnErrorField = useCallback(
    (err) => {
      const firstError = Object.keys(err)[0];
      if (errorFocusMap[firstError]) {
        errorFocusMap[firstError]();
      }
    },
    [errorFocusMap]
  );

  // take all form IDs from the login form validation schema and pass into the Screen Reader Errors Component so that it can be read
  // we want to use this anywhere we want a form's error message to be screen reader friendly
  const errorLabelMap = useMemo(
    () => ({
      networkSlug: 'Prefix',
      id: 'Identification Number',
      termsChecked: 'Checkbox',
      suffix: 'Suffix',
      memberError: 'Member Error',
    }),
    []
  );

  useEffect(() => {
    focusOnErrorField(errors);
  }, [errors, focusOnErrorField, isValidating]);

  return <ScreenReaderErrors errorLabelMap={errorLabelMap} />;
}
