import React, { useCallback } from 'react';
import { FormControlLabel, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import AntSwitch from 'components/AntSwitch';
import Tooltip from 'components/Tooltip';

export default function OutOfPocketMaximumSwitch() {
  const dispatch = useDispatch();
  const userHasMetOutOfPocketMaximum = useSelector(select.ui.userHasMetOutOfPocketMaximum);

  const handleChange = useCallback(
    () => dispatch(actions.ui.toggleUserHasMetOutOfPocketMaximum()),
    [dispatch]
  );

  return (
    <div style={{ display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10 }}>
      <FormControlLabel
        labelPlacement="start"
        label={
          <Typography
            color={userHasMetOutOfPocketMaximum ? 'primary' : 'textSecondary'}
            component="span"
          >
            I have met my out-of-pocket maximum
          </Typography>
        }
        control={<AntSwitch onChange={handleChange} />}
      />
      <Tooltip
        message="Once you reach your annual out-of-pocket maximum, your health plan will pay 100% of covered expenses for the rest of the year."
        aria-label="More information about out-of-pocket maximum"
      />
    </div>
  );
}
