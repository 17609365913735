import React from 'react';
import { Typography } from '@material-ui/core';

export default function RewardLimitDisclaimer(props) {
  return (
    <Typography {...props}>
      *Members are limited to one digital gift card per household upon selecting a provider to
      visit.
    </Typography>
  );
}
