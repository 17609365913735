import { useCallback, useState } from 'react';
import useResizeObserver from './useResizeObserver';

const useResponsiveMobileMapHeight = (ref) => {
  const [height, setHeight] = useState(null);
  const setHeightListener = useCallback(() => {
    setHeight(ref.current?.clientHeight);
  }, [ref]);

  useResizeObserver(ref, setHeightListener);

  return height;
};

export default useResponsiveMobileMapHeight;
