/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ children, location: { pathname, hash, key } }) {
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      // if hash is present, scroll element with hash id into view
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [pathname, hash, key]);

  return children || null;
}

export default withRouter(ScrollToTop);
