import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatGeolocate } from 'utils/utils';
import { values as selectConfigValues } from '../config/selectConfig';

// eslint-disable-next-line import/prefer-default-export
export const geolocateMapCenter = createAsyncThunk('geolocateMapCenter', async (args, thunkApi) => {
  const { mapCenter } = args;
  const { getState } = thunkApi;
  const { AZURE_MAPS_KEY } = selectConfigValues(getState());

  let addressComponents;
  // fallback locationInput value
  let locationInput = `Near ${mapCenter.latitude.toFixed(2)}, ${mapCenter.longitude.toFixed(2)}`;
  try {
    let res = await fetch(
      `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${AZURE_MAPS_KEY}&api-version=1.0&query=${mapCenter.latitude},${mapCenter.longitude}`
    );
    res = await res.json();
    addressComponents = formatGeolocate(res);
  } catch {
    // do nothing
  }

  const { city, state, zip, neighbourhood, street, isOutsideOfUSA, country } =
    addressComponents || {};
  if (neighbourhood) {
    locationInput = `Near ${neighbourhood}, ${city} ${state}`;
  } else if (street) {
    locationInput = `Near ${street}, ${city} ${state}`;
  } else if (city && state) {
    locationInput = `Near ${city}, ${state}`;
  }

  if (isOutsideOfUSA && country) locationInput = `${locationInput}, ${country}`;

  return {
    locationInput,
    locationComponents: { city, state, zip },
    latLong: mapCenter,
  };
});
