import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18Z" />
    <path d="M19 8H13V9.5H19V8Z" />
    <path d="M19 11H13V12.5H19V11Z" />
    <path d="M18 14H13V15.5H18V14Z" />
    <path d="M19 11H13V12.5H19V11Z" />
    <path d="M11 11H9V9H7V11H5V13H7V15H9V13H11V11Z" />
    <path d="M11 11H9V9H7V11H5V13H7V15H9V13H11V11Z" />
  </>,
  'Service'
);
