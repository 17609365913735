import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: null,
  message: null,
  link: { to: null, label: null },
  href: { to: null, label: null },
};

const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    open(state, action) {
      const { message, title, link, href } = action.payload;
      state.title = title;
      state.message = message;
      if (link) {
        state.link = link;
      } else {
        state.link = initialState.link;
      }
      if (href) {
        state.href = href;
      } else {
        state.href = initialState.href;
      }
    },
    close() {
      return initialState;
    },
  },
});

export const { open, close } = tooltipSlice.actions;

export default tooltipSlice;
