import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  '@keyframes jumpingAnimation': {
    0: {
      transform: 'translate(0, 0)',
    },
    '16%': {
      transform: 'translate(0, -10px)',
    },
    '33%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(0, 0)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    padding: 12,
    borderRadius: 8,
    '& :nth-last-child(1)': {
      animation: '$jumpingAnimation 1.2s 0.4s linear infinite',
      marginRight: 0,
    },
    '& :nth-last-child(2)': {
      animation: '$jumpingAnimation 1.2s 0.2s linear infinite',
    },
    '& :nth-last-child(3)': {
      animation: '$jumpingAnimation 1.2s 0s linear infinite',
    },
  },
  dot: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    marginRight: 4,
  },
  // "small" variant
  '@keyframes jumpingAnimationSmall': {
    0: {
      transform: 'translate(0, 0)',
    },
    '16%': {
      transform: 'translate(0, -3px)',
    },
    '33%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(0, 0)',
    },
  },
  small: {
    padding: '0 1px 0 2px',
    '& :nth-last-child(1)': {
      animation: '$jumpingAnimationSmall 1.2s 0.4s linear infinite',
      marginRight: 0,
    },
    '& :nth-last-child(2)': {
      animation: '$jumpingAnimationSmall 1.2s 0.2s linear infinite',
    },
    '& :nth-last-child(3)': {
      animation: '$jumpingAnimationSmall 1.2s 0s linear infinite',
    },
    '& $dot': {
      width: 3,
      height: 3,
      marginRight: 2,
    },
    '& > :last-child': { marginRight: 0 },
  },
}));

function LoadingDots({ small, additionalClass }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.small]: small,
        [additionalClass]: Boolean(additionalClass),
      })}
      data-testid="loading-dots"
      aria-label="loading"
    >
      <div className={`${classes.dot} dot`} />
      <div className={`${classes.dot} dot`} />
      <div className={`${classes.dot} dot`} />
    </div>
  );
}

LoadingDots.propTypes = {
  small: PropTypes.bool,
  additionalClass: PropTypes.string,
};

LoadingDots.defaultProps = {
  small: false,
  additionalClass: null,
};

export default LoadingDots;
