import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { thunks } from 'store/toolkit';

export default function useLogout() {
  const history = useHistory();
  const dispatch = useDispatch();
  return useCallback(() => {
    history.push('/login');
    dispatch(thunks.app.userLoggedOut());
  }, [history, dispatch]);
}
