/* eslint-disable consistent-return */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Modal from 'components/Modals/Modal';
import emboldIcon from 'assets/embold-icon.svg';
import { actions } from 'store/toolkit';

const useStyles = makeStyles(() => ({
  modalHeader: {
    width: '95%',
    margin: '10px auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
  },
  emboldIcon: {
    width: 20,
    marginBottom: -3,
    marginRight: 5,
  },
  modalStyles: {
    '& [data-reach-dialog-content]': {
      padding: '15px 40px',
    },
  },
}));

function CostModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Modal
      handleClose={() => {
        dispatch(actions.ui.closeProfileModal());
      }}
      open
      ariaId="specialties-modal"
      customStyles={classes.modalStyles}
      fullScreen={smDown}
    >
      <>
        <Typography
          variant="h2"
          className={`${classes.modalHeader} specialties-modal-header`}
          id="specialties-modal-title"
        >
          <img src={emboldIcon} className={classes.emboldIcon} alt="" /> Cost Efficiency
        </Typography>

        <Grid item xs={12} container>
          <Grid item id="specialties-modal-description">
            <Typography paragraph align="left">
              Embold recommends that you first look for a high-quality provider and then consider
              their Cost Efficiency. High-quality and highly cost-efficient providers deliver the
              best care while using the least resources.
            </Typography>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default CostModal;
