import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import { select } from 'store/toolkit';
import AddressLink from 'components/Profile/AddressLink';

export default function PlaceAddressCell({ placeId }) {
  const dispatch = useDispatch();
  const place = useSelector(select.place(placeId).data);

  const handleAddressClick = useCallback(() => {
    dispatch(_analyticsActions.addressClickFromCompareModal(placeId));
  }, [dispatch, placeId]);

  if (!place) return null;

  return <AddressLink onClick={handleAddressClick} address={place} />;
}

PlaceAddressCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
