import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

export default function GenderCell({ providerId }) {
  const { gender } = useSelector(select.provider(providerId).data) || {};

  if (!gender) return null;

  return gender;
}

GenderCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
