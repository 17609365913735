export function getEnv() {
  return 'staging';
}

export function getClient() {
  return 'qualityforum';
}

export function selectToken(apiTokens) {
  const env = getEnv();
  if (env === 'local' || env === 'testing') return apiTokens.dev;
  if (env === 'staging') return apiTokens.staging;
  return apiTokens.prod;
}

export function selectMapKey(azureMapsKeys) {
  const env = getEnv();
  if (env === 'local' || env === 'testing') return azureMapsKeys.dev;
  if (env === 'staging') return azureMapsKeys.staging;
  return azureMapsKeys.prod;
}
