import React from 'react';
import { useSelector } from 'react-redux';

import SectionHospitalAffiliations from 'components/ModernExperience/Profile/ProviderProfile/SectionHospitalAffiliations';
import SectionGroupAffiliation from 'components/ModernExperience/Profile/ProviderProfile/SectionGroupAffiliation';
import { logDevMessage } from 'utils/utils';
import { select } from 'store/toolkit';
import ProfileSectionModal from '../ProfileSectionModal';

export default function AffiliationsModal(props) {
  const { data: profileData } = useSelector(select.ui.profileModal);

  const { id } = profileData || {};

  if (!id) {
    logDevMessage('Missing id property in the uiSlice.profileModal.data object');
    return null;
  }

  return (
    <ProfileSectionModal title="Hospital and Group Affiliations" {...props}>
      <SectionGroupAffiliation providerId={id} />
      <SectionHospitalAffiliations providerId={id} />
    </ProfileSectionModal>
  );
}
