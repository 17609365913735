/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, SOCT_PROVIDER_LOOKUP, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import soctLoginLogo from 'assets/soct_logo.svg';
import soctHeaderLogo from 'assets/soct_header_logo.png';

const apiTokens = {
  dev: 'Token 70397bf834f307e965d40b8ebb9a0eaf9807f4e6',
  staging: 'Token 5d594fe981620ea7b15615885c795ec5859ba461',
  prod: 'Token 7e18e7532ed2f16ce9d39318c2cd31f02b2b0570',
};

const token = selectToken(apiTokens);

const soctProviderLookupConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'carecompass-providerlookup',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: SOCT_PROVIDER_LOOKUP,
  EMBOLD_CLIENT: SOCT_PROVIDER_LOOKUP,
  EMBOLD_CLIENT_SLUG: SOCT_PROVIDER_LOOKUP,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: false,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: [
    '^(standardaccess|primarycareaccess|expandedaccess|qualityfirstselectaccess|preferredaccessoutofarea)$',
  ],

  // other
  BENEFITS_CHANGE: false,
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Hartford },
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'soctProviderLookupUserId',

  // content
  DISPLAY_NAME: 'State of Connecticut',
  SCHEDULE_PHONE_NUMBER: '833-740-3258',
  HEADER_LOGO: soctHeaderLogo,
  WELCOME_MESSAGE: `If you are already in a plan please login through Quantum [HERE](https://carecompass.quantum-health.com/ "Log in at Quantum.com") to view quality information about providers and other benefits that may be available to you`,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: soctLoginLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default soctProviderLookupConfig;
