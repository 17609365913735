import React from 'react';
import PropTypes from 'propTypes/index';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  grid: {
    background: theme.palette.grey[100],
    borderRadius: 10,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  iconSuccess: {
    fontSize: '3rem',
    color: theme.palette.success.main,
  },
  iconError: {
    fontSize: '3rem',
    color: theme.palette.error.main,
  },
}));
export default function RedeemRewardConfirmation({ error, message }) {
  const classes = useStyles();

  return (
    <Grid
      classes={{ root: classes.grid }}
      justifyContent="center"
      alignItems="center"
      direction="column"
      container
    >
      {error ? (
        <ErrorOutline className={classes.iconError} />
      ) : (
        <CheckCircleOutline className={classes.iconSuccess} />
      )}
      <Typography variant="h2" component="strong" color="primary">
        {error ? 'Oops!' : 'Success!'}
      </Typography>
      <br />
      <Typography variant="subtitle1" component="strong">
        {error
          ? message
          : 'Look out for an email within 10 business days for receiving your reward.'}
      </Typography>

      {error && (
        <Link component={RouterLink} to="/support">
          Contact Support
        </Link>
      )}
    </Grid>
  );
}
RedeemRewardConfirmation.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
};
RedeemRewardConfirmation.defaultProps = {
  error: false,
  message:
    'Looks like there was an issue handling your request. Please refresh your page or try again later.',
};
