import React, { useEffect, useState } from 'react';
import { ChildrenType } from 'propTypes/index';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { FOOTER_HEIGHT, GO_BACK_BAR_HEIGHT, HEADER_HEIGHT } from './constants';

const useStyles = ({ nonStickyMarginTop, stickyTop, stickyHeight }) =>
  makeStyles((theme) => ({
    sidebar: {
      [theme.breakpoints.up('md')]: {
        overflowY: 'scroll',
        paddingBottom: '2rem',
        marginTop: nonStickyMarginTop,
      },
    },
    sidebarFixed: {
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        top: stickyTop,
        marginTop: 0,
        height: stickyHeight || 'auto',
        paddingBottom: FOOTER_HEIGHT + theme.spacing(2),
      },
    },
  }));

const CONTENT_MARGIN = 16;

function StickySidebar({ children }) {
  const nonStickyMarginTop = 48; // 3rem
  const stickyTop = HEADER_HEIGHT + GO_BACK_BAR_HEIGHT + CONTENT_MARGIN;

  const [isSticky, setIsSticky] = useState(false);
  const [stickySidebarHeight, setStickySidebarHeight] = useState(null);

  const classes = useStyles({
    stickyTop,
    nonStickyMarginTop,
    stickyHeight: stickySidebarHeight,
  })();

  useEffect(() => {
    const setStickySidebarStyles = () => {
      setIsSticky(nonStickyMarginTop < window.scrollY);
      setStickySidebarHeight(window.innerHeight - stickyTop);
    };
    window.addEventListener('scroll', setStickySidebarStyles);
    window.addEventListener('resize', setStickySidebarStyles);
    return () => {
      window.removeEventListener('scroll', setStickySidebarStyles);
      window.removeEventListener('resize', setStickySidebarStyles);
    };
  }, [stickyTop]);

  return (
    <div className={clsx(classes.sidebar, { [classes.sidebarFixed]: isSticky })}>{children}</div>
  );
}

export default StickySidebar;

StickySidebar.propTypes = {
  children: ChildrenType.isRequired,
};
