import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select, thunks } from 'store/toolkit';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Grow, Button, Typography } from '@material-ui/core/';

import EmboldIcon from 'assets/embold-icon.svg';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    '* img': { height: 24 },
    '& > *:not(:last-child)': { marginRight: theme.spacing(1) },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
  },
}));

export default function SpecialtyButtons({ search }) {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();
  const appName = useSelector(select.content.appName);

  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);

  const buttons = useMemo(() => {
    const result = [];
    if (!search) return result;

    // add initial search as long as there were results. If there were no results, there is no need to show a button that performs the same search in PG
    if (search.resultList?.length) result.push(search);

    // add parent specialty on subspecialty searches
    if (search.subspecialtyId > 0) {
      result.push({ ...search, subspecialtyId: undefined, subspecialtyName: undefined });
    }

    return result;
  }, [search]);

  const handleSpecialtyClick = useCallback(
    (specialtySearchObject) => {
      dispatch(actions.chat.specialtySearchInPg(specialtySearchObject));
      searchDispatch(thunks.results.executeSearch());
    },
    [dispatch, searchDispatch]
  );

  const goHome = useCallback(() => {
    dispatch(actions.chat.goToHomepage(search));
    history.push('/');
  }, [search, history, dispatch]);

  return (
    <Paper elevation={0} className={classes.container}>
      <Box className={classes.titleContainer}>
        {!isWhitelabeled && <img src={EmboldIcon} alt="" />}
        <Typography variant="h3" component="h2">
          Explore more in {appName}
        </Typography>
      </Box>

      <Box className={classes.buttonContainer}>
        {buttons.map((specialty, index) => (
          <Grow
            in
            timeout={index * 500}
            key={`search-btn-${specialty.subspecialtyId || specialty.specialtyId}`}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleSpecialtyClick(specialty)}
            >
              Search for {specialty.subspecialtyName || specialty.specialtyName}
            </Button>
          </Grow>
        ))}
        <Grow in timeout={buttons.length * 500}>
          <Button variant="outlined" color="secondary" onClick={goHome}>
            Visit Homepage
          </Button>
        </Grow>
      </Box>
    </Paper>
  );
}

SpecialtyButtons.propTypes = {
  search: PropTypes.shape({
    subspecialtyId: PropTypes.number,
    resultList: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

SpecialtyButtons.defaultProps = {
  search: undefined,
};
