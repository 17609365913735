/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { MICROSOFT } from 'utils/constants';
import LanguageIcon from '@material-ui/icons/Language';

// eslint-disable-next-line import/prefer-default-export
export const getPlaceNetworkContent = (client) => {
  const networkContent = {
    networkText: 'In Network',
    showTooltip: false,
    getIcon: (palette) => (
      <LanguageIcon color="inherit" fontSize="inherit" htmlColor={palette.success.dark} />
    ),
    showNetworkStatus: false,
  };

  // Microsoft
  if (client === MICROSOFT) {
    networkContent.showNetworkStatus = false;
  }

  return networkContent;
};
