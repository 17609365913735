import React from 'react';
import PropTypes from 'propTypes';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import PersonPin from '@material-ui/icons/PersonPin';

import ProfileSection from '../ProfileSection';
import DetailIconList from '../DetailIconList';

function SectionCredentialsExperience({ providerId }) {
  const { medicalSchool, yearsExperience, boardCertified, npi, totalCare } =
    useSelector(select.provider(providerId).data) || {};
  const isPcp = useSelector(select.provider(providerId).isPcp);
  const showTotalCare = useSelector(select.featureFlags.showTotalCare);

  const items = [];
  if (medicalSchool) {
    items.push({
      text: medicalSchool,
      icon: <SchoolOutlinedIcon />,
    });
  }
  if (yearsExperience) {
    items.push({
      text: `Years of experience: ${yearsExperience}`,
      icon: <AccessTimeOutlinedIcon />,
    });
  }
  if (boardCertified) {
    items.push({
      text: 'Board certification',
      icon: <BookOutlinedIcon />,
    });
  }
  if (npi) {
    items.push({
      text: `NPI: ${npi}`,
      icon: <BookOutlinedIcon />,
    });
  }
  if (showTotalCare && totalCare) {
    items.push({
      text: 'BCBS Total Care Provider',
      icon: <VerifiedUserOutlinedIcon />,
    });
  }
  if (isPcp) {
    items.push({
      text: 'Serves as PCP',
      icon: <PersonPin />,
    });
  }

  if (!items.length) return null;

  return (
    <ProfileSection TitleProps={{ title: 'Credentials & Experience' }}>
      <DetailIconList items={items} />
    </ProfileSection>
  );
}

export default SectionCredentialsExperience;

SectionCredentialsExperience.propTypes = {
  providerId: PropTypes.string.isRequired,
};
