import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';

import useUrlProfileSearch from 'hooks/queryString/useUrlProfileSearch';
import useUpdateTitle from 'hooks/useUpdateTitle';
import { PROVIDER_TYPE } from 'utils/constants';

import ProfileSkeleton from '../../LoadingSkeletons/ProfileSkeleton';
import ProfileError from '../ProfileError';
import ProviderProfile from './ProviderProfile';

export default function ProviderProfilePage({ id }) {
  const resultIds = useSelector(select.results.idList);
  const fullNameAndTitle = useSelector(select.provider(resultIds[0]).fullNameAndTitle);

  useUpdateTitle(fullNameAndTitle || 'Provider Profile', { hidePrefix: true });

  const { error, fetching } = useUrlProfileSearch({ id, searchType: PROVIDER_TYPE });

  if (error || (!fetching && resultIds?.length === 0))
    return (
      <ProfileError
        heading="No Results"
        text="We were unable to find a provider that matched your search
        criteria. Here are a couple of solutions that might be able to help."
        buttons={['newsearch', 'refresh']}
      />
    );

  return fetching ? <ProfileSkeleton /> : <ProviderProfile providerId={resultIds[0]} />;
}

ProviderProfilePage.propTypes = {
  id: PropTypes.string.isRequired,
};
