import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select, actions, thunks } from 'store/toolkit';
import { PLACE_TYPE } from 'utils/constants';

export const refetchResults = ({ entityData, dispatch, entityType }) => {
  if (entityType === PLACE_TYPE) {
    /* refetch places */
    const ids = entityData.map(({ id }) => id);
    // throw error if no places or any place is missing an ID
    if (!ids.every(Boolean) || ids.length === 0) throw new Error('Error performing search');
    dispatch(thunks.results.searchPlacesByIds({ ids }));
  } else {
    /* refetch providers */
    const npis = entityData.map(({ npi }) => npi);
    // throw error if no providers or any provider is missing an NPI
    if (!npis.every(Boolean) || npis.length === 0) throw new Error('Error performing search');
    if (npis.length === 1) {
      dispatch(thunks.results.searchProviderById({ id: npis[0] }));
    } else {
      dispatch(thunks.results.searchProvidersByIds({ npis }));
    }
  }
};

export const updateNetworkAndNotices = ({
  initialSlug,
  dispatch,
  setNotice,
  shareData: { networkSlug: shareNetworkSlug, entityData, entityType },
  handleRefetch = refetchResults, // always use "refetchResults" from module, except to test
}) => {
  if (!initialSlug) {
    // temporarily update network value to use network from share data
    dispatch(actions.config.setNetworkSlug(shareNetworkSlug));
    setNotice('info');
  } else if (shareNetworkSlug !== initialSlug) {
    // refetch profile(s) using correct network information, show notice to user
    handleRefetch({ entityData, dispatch, entityType });
    setNotice('mismatch');
  } else if (shareNetworkSlug === initialSlug) {
    // leave network value as is - share data will already display correctly
  }
};

/**
 * useHandleShareNetwork
 * This hook references network data from local storage and compares it to the network that the share was created from
 * if( no initial slug ){
 *  use slug from share to render profile
 * } else if ( slug matches ) {
 *  render profile as usual
 * } else if ( slug doesn't match ) {
 *  1. refetch profile(s) with slug from local storage
 *  2. show warning text
 * }
 * @returns {func} payload.handleShareNetwork - accepts share data as a parameter
 * @returns {func} payload.notice - notice type to display above share UI
 */
const useHandleShareNetwork = () => {
  const dispatch = useDispatch();
  const [notice, setNotice] = useState('');

  const currentSlug = useSelector(select.networks.currentSlug);
  // memoize slug from initial mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSlug = useMemo(() => currentSlug, []);

  const handleShareNetwork = useCallback(
    (shareData) => updateNetworkAndNotices({ initialSlug, dispatch, setNotice, shareData }),
    [dispatch, initialSlug]
  );

  useEffect(
    () => () => {
      if (!initialSlug) dispatch(actions.config.setNetworkSlug(null));
    },
    // clear slug on unmount for unauthenticated users
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    handleShareNetwork,
    notice,
  };
};

export default useHandleShareNetwork;
