import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

/**
 * @typedef {object} TitleOptions
 * @property {boolean} hidePrefix When true, only the pageName is used. Example: 'Home" rather than "Embold Health Provider Guide - Home"
 */

const defaultTitleOptions = {
  hidePrefix: false,
};

/**
 *
 * @param {string} pageTitle
 * @param {TitleOptions} options
 */
export default function useUpdateTitle(pageTitle = '', options = defaultTitleOptions) {
  const brandAndAppName = useSelector(select.content.brandAndAppName);
  const { hidePrefix } = options;

  const title = useMemo(() => {
    if (hidePrefix) return pageTitle;

    if (!pageTitle) return brandAndAppName;
    return `${brandAndAppName} - ${pageTitle}`;
  }, [pageTitle, brandAndAppName, hidePrefix]);

  useEffect(() => {
    document.title = title;
  }, [title]);
}
