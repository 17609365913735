import React from 'react';
import { useSelector } from 'react-redux';
import {
  AzureMapFeature,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
} from 'react-azure-maps';

import { select } from 'store/toolkit';

/** This component renders a bubble layer to visually represent the search radius */
export default function RadiusMapLayer() {
  const { latitude, longitude } = useSelector(select.location.latLong);
  const isBoundingBoxSearch = useSelector(select.search.isBoundingBoxSearch);
  const radius = useSelector(select.filters.radiusInMeters);

  if (isBoundingBoxSearch) return null;

  return (
    <AzureMapDataSourceProvider id="radius-provider">
      <AzureMapLayerProvider
        id="radius-layer"
        options={{
          color: '#4E3FC8',
          opacity: 0.1,
          radius: {
            stops: [
              [0, 0],
              [20, radius / 0.075 / Math.cos((latitude * Math.PI) / 180)],
            ],
            base: 2,
          },
          strokeWidth: 0,
        }}
        type="BubbleLayer"
      />
      <AzureMapFeature
        key="radius"
        variant="shape"
        id="radius"
        type="Point"
        coordinate={[longitude, latitude]}
        setCoords={[longitude, latitude]}
      />
    </AzureMapDataSourceProvider>
  );
}
