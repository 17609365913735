import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useTableValue from 'components/ModernExperience/CompareTable/useTableValue';
import ThemedTableCell from './ThemedTableCell';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // all direct children cells
      padding: theme.spacing(0.5),
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
  },
  labelCell: {
    maxWidth: 180,
    verticalAlign: 'top',
  },
  dataCell: {
    textAlign: 'left',
    maxWidth: 180,
    verticalAlign: 'top',
  },
}));

export default function CompareRow({ label, render, ...rest }) {
  const classes = useStyles();
  const { resultIds } = useTableValue();

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.labelCell}>{label}</TableCell>
      {resultIds.map((id) => (
        <ThemedTableCell key={`${label}-${id}`} className={classes.dataCell}>
          {render(id, { label, ...rest })}
        </ThemedTableCell>
      ))}
    </TableRow>
  );
}

CompareRow.propTypes = {
  label: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};
