// this array of delay times in ms is to control how much delay should occur before re-attempting a failed fetch
export const FETCH_DELAY_TIMES = [0, 200, 500, 750, 1000, 2000, 2000, 3000, 3000, 10000, 10000]; // this is the amount of delay to apply

export const MAX_RETRIES = FETCH_DELAY_TIMES.length;

/**
 * @param {number} attemptIndex The number of attempts made, used for dynamically setting the delay time.
 * @param {number=} overrideDelay The fixed delay time to optionally be used.
 * @returns {promise}
 */
export async function delayByAttempt(attemptIndex, overrideDelay) {
  const delayInMs = overrideDelay || FETCH_DELAY_TIMES[attemptIndex] || 0;

  return new Promise((resolve) => {
    setTimeout(resolve, delayInMs);
  });
}
