import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function AgreeToTermsLabel() {
  return (
    <Typography>
      I agree to the{' '}
      <Link component={RouterLink} to="/privacy">
        Privacy Statement
      </Link>{' '}
      and{' '}
      <Link component={RouterLink} to="/terms">
        Terms of Use
      </Link>
      .
    </Typography>
  );
}
