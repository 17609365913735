import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { select, thunks, actions } from 'store/toolkit';
import { createProviderCostKey } from 'store/slices/cost/costUtils';

/**
 * This hook contains all of the data and state for a providers cost information by location.
 *
 * @param {number|string} npi
 * @param {string} locationId
 * @returns {object} { data, isLoading, error, isError, fetchProviderCost, invalidateData }
 *
 * @example
 * const { data, isLoading, error, isError, fetchProviderCost, invalidateData } = useProviderCost('123', 'abc');
 * isLoading // the loading state of the fetch
 * error; // the error message if there is any
 * isError; // a boolean value
 *
 * data; // the response data or undefined if the fetch has not completed
 *
 * fetchProviderCost(); // will trigger a dispatch to fetch the relevant provider cost data
 */
export default function useProviderCost(npi, location) {
  const { locationId, state, productIds, tiering } = location;
  const dispatch = useDispatch();
  const selector = useMemo(
    () => select.cost.byKey(createProviderCostKey({ npi, locationId })) || {},
    [npi, locationId]
  );

  const networkSlug = useSelector(select.networks.currentSlug);
  const locationTier = tiering?.[networkSlug];
  // data
  const { data = null, isLoading = false, error = null } = useSelector(selector) || {};

  const isError = Boolean(error);

  // functions
  const fetchProviderCost = useCallback(async () => {
    dispatch(
      thunks.cost.fetchProviderCost({
        npi,
        locationId,
        locationState: state,
        locationTier,
        productIds,
        networkSlug,
      })
    );
  }, [dispatch, npi, productIds, locationId, state, locationTier, networkSlug]);

  const invalidateData = useCallback(() => {
    dispatch(actions.cost.invalidateKey({ npi, locationId }));
  }, [npi, locationId, dispatch]);

  return { data, isLoading, isError, error, fetchProviderCost, invalidateData };
}
