/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { validateLatLong, logDevMessage } from 'utils/utils';
import { CONFIG_SLICE_NAME, LOCATION_SLICE_NAME } from 'store/slices/slicesNames';

// Basic selectors
export const latLongResolved = (state) => state[LOCATION_SLICE_NAME].latLongResolved;
export const locationInput = (state) => state[LOCATION_SLICE_NAME].locationInput;
export const locationComponents = (state) => state[LOCATION_SLICE_NAME].locationComponents;
export const locationResolved = (state) => state[LOCATION_SLICE_NAME].locationResolved;
export const geolocationResolved = (state) => state[LOCATION_SLICE_NAME].geolocationResolved;
export const userDeniedLocation = (state) => state[LOCATION_SLICE_NAME].userDeniedLocation;
export const fetchingGeo = (state) => state[LOCATION_SLICE_NAME].fetchingGeo;
export const error = (state) => state[LOCATION_SLICE_NAME].error;
export const city = (state) => state[LOCATION_SLICE_NAME].locationComponents?.city;
export const state = (state) => state[LOCATION_SLICE_NAME].locationComponents?.state;
export const zip = (state) => state[LOCATION_SLICE_NAME].locationComponents?.zip;
export const locationHistory = (state) => state[LOCATION_SLICE_NAME].locationHistory;

// Advanced selectors
export const latLong = createSelector(
  [
    (state) => state[LOCATION_SLICE_NAME].latLong,
    (state) => state[CONFIG_SLICE_NAME]?.values?.DEFAULT_LOCATION,
  ],
  (latLong, defaultLatLong) => {
    // return the default lat, long for a given network slug should our latLong in state get cleared out for whatever reason
    // otherwise our map component will be angry
    if (!latLong.latitude || !latLong.longitude) {
      logDevMessage('latLong object has null values, returning default latLong ');
      return { latitude: defaultLatLong.lat, longitude: defaultLatLong.long };
    }

    return latLong;
  }
);

export const validatedLatLong = createSelector(
  [latLong, latLongResolved],
  (latLong, isResolved) => {
    if (!isResolved) return null;

    const isValid = validateLatLong(latLong.latitude, latLong.longitude);
    if (!isValid) return null;

    return latLong;
  }
);

export const validatedLocationComponents = createSelector(
  [locationComponents, geolocationResolved],
  (locationComponents, isResolved) => {
    if (!isResolved) return null;

    return locationComponents;
  }
);
