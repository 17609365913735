import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useUpdateTitle from 'hooks/useUpdateTitle';
import { select } from 'store/toolkit';
import events from './events';

const eventsList = Object.keys(events);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  table: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    '& > *': { fontWeight: 'bold', fontSize: '1.25rem' },
  },
}));

export default function AnalyticsPage() {
  const classes = useStyles();
  const appName = useSelector(select.content.appName);

  useUpdateTitle('Analytics');

  return (
    <Box className={classes.root}>
      <Typography variant="h1" align="center">
        {appName} Analytics Events
      </Typography>

      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>Code</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Label</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventsList.map((event) => (
            <TableRow key={events[event].code}>
              <TableCell>{events[event].code}</TableCell>
              <TableCell>
                <pre>{event}</pre>
              </TableCell>
              <TableCell>{events[event].category}</TableCell>
              <TableCell>{events[event].actionDescription}</TableCell>
              <TableCell>{events[event].labelDescription}</TableCell>
              <TableCell>{events[event].description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
