import React from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function HealthPlanDisclaimer(props) {
  return (
    <Link component={RouterLink} to="/terms#reliance-on-information-posted" {...props}>
      Always confirm that the provider accepts your health plan.
    </Link>
  );
}
