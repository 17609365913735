import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableBody } from '@material-ui/core';

import { select } from 'store/toolkit';
import NetworkCoverageCell from './CellComponents/NetworkCoverageCell';
import PlaceActionButtonsRow from './PlaceActionButtonsRow';
import CompareTableHeader from './CompareTableHeader';
import HospitalSafetyGradeCell from './CellComponents/HospitalSafetyGradeCell';
import PlaceDistanceCell from './CellComponents/PlaceDistanceCell';
import PlaceAddressCell from './CellComponents/PlaceAddressCell';
import PlacePhoneNumberCell from './CellComponents/PlacePhoneNumberCell';
import CompareRow from './CompareRow';
import RemoveButtonRow from './RemoveButtonRow';
import { TableContext } from './useTableValue';

export default function PlaceCompareTable({
  resultIds = [],
  showRemoveButtons,
  showActionButtons,
}) {
  const locationText = useSelector(select.results.locationInput);

  const tableContext = useMemo(
    () => ({
      // include any values that should be available to table children
      resultIds,
      columns: resultIds.length + 1,
    }),
    [resultIds]
  );

  return (
    <TableContext.Provider value={tableContext}>
      <TableContainer>
        <Table>
          <CompareTableHeader />

          <TableBody>
            {showRemoveButtons && <RemoveButtonRow />}
            {showActionButtons && <PlaceActionButtonsRow />}

            <CompareRow
              label="Network Coverage"
              render={(id) => <NetworkCoverageCell resultId={id} />}
            />
            <CompareRow
              label="Phone Number"
              render={(id) => <PlacePhoneNumberCell placeId={id} />}
            />
            {Boolean(locationText) && (
              // if there is no location input in results, we should not display a distance as it would not be reliably accurate.
              // legacy share links did NOT include location of the initial search. Because of this we would NOT be able to accurately display a meaningful distance
              <CompareRow
                label={`Distance (from ${locationText})`}
                render={(id) => <PlaceDistanceCell placeId={id} />}
              />
            )}
            <CompareRow
              label="Hospital Safety Grade"
              render={(id) => <HospitalSafetyGradeCell placeId={id} />}
            />
            <CompareRow label="Address" render={(id) => <PlaceAddressCell placeId={id} />} />
          </TableBody>
        </Table>
      </TableContainer>
    </TableContext.Provider>
  );
}

PlaceCompareTable.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showRemoveButtons: PropTypes.bool,
  showActionButtons: PropTypes.bool,
};

PlaceCompareTable.defaultProps = {
  showRemoveButtons: false,
  showActionButtons: false,
};
