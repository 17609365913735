import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Link, Grid, Typography, Box, Button, useMediaQuery, makeStyles } from '@material-ui/core/';
import { AzureMapsProvider } from 'react-azure-maps';
import { useProviderScore } from 'hooks/Provider/';
import { _analyticsActions } from 'analytics/index';
import useFocusAnchors from 'utils/FocusRefContext';
import useReturnFocus from 'hooks/useReturnFocus';
import { getPlaces } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import {
  PERCENTILE_THRESHOLD,
  SUBSPECIALTY_SECTION,
  COST_EFFICIENCY_SECTION,
  STRENGTHS_SECTION,
} from 'utils/constants';

import ShareIcon from '@material-ui/icons/Share';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import Male from 'icons/Male';
import Female from 'icons/Female';
import OutcareColored from 'icons/colored/OutcareColored';
import ScoreIcon from 'components/ScoreIcon';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import IconLabel from 'components/Results/ResultCard/IconLabel';
import CoverageRibbon from 'components/Results/ResultCard/Ribbons/CoverageRibbon';
import LocationsMinimap from 'components/Modals/ChatModal/LocationsMinimap';
import Tooltip from 'components/Tooltip';
import HighPerformingRibbon from 'components/Results/ResultCard/Ribbons/HighPerformingRibbon';
import ChatScoreTierPill from './ChatScoreTierPill';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: `${theme.spacing(1)}px`,
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  cardTitle: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(1),
      flex: 1,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        display: 'block',
        marginTop: 0,
      },
    },
  },
  row: {
    padding: 2,
  },
  section: {
    padding: '0px 8px',
  },
  specialties: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  shareButton: {
    display: 'flex',
    marginTop: 5,
    marginRight: 10,
    marginBottom: 5,
    minWidth: 110,
    '& svg': {
      marginRight: 4,
      height: 18,
    },
  },
  locationSection: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    padding: 12,
    borderRadius: 10,
  },
  icon: {
    height: 20,
    width: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  specializationContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    padding: 8,
    borderRadius: 10,
    marginBottom: 8,
    display: 'flex',
  },
  coverageDecreasing: {
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
}));

function ProviderCardLarge({ provider, coords, queryParams }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    entityId,
    entityName,
    title,
    gender,
    subspecialties,
    diagnosingPercentile,
    treatmentPercentile,
    outcomesPercentile,
    scoreCareJourneyCost,
    specialty,
    outcareCompetent,
    languagesArray,
    benefitDecrease,
    schedulingUrl,
  } = provider;

  const score = useProviderScore(provider);
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();

  const locationComponents = useSelector(select.location.locationComponents);
  const isTodayInBenefitChangeDateRange = useSelector(
    select.content.isTodayInBenefitChangeDateRange
  );
  const benefitsChangeText = useSelector(select.content.benefitsChange);
  const { city, state } = locationComponents;

  useEffect(() => {
    dispatch(_analyticsActions.chatProfileView({ coordinates: coords, providerData: provider }));
  }, [coords, dispatch, entityId, provider]);

  const networkSlug = useSelector(select.networks.currentSlug);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);
  const showShare = useSelector(select.featureFlags.showShare);
  const showOutcare = useSelector(select.featureFlags.showOutcare);
  const showHighPerformingRibbon = useSelector(select.featureFlags.showHighPerformingRibbon);

  const locationCity = queryParams.city || city;
  const locationState = queryParams.stateAbbreviation || state;

  const uniquePlaces = getPlaces(provider);
  const placesAcceptingCount = uniquePlaces?.reduce((acceptingCount, place) => {
    // eslint-disable-next-line no-param-reassign
    if (place.acceptingNewPatients) acceptingCount += 1;
    return acceptingCount;
  }, 0);
  const nwa = networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas';

  const showDiagnosing = diagnosingPercentile && diagnosingPercentile >= PERCENTILE_THRESHOLD;
  const showTreatment = treatmentPercentile && treatmentPercentile >= PERCENTILE_THRESHOLD;
  const showOutcomes = outcomesPercentile && outcomesPercentile >= PERCENTILE_THRESHOLD;

  const getStrengths = () => {
    let strengths = '';
    if (showDiagnosing) strengths = 'Diagnosing';
    if (showTreatment) strengths = strengths.length ? `${strengths}, Treatment` : 'Treatment';
    if (showOutcomes) strengths = strengths.length ? `${strengths}, Outcomes` : 'Outcomes';

    return strengths;
  };

  const handleShareClick = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [provider] }));
  };

  const handleScheduleClick = () => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: provider }));
  };

  const costEfficiencyMap = {
    1: 'Less Efficient',
    2: 'Average',
    3: 'More Efficient',
  };

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      <Grid className={classes.container}>
        {!smDown && (
          <Grid container className={classes.row}>
            <ChatScoreTierPill provider={provider} />
            <Grid style={{ marginLeft: 'auto' }}>
              <Link
                href={`/profile/provider/${entityId}?network_slug=${networkSlug}&location=${
                  coords.latitude
                },${coords.longitude}&location_input=${locationCity}, ${locationState}&city=${
                  locationCity || ''
                }&state=${locationState || ''}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                More Details <ChevronRight />
              </Link>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.row}>
          <Grid item xs={smDown ? 12 : 6} className={classes.section}>
            {!smDown && (
              <Grid className={classes.titleRow}>
                {score && (
                  <div style={{ marginRight: 8 }}>
                    <Typography variant="srOnly">{score.name}</Typography>
                    <ScoreIcon score={score.value} coloredText emboldRecommendedSize={30} />
                  </div>
                )}

                <Box style={{ marginLeft: 0 }}>
                  <Typography
                    variant="h3"
                    classes={{
                      root: classes.cardTitle,
                    }}
                  >
                    {entityName}
                    {Boolean(title) && ` ${title}`}
                  </Typography>
                  {specialty && (
                    <Typography style={{ fontSize: '.9rem', marginTop: '-8px' }}>
                      {specialty}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            {showHighPerformingRibbon && (
              <div style={{ marginLeft: 7 }}>
                <HighPerformingRibbon result={provider} />
              </div>
            )}
            <CoverageRibbon result={provider} />

            {isTodayInBenefitChangeDateRange && benefitDecrease && !nwa && (
              <Tooltip
                title={benefitsChangeText.cardAttribute.text}
                message={benefitsChangeText.cardAttribute.tooltip}
              >
                <IconLabel
                  img={<ReportProblemOutlinedIcon color="error" />}
                  className={classes.coverageDecreasing}
                >
                  {benefitsChangeText.cardAttribute.text}
                </IconLabel>
              </Tooltip>
            )}
            {(gender === 'Female' || gender === 'Male') && (
              <IconLabel
                img={gender === 'Male' ? <Male color="primary" /> : <Female color="primary" />}
              >
                {gender}
              </IconLabel>
            )}
            {showOutcare && outcareCompetent && (
              <IconLabel img={<OutcareColored />} textClass="accepting">
                LGBTQ+ Competent
              </IconLabel>
            )}
            {languagesArray?.length > 0 && (
              <IconLabel
                style={{
                  flexWrap: 'nowrap',
                }}
                img={<RecordVoiceOverOutlinedIcon />}
                id="language"
                TypographyProps={{
                  style: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
                }}
              >
                {languagesArray.join(', ')}
              </IconLabel>
            )}
            {placesAcceptingCount > 0 ? (
              <IconLabel img={<CheckOrXIcon checked />} textClass="accepting">
                Accepting patients
              </IconLabel>
            ) : (
              <IconLabel img={<CheckOrXIcon checked={false} />} textClass="not-accepting">
                Not accepting patients
              </IconLabel>
            )}
            {uniquePlaces?.length > 0 && (
              <IconLabel img={<LocationOnOutlinedIcon color="primary" />} textClass="accepting">
                {uniquePlaces?.length} nearby locations
              </IconLabel>
            )}
            {!smDown && (
              <Grid container>
                {showShare && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.shareButton}
                    onClick={handleShareClick}
                    size="small"
                    ref={shareButtonRef}
                  >
                    <ShareIcon />
                    Share
                  </Button>
                )}
                {showScheduleButton &&
                  (schedulingUrl ? (
                    <Button
                      className={classes.shareButton}
                      color="primary"
                      variant="outlined"
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                      href={schedulingUrl}
                    >
                      <CalendarMonthIcon />
                      Schedule
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.shareButton}
                      onClick={handleScheduleClick}
                      size="small"
                      ref={callButtonRef}
                    >
                      Need Help?
                    </Button>
                  ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={smDown ? 12 : 6} className={classes.specialties}>
            {subspecialties && subspecialties.length > 0 && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <SUBSPECIALTY_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Tooltip
                    title={SUBSPECIALTY_SECTION.title}
                    message={SUBSPECIALTY_SECTION.description}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      {SUBSPECIALTY_SECTION.title}:{' '}
                    </Typography>
                  </Tooltip>
                  <Typography>
                    {subspecialties.length === 1
                      ? subspecialties[0].subspecialtyName
                      : subspecialties
                          .map((subspecialty) => subspecialty.subspecialtyName)
                          .join(', ')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(showDiagnosing || showOutcomes || showTreatment) && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <STRENGTHS_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={STRENGTHS_SECTION.title}
                      message={STRENGTHS_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {STRENGTHS_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{getStrengths()}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {scoreCareJourneyCost && costEfficiencyMap[scoreCareJourneyCost] && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <COST_EFFICIENCY_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={COST_EFFICIENCY_SECTION.title}
                      message={COST_EFFICIENCY_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {COST_EFFICIENCY_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{costEfficiencyMap[scoreCareJourneyCost]}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item xs={12} className={classes.locationSection}>
            <AzureMapsProvider>
              <LocationsMinimap provider={provider} coords={coords} />
            </AzureMapsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ProviderCardLarge.propTypes = {
  provider: PropTypes.shape({
    entityId: PropTypes.string,
    entityName: PropTypes.string,
    title: PropTypes.string,
    gender: PropTypes.string,
    closestVisibleLocation: PropTypes.shape({
      city: PropTypes.string,
      address_1: PropTypes.string,
      address_2: PropTypes.string,
      is_wheelchair_accessible: PropTypes.bool,
      zipcode: PropTypes.string,
      distanceInMiles: PropTypes.number,
      product_ids: PropTypes.arrayOf(PropTypes.string),
      phone: PropTypes.string,
      name: PropTypes.string,
      specialization: PropTypes.string,
      location: PropTypes.shape({
        lon: PropTypes.number,
        lat: PropTypes.number,
      }),
      state: PropTypes.string,
      fax: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    subspecialties: PropTypes.arrayOf(
      PropTypes.shape({
        subspecialtyId: PropTypes.number.isRequired,
        subspecialtyName: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ),
    diagnosingPercentile: PropTypes.number,
    treatmentPercentile: PropTypes.number,
    outcomesPercentile: PropTypes.number,
    scoreCareJourneyCost: PropTypes.number,
    specialty: PropTypes.string,
    places: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    outcareCompetent: PropTypes.bool,
    languagesArray: PropTypes.arrayOf(PropTypes.string),
    benefitDecrease: PropTypes.bool,
    schedulingUrl: PropTypes.string,
  }).isRequired,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  queryParams: PropTypes.shape({
    specialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
      })
    ),
    subspecialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
        subspecialtyId: PropTypes.number,
        subspecialtyName: PropTypes.string,
      })
    ),
    radius: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    lgbtqCompetent: PropTypes.bool,
    lookingForDiagnosis: PropTypes.bool,
    lookingForTreatment: PropTypes.bool,
    gender: PropTypes.string,
    languagesSpoken: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    stateAbbreviation: PropTypes.string,
  }).isRequired,
};

export default ProviderCardLarge;
