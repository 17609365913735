import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

export default function StrengthCell({ providerId, strengthKey }) {
  const classes = useStyles();
  const strengthObj = useSelector(select.provider(providerId)[strengthKey]);

  if (!strengthObj) return <NoDataIcon />;

  return (
    <Typography component="span" color="primary" classes={classes}>
      {strengthObj.adjective}
    </Typography>
  );
}

StrengthCell.propTypes = {
  providerId: PropTypes.string.isRequired,
  strengthKey: PropTypes.oneOf(['diagnosingStrength', 'treatmentStrength', 'outcomesStrength'])
    .isRequired,
};
