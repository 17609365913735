import React from 'react';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import FeaturedBenefit from 'components/ModernExperience/Benefit/FeaturedBenefit';

const useStyles = makeStyles((theme) => ({
  featuredBenefitStyles: {
    marginBottom: 8,
    marginTop: 5,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 8,
  },
}));

function PromotionBySpecialty({ specialtyId, subspecialtyId }) {
  const classes = useStyles();
  const selectedPromotion = useSelector(
    select.promotions.fromSpecialtyIds(specialtyId, subspecialtyId)
  );

  const isSmDown = useSelector(select.ui.isSmDown);

  if (!selectedPromotion) {
    return null;
  }

  return (
    <FeaturedBenefit
      benefit={selectedPromotion}
      isMobile={isSmDown}
      additionalClass={classes.featuredBenefitStyles}
    />
  );
}

PromotionBySpecialty.propTypes = {
  specialtyId: PropTypes.number.isRequired,
  subspecialtyId: PropTypes.number,
};

PromotionBySpecialty.defaultProps = {
  subspecialtyId: null,
};

export default PromotionBySpecialty;
