import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useLocation } from 'react-router-dom';
import { select } from 'store/toolkit';
import { LOGIN_ROUTE } from '../navigation/navigationConstants';

import FooterNav from './FooterNav';
import FooterLogo from './FooterLogo';
import DisclaimerText from './DisclaimerText';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    zIndex: 20,
    background: theme.palette.common.white,
    flex: '0 0 auto',
    padding: '1.25rem',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px 4px',
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const location = useLocation();
  const isMobileLayout = useSelector(select.ui.isMdDown);

  const isLoginPage = location.pathname === LOGIN_ROUTE;
  const showFooter = isLoginPage || !isMobileLayout; // show footer nav: always on login page, otherwise only on desktop width

  if (!showFooter) return null;

  return (
    <footer className={classes.footer} role="contentinfo" id="footer">
      <FooterNav />
      <FooterLogo />
      <DisclaimerText />
    </footer>
  );
}

export default Footer;
