import React from 'react';
import PropTypes from 'propTypes';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { openInNewTab } from 'utils/utils';
import DetailsButton from '../DetailsButton';

function ProviderDetailsButton({ providerId, buttonClasses }) {
  const accessibleLabel = useSelector(select.provider(providerId).fullNameAndTitle);
  const profileDetailsLink = useSelector(select.results.profileDetailsLink(providerId));

  const handleProviderDetailsClick = () => {
    openInNewTab(profileDetailsLink);
  };
  return (
    <DetailsButton
      onClick={handleProviderDetailsClick}
      accessibleLabel={accessibleLabel}
      buttonClasses={buttonClasses}
    />
  );
}

export default React.memo(ProviderDetailsButton);

ProviderDetailsButton.propTypes = {
  providerId: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
};

ProviderDetailsButton.defaultProps = {
  buttonClasses: '',
};
