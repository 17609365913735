import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import ScoreIcon from 'components/ModernExperience/ScoreIcon';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles((theme) => ({
  scoreIcon: { margin: `${theme.spacing(0.5)}px 0px` },
}));
export default function ScoreCell({ providerId }) {
  const classes = useStyles();
  const score = useSelector(select.provider(providerId).score);

  if (!score) return <NoDataIcon />;

  return <ScoreIcon score={score.value} tier={score.tier} additionalClass={classes.scoreIcon} />;
}

ScoreCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
