import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import HospitalGradeIcon from 'icons/dynamic/HospitalGradeIcon';
import ProfileBadge from '../../ProfileBadge';

export default function HospitalSafetyGradeBadge({ placeId }) {
  const placeData = useSelector(select.place(placeId).data) || {};
  const { hospitalGrade, hospitalGradeLastUpdated } = placeData;

  if (!hospitalGrade) return null;

  return (
    <ProfileBadge
      icon={<HospitalGradeIcon grade={hospitalGrade} lastUpdated={hospitalGradeLastUpdated} />}
      AvatarProps={{ variant: 'square' }}
      label="Hospital Safety Grade"
      TooltipProps={{
        title: 'Hospital Safety Grade',
        message:
          'The Hospital Safety Grade assigns an ‘A’, ‘B’, ‘C’, ‘D’ or ‘F’ grade to nearly 3,000 hospitals across the country based on their performance in preventing medical errors, injuries, accidents, infections and other harms to patients in their care.',
      }}
    />
  );
}

HospitalSafetyGradeBadge.propTypes = {
  placeId: PropTypes.string.isRequired,
};
