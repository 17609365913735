/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Skeleton } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useDebounce from 'hooks/useDebounce';
import {
  CARE_CATEGORIES,
  CARE_CATEGORY_OPTIONS,
  AUTOCOMPLETE_RADIUS_5,
  AUTOCOMPLETE_RADIUS_4,
  NEW_SPECIALTY_TYPE,
} from 'utils/constants';
import SearchIcon from '@material-ui/icons/Search';
import { actions, select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  inputRootGrouped: {
    fontSize: '1rem',
    fontFamily: 'Lato, Arial, sans-serif',
    paddingRight: 58,
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0,0,0,0.23)',
      borderLeft: 'none',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    '&.Mui-focused': {
      marginTop: '.5px',
      paddingBottom: '5.5px !important',
    },
  },
  input: {
    minWidth: '0px !important',
    lineHeight: 'normal',
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.grey[600],
      fontSize: '.875rem',
      paddingLeft: 8,
    },
  },
  option: {
    '&[aria-disabled="true"]': { opacity: 1 },
    '&[data-focus="true"]': {
      outline: `2px solid ${theme.palette.info.main}`,
      background: 'none',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  listbox: {
    padding: '.5rem',
  },
  clearButtonContainer: {
    position: 'absolute',
    '& button': {
      padding: 6,
    },
  },
  startIcon: {
    color: theme.palette.grey[700],
  },
}));

let timer = null;
let abortFetch = false;

export const getPlaceholderText = (careCategory) =>
  CARE_CATEGORY_OPTIONS[careCategory]?.searchText || 'Select a search type';

const highlightSearchTerms = (searchString, originalString) => {
  if (!get(originalString, 'length')) return '';

  if (!searchString.length) {
    return originalString;
  }

  const index = originalString.toLowerCase().indexOf(searchString.toLowerCase());

  if (index === -1) {
    return originalString;
  }

  const highlightedString = (
    <span>
      {index > 0 && originalString.slice(0, index)}
      <b>{originalString.slice(index, index + searchString.length)}</b>
      {originalString.slice(index + searchString.length)}
    </span>
  );

  return highlightedString;
};

function getLabelText(searchInput, suggestion, careCategory, useNewSpecialtyAutocomplete) {
  const { entityName, specialty, name, searchTerm, specialtyName, subspecialtyName, serviceName } =
    suggestion;
  let labelText = '';
  if (!searchInput || isEmpty(suggestion) || name) {
    labelText = name;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_NAME && entityName) {
    labelText = `${entityName}${specialty ? ` (${specialty})` : ''}`;
  } else if (careCategory === CARE_CATEGORIES.FACILITY_NAME) {
    labelText = name;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY) {
    labelText = useNewSpecialtyAutocomplete
      ? subspecialtyName || specialtyName
      : searchTerm
      ? `${searchTerm} (${subspecialtyName || specialtyName})`
      : subspecialtyName
      ? `${subspecialtyName}`
      : specialtyName;
  } else if (careCategory === CARE_CATEGORIES.FACILITY_TYPE) {
    labelText = useNewSpecialtyAutocomplete
      ? subspecialtyName || specialtyName
      : searchTerm
      ? `${searchTerm} (${subspecialtyName || specialtyName})`
      : subspecialtyName
      ? `${subspecialtyName}`
      : specialtyName;
  } else if (careCategory === CARE_CATEGORIES.SERVICE) {
    labelText = serviceName;
  }
  return labelText;
}

function renderOption(suggestion, careCategory, searchInput, useNewSpecialtyAutocomplete) {
  const labelText = getLabelText(
    searchInput,
    suggestion,
    careCategory,
    useNewSpecialtyAutocomplete
  );

  return labelText ? (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <SearchIcon style={{ marginRight: '10px', height: '16px' }} />{' '}
      {highlightSearchTerms(searchInput, labelText)}
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Skeleton
        variant="circle"
        height={16}
        width={16}
        style={{ marginRight: '10px' }}
        animation="wave"
      />{' '}
      <Skeleton variant="text" height={16} width="90%" animation="wave" />
    </div>
  );
}

function NoResults() {
  return <span aria-live="polite">No Results</span>;
}

function ClearInputButton({ handleClick, offsetRight }) {
  const classes = useStyles();
  return (
    <div className={classes.clearButtonContainer} style={{ right: offsetRight ? 30 : 5 }}>
      <IconButton onClick={handleClick}>
        <Typography variant="srOnly">Clear input</Typography>
        <CloseIcon alt="" />
      </IconButton>
    </div>
  );
}

ClearInputButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  offsetRight: PropTypes.bool.isRequired,
};

function ProviderAutocomplete({ grouped }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const latLong = useSelector(select.location.latLong);
  const { latitude, longitude } = latLong;

  // axios instance
  const axios = useSelector(select.axios.axiosInstance);

  // search state
  const searchInput = useSelector(select.search.text);
  const careCategory = useSelector(select.search.type);
  const specialtyId = useSelector(select.search.specialtyId);
  const subspecialtyId = useSelector(select.search.subspecialtyId);
  const entityId = useSelector(select.search.entityId);
  const searchTypeOptions = useSelector(select.search.currentSearchTypeOptions);
  const useNewSpecialtyAutocomplete = useSelector(select.featureFlags.useNewSpecialtyAutocomplete);

  const networkSlug = useSelector(select.networks.currentSlug);

  const searchInputRef = useRef();
  const [fetchingSuggestions, setFetchingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const showCommonSearches = !searchInput;
  const requiresSelection = Boolean(searchTypeOptions?.requiresSelection);

  const showClearInputButton = useMemo(() => searchInput.length > 0, [searchInput]);

  useEffect(() => {
    if (!searchInput) {
      setSuggestions(CARE_CATEGORY_OPTIONS[careCategory]?.commonSearches || []);
    } else {
      setSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careCategory]);

  const debouncedSearchInput = useDebounce(searchInput, 300);
  useEffect(() => {
    if (debouncedSearchInput?.length < 2) {
      setSuggestions(
        showCommonSearches ? CARE_CATEGORY_OPTIONS[careCategory]?.commonSearches || [] : []
      );
      return;
    }
    const fetchSuggestions = () => {
      setSuggestions([...suggestions, {}, {}, {}]);
      setFetchingSuggestions(true);

      const location = `${latitude},${longitude}`;
      const searchType =
        useNewSpecialtyAutocomplete && careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY
          ? NEW_SPECIALTY_TYPE
          : careCategory;

      axios(
        `/autocomplete/?type=${searchType}&q=${encodeURIComponent(
          debouncedSearchInput
        )}&location=${location}&radius=${AUTOCOMPLETE_RADIUS_5}&neighbours=${AUTOCOMPLETE_RADIUS_4}&network_slug=${networkSlug}`
      )
        .then(({ data }) => {
          const {
            providers = [],
            specialties = [],
            subspecialties = [],
            searchTerms = [],
            places = [],
            services = [],
          } = data;

          if (
            (careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY ||
              careCategory === CARE_CATEGORIES.FACILITY_TYPE) &&
            !abortFetch
          ) {
            setFetchingSuggestions(false);
            setSuggestions([...subspecialties, ...specialties, ...searchTerms]);
          } else if (careCategory === CARE_CATEGORIES.PROVIDER_NAME && !abortFetch) {
            setFetchingSuggestions(false);
            setSuggestions(providers);
          } else if (
            (careCategory === CARE_CATEGORIES.FACILITY_TYPE ||
              careCategory === CARE_CATEGORIES.FACILITY_NAME) &&
            !abortFetch
          ) {
            setFetchingSuggestions(false);
            setSuggestions(places);
          } else if (careCategory === CARE_CATEGORIES.SERVICE && !abortFetch) {
            setFetchingSuggestions(false);
            setSuggestions(services);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          setFetchingSuggestions(false);
        });
    };

    if (timer) {
      clearTimeout(timer);
    }

    if (!debouncedSearchInput) {
      abortFetch = true;
      setSuggestions(
        showCommonSearches ? CARE_CATEGORY_OPTIONS[careCategory]?.commonSearches || [] : []
      );
    } else if (
      debouncedSearchInput &&
      !specialtyId &&
      !subspecialtyId &&
      networkSlug &&
      !entityId
    ) {
      abortFetch = false;

      timer = setTimeout(() => {
        fetchSuggestions();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput, latitude, longitude]);

  const handleChangeSearch = (event, value, reason) => {
    if (reason !== 'reset') {
      // if reason is reset, no need to dispatch a text update because the suggestionSelected action has already been dispatched
      dispatch(actions.search.handleTextInput({ value, reason }));
    }
  };

  const handleSelected = (event, suggestion) => {
    if (!suggestion) {
      return;
    }
    dispatch(actions.ui.closeModal('providerSearch'));
    dispatch(
      actions.search.suggestionSelected({
        suggestion,
        useNewSpecialtyAutocomplete,
      })
    );
  };

  const getOptionSelected = (suggestion, value) => {
    const { entityName, name } = suggestion;

    if (careCategory === CARE_CATEGORIES.PROVIDER_NAME) {
      return entityName === value;
    }
    if (
      careCategory === CARE_CATEGORIES.FACILITY_TYPE ||
      careCategory === CARE_CATEGORIES.FACILITY_NAME
    ) {
      return name === value;
    }
    if (careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY) {
      const labelText = getLabelText(value, suggestion, careCategory, useNewSpecialtyAutocomplete);

      return labelText === value;
    }
    return false;
  };

  const getOptionLabel = (option) => {
    const labelText = getLabelText(searchInput, option, careCategory, useNewSpecialtyAutocomplete);

    return labelText || option;
  };

  useEffect(() => {
    if (!searchInput && careCategory) {
      setOpen(true);
      searchInputRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careCategory]);

  const placeholderText = useMemo(() => getPlaceholderText(careCategory), [careCategory]);
  const accessibleLabel = useMemo(
    () =>
      `${CARE_CATEGORY_OPTIONS[careCategory]?.accessibleLabel || ''} ${
        open && suggestions.length ? 'expanded' : 'collapsed'
      }`,
    [careCategory, open, suggestions.length]
  );

  return (
    <Autocomplete
      id="provider-search"
      aria-controls="provider-search-popup"
      classes={{
        ...classes.autocompleteRoot,
        option: classes.option,
        listbox: classes.listbox,
      }}
      open={open}
      fullWidth
      freeSolo={!requiresSelection}
      disabled={!careCategory}
      autoSelect={requiresSelection}
      autoHighlight={requiresSelection}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={searchInput}
      onChange={handleSelected}
      onInputChange={handleChangeSearch}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={suggestions}
      filterOptions={(x) => x}
      loading={fetchingSuggestions}
      loadingText=""
      disableClearable
      noOptionsText={<NoResults />}
      getOptionDisabled={(option) => isEmpty(option)}
      renderOption={(option) =>
        renderOption(option, careCategory, searchInput, useNewSpecialtyAutocomplete)
      }
      renderInput={(params) => (
        <>
          {!requiresSelection && searchInput.length > 0 && suggestions.length === 0 && (
            <Typography variant="srOnly" aria-live="polite">
              No suggestions available, press enter to perform free text search for {searchInput}
            </Typography>
          )}
          <TextField
            {...params}
            autoFocus={!grouped}
            placeholder={placeholderText}
            variant="outlined"
            size="small"
            inputRef={searchInputRef}
            /* eslint-disable react/jsx-no-duplicate-props */
            /* inputProps applies to the HTML <input> whereas InputProps applies to the MUI <Input> component see https://v4.mui.com/api/text-field/#props */
            inputProps={{
              ...params.inputProps,
              maxLength: 150,
              'aria-label': accessibleLabel,
              style: {
                paddingRight: showClearInputButton ? 35 : 4,
              },
              className: `${params.inputProps?.className} ${classes.input}`,
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: grouped ? classes.inputRootGrouped : '',
              },
              startAdornment: <SearchIcon className={classes.startIcon} />,
              endAdornment: (
                <>
                  {showClearInputButton && (
                    <ClearInputButton
                      handleClick={() => handleChangeSearch(undefined, '', 'clear')}
                      offsetRight={requiresSelection}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
}

ProviderAutocomplete.defaultProps = {
  grouped: false,
};

ProviderAutocomplete.propTypes = {
  grouped: PropTypes.bool,
};

export default ProviderAutocomplete;
