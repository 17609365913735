import React from 'react';
import { Grid, Grow, makeStyles } from '@material-ui/core';
import PropTypes from 'propTypes';
import { PROVIDER_CARD_VIEW_OPTIONS } from 'store/slices/ui/uiConstants';

import ResultCardSkeleton from './ResultCardSkeleton';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& > section': {
      margin: '8px 0 16px',
    },
  },
}));

function SkeletonList({ view, isVisible }) {
  const classes = useStyles();
  return (
    <Grow in={isVisible} timeout={{ enter: 200, exit: 0 }} mountOnEnter unmountOnExit>
      <Grid aria-hidden="true" classes={{ root: classes.wrapper }}>
        <ResultCardSkeleton view={view} />
        <ResultCardSkeleton view={view} />
        <ResultCardSkeleton view={view} />
      </Grid>
    </Grow>
  );
}

export default SkeletonList;

SkeletonList.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};
