import React from 'react';
import PropTypes from 'propTypes/index';
import { Typography, Box, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import Tooltip from 'components/Tooltip';

import { STRENGTHS_SECTION } from 'utils/constants';
import ProfileSection from '../ProfileSection';
import SectionSubheader from '../SectionSubheader';
import { PROFILE_HORIZONTAL_LI_GUTTERS } from '../AccordionList';

const useStyles = makeStyles((theme) => ({
  padded: {
    padding: `${theme.spacing(1)}px ${PROFILE_HORIZONTAL_LI_GUTTERS} 0`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(1),
    },
  },
}));

function ProviderStrengthSection({ title, verbiage }) {
  return (
    <Box>
      <Tooltip
        message={verbiage.supplementalExplanatoryLanguage}
        aria-label={`More information about ${title}`}
      >
        <SectionSubheader component="span">{title}</SectionSubheader>
      </Tooltip>

      <Typography>
        {verbiage.preface} <strong>{verbiage.adjective}</strong> {verbiage.categoryLanguage}
      </Typography>
    </Box>
  );
}

function SectionStrengths({ providerId }) {
  const classes = useStyles();
  const providerStrengths = useSelector(select.provider(providerId).providerStrengths);

  if (!providerStrengths?.length) return null;

  return (
    <ProfileSection
      TitleProps={{
        title: STRENGTHS_SECTION.title,
        icon: <STRENGTHS_SECTION.IconComponent />,
        TooltipProps: {
          title: STRENGTHS_SECTION.title,
          message: STRENGTHS_SECTION.description,
        },
      }}
    >
      <List>
        {providerStrengths.map(({ label, percentile, ...verbiage }) => (
          <ListItem key={`${providerId}-${label}`} classes={{ root: classes.padded }}>
            <ProviderStrengthSection title={label} verbiage={verbiage} percentile={percentile} />
          </ListItem>
        ))}
      </List>
    </ProfileSection>
  );
}

export default SectionStrengths;

SectionStrengths.propTypes = {
  providerId: PropTypes.string.isRequired,
};

ProviderStrengthSection.propTypes = {
  title: PropTypes.string.isRequired,
  verbiage: PropTypes.shape({
    preface: PropTypes.string.isRequired,
    adjective: PropTypes.string.isRequired,
    categoryLanguage: PropTypes.string.isRequired,
    supplementalExplanatoryLanguage: PropTypes.string.isRequired,
  }).isRequired,
};
