import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/**
 * This hook returns a function that will navigate to the path stored in a locations state.
 * The primary use for this would be after a user has been redirected to the login route because the
 * intended route was protected. The returned function will navigate them to that initial route or
 * to a fallback if it doesn't exist.
 * See example at https://v5.reactrouter.com/web/example/auth-workflow at line 174
 * @param {string} fallbackRoute The route to navigate to if there is no FROM route stored in state
 */
export default function useNavToIntendedRoute(fallbackRoute = '/') {
  const location = useLocation();
  const history = useHistory();
  if (!location || !history) {
    throw new Error('This hook must be used inside a Router component');
  }

  // When a user is redirected, their intended route is stored in location state as 'from'
  const { from } = location.state || { from: { pathname: fallbackRoute } };
  const navToIntendedRoute = useCallback(
    () => history.replace(from, { wasRedirectedFromLogin: true }), // allows other components to see if they arrived at this location after being redirected from /login
    [from, history]
  );

  return navToIntendedRoute;
}
