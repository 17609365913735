import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthProtectedRoute from 'components/Navigation/AuthProtectedRoute';
import { select } from 'store/toolkit';
import NoActiveRewards from './NoActiveRewards';
import RewardsLoadingSkeleton from './RewardsLoadingSkeleton';
import RewardsPage from './RewardsPage';
import RedeemRewardPage from './RedeemRewardPage';

export default function RewardsRoutes() {
  const enableRewards = useSelector(select.featureFlags.enableRewards);
  const configIsLoading = useSelector(select.config.isLoading);
  const rewardsIsLoading = useSelector(select.rewards.isLoading);
  const hasActiveCampaign = useSelector(select.rewards.hasActiveCampaign);

  if (configIsLoading || rewardsIsLoading) return <RewardsLoadingSkeleton />; // handle loading to check and see if there are any active rewards
  if (!enableRewards) return <Redirect to="/" />; // if feature flag is not enabled, redirect home
  if (!hasActiveCampaign) return <NoActiveRewards />; // after loading, if there are no rewards or it errored, tell the user there is no active reward

  // finally render two potential routes for the user. one to show reward info, and one for reward redemption
  return (
    <Switch>
      <AuthProtectedRoute
        exact
        path="/rewards/redeem/:id"
        render={(props) => <RedeemRewardPage {...props} />}
      />
      <AuthProtectedRoute exact path="/rewards" render={() => <RewardsPage />} />
      <Redirect to="/rewards" />
    </Switch>
  );
}
