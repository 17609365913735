import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <rect
      x="21.5"
      y="3.57129"
      width="4.58741"
      height="18.9075"
      rx="1.41714"
      transform="rotate(90 21.5 3.57129)"
      fill="#8EC642"
    />
    <rect
      x="21.5"
      y="9.64648"
      width="4.52541"
      height="18.9075"
      rx="1.41714"
      transform="rotate(90 21.5 9.64648)"
      fill="#D0DF00"
    />
    <rect
      x="21.5"
      y="15.6597"
      width="4.5874"
      height="18.9075"
      rx="1.41714"
      transform="rotate(90 21.5 15.6597)"
      fill="#8EC642"
    />
    <path
      d="M4.2041 5.89452L4.86845 6.63215C4.94676 6.7191 5.08312 6.7191 5.16143 6.63215L6.74577 4.87305"
      stroke="#D0DF00"
      strokeWidth="0.75"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M4.2041 11.9697L4.86845 12.7073C4.94676 12.7943 5.08312 12.7943 5.16143 12.7073L6.74577 10.9482"
      stroke="#8EC642"
      strokeWidth="0.75"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M4.2041 17.9829L4.86845 18.7205C4.94676 18.8075 5.08312 18.8075 5.16143 18.7205L6.74577 16.9614"
      stroke="#D0DF00"
      strokeWidth="0.75"
      strokeLinecap="round"
      fill="none"
    />
  </>,
  'PrimaryCare'
);
