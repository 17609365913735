import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import IconLabel from 'components/Results/ResultCard/IconLabel';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import Tooltip from 'components/Tooltip';

import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';

import Modal from 'components/Modals/Modal';
import { getLocalStorageExpire, setLocalStorageExpire } from 'utils/utils';
import { WALMART } from 'utils/constants';
import { actions, select } from 'store/toolkit';
import {
  highPerformingPill as createHighPerformingPillSelector,
  highPerformingBadge as createHighPerformingBadgeSelector,
} from 'store/slices/results/selectProvider';
import { getNetworkContent } from 'store/slices/results/providerUtils';
import Attribute from 'components/ModernExperience/Attribute';
import useThemeColor from 'hooks/useThemeColor';
import HighlyRatedIcons from './HighlyRatedIcons';

const useStyles = makeStyles((theme) => ({
  headerText: {
    textAlign: 'center',
    margin: '20px 0',
  },
  bannerExample: {
    textAlign: 'center',
    fontWeight: 700,
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
      marginRight: 5,
      transform: 'translateY(4px)',
    },
  },
  acceptButton: {
    margin: '20px 0',
  },
  coverageDecreasing: {
    color: theme.palette.warning.main,
    justifyContent: 'center',
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
      color: 'inherit',
    },
    '& img, svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  preferred: {
    color: theme.palette.success.dark,
  },
}));

// Increment to invalidate 'wmtBenefitsNotifier' storage item on all browsers
export const WMT_BENEFITS_NOTIFIER_VERSION = 1;
// Increment to invalidate 'BENEFITS_CHANGE' storage item on all browsers.
// This will ensure benefits modal is shown during new open enrollment periods
export const WMT_BENEFITS_CHANGE_VERSION = 1;
// Storage keys
export const WMT_BENEFITS_NOTIFIER_STORAGE_KEY = 'wmtBenefitsNotifier';
export const WMT_BENEFITS_CHANGE_STORAGE_KEY = 'BENEFITS_CHANGE';

/**
 * Conditions to show this modal:
 *
 * 1. The 'wmtBenefitsNotifier' localStorage item has an integer value less than 2.
 * This value is incremented whenever the modal is closed and the localStorage item
 * has no expiry, so a new user will see it the first *two* times they visit the app.
 *
 * ** OR **
 *
 * 2. The 'BENEFITS_CHANGE' localStorage item is false AND today is within the
 * "benefits_change" date range set within the Client Config. This indicates that the
 * user has not seen the most current "benefits changing UI".
 *
 * */
export const checkStorageAndShowModal = (dispatch, isTodayInBenefitChangeDateRange) => {
  const benefitModalShownCount = parseInt(
    getLocalStorageExpire(WMT_BENEFITS_NOTIFIER_STORAGE_KEY, WMT_BENEFITS_NOTIFIER_VERSION) ?? 0,
    10
  );

  // Condition 1:
  if (benefitModalShownCount < 2) {
    dispatch(actions.ui.openModal('highQuality'));
    return;
  }

  // Condition 2:
  const wasPreviousSessionInDateRange = Boolean(
    getLocalStorageExpire(WMT_BENEFITS_CHANGE_STORAGE_KEY, WMT_BENEFITS_CHANGE_VERSION)
  );

  if (!wasPreviousSessionInDateRange && isTodayInBenefitChangeDateRange) {
    dispatch(actions.ui.openModal('highQuality'));
  }
};

export const incrementStorageValue = () => {
  const modalShownCount = parseInt(
    getLocalStorageExpire(WMT_BENEFITS_NOTIFIER_STORAGE_KEY, WMT_BENEFITS_NOTIFIER_VERSION) ?? 0,
    10
  );
  setLocalStorageExpire(
    WMT_BENEFITS_NOTIFIER_STORAGE_KEY,
    modalShownCount + 1,
    null,
    WMT_BENEFITS_NOTIFIER_VERSION
  );
};

function WMTBenefitsNotifierModal() {
  const classes = useStyles();
  const open = useSelector(select.ui.highQualityModalOpen);
  const isTodayInBenefitChangeDateRange = useSelector(
    select.content.isTodayInBenefitChangeDateRange
  );
  const benefitsChange = useSelector(select.content.benefitsChange);
  const appName = useSelector(select.content.appName);
  const networkSlug = useSelector(select.networks.currentSlug);

  const dispatch = useDispatch();

  useEffect(() => {
    // On initial render of this component:
    // 1. determine whether or not to show the modal
    checkStorageAndShowModal(dispatch, isTodayInBenefitChangeDateRange);

    // 2. always update benefits change localStorage item to reflect if most recent session was is in the date range
    setLocalStorageExpire(
      WMT_BENEFITS_CHANGE_STORAGE_KEY,
      isTodayInBenefitChangeDateRange,
      null,
      WMT_BENEFITS_CHANGE_VERSION
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    dispatch(actions.ui.closeModal('highQuality'));
    incrementStorageValue();
  }, [dispatch]);

  // Modal content
  const exampleProvider = { highPerforming: true }; // mock provider object of a high performing provider
  const exampleHighPerformingPillProps = createHighPerformingPillSelector().resultFunc(
    exampleProvider,
    true
  );
  const exampleHighPerformingBadgeProps = createHighPerformingBadgeSelector().resultFunc(
    exampleHighPerformingPillProps,
    true
  );
  const exampleNetworkContent = getNetworkContent({
    provider: exampleProvider,
    network: 'bcbstexas',
    client: WALMART,
  });
  const networkColor = useThemeColor(exampleNetworkContent.textColor);

  const nwa = useMemo(
    () => networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas',
    [networkSlug]
  );
  return (
    <Modal
      open={open}
      ariaId="wmt-benefits-modal"
      allowBackdropClick={false}
      showX
      handleClose={handleClose}
    >
      <Container component="section">
        <Typography variant="h2" id="wmt-benefits-modal-title" className={classes.headerText}>
          High-Quality Care
        </Typography>

        <div id="wmt-benefits-modal-description">
          {!nwa ? (
            <div data-testid="benefits-text">
              <Typography paragraph align="center" style={{ marginTop: 20 }}>
                We’ve identified local doctors who have consistently delivered the best value and
                quality care. They’re called <i>preferred providers</i>, and when you use them,
                Walmart covers a higher share of eligible costs. When you use the {appName}, just
                look for “In-Network/Preferred” in green next to the provider’s name to find a
                preferred doctor.
              </Typography>
              <Grid container alignItems="center" direction="column">
                <Grid item>
                  <Attribute
                    text={exampleNetworkContent.networkText}
                    icon={exampleNetworkContent.networkIcon}
                    additionalIconClass={classes.preferred}
                    TooltipProps={{ message: exampleNetworkContent.tooltipMessage }}
                    TypographyProps={{
                      style: {
                        fontWeight: 'bold',
                        color: networkColor,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography paragraph align="center" style={{ marginTop: 20 }}>
                    You can also find preferred doctors in your community who are highly rated.
                    We’ve identified those preferred doctors who have consistently been among the
                    best in the community at delivering quality care. Just look for the blue “highly
                    rated” ribbon next to the provider’s name.
                  </Typography>
                </Grid>
                <Grid item>
                  <Pill {...exampleHighPerformingPillProps} />
                </Grid>
              </Grid>
              {isTodayInBenefitChangeDateRange && (
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  data-testid="benefits-change-text"
                >
                  <Grid item>
                    <Typography paragraph align="center" style={{ marginTop: 20 }}>
                      Providers that will not be preferred in 2024 have the following symbol in the
                      {appName}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title="In Network Non-Preferred"
                      message="This provider is in-network but has been designated by your medical plan as a non-preferred provider. Your coverage will be lower when you get care from this provider."
                    >
                      <IconLabel
                        img={<ReportProblemOutlinedIcon color="inherit" />}
                        className={classes.coverageDecreasing}
                      >
                        {benefitsChange.cardAttribute.text}
                      </IconLabel>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography paragraph align="center" style={{ marginTop: 20 }}>
                      You will pay a higher share of eligible costs from this provider in 2024 than
                      you paid in 2023.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : (
            <div data-testid="nwa-benefits-text">
              <Typography paragraph align="center">
                Use the {appName} to find highly rated doctors in your local community. We’ve
                identified doctors who have consistently been among the best in the community at
                delivering quality care. Just look for “highly rated” next to the doctor’s name.
              </Typography>

              <HighlyRatedIcons
                badgeProps={exampleHighPerformingBadgeProps}
                pillProps={exampleHighPerformingPillProps}
              />

              <Typography paragraph align="center">
                Available for associates enrolled in the Premier, Contribution, or Saver plans in
                Northwest Arkansas
              </Typography>
            </div>
          )}
        </div>

        <Grid container direction="row" justifyContent="center">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.acceptButton}
          >
            OK
          </Button>
        </Grid>
      </Container>
    </Modal>
  );
}

export default WMTBenefitsNotifierModal;
