import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import events from './events';

const reducers = {};

for (const [key, value] of Object.entries(events)) {
  reducers[key] = value.reducer;
}

const analyticsSlice = createSlice({
  name: '_analytics',
  initialState: null,
  reducers,
  extraReducers(builder) {
    for (const key in events) {
      if (events[key] && events[key].triggers) {
        const event = events[key];
        builder.addMatcher(isAnyOf(...event.triggers), event.reducer);
      }
    }

    builder.addDefaultCase(() => null);
  },
});

export default analyticsSlice;
