import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';

import { CHAT_BANNER_VARIANT } from 'store/slices/banner/bannerConstants';
import { actions, select } from 'store/toolkit';
import { QUANTUM } from 'utils/constants';

function getChatBannerColor(themeVariant) {
  switch (themeVariant) {
    case QUANTUM:
      return 'primary';
    default:
      return 'secondary';
  }
}

export default function useChatBanner(isChatPage) {
  const dispatch = useDispatch();
  const enableChat = useSelector(select.featureFlags.enableChat);
  const bannerVariant = useSelector(select.banner.variant);
  const { variantName } = useTheme();

  const [hasDismissed, setHasDismissed] = useState(false);

  const handleChatBannerDismiss = useCallback(() => setHasDismissed(true), [setHasDismissed]);

  useEffect(() => {
    if (enableChat && !hasDismissed && isChatPage) {
      // show the chat banner if we are on a chat page, and the banner hasn't been previously dismissed
      dispatch(
        actions.banner.showBanner({
          variant: CHAT_BANNER_VARIANT,
          color: getChatBannerColor(variantName),
          text: 'Try Virtual Assistant',
        })
      );
    }
  }, [enableChat, hasDismissed, isChatPage, dispatch, variantName]);

  useEffect(() => {
    // hide the chat banner if isChatPage changes to false
    if (!isChatPage && bannerVariant === CHAT_BANNER_VARIANT) {
      dispatch(actions.banner.hideBanner());
    }
  }, [isChatPage, dispatch, bannerVariant]);

  return handleChatBannerDismiss;
}
