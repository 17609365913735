import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse', // the reason for reversing this column is so a screen reader reads the name, then subspecialty, then the pill content
    alignItems: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  pill: { marginBottom: theme.spacing(0.5) },
}));

export default function ProfileTabLabel({ id, additionalClass }) {
  const classes = useStyles();
  const result = useSelector(select.results.byId(id));
  // we can't conditionally mount hooks, so we attempt fullNameAndTitle/score from the provider selector, if the id does not return a provider result, it will be undefined
  const fullNameAndTitle = useSelector(select.provider(id).fullNameAndTitle);
  const allPills = useSelector(select.provider(id).pillList);

  const firstPillProps = allPills[0];

  const line1 = fullNameAndTitle || result.name; // providers use fullNameAndTitle (will be null for places), places use name

  return (
    <div className={`${classes.root} ${additionalClass}`}>
      <div className={classes.textGroup}>
        <Typography variant="h4" component="span">
          {line1}
        </Typography>
        {/* providers use the specialty property, places do not have a specialty but do have specialtyName */}
        <Typography component="span">{result.specialty || result.specialtyName}</Typography>
      </div>
      {Boolean(firstPillProps) && (
        // set the tooltip message as undefined, because a tooltip would render a button within a button, leading to a invalid DOM nesting error
        <Pill {...firstPillProps} additionalClass={classes.pill} TooltipProps={{}} />
      )}
    </div>
  );
}

ProfileTabLabel.propTypes = {
  id: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

ProfileTabLabel.defaultProps = {
  additionalClass: '',
};
