import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'propTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { actions, select } from 'store/toolkit';
import GeolocateButton from 'components/LocationSearch/GeolocateButton';
import LocationModal from 'components/LocationSearch/LocationModal';
import useFocusAnchors from 'utils/FocusRefContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: 20,
  },
  input: {
    '& ::placeholder': {
      opacity: 1,
      color: theme.palette.grey[600],
      fontSize: '.875rem',
    },
  },
}));

function LocationDummy({ autoSearch, submitSearch }) {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { locationDummy } = useFocusAnchors();
  const classes = useStyles();

  const locationInput = useSelector(select.location.locationInput);

  return (
    <>
      <TextField
        id="location-search-dummy"
        placeholder="Address, city, or zip code"
        value={locationInput}
        variant="outlined"
        margin="dense"
        inputRef={locationDummy}
        onClick={
          smDown
            ? () => {
                dispatch(actions.ui.openModal('location'));
              }
            : undefined
        }
        onChange={
          smDown
            ? ({ target: { value } }) => {
                dispatch(actions.ui.openModal('location'));
                dispatch(actions.location.setLocationInput(value));
              }
            : undefined
        }
        classes={{ root: classes.root }}
        InputProps={{
          classes: { root: classes.input },
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ paddingLeft: '2px' }}
            >
              <GeolocateButton />
            </InputAdornment>
          ),
        }}
      />
      <LocationModal autoSearch={autoSearch} submitSearch={submitSearch} />
    </>
  );
}

export default LocationDummy;

LocationDummy.propTypes = {
  submitSearch: PropTypes.func.isRequired,
  autoSearch: PropTypes.bool.isRequired,
};
