import React from 'react';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import ProviderSpecialty from '../StyledWrappers/ProviderSpecialty';
import ResultTitle from '../StyledWrappers/ResultTitle';

function ProviderNameAndSpecialty({ providerId }) {
  const nameAndTitle = useSelector(select.provider(providerId).fullNameAndTitle);
  const { specialty } = useSelector(select.provider(providerId).data);

  return (
    <>
      <ResultTitle resultId={providerId}>{nameAndTitle}</ResultTitle>
      <ProviderSpecialty>{specialty}</ProviderSpecialty>
    </>
  );
}

export default React.memo(ProviderNameAndSpecialty);

ProviderNameAndSpecialty.propTypes = {
  providerId: PropTypes.string.isRequired,
};
