import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Paper, Typography, Button, SvgIcon } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import GiftIcon from 'icons/Gift';

import { select } from 'store/toolkit';
import useThemeColor from 'hooks/useThemeColor';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > h3': {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
    },
    '& > a': { minWidth: 200, alignSelf: 'flex-start' },
  },
  giftCardContainer: {
    padding: theme.spacing(1),
    '& > *': { width: '100%', height: 'auto' },
  },
}));

function GiftCard({ color, ...props }) {
  const color1 = useThemeColor(color);
  const color2 = lighten(color1, 0.5);

  return (
    <SvgIcon width="208" height="143" viewBox="0 0 208 143" {...props}>
      <rect x="0.236328" width="207.618" height="142.379" rx="16.7505" fill={color1} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.939 36.8331C125.294 33.1891 131.189 33.7043 133.861 37.875C136.439 41.9007 134.678 47.2795 130.218 49.0005L100.006 60.6572L121.939 36.8331ZM72.0008 74.1553L34.5172 59.6931C23.2912 55.3618 18.859 41.8246 25.3497 31.6927C32.0742 21.196 46.9101 19.8994 55.3534 29.0706L84.4303 60.6542L113.507 29.0706C121.951 19.8994 136.786 21.196 143.511 31.6927C150.002 41.8246 145.57 55.3618 134.343 59.6931L96.8599 74.1553L120.384 99.7073C122.528 102.036 122.378 105.661 120.049 107.804C117.721 109.948 114.096 109.798 111.952 107.47L84.4303 77.5754L56.9084 107.47C54.7648 109.798 51.1396 109.948 48.8113 107.804C46.4829 105.661 46.3331 102.036 48.4767 99.7073L72.0008 74.1553ZM46.9216 36.8331C43.5668 33.1891 37.672 33.7043 35.0002 37.875C32.4212 41.9007 34.1822 47.2795 38.6427 49.0005L68.8548 60.6572L46.9216 36.8331Z"
        fill={color2}
      />
      <path d="M84.8704 0V142.379M207.854 70.9692H0.236328" stroke={color2} strokeWidth="11.4609" />
      <path
        d="M143.938 123.425H188.018"
        stroke="white"
        strokeWidth="11.4609"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

GiftCard.propTypes = { color: PropTypes.string.isRequired };

export default function RewardsSection() {
  const classes = useStyles();
  const isSmDown = useSelector(select.ui.isSmDown);

  const giftCards = useMemo(() => {
    const colors = ['red.500', 'purple.600', 'blue.400', 'orange.600']; // 4 colored gift cards

    // add two additional gift cards above small breakpoint
    if (!isSmDown) colors.splice(2, 0, 'blue.900');
    if (!isSmDown) colors.push('green.500');

    return colors.map((color) => (
      <Grid item xs={6} md={4} className={classes.giftCardContainer} key={`gift-card-${color}`}>
        <GiftCard color={color} />
      </Grid>
    ));
  }, [isSmDown, classes]);

  return (
    <Paper component={Grid} container className={classes.root} justifyContent="space-evenly">
      <Grid item xs={12} sm={6} md={5} className={classes.textContainer}>
        <Typography variant="h2" component="h3" color="primary">
          <GiftIcon />
          Rewards Program
        </Typography>

        <Typography>
          Get rewarded with a digital gift card for seeing a high-quality doctor. Click the rewards
          button on a Provider’s profile to redeem your gift.
        </Typography>

        <Button component={RouterLink} variant="contained" color="primary" to="/rewards">
          Learn More
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={5}
        container
        role="img"
        aria-label="a collection of colored gift cards"
        className={classes.giftCardGroup}
      >
        {giftCards}
      </Grid>
    </Paper>
  );
}
