import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import { SCORE_COLORS_BY_TIER, SCORE_TIER_LANGUAGE, SUBSPECIALTY_TYPES } from 'utils/constants';
import useThemeColor from 'hooks/useThemeColor';

/**
 * Accepts a subspecialty object and returns the explanatory text on what this measurement means.
 * @param {object} subspecialtyObject
 * @param {number} subspecialtyObject.subspecialtyId
 * @param {string} subspecialtyObject.subspecialtyName
 * @param {string} subspecialtyObject.subspecialtyType
 * @param {number=} subspecialtyObject.subspecialtyScoreTier
 * @param {number=} subspecialtyObject.subspecialtyScore
 *
 * @param {object=} options An optional object for changing the output of the hook
 * @param {options.asComponent} bool Setting as true will cause the hook to output React nodes rather than plain strings
 *
 * @returns {string|undefined|Node} A string description of the subspecialty measurement
 */
export default function useSubspecialtyExplanationText(subspecialtyObject, options = {}) {
  const { subspecialtyName, subspecialtyType, subspecialtyScore, subspecialtyScoreTier } =
    subspecialtyObject;
  const { asComponent = false, props = { variant: 'body1', component: 'p' } } = options;

  const showScores = useSelector(select.featureFlags.showDrScore);
  const showEmboldRecommended = useSelector(select.featureFlags.showEmboldRecommended);

  const scoreColor = SCORE_COLORS_BY_TIER[subspecialtyScoreTier];
  const scoreColorHex = useThemeColor(scoreColor);

  const highVolumePrefix = 'This provider performs a';
  const highVolumeDescriptor = 'high volume'; // highlighted text
  const highVolumeSuffix = 'of these cases per year.';

  const selfIdentifiedText = 'This provider self-identifies in this area';

  const scorePrefix = 'This provider scores';
  const scoreSuffix = `${subspecialtyScore} for ${subspecialtyName}.`; // highlighted text

  const scoreTierPrefix = SCORE_TIER_LANGUAGE[subspecialtyScoreTier]; // highlighted text
  const scoreTierSuffix = 'in this area.';

  return useMemo(() => {
    let scoreText;
    let scoreTierText;

    // used in the profile focus areas section
    if (asComponent) {
      switch (subspecialtyType) {
        case SUBSPECIALTY_TYPES.SELF_IDENTIFIED:
          return <Typography {...props}>{selfIdentifiedText}</Typography>;
        case SUBSPECIALTY_TYPES.HIGH_VOLUME:
          return (
            <Typography {...props}>
              {highVolumePrefix}{' '}
              <Typography component="strong" variant="subtitle2">
                {highVolumeDescriptor}
              </Typography>{' '}
              {highVolumeSuffix}
            </Typography>
          );
        case SUBSPECIALTY_TYPES.SCORED:
          scoreText = (
            <>
              {scorePrefix}{' '}
              <Typography component="strong" variant="subtitle2" style={{ color: scoreColorHex }}>
                {scoreSuffix}
              </Typography>
            </>
          );
          // in some cases (walmart national) the score tier may be 0, even though it is a provider with a subspecialty score
          // in these cases there should be no scoreTier text
          scoreTierText = subspecialtyScoreTier ? (
            <>
              <Typography component="strong" variant="subtitle2" style={{ color: scoreColorHex }}>
                {scoreTierPrefix}
              </Typography>{' '}
              {scoreTierSuffix}
            </>
          ) : null;

          if (showEmboldRecommended && showScores)
            return (
              <Typography {...props}>
                {scoreTierText} {scoreText}
              </Typography>
            );
          if (showEmboldRecommended) return <Typography {...props}>{scoreTierText}</Typography>;
          if (showScores) return <Typography {...props}>{scoreText}</Typography>;
          return null; // when neither feature is turned on scored subspecialties have no explanatory text
        default:
          return null;
      }
    }

    // used in the result card metric
    switch (subspecialtyType) {
      case SUBSPECIALTY_TYPES.SELF_IDENTIFIED:
        return selfIdentifiedText;
      case SUBSPECIALTY_TYPES.HIGH_VOLUME:
        return `${highVolumePrefix} ${highVolumeDescriptor} ${highVolumeSuffix}`;
      case SUBSPECIALTY_TYPES.SCORED:
        scoreText = `${scorePrefix} ${scoreSuffix}`;
        // in some cases (walmart national) the score tier may be 0, even though it is a provider with a subspecialty score
        // in these cases there should be no scoreTier text
        scoreTierText = subspecialtyScoreTier ? `${scoreTierPrefix} ${scoreTierSuffix}` : '';

        if (showEmboldRecommended && showScores) return `${scoreTierText} ${scoreText}`;
        if (showEmboldRecommended) return scoreTierText;
        if (showScores) return scoreText;
        return ''; // when neither feature is turned on scored subspecialties have no explanatory text
      default:
        return '';
    }
  }, [
    showScores,
    showEmboldRecommended,
    subspecialtyType,
    asComponent,
    props,
    scoreColorHex,
    scoreTierPrefix,
    scoreSuffix,
    subspecialtyScoreTier,
  ]);
}
