import { createSlice } from '@reduxjs/toolkit';

import * as app from 'store/appActions';
import { ENDPOINTS } from 'store/fusionServices/fusionConstants';
import { getNearestValidRadius } from 'store/slices/filters/filterUtils';
import { isValidCoords } from 'utils/mapUtils';
import {
  executeSearch,
  fetchExpandSearchSuggestion,
  loadMoreResults,
  searchBySingleProvidersSpecialty,
  fetchShare,
  searchThisArea,
} from './resultsThunks';

import { RESULTS_SLICE_NAME } from '../slicesNames';

const initialState = {
  isLoading: false,
  isLoadingMore: false, // Load More button in Providers list was clicked
  error: null,

  request: {
    // request stores all of the data that went into the search
    url: null,
    endpoint: null,
    filters: {}, // active filter values
    search: {}, // copy of the search slice state
    location: {
      radius: null,
      boundingBox: null,
    },
  },

  response: {
    // response stores all of the data returned by the api
    count: null,
    results: {},
    resultIdList: [],
    next: null,
    filterCounts: null,
  },
  shareType: null, // only populated when loading in results from a share

  compareList: [],

  // selected map marker
  selectedId: null, // map marker was clicked
  hoveredId: null, // provider or place card was highlighted
  clusterIds: [], // map cluster was clicked
  clusterMarkerId: null,
  selectedFeatureCoords: {
    latitude: null,
    longitude: null,
  },

  suggestedExpandedRadius: null,
};

function handleLoading(state, action) {
  const { arg = {} } = action.meta || {};

  // on url searches, preserve the compare list, otherwise reset it
  const compareList = arg.isUrlSearch ? state.compareList : initialState.compareList;
  return { ...initialState, isLoading: true, compareList, shareType: state.shareType };
}

function resetActiveResults(state) {
  state.selectedId = initialState.selectedId;
  state.clusterIds = initialState.clusterIds;
  state.clusterMarkerId = initialState.clusterMarkerId;
  state.selectedFeatureCoords = initialState.selectedFeatureCoords;
}

/** This is the generic error handler. However, if you want to customize the way an error is handle by a certain thunk, you can always write an individual error handler in the reducer below. */
function handleError(state, action) {
  state.isLoading = false;
  state.isLoadingMore = false;
  const { payload = {}, error } = action;
  // The hierarchy is as follows:
  // If a payload with a message property is sent, we should assume it is intended as a user facing error message. This would be done with rejectWithValue({ message: 'My custom error message' })
  // If there is no payload message, we can fallback to the error.message property, however this is generated by whatever mechanism threw the error. Example: Axios will throw an error that simply says "Request failed with status code of 404",
  // Last resort should be to simply set this value to a generic message so that any part of the app checking for truthiness will indicate that an error did actually occur. This should only happen if an error is thrown with no message property. Example: throw new Error();
  state.error = payload?.message || error.message || 'An unexpected error occurred';
}

const resultsSlice = createSlice({
  name: RESULTS_SLICE_NAME,
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

    toggleIdInCompareList(state, action) {
      const id = action.payload;
      if (!id) throw new Error('No id passed to toggleIdInCompareList');

      if (state.compareList.includes(id)) {
        state.compareList = state.compareList.filter((el) => el !== id); // filter out the id
      } else {
        state.compareList.push(id);
      }
    },

    clearCompareList(state) {
      state.compareList = [];
    },

    clearActiveResults(state) {
      resetActiveResults(state);
    },

    setHoveredResult(state, action) {
      const id = action.payload;
      if (state.hoveredId === id) return;
      if (!id) throw new Error('No id passed to setActiveResult');

      if (state.response.results[id]) {
        state.hoveredId = id;
      } else {
        throw new Error('No result with that ID');
      }
    },

    clearHoveredResult(state) {
      state.hoveredId = null;
    },

    setActiveResult(state, action) {
      const id = action.payload;
      if (state.selectedId === id) return;
      if (!id) throw new Error('No id passed to setActiveResult');

      if (state.response.results[id]) {
        state.selectedId = id;
      } else {
        throw new Error('No result with that ID');
      }
    },

    clearActiveResult(state) {
      state.selectedId = null;
    },

    overrideResultValue(state, action) {
      if (process.env.NODE_ENV === 'development') {
        const { id, values = {} } = action.payload;

        if (state.response.results[id]) {
          state.response.results[id] = { ...state.response.results[id], ...values };
        }
      }
    },
  },
  extraReducers(builder) {
    // execute search
    builder
      .addCase(executeSearch.pending, handleLoading)
      .addCase(executeSearch.rejected, handleError)
      .addCase(executeSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const { request, response } = action.payload;

        state.response = response;
        state.request = request;

        // remove id's from compare list that do not exist in new results.
        // this ensures that any id from the query params that is invalid or does not exist in results is NOT in compare list
        state.compareList = state.compareList.filter((id) => response.resultIdList.includes(id));
        resetActiveResults(state);
      });

    // load more results
    builder
      .addCase(loadMoreResults.pending, (state) => {
        state.isLoadingMore = true;
      })
      .addCase(loadMoreResults.rejected, handleError)
      .addCase(loadMoreResults.fulfilled, (state, action) => {
        state.isLoadingMore = false;
        state.isLoading = false;
        state.error = null;

        const response = action.payload;
        const { results = {}, resultIdList = [], next } = response;

        // add new results to result object
        state.response.results = { ...state.response.results, ...results };
        // add new ids to resultIdList
        state.response.resultIdList = [...state.response.resultIdList, ...resultIdList];
        // add next pagination link
        state.response.next = next;
      });

    builder.addCase(searchThisArea.pending, (state) => {
      state.isLoading = true;
    });

    // "see more provider's with X specialty"
    builder.addCase(searchBySingleProvidersSpecialty.fulfilled, (state, action) => {
      // get the initial provider that was searched for from payload
      const provider = action.payload;

      // add provider to the results object
      state.response.results[provider.entityId] = provider;

      state.response.resultIdList = state.response.resultIdList.filter(
        (id) => id !== provider.entityId
      ); // filter out the original provider id as to not duplicate
      state.response.resultIdList.unshift(provider.entityId); // add the id to the top of the resultIdList so this provider appears at the top
      state.selectedId = provider.entityId; // set the selectedId to the provider that was initially searched
    });

    builder.addCase(fetchExpandSearchSuggestion.fulfilled, (state, action) => {
      const { distance } = action.payload; // get the distance to the nearest result

      const suggestedRadius = getNearestValidRadius(distance); // find the nearest valid radius value

      // suggestedRadius will either be a valid radius or -1
      if (suggestedRadius > 0) state.suggestedExpandedRadius = suggestedRadius;
    });

    // App actions
    builder
      .addCase(app.startOver, () => initialState)
      .addCase(app.mapLocationClicked, (state, action) => {
        const { marker = {}, latitude, longitude } = action.payload;

        const { resultId } = marker;

        if (resultId) {
          state.selectedId = resultId;
          state.selectedFeatureCoords = { latitude, longitude };
        }
      })
      .addCase(app.showClusterResults, (state, action) => {
        const { resultIds = [], latitude, longitude, clusterId } = action.payload;

        state.selectedId = initialState.selectedId;
        // cluster list
        state.clusterIds = resultIds;
        state.clusterMarkerId = clusterId;
        state.selectedFeatureCoords = { latitude, longitude };
      })
      .addCase(app.mapClicked, (state) => {
        resetActiveResults(state);
      });

    builder
      .addCase(fetchShare.pending, handleLoading)
      .addCase(fetchShare.rejected, (state, action) => {
        state.isLoading = false;
        const { status } = action.payload;

        if (status === 410) {
          state.error =
            'The link you are trying to access has expired. Please request a new link from the person who shared this provider with you.';
        } else if (status === 502) {
          state.error = 'There was an unexpected problem calling the share url service.';
        } else {
          state.error = 'There was an unexpected problem retrieving the share url.';
        }
      })
      .addCase(fetchShare.fulfilled, (state, action) => {
        const { entityData = [], entityType = '', location = '', locationInput } = action.payload;

        state.isLoading = false;
        state.request.endpoint = ENDPOINTS.SHARE;
        // update request location
        if (typeof location === 'string') {
          // legacy share links will NOT have location or locationInput data
          let [latitude, longitude] = location.split(',');
          latitude = +latitude; // convert strings to numbers
          longitude = +longitude;

          if (isValidCoords(latitude, longitude) && locationInput) {
            state.request.location.coordinates = { latitude, longitude };
            state.request.location.input = locationInput;
          }
        }

        // store the type of share as a user facing string: 'Provider' or 'Place'
        state.shareType = `${entityType.substring(0, 1).toUpperCase()}${entityType.substring(1)}`;

        const idKey = entityType === 'provider' ? 'entityId' : 'id';
        const idList = [];
        for (const result of entityData) {
          const resultId = result[idKey];

          idList.push(resultId);
          state.response.results[resultId] = result;
        }
        state.response.resultIdList = idList;

        const total = idList.length;
        state.response.count = total;
        if (total > 1) {
          // when more than 1 id was set, automatically set compare list
          state.compareList = idList;
        }
      });

    builder.addCase(app.updateStoreFromUrl, (state, action) => {
      const { compareList = [] } = action.payload;
      if (Array.isArray(compareList)) {
        state.compareList = compareList;
      }
    });
  },
});

export default resultsSlice;
export const {
  reset,
  toggleIdInCompareList,
  clearCompareList,
  setActiveResult,
  clearActiveResults,
  setHoveredResult,
  clearHoveredResult,
  setIsLoading,
} = resultsSlice.actions;
