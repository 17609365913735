import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TableRow, TableCell, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ShareIcon from '@material-ui/icons/Share';

import { select, actions } from 'store/toolkit';
import useTableValue from 'components/ModernExperience/CompareTable/useTableValue';
import useIsSharePage from 'hooks/useIsSharePage';

const useStyles = makeStyles((theme) => ({
  row: {
    '& > *': {
      padding: 0,
      borderBottom: 'none',
    },
  },
  buttonCell: {
    '& button': { padding: theme.spacing(1), marginRight: theme.spacing(1) },
    '& svg': { color: theme.palette.primary.main },
    '& :last-child': { marginRight: 0 },
  },
}));

function ShareButton({ resultId }) {
  const placeData = useSelector(select.place(resultId).data);
  const dispatch = useDispatch();

  const handleShare = useCallback(() => {
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [placeData] }));
  }, [dispatch, placeData]);

  return (
    <IconButton onClick={handleShare} aria-label={`share ${placeData?.entityName}`}>
      <ShareIcon />
    </IconButton>
  );
}

export default function PlaceActionButtonsRow() {
  const classes = useStyles();
  const { resultIds } = useTableValue();
  const isSharePage = useIsSharePage();
  const enableShare = useSelector(select.featureFlags.showShare);

  const showShareButton = !isSharePage && enableShare;

  if (!showShareButton) return null;

  return (
    <TableRow className={classes.row}>
      <TableCell>
        <Typography variant="srOnly">Share</Typography>
      </TableCell>
      {resultIds.map((id) => (
        <TableCell key={`action-${id}`} className={classes.buttonCell}>
          <ShareButton resultId={id} />
        </TableCell>
      ))}
    </TableRow>
  );
}

ShareButton.propTypes = {
  resultId: PropTypes.string.isRequired,
};
