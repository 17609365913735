import * as sliceNames from './slices/slicesNames';
import * as filters from './slices/filters/filterConstants';
import * as map from './slices/map/mapConstants';
import * as ui from './slices/ui/uiConstants';

const constants = {
  sliceNames,
  filters,
  map,
  ui,
};

export default constants;
