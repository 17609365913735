import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import { select } from 'store/toolkit';
import AddressLink from 'components/Profile/AddressLink';

export default function NearestLocationCell({ providerId }) {
  const dispatch = useDispatch();
  const nearestLocation = useSelector(select.provider(providerId).closestLocation);

  const handleAddressClick = useCallback(() => {
    dispatch(_analyticsActions.addressClickFromCompareModal(providerId));
  }, [dispatch, providerId]);

  if (!nearestLocation) return null;

  return <AddressLink onClick={handleAddressClick} address={nearestLocation} />;
}

NearestLocationCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
