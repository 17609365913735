import PropTypes from 'prop-types';
import { SUBSPECIALTY_TYPES, VALID_SCORE_TIERS } from 'utils/constants';

export default PropTypes;

export const SubspecialtyDataShape = PropTypes.shape({
  subspecialtyName: PropTypes.string.isRequired,
  subspecialtyScore: PropTypes.number,
  subspecialtyId: PropTypes.number.isRequired,
  subspecialtyType: PropTypes.oneOf(Object.values(SUBSPECIALTY_TYPES)).isRequired,
  subspecialtyScoreTier: PropTypes.oneOf(VALID_SCORE_TIERS),
});

const LaymansSubspecialtyDataShape = PropTypes.shape({
  subspecialtyName: PropTypes.string,
  subspecialtyId: PropTypes.number,
});

const LocationDataShape = PropTypes.shape({
  lon: PropTypes.number,
  lat: PropTypes.number,
});

const SuggestDataShape = PropTypes.shape({
  input: PropTypes.arrayOf(PropTypes.string),
  contexts: PropTypes.shape({
    loc: PropTypes.arrayOf(LocationDataShape),
  }),
});

export const ProviderLocationDataShape = PropTypes.shape({
  city: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  isWheelchairAccessible: PropTypes.bool,
  zipcode: PropTypes.string,
  productIds: PropTypes.arrayOf(PropTypes.string),
  phone: PropTypes.string,
  acceptingNewPatients: PropTypes.bool,
  name: PropTypes.string,
  specialization: PropTypes.string,
  location: LocationDataShape,
  state: PropTypes.string,
  fax: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const ProviderDataShape = PropTypes.shape({
  specialty: PropTypes.string,
  gender: PropTypes.string,
  yearsExperience: PropTypes.number,
  entityName: PropTypes.string,
  curatedOut: PropTypes.bool,
  scoreCareJourneyDiagnosing: PropTypes.number,
  outcareCompetent: PropTypes.bool,
  title: PropTypes.string,
  titleArray: PropTypes.arrayOf(PropTypes.string),
  subspecialties: PropTypes.arrayOf(SubspecialtyDataShape),
  scoringOrder: PropTypes.number,
  overallPercentile: PropTypes.number,
  groupAffiliations: PropTypes.arrayOf(PropTypes.string),
  medicalSchool: PropTypes.string,
  scoreCareJourneyOutcomes: PropTypes.number,
  diagnosingPercentile: PropTypes.number,
  languagesArray: PropTypes.arrayOf(PropTypes.string),
  qualityMetricValue: PropTypes.number,
  firstName: PropTypes.string,
  outcareUrl: PropTypes.string,
  benefitDecrease: PropTypes.bool,
  languages: PropTypes.string,
  healthPlan: PropTypes.string,
  npi: PropTypes.number,
  appropriatenessMetricValue: PropTypes.number,
  hospitalAffiliations: PropTypes.arrayOf(PropTypes.string),
  regionId: PropTypes.number,
  lastName: PropTypes.string,
  performanceMetricValue: PropTypes.number,
  scoreCareJourneyTreatment: PropTypes.number,
  laymansSubspecialties: PropTypes.arrayOf(LaymansSubspecialtyDataShape),
  scoreCareJourneyCost: PropTypes.number,
  suggest: PropTypes.arrayOf(SuggestDataShape),
  entityId: PropTypes.string,
  overallMetricValue: PropTypes.number,
  boardCertified: PropTypes.bool,
  outcomesPercentile: PropTypes.number,
  emboldEvaluated: PropTypes.bool,
  specialtyId: PropTypes.number,
  acceptingNewPatients: PropTypes.bool,
  scoreOverallQuality: PropTypes.number,
  treatmentPercentile: PropTypes.number,
  telehealthAvailable: PropTypes.bool,
  outcareCertified: PropTypes.bool,
  region: PropTypes.string,
  highPerforming: PropTypes.bool,
  costMetricValue: PropTypes.number,
  places: PropTypes.arrayOf(ProviderLocationDataShape),
  closestVisibleLocation: ProviderLocationDataShape,
});

export const PlaceDataShape = PropTypes.shape({
  city: PropTypes.string,
  npi: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  latitude: PropTypes.number,
  suggest: PropTypes.arrayOf(SuggestDataShape),
  type: PropTypes.string,
  specialty_name: PropTypes.string,
  subspecialties: PropTypes.arrayOf(LaymansSubspecialtyDataShape),
  is_wheelchair_accessible: PropTypes.bool,
  zipcode: PropTypes.string,
  type_display: PropTypes.arrayOf(PropTypes.string),
  product_ids: PropTypes.arrayOf(PropTypes.string),
  phone: PropTypes.string,
  specialty_id: PropTypes.number,
  name: PropTypes.string,
  facility_id: PropTypes.string,
  specialization: PropTypes.string,
  location: LocationDataShape,
  id: PropTypes.string,
  state: PropTypes.string,
  fax: PropTypes.string,
  longitude: PropTypes.number,
  distanceInMiles: PropTypes.arrayOf(PropTypes.number),
});

export const RefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const ChildrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const PromotionDataShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  client: PropTypes.string,
  programName: PropTypes.string.isRequired,
  programSubname: PropTypes.string,
  programDescription: PropTypes.string,
  detailedDescription: PropTypes.string,
  whenToUse: PropTypes.arrayOf(PropTypes.string),
  accessAppLink: PropTypes.string,
  accessChatLink: PropTypes.string,
  getStartedLink: PropTypes.string,
  accessEmail: PropTypes.string,
  pdfLink: PropTypes.string,
  costText: PropTypes.string,
  costBadgeText: PropTypes.string,
  ratingIcon: PropTypes.string,
  employeeRating: PropTypes.string,
  website: PropTypes.string,
  cardSubtext: PropTypes.string,
  phoneNumber: PropTypes.string,
  fax: PropTypes.string,
  costIcon: PropTypes.string,
  logo: PropTypes.string,
  badge1Text: PropTypes.string,
  badge2: PropTypes.string,
  badge2Text: PropTypes.string,
  badge3Text: PropTypes.string,
  telehealthAvailable: PropTypes.bool,
  weight: PropTypes.number,
  specialties: PropTypes.arrayOf(
    PropTypes.shape({
      specialtyId: PropTypes.number,
      specialtyName: PropTypes.string,
    })
  ),
  subspecialties: PropTypes.arrayOf(
    PropTypes.shape({
      subspecialtyId: PropTypes.number,
      subspecialtyName: PropTypes.string,
      description: PropTypes.string,
      isScored: PropTypes.bool,
    })
  ),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  radius: PropTypes.number,
  isPrimary: PropTypes.bool,
});

export const FilterMenuProps = {
  classes: PropTypes.shape({
    popupButton: PropTypes.string,
    button: PropTypes.string,
    popupButtonActiveFilter: PropTypes.string,
    filterButtonCount: PropTypes.string,
    modalOverrides: PropTypes.string,
    modalContent: PropTypes.string,
    toolbarHeader: PropTypes.string,
    distanceLabel: PropTypes.string,
    chip: PropTypes.string,
    sliderContainer: PropTypes.string,
    filterLabel: PropTypes.string,
    toolbarWrapper: PropTypes.string,
    toolbarContent: PropTypes.string,
    clearButton: PropTypes.string,
  }),
  chipOpen: PropTypes.bool,
  handleCloseChip: PropTypes.func,
};
