// More exports expected, this disabled can be deleted once another is added
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ENDPOINTS, PARAMS } from 'store/fusionServices/fusionConstants';

import { axiosInstance as selectAxios } from '../config/selectAxios';
import { slug as selectClientSlug } from '../config/selectClient';
import { currentSlug as selectNetworkSlug } from '../config/selectNetworks';
import { REWARDS_SLICE_NAME } from '../slicesNames';

export const fetchCampaign = createAsyncThunk(
  `${REWARDS_SLICE_NAME}/fetchCampaign`,
  async (arg, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    const axios = selectAxios(state);
    const clientSlug = selectClientSlug(state);
    const networkSlug = selectNetworkSlug(state);

    const url = `${ENDPOINTS.REWARDS_CAMPAIGN}/?${PARAMS.CLIENT_SLUG}=${clientSlug}&${PARAMS.NETWORK_SLUG}=${networkSlug}`;

    const response = await axios.get(url);

    return response.data;
  }
);
