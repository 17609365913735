import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M14.6678 15.506C14.6678 16.9657 13.4789 18.1514 12.0224 18.1514C10.5658 18.1514 9.37695 16.9657 9.37695 15.506C9.37695 14.0462 10.4447 13.2398 10.8431 12.9657C11.4805 7.59203 13.4885 2.26932 13.5108 2.21514C13.5714 2.05259 13.7467 1.96334 13.9124 2.01434C14.0781 2.06215 14.1801 2.22788 14.1451 2.4C14.1355 2.45737 12.9881 8.13705 13.208 12.9625C13.6351 13.2271 14.6678 14.0239 14.6678 15.506Z"
      fill="#FB8943"
    />
    <path
      d="M20 13.0577V20.6912C20 21.1565 19.6239 21.5326 19.1586 21.5326H4.84143C4.3761 21.5326 4 21.1565 4 20.6912V13.0577C4 12.5924 4.3761 12.2163 4.84143 12.2163H7.76096C8.27729 12.2163 8.69801 12.6338 8.69801 13.1502C8.69801 13.2617 8.5992 13.5518 8.57052 13.6219C8.25817 14.1956 8.09243 14.849 8.09243 15.5087C8.09243 17.6856 9.86454 19.4609 12.0446 19.4609C14.2247 19.4609 16 17.6856 16 15.5087C16 14.8522 15.8374 14.2052 15.5283 13.6314C15.4837 13.5295 15.3944 13.2585 15.3944 13.1502C15.3944 12.6338 15.8151 12.2163 16.3315 12.2163H19.1586C19.6239 12.2163 20 12.5924 20 13.0577Z"
      fill="#FFC75A"
    />
    <path
      d="M9.20159 9.1665H4.84143C4.37672 9.1665 4 9.54323 4 10.0079V10.2279C4 10.6926 4.37672 11.0693 4.84143 11.0693H9.20159C9.6663 11.0693 10.043 10.6926 10.043 10.2279V10.0079C10.043 9.54323 9.6663 9.1665 9.20159 9.1665Z"
      fill="#FFC75A"
    />
    <path
      d="M19.1582 9.1665H15.1295C14.6648 9.1665 14.2881 9.54323 14.2881 10.0079V10.2279C14.2881 10.6926 14.6648 11.0693 15.1295 11.0693H19.1582C19.6229 11.0693 19.9996 10.6926 19.9996 10.2279V10.0079C19.9996 9.54323 19.6229 9.1665 19.1582 9.1665Z"
      fill="#FFC75A"
    />
  </>,
  'Dermatology'
);
