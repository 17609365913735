import React from 'react';
import { Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '3rem',
    color: theme.palette.success.main,
    marginBottom: theme.spacing(1),
  },
}));

export default function SupportTicketSuccess() {
  const classes = useStyles();

  return (
    <>
      <CheckCircleOutline className={classes.icon} />
      <Typography variant="h3" component="strong">
        Your ticket was submitted successfully!
      </Typography>
    </>
  );
}
