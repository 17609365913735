import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import ProfileSection from 'components/ModernExperience/Profile/ProfileSection';
import { COST_EFFICIENCY_SECTION } from 'utils/constants';
import CostStepper from 'components/ModernExperience/Profile/ProviderProfile/CostStepper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
  },
  aboutText: {
    textAlign: 'center',
    margin: theme.spacing(1),
    maxWidth: 500,
  },
}));

export default function SectionCost({ providerId }) {
  const classes = useStyles();
  const showCost = useSelector(select.featureFlags.showCost);
  const { scoreCareJourneyCost: providerCostTier } =
    useSelector(select.provider(providerId).data) || {};

  if (!showCost || !providerCostTier) return null;

  return (
    <ProfileSection
      TitleProps={{
        title: COST_EFFICIENCY_SECTION.title,
        icon: <COST_EFFICIENCY_SECTION.IconComponent />,
        TooltipProps: {
          title: COST_EFFICIENCY_SECTION.title,
          message: COST_EFFICIENCY_SECTION.description,
        },
      }}
    >
      <Box className={classes.root}>
        <CostStepper costTier={providerCostTier} />

        <Typography className={classes.aboutText} variant="caption">
          More efficient providers tend to practice in lower cost facilities, order fewer tests and
          procedures, and have fewer office visits per patient on average.
        </Typography>
      </Box>
    </ProfileSection>
  );
}

SectionCost.propTypes = {
  providerId: PropTypes.string.isRequired,
};
