import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { select, actions, thunks } from 'store/toolkit';
import { addDistanceValuesAndSort } from 'store/slices/promotions/promoteUtils';

const usePromote = () => {
  const dispatch = useDispatch();
  const allPromotionsArray = useSelector(select.promotions.promotions) || []; // This || [] is needed for the App.test.jsx test to pass
  const isFetchingPromotions = useSelector(select.promotions.isLoading);
  const latLong = useSelector(select.location.latLong);

  const networkSlug = useSelector(select.networks.currentSlug);
  const { latitude, longitude } = latLong;

  /* ********************* */
  // fetch promotions on load or whenever networkSlug is changed
  /* ********************* */
  useEffect(() => {
    if (networkSlug) {
      dispatch(thunks.promotions.fetchPromotions());
    }
  }, [networkSlug, dispatch]);

  /* ********************* */
  // recalculate distances when latlong is changed
  /* ********************* */
  useEffect(() => {
    if (!isFetchingPromotions) {
      const updatedDistances = addDistanceValuesAndSort(allPromotionsArray, latitude, longitude);
      dispatch(actions.promotions.updatePromotions(updatedDistances));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [latitude, longitude, isFetchingPromotions, dispatch]);
};

export default usePromote;
