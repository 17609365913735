import React from 'react';
import PropTypes from 'prop-types';
import { selectCalculateScoreTier } from 'store/slices/results/selectProvider';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';

function ChatScoreTierPill({ provider }) {
  const showEmboldRecommended = useSelector(select.featureFlags.showEmboldRecommended);
  const clientName = useSelector(select.client.name);
  const scoreTier = selectCalculateScoreTier.resultFunc(
    showEmboldRecommended,
    clientName
  )(provider);

  if (!scoreTier) return null;

  const scoreTierPillProps = {
    label: scoreTier.text, // "Meets Standards etc"
    TooltipProps: {
      title: scoreTier.text,
      message: scoreTier.description,
    },
    inverted: scoreTier.inverted,
    color: scoreTier.color,
    icon: scoreTier.icon,
  };

  return <Pill {...scoreTierPillProps} />;
}

ChatScoreTierPill.propTypes = {
  provider: PropTypes.shape({}).isRequired,
};

export default ChatScoreTierPill;
