import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';

import { thunks } from 'store/toolkit';
import { ASSISTANT } from 'store/slices/chat/chatConstants';
import TextInput from 'components/Forms/TextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import CheckboxInput from 'components/Forms/CheckboxInput';

const useStyles = makeStyles((theme) => ({
  rejectedButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const formInitialValues = {
  content: '',
  isEmergency: false,
  end: false,
};

export default function AskEvaTool() {
  const classes = useStyles();
  const [errorText, setErrorText] = useState('');

  const dispatch = useDispatch();

  const handlePending = useCallback(() => dispatch(thunks.chat.askEva.pending()), [dispatch]);

  const handleErrorTextInput = useCallback((e) => setErrorText(e.target.value), [setErrorText]);

  const handleRejected = useCallback(() => {
    dispatch(thunks.chat.askEva.rejected(errorText));
    setErrorText('');
  }, [dispatch, errorText, setErrorText]);

  const handleSubmit = useCallback(
    async (values, formikTools) => {
      dispatch(
        thunks.chat.askEva.fulfilled({
          chatResponse: { content: values.content, role: ASSISTANT },
          end: values.end,
          isEmergency: values.isEmergency,
          parameters: {},
        })
      );

      formikTools.resetForm();
    },
    [dispatch]
  );

  const buttonProps = { size: 'small', variant: 'contained' };
  const gridContainers = { spacing: 1 };
  const textInputProps = { variant: 'standard', size: 'small' };

  return (
    <>
      {/* Pending */}
      <Grid container {...gridContainers}>
        <Grid
          item
          xs={12}
          component={Button}
          onClick={handlePending}
          color="secondary"
          {...buttonProps}
        >
          Dispatch Pending
        </Grid>
      </Grid>

      {/* Fulfilled */}
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        <Grid container component={Form} autoComplete="off" {...gridContainers}>
          <Grid item xs={12}>
            <CheckboxInput name="isEmergency" label="Emergency" CheckboxProps={{ size: 'small' }} />
            <CheckboxInput name="end" label="End" CheckboxProps={{ size: 'small' }} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            component={TextInput}
            name="content"
            label="Content"
            variant="standard"
            {...textInputProps}
          />
          <Grid item xs={12} sm={3} component={SubmitButton} {...buttonProps}>
            Dispatch Fulfilled
          </Grid>
        </Grid>
      </Formik>

      {/* Rejected */}
      <Grid container {...gridContainers}>
        <Grid
          item
          xs={12}
          sm={9}
          component={TextField}
          label="Error Message (payload)"
          value={errorText}
          onChange={handleErrorTextInput}
          {...textInputProps}
        />
        <Grid
          item
          component={Button}
          xs={12}
          sm={3}
          onClick={handleRejected}
          {...buttonProps}
          classes={{ root: classes.rejectedButton, hover: classes.rejectedButtonHover }}
        >
          Dispatch Rejected
        </Grid>
      </Grid>
    </>
  );
}
