import { createSlice } from '@reduxjs/toolkit';

import { fetchService } from './servicesThunks';
import { SERVICES_SLICE_NAME } from '../slicesNames';

const initialState = {
  isLoading: false,
  error: null,
  services: {},
};

const servicesSlice = createSlice({
  name: SERVICES_SLICE_NAME,
  initialState,
  reducer: {},
  extraReducers(builder) {
    builder
      .addCase(fetchService.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const serviceData = action.payload;

        state.services[serviceData.serviceId] = serviceData;
      });
  },
});

export default servicesSlice;
