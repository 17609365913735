import React from 'react';
import PropTypes from 'propTypes';

import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import { useSelector, useDispatch } from 'react-redux';
import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

import useLocationPhoneNumberOverride from 'hooks/useLocationPhoneNumberOverride';

import CardIconButton from './StyledWrappers/CardIconButton';

function CallButton({ providerId }) {
  const dispatch = useDispatch();
  const providerData = useSelector(select.provider(providerId).data);
  const closestLocation = useSelector(select.provider(providerId).closestLocation);
  const smDown = useSelector(select.ui.isSmDown);

  // TODO: Move this to a RTK selector
  const phoneNumber = useLocationPhoneNumberOverride(
    closestLocation.phone,
    providerData.clientFeatured
  );

  const handlePhoneClick = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${phoneNumber}`;

    dispatch(_analyticsActions.phoneClickFromResultCard(providerId));
  };

  return (
    <CardIconButton
      text={smDown ? 'Call' : phoneNumber}
      onClick={handlePhoneClick}
      icon={<CallOutlinedIcon color="secondary" />}
    />
  );
}

export default CallButton;

CallButton.propTypes = {
  providerId: PropTypes.string.isRequired,
};
