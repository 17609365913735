import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path d="M20.1273 11.0909C19.7091 7.3 16.7 4.29091 12.9091 3.87273V2H11.0909V3.87273C7.3 4.29091 4.29091 7.3 3.87273 11.0909H2V12.9091H3.87273C4.29091 16.7 7.3 19.7091 11.0909 20.1273V22H12.9091V20.1273C16.7 19.7091 19.7091 16.7 20.1273 12.9091H22V11.0909H20.1273ZM12 18.3636C8.48182 18.3636 5.63636 15.5182 5.63636 12C5.63636 8.48182 8.48182 5.63636 12 5.63636C15.5182 5.63636 18.3636 8.48182 18.3636 12C18.3636 15.5182 15.5182 18.3636 12 18.3636Z" />
    <path d="M14.7273 12C14.7273 13.5062 13.5062 14.7273 12 14.7273C10.4938 14.7273 9.27273 13.5062 9.27273 12C9.27273 10.4938 10.4938 9.27273 12 9.27273C13.5062 9.27273 14.7273 10.4938 14.7273 12Z" />
  </>,
  'FocusAreas'
);
