import { DEMO, DEFAULT_LOCATIONS, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';

const apiTokens = {
  dev: 'Token 72544c7d66f71d808ce57698ee3b1d3e3da7cc06',
  staging: 'Token 626a1d0c06bb79e7962a90a507f0c173c5f55291',
  prod: 'Token 736e081846985b1729bbfbf533e24fe320cf474b',
};

const token = selectToken(apiTokens);

const customerDemoConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'customerdemo',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'customerdemo',
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'customerdemo',

  // toggles
  SHOW_COST: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  CLOSED_ACCESS: true,

  // login
  LOGIN_NETWORK_NAME: 'customerDemoUserId',
  SHOW_REGION_SELECTOR: false,
  LOGIN_COOKIE_AUTH_REGEX: ['acmetechnology', 'i'],

  // other
  BENEFITS_CHANGE: false,
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },

  // content
  DISPLAY_NAME: 'ACME',
  SCHEDULE_PHONE_NUMBER: '800-555-1234',
  HEADER_LOGO: acmeHeaderLogo,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  FORM_VARIANT: CHECKMARK,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE:
    'ACME and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
};

export default customerDemoConfig;
