import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';

import CollapsingProfile from 'components/ModernExperience/Profile/CollapsingProfile';

export default function CollapsingProfileList({ resultIds = [], ...props }) {
  const [openIds, setOpenIds] = useState({});

  const handleClick = useCallback(
    (id) => {
      // before { 123: true } >>> handleClick(123) >>> after { 123: false }
      // before { 123: true } >>> handleClick(456) >>> after { 123: true, 456: true }
      setOpenIds((prevIdObj) => ({ ...prevIdObj, [id]: !prevIdObj[id] }));
    },
    [setOpenIds]
  );

  if (!resultIds?.length) return null;

  return (
    <List {...props}>
      {resultIds.map((id) => (
        <CollapsingProfile
          key={`profile-list-item-${id}`}
          id={id}
          isOpen={!!openIds[id]}
          onClick={handleClick}
          wrapper={ListItem}
        />
      ))}
    </List>
  );
}

CollapsingProfileList.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CollapsingProfileList.defaultProps = {};
