import { select, actions } from 'store/toolkit';
import { UI_INTERACTION, CHAT_VIEW } from './analyticsConstants';

/* ************************************* */
/* *** 400-499 UI Interaction Events *** */
/* ************************************* */

export default {
  tooltipOpened: {
    code: 400,
    category: UI_INTERACTION,
    description: 'A user opened a tooltip to get more information',
    getEventData: (state) => ({
      title: select.tooltip.getTitle(state),
      message: select.tooltip.getMessage(state),
    }),
    triggers: [actions.tooltip.open],
  },

  chatModalOpened: {
    code: 401,
    category: CHAT_VIEW,
    description: 'A user has opened the chat modal',
    getEventData: () => ({
      eventAction: 'Chat Modal Opened',
      eventLabel: 'Chat Modal Opened',
    }),
    labelDescription: 'Chat Modal Opened',
    actionDescription: 'Chat Modal Opened',
  },
};
