/* eslint-disable camelcase */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import useFilterOptionsQuery from './useFilterOptionsQuery';

/** TODO RTK This hook is a temporary use hook for generating the query string for the /result-count/ endpoint. Eventually once all state
 * has been migrated from context to Redux, this hook should be deprecated in favor of a selector.
 */
export default function useResultCountQuery() {
  const baseQueryString = useFilterOptionsQuery();
  const isProviderSearch = useSelector(select.search.isProviderSearch);
  const providerFilterQuery = useSelector(select.filters.providerFiltersAsQueryParams);
  const placeFilterQuery = useSelector(select.filters.placeFiltersAsQueryParams);

  const filterQuery = isProviderSearch ? providerFilterQuery : placeFilterQuery;

  const resultCountQuery = useMemo(() => {
    if (!baseQueryString) return undefined;
    if (filterQuery) return `${baseQueryString}&${filterQuery}`;
    return baseQueryString;
  }, [baseQueryString, filterQuery]);

  return resultCountQuery;
}
