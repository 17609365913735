/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, ENSEMBLE, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import ensembleLogo from 'assets/ensemble-logo.png';

const apiTokens = {
  dev: 'Token 850ea3a3dd2f3d7eddcfc7d5e57dc5c3d539fcd1',
  staging: 'Token 3fdb3f99bf04a82ba9dd0e90814144bec98b5ad4',
  prod: 'Token bfc5c31204c281a50a2517a40f1e3a9f0e0d4730',
};

const token = selectToken(apiTokens);

const ensembleConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'ensemblehp',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: ENSEMBLE,
  EMBOLD_CLIENT: ENSEMBLE,
  EMBOLD_CLIENT_SLUG: ENSEMBLE,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: [
    '^(anthembroadnetworkhdhp|anthembroadnetworkepo|nthemlimitednetworkepo|healthcarehighwaysnetworkppo|imaginehealthnetworkppo)$',
  ],

  // other
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Cincinnati },
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'ensembleUserId',

  // content
  DISPLAY_NAME: 'Ensemble',
  SCHEDULE_PHONE_NUMBER: '866-871-0946',
  HEADER_LOGO: ensembleLogo,
  WELCOME_MESSAGE:
    'Ensemble and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: ensembleLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default ensembleConfig;
