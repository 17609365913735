import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { Grid, MenuItem, Container } from '@material-ui/core';
import { getToday } from 'utils/utils';
import usStates from 'utils/usStates';
import { Form, useFormikContext, useField } from 'formik';
import useThemeColor from 'hooks/useThemeColor';

import TextInput from '../TextInput';
import {
  CITY,
  CONFIRM_DETAILS_CHECKBOX,
  CONFIRM_EMAIL,
  DATE_OF_VISIT,
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_EMAIL,
  SELECTED_PROVIDER,
  STATE,
  VISITED_TIME_FRAME,
  VISITED_TIME_FRAME_OPTIONS,
  ZIP,
} from './redeemRewardForm.schema';
import RadioGroupInput from '../RadioGroupInput';
import CheckboxInput from '../CheckboxInput';
import ConfirmDetailsText from './ConfirmDetailsText';
import SubmitButton from '../SubmitButton';

const useFormStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      '& fieldset': {
        borderRadius: theme.shape.borderRadius * 3,
      },
      '& .MuiSelect-root': {
        borderRadius: theme.shape.borderRadius * 3,
      },
    },
  },
  greyContainer: {
    background: theme.palette.grey[100],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    borderRadius: 10,
  },
  inputRow: {
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiGrid-item:first-child': {
      paddingLeft: 0, // on the first input of a row, remove padding at the start that is set by the spacing prop
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-item': { padding: 0 }, // on xs screens, the spacing prop makes inputs inside the grid a different width from the rest, so override this
    },
  },
  checkboxRow: {
    paddingTop: theme.spacing(2),
  },
  stateField: {
    background: 'transparent',
    '&:focus': {
      background: 'transparent',
    },
  },
  /* RADIO GROUP STYLES */
  // options & label wrapper
  radioForm: {
    marginTop: -12,
  },
  // options wrapper
  radioGroup: {
    padding: 0,
  },
  // group label
  radioFormLabel: {
    textTransform: 'none',
    fontWeight: 400,
    margin: 0,
    fontSize: '.7rem', // ~11px
  },
  // option label
  radioItemLabel: {
    marginTop: 4,
    marginRight: 0,
  },
  // option label text
  radioItemLabelText: {
    fontSize: '.75rem',
    fontWeight: 700,
  },
  // button icon wrapper
  radioButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  /* END RADIO GROUP STYLES */
  submitButton: {
    fontSize: '1rem',
  },
}));

function RedeemRewardsFormFields({ providerNameAndTitle, disabled }) {
  const classes = useFormStyles();
  const { values, handleChange, setFieldValue, isSubmitting } = useFormikContext();

  const formBackground = useThemeColor('grey')[100];

  const today = getToday();
  const todayLastYear = getToday({ offsetYear: -1 }); // @TODO: should we actually force them to a year range?

  // radio button value === "future"
  const isFutureVisit = useMemo(
    () => values[VISITED_TIME_FRAME] === VISITED_TIME_FRAME_OPTIONS[1].value,
    [values]
  );

  const dateConstraint = useMemo(
    () => (isFutureVisit ? { min: today } : { min: todayLastYear, max: today }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFutureVisit]
  );

  const [, , helpers] = useField(DATE_OF_VISIT);
  const resetDateField = useCallback(() => {
    helpers.setTouched(false);
    setFieldValue(DATE_OF_VISIT, '');
  }, [helpers, setFieldValue]);

  useEffect(
    () => setFieldValue(SELECTED_PROVIDER, providerNameAndTitle),
    [setFieldValue, providerNameAndTitle]
  );

  return (
    <Form className={classes.root} noValidate>
      <Container classes={{ root: classes.greyContainer }}>
        <Grid container className={classes.inputRow} spacing={1}>
          <Grid item xs={12} sm={5}>
            <TextInput
              bgColor={formBackground}
              fullWidth
              required
              name={SELECTED_PROVIDER}
              label="Selected Provider"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <RadioGroupInput
              name={VISITED_TIME_FRAME}
              label="Select one:"
              options={VISITED_TIME_FRAME_OPTIONS}
              FormControlProps={{ classes: { root: classes.radioForm }, disabled }}
              classes={{ root: classes.radioGroup }}
              FormLabelProps={{ classes: { root: classes.radioFormLabel } }}
              FormControlLabelProps={{
                classes: { root: classes.radioItemLabel, label: classes.radioItemLabelText },
              }}
              RadioProps={{ classes: { root: classes.radioButton } }}
              onChange={(e) => {
                resetDateField();
                handleChange(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput
              bgColor={formBackground}
              fullWidth
              required
              name={DATE_OF_VISIT}
              label="Date of Visit"
              type="date"
              disabled={disabled}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ ...dateConstraint }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.inputRow} spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInput
              bgColor={formBackground}
              name={FIRST_NAME}
              label="First Name"
              required
              fullWidth
              disabled={disabled}
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              bgColor={formBackground}
              name={LAST_NAME}
              label="Last Name"
              required
              fullWidth
              disabled={disabled}
              autoComplete="family-name"
            />
          </Grid>
        </Grid>

        <Grid container className={classes.inputRow} spacing={1}>
          <Grid item xs={12}>
            <TextInput
              bgColor={formBackground}
              name={PREFERRED_EMAIL}
              label="Preferred Email"
              fullWidth
              required
              disabled={disabled}
              autoComplete="email"
              inputProps={{
                autoCapitalize: 'off', // This prevents iOS safari from auto-capitalizing email
              }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.inputRow} spacing={1}>
          <Grid item xs={12}>
            <TextInput
              bgColor={formBackground}
              name={CONFIRM_EMAIL}
              label="Confirm Email"
              fullWidth
              required
              disabled={disabled}
              autoComplete="email"
              inputProps={{
                autoCapitalize: 'off', // This prevents iOS safari from auto-capitalizing email
              }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.inputRow} spacing={1}>
          <Grid item xs={12} sm={7}>
            <TextInput
              bgColor={formBackground}
              name={CITY}
              label="City"
              fullWidth
              autoComplete="address-level2"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextInput
              select
              bgColor={formBackground}
              name={STATE}
              disabled={disabled}
              label="State"
              fullWidth
              SelectProps={{
                classes: { root: classes.stateField },
              }}
              inputProps={{ autoComplete: 'address-level1' }}
              aria-label="collapsed" // Screen reader will only read this label when focused on the input and the options flyout is collapsed
            >
              {usStates.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextInput>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput
              name={ZIP}
              bgColor={formBackground}
              label="Zip"
              fullWidth
              disabled={disabled}
              type="tel" // This is to make mobile devices open a numeric keyboard, but not affect any input validation
              inputProps={{ maxLength: 5 }}
              autoComplete="postal-code"
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container className={`${classes.inputRow} ${classes.checkboxRow}`} spacing={1}>
          <CheckboxInput
            name={CONFIRM_DETAILS_CHECKBOX}
            label={<ConfirmDetailsText />}
            disabled={disabled}
          />
        </Grid>

        <SubmitButton classes={{ root: classes.submitButton }} disabled={disabled || isSubmitting}>
          Submit Reward Redemption Form
        </SubmitButton>
      </Container>
    </Form>
  );
}

RedeemRewardsFormFields.propTypes = {
  providerNameAndTitle: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};
RedeemRewardsFormFields.defaultProps = {
  providerNameAndTitle: '',
};

export default RedeemRewardsFormFields;
