/**
 * @typedef {Object} responseData
 * @property {number} count The total number of results from a query
 * @property {string|null} next The url of the next page of results
 * @property {string|null} previous The url of the previous page of results
 * @property {object[]} results The array of provider or place objects
 */

/**
 * @typedef {object} singleProviderResponseData
 */

/**
 *
 * @typedef {Object} payloadResponse
 * @property {number} count The total number of results from a query
 * @property {string|null} next The url of the next page of results
 * @property {object} results The object that stores the results indexed by an id
 * @property {string[]} resultIdList The ordered list of id's in the results object
 */

/**
 * Single provider responses that come from the "/providers/{id}" endpoint have a different response shape.
 * This function should alter the response to match the same object shape as a multiple provider response.
 * @param {singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSingleProviderResponseData(responseData) {
  return {
    count: 1,
    filterCounts: {},
    next: null,
    previous: null,
    resultIdList: [responseData.entityId],
    results: {
      [responseData.entityId]: responseData,
    },
  };
}

/**
 * Single provider responses that come from the "/providers/{id}" endpoint have a different response shape.
 * This function should alter the response to match the same object shape as a multiple provider response.
 * @param {singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSinglePlaceResponseData(responseData) {
  return {
    count: 1,
    filterCounts: {},
    next: null,
    previous: null,
    resultIdList: [responseData.id],
    results: {
      [responseData.id]: responseData,
    },
  };
}

/**
 * This function takes the response data from the api formats it to a response object that is used by the Redux reducer.
 * It's primary responsibility is to take the array of results, and store them as an object indexed by id, and an ordered
 * resultsIdList.
 * @param {responseData|singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSearchResponseData(responseData) {
  if (!responseData.results) {
    // if there is no results property but there is an entityId property, this would indicate that this is responseData for a single provider
    if (responseData.entityId) return parseSingleProviderResponseData(responseData);
    // if there is no results property but there is an id property, this would indicate that this is responseData for a single place
    if (responseData.id) return parseSinglePlaceResponseData(responseData);
  }
  // destructure the properties from the response that we wish to utilize
  const { count = 0, filterCounts = {}, next = null, results: resultsArray = [] } = responseData;

  const resultIdList = []; // array to store the ordered list of ID's
  const results = {}; // object to store the results (providers or place objects), indexed by ID

  // iterate over each result in the response and add to the ordered list, and the results object
  for (const result of resultsArray) {
    if (result.id) {
      // must be a place object (provider's do not have an id property), so index by id
      results[result.id] = result;
      resultIdList.push(result.id);
    } else {
      // must be a provider, so index by entityId
      results[result.entityId] = result;
      resultIdList.push(result.entityId);
    }
  }

  return { count, filterCounts, next, resultIdList, results };
}

/**
 * Is provided pathname sting the results page pathname
 * @param {string} pathname
 * @returns {bool}
 */
export const isResultsPathname = (pathname) => pathname === '/results';
