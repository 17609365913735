import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getBounds, logDevMessage } from 'utils/utils';
import { isValidCoords, getBoundingBoxFromCoordinatesList } from 'utils/mapUtils';
import {
  mapLocationClicked,
  mapScreenReaderLocationFocused,
  showClusterResults,
  startOver,
  updateStoreFromUrl,
} from 'store/appActions';
import { MAP_SLICE_NAME } from '../slicesNames';
import {
  MIN_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  ZOOM_INCREMENT,
  DEFAULT_ZOOM_LEVEL,
  OFFSET_SHIFT_IN_PX,
  DEFAULT_BOUNDS_PADDING_IN_PX,
} from './mapConstants';
import { reframeBoundsToActiveMarker } from './mapUtils';
import { searchThisArea } from '../results/resultsThunks';

const initialState = {
  map: {
    /* This object should never be updated by a user action */
    center: { latitude: null, longitude: null },
    bounds: {
      sw: { latitude: null, longitude: null },
      ne: { latitude: null, longitude: null },
    },
    zoom: null,
    isLoading: false,
    isMounted: false,
  },

  pendingCameraUpdates: {
    /*
      for valid properties that can be added to this, see microsoft docs
      camera options: https://learn.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraoptions?view=azure-maps-typescript-latest
      camera bound options: https://learn.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraboundsoptions?view=azure-maps-typescript-latest
    */
  },

  isOffset: false, // indicates that the bounds have been shifted down
  hasMoved: false,
  showActiveResultLocations: false,
};

function fitBoundsToCoordinatesList(state, action) {
  const { coordinates, padding = DEFAULT_BOUNDS_PADDING_IN_PX, offset = false } = action.payload;

  if (!Array.isArray(coordinates))
    throw new Error('fitBoundsToCoordinatesList received a payload that is not an array');

  // get new bounds
  const newBounds = getBoundingBoxFromCoordinatesList(coordinates);

  const cameraUpdates = {
    bounds: [
      newBounds.sw.longitude,
      newBounds.sw.latitude,
      newBounds.ne.longitude,
      newBounds.ne.latitude,
    ],
    padding,
    type: 'fly',
    duration: 300,
  };
  if (offset) cameraUpdates.cameraOffset = [0, OFFSET_SHIFT_IN_PX];
  // send new bounds to map
  state.hasMoved = false;
  state.pendingCameraUpdates = cameraUpdates;
}

function updateCameraToBounds(state, bounds, offset = false) {
  const { sw, ne } = bounds;

  // build camera changes
  const cameraUpdates = {
    bounds: [sw.longitude, sw.latitude, ne.longitude, ne.latitude],
    type: 'fly',
    duration: 800,
  };
  if (offset) cameraUpdates.cameraOffset = [0, OFFSET_SHIFT_IN_PX];

  // if coords are valid, then move the camera
  if (isValidCoords(sw.latitude, sw.longitude) && isValidCoords(ne.latitude, ne.longitude)) {
    state.pendingCameraUpdates = cameraUpdates;
    state.isOffset = offset;
  } else {
    logDevMessage(`Invalid coordinates`);
  }
}

const mapSlice = createSlice({
  name: MAP_SLICE_NAME,
  initialState,
  reducers: {
    /* *********************************************** */
    /* *** Actions that update Redux on Map Events *** */
    /* *********************************************** */
    mounted(state) {
      state.map.isLoading = true;
      state.map.isMounted = true;
    },
    unmounted(state) {
      state.map.isMounted = false;
    },
    loaded(state, action) {
      state.map.isLoading = false;

      const { zoom, center, sw, ne } = action.payload;
      state.map.bounds.sw = sw;
      state.map.bounds.ne = ne;
      state.map.center = center;
      state.map.zoom = zoom;
    },
    moved(state, action) {
      const { zoom, center, sw, ne } = action.payload;
      state.map.bounds.sw = sw;
      state.map.bounds.ne = ne;
      state.map.center = center;
      state.map.zoom = zoom;
      state.pendingCameraUpdates = {};
    },
    movedByUser(state) {
      state.isOffset = false;
      state.hasMoved = true;
    },
    /* ******************************************** */
    /* *** Actions that send updates to the map *** */
    /* ******************************************** */
    zoomIn(state) {
      if (state.map.zoom && state.map.zoom < MAX_ZOOM_LEVEL) {
        state.pendingCameraUpdates = { zoom: state.map.zoom + ZOOM_INCREMENT };
        state.hasMoved = true;
      }
    },
    zoomOut(state) {
      if (state.map.zoom && state.map.zoom > MIN_ZOOM_LEVEL) {
        state.pendingCameraUpdates = { zoom: state.map.zoom - ZOOM_INCREMENT };
        state.hasMoved = true;
      }
    },
    resetZoom(state) {
      state.pendingCameraUpdates = { zoom: DEFAULT_ZOOM_LEVEL };
    },
    moveCenterTo(state, action) {
      const { latitude, longitude, offset = false, zoom = state.map.zoom } = action.payload;

      // build camera move
      const cameraUpdates = { center: [longitude, latitude], zoom };
      if (offset) cameraUpdates.centerOffset = [0, OFFSET_SHIFT_IN_PX];

      if (isValidCoords(latitude, longitude)) {
        state.pendingCameraUpdates = cameraUpdates;
        state.isOffset = offset;
      } else {
        logDevMessage(`Invalid coordinates ${latitude}, ${longitude}`);
      }
    },
    moveBoundsTo(state, action) {
      const { bounds, offset = false } = action.payload;
      updateCameraToBounds(state, bounds, offset);
    },
    toggleOffset(state) {
      const offset = state.isOffset ? -OFFSET_SHIFT_IN_PX : OFFSET_SHIFT_IN_PX;

      state.isOffset = !state.isOffset;
      state.pendingCameraUpdates = {
        centerOffset: [0, offset],
        duration: 200,
      };
    },
    expandCluster(state, action) {
      fitBoundsToCoordinatesList(state, action);
    },
    updateBoundsToFitResults(state, action) {
      fitBoundsToCoordinatesList(state, action);
    },
    fitBoundsToResults(state, action) {
      const { results, mileage = 0.5, padding = 0.2 } = action.payload;

      if (!results || !Array.isArray(results) || !results.length) {
        throw new Error('Invalid results. Must be array with at least one element');
      }

      // bounds returned from getBounds are [lng, lat, lng, lat]
      const bounds = getBounds(results, mileage, padding);

      state.pendingCameraUpdates = { bounds };
    },
    resetMapHasMoved(state) {
      state.hasMoved = false;
    },
    setShowActiveResultLocations(state, action) {
      state.showActiveResultLocations = Boolean(action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(startOver, (state, action) => {
      const { lastLocation = {} } = action.payload;
      const { latLong } = lastLocation;

      const cameraUpdates = { zoom: DEFAULT_ZOOM_LEVEL };
      if (isValidCoords(latLong?.latitude, latLong?.longitude)) {
        cameraUpdates.center = [latLong.longitude, latLong.latitude];
      }
      state.pendingCameraUpdates = cameraUpdates;
    });

    // url direct search
    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const { bounding_box, location } = action.payload;
      /* Note, this action is antithetical to the practice of NOT updating the map object from anything but the map event.
       * However, this is necessary to do for a URL direct search. When a bounding box search is executed it is looking for
       * the bounds from the map's state. However, on a refresh, the map does not instantly move and therefore when the URL direct
       * search gets executed, there would be no bounds to use. So with this action, we set our "artificial" map bounds for the
       * search to take place, then when the results are returned the map will auto fit to those results and the map's real
       * bounds will be updated.
       */
      if (bounding_box) {
        const bounds = bounding_box;
        state.map.bounds = bounds;
        if (location) {
          // location param was passed so use it to set map center
          state.map.center = location;
        } else {
          // older links may not include the location param, but we can still calculate a map center given the bounds
          const latitude = (bounding_box.ne.latitude + bounding_box.sw.latitude) / 2;
          const longitude = (bounding_box.ne.longitude + bounding_box.sw.longitude) / 2;
          state.map.center = { latitude, longitude };
        }

        // update the camera to move the map to the bounding box
        updateCameraToBounds(state, bounds);
      }
    });

    builder
      .addCase(mapScreenReaderLocationFocused, (state, action) => {
        const { latitude, longitude } = action.payload;

        if (isValidCoords(latitude, longitude))
          state.pendingCameraUpdates = { center: [longitude, latitude] };
      })
      .addCase(searchThisArea.pending, (state) => {
        state.hasMoved = false;
      })
      .addMatcher(isAnyOf(showClusterResults, mapLocationClicked), (state, action) => {
        const { latitude, longitude, bounds, mapHeight } = action.payload;
        // dont move map if the needed data wasn't passed properly for any reason
        if (!bounds || !isValidCoords(latitude, longitude) || !mapHeight) return;

        const reframedBounds = reframeBoundsToActiveMarker({
          latitude,
          longitude,
          bounds,
          mapHeight,
        });
        // dont move the map if the reframing computation fails
        if (!reframedBounds) return;
        const { sw, ne } = reframedBounds;

        const cameraUpdates = {
          bounds: [sw.longitude, sw.latitude, ne.longitude, ne.latitude],
          type: 'fly',
          duration: 300,
        };

        state.pendingCameraUpdates = cameraUpdates;
      });
  },
});

export default mapSlice;
export const {
  mounted,
  unmounted,
  loaded,
  moved,
  movedByUser,
  zoomIn,
  zoomOut,
  resetZoom,
  moveCenterTo,
  moveBoundsTo,
  toggleOffset,
  fitBoundsToResults,
  resetMapHasMoved,
  setShowActiveResultLocations,
  expandCluster,
  updateBoundsToFitResults,
} = mapSlice.actions;
