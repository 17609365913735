import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'propTypes/index';
import clsx from 'clsx';
import { actions, select } from 'store/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useFormikContext, Form } from 'formik';

import { Collapse, Typography, Grid, makeStyles, Link } from '@material-ui/core';

import CheckboxInput from 'components/Forms/CheckboxInput';
import SubmitButton from 'components/Forms/SubmitButton';

import ScenarioDropdown from './ScenarioDropdown';
import {
  MONITORING_CHECKBOX,
  SCENARIO_ID,
  TERMS_CHECKBOX,
} from './useChatAcknowledgementFormSchema';
import ChatDisclaimer from './ChatDisclaimer';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    rowGap: 5,
    background: theme.palette.common.white,
    borderRadius: 18,
    padding: theme.spacing(2.5),
    border: `1px solid ${theme.palette.common.white}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  text: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  checkboxLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  continueButton: {
    maxWidth: 140,
    marginTop: theme.spacing(1.5),
  },
}));

function ChatAcknowledgementFormFields({ isError }) {
  const classes = useStyles();
  const [expandedDisclaimer, setExpandedDisclaimer] = useState(false);
  const chatKey = useSelector(select.chat.chatKey);
  const enableChatScenario = useSelector(select.featureFlags.enableChatScenario);
  const dispatch = useDispatch();
  const closeChat = useCallback(() => {
    dispatch(actions.ui.closeModal('chat'));
  }, [dispatch]);

  const { values, resetForm } = useFormikContext();

  // reset to initial values on every new chat
  useEffect(() => {
    setExpandedDisclaimer(false);
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatKey]);

  const validScenarioId = useMemo(
    () => !enableChatScenario || values[SCENARIO_ID],
    [enableChatScenario, values]
  );

  const disableSubmit = useMemo(
    () => !values[TERMS_CHECKBOX] || !values[MONITORING_CHECKBOX] || !validScenarioId,
    [validScenarioId, values]
  );

  const toggleShowFullDisclaimer = (e) => {
    e.preventDefault();
    setExpandedDisclaimer((prev) => !prev);
  };

  return (
    <Grid
      component={Form}
      noValidate
      container
      direction="column"
      classes={{
        root: clsx(classes.disclaimer, {
          [classes.error]: isError,
        }),
      }}
    >
      {isError && (
        <Typography color="error" aria-live="polite">
          <strong>Please agree to the terms before starting your chat</strong>
        </Typography>
      )}
      <Typography classes={{ root: classes.text }}>
        <strong>Disclaimer:</strong> The virtual assistant is not intended to diagnose, treat, cure,
        or prevent any disease or medical condition. You should always consult a healthcare
        professional if you have any questions regarding a medical condition.{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          aria-expanded={expandedDisclaimer}
          component="button"
          onClick={toggleShowFullDisclaimer}
          style={{ verticalAlign: 'baseline' }}
        >
          {expandedDisclaimer ? 'Hide' : 'Show'} full disclaimer.
        </Link>
      </Typography>
      <Collapse in={expandedDisclaimer}>
        <ChatDisclaimer />
      </Collapse>
      <CheckboxInput
        name={TERMS_CHECKBOX}
        label={
          <Typography classes={{ root: classes.checkboxLabel }}>
            I agree to the{' '}
            <Link component={RouterLink} onClick={() => closeChat()} to="/privacy#beta-testing">
              Privacy Statement
            </Link>{' '}
            and{' '}
            <Link component={RouterLink} onClick={() => closeChat()} to="/terms#beta-testing">
              Terms of Use
            </Link>
            .
          </Typography>
        }
      />
      <CheckboxInput
        name={MONITORING_CHECKBOX}
        label={
          <Typography classes={{ root: classes.checkboxLabel }}>
            I consent to the monitoring and recording of this chat by Embold Health [and its
            third-party service provider, Microsoft], [for quality and training purposes and
            purposes of responding to your questions and requests] according to Embold’s{' '}
            <Link component={RouterLink} onClick={() => closeChat()} to="/privacy#beta-testing">
              Privacy Statement
            </Link>{' '}
            and{' '}
            <Link component={RouterLink} onClick={() => closeChat()} to="/terms#beta-testing">
              Terms of Use
            </Link>
            .
          </Typography>
        }
      />
      <ScenarioDropdown />

      <SubmitButton classes={{ root: classes.continueButton }} disabled={disableSubmit}>
        Continue
      </SubmitButton>
    </Grid>
  );
}

export default ChatAcknowledgementFormFields;

ChatAcknowledgementFormFields.propTypes = {
  isError: PropTypes.bool,
};
ChatAcknowledgementFormFields.defaultProps = {
  isError: false,
};
