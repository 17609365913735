import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core/';
import ProfileSectionTitle from '../Profile/ProfileSectionTitle';

const useStyles = makeStyles((theme) => ({
  sectionTitleCell: {
    border: 'none',
    paddingBottom: 0,
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  sectionTitle: {
    marginLeft: -16,
  },
  paymentCell: {
    paddingTop: 0,
    lineHeight: '1',
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
    },
    width: '12.5%',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
  },
  treatmentCategoryCell: {
    width: '100%',
  },
}));

const CATCHALL_SERVICE = 'Other Treatments';

export default function ServiceCostTableHead({ service }) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.sectionTitleCell}>
          <ProfileSectionTitle
            title={service.name || CATCHALL_SERVICE}
            TooltipProps={{
              message: service.descriptionPlainLanguage,
              title: service.name,
            }}
            additionalStyles={classes.sectionTitle}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell className={classes.paymentCell} align="center">
          Plan Pays
        </TableCell>
        <TableCell className={classes.paymentCell} align="center">
          You Pay
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

ServiceCostTableHead.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    descriptionPlainLanguage: PropTypes.string,
  }).isRequired,
};
