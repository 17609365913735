import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';

function ResponsiveIconButton({
  children,
  startIcon,
  endIcon,
  iconOnly,
  MuiIconButtonProps,
  MuiButtonProps,
  // eslint-disable-next-line react/prop-types
  innerRef,
  ...props
}) {
  if (iconOnly) {
    return (
      <IconButton aria-label={children} {...MuiIconButtonProps} ref={innerRef} {...props}>
        {startIcon || endIcon}
      </IconButton>
    );
  }

  return (
    <Button startIcon={startIcon} endIcon={endIcon} {...MuiButtonProps} ref={innerRef} {...props}>
      {children}
    </Button>
  );
}

ResponsiveIconButton.propTypes = {
  iconOnly: PropTypes.bool,
  children: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  MuiIconButtonProps: PropTypes.shape({}), // see https://v4.mui.com/api/icon-button/#props
  MuiButtonProps: PropTypes.shape({}), // see https://v4.mui.com/api/button/#props
};

ResponsiveIconButton.defaultProps = {
  iconOnly: false,
  startIcon: null,
  endIcon: null,
  MuiIconButtonProps: {},
  MuiButtonProps: {},
};

/**
 * This component by default returns a standard Mui Button component.
 * If the iconOnly prop is truthy, it will return an IconButton component using the startIcon or endIcon.
 * The children, will be the text for the Button or the aria-label for IconButton
 *
 * MuiButtonProps are passed only to the Button.
 * MuiIconButtonProps are passed only to the IconButton.
 *
 * All other props are forwarded to the root component
 */
export default React.forwardRef((props, ref) => <ResponsiveIconButton {...props} innerRef={ref} />);
