import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, Divider, Typography, Grid, Button, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics';
import { createProviderCostKey } from 'store/slices/cost/costUtils';
import useProviderCost from 'hooks/Provider/useProviderCost';

import { withStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ErrorIcon from '@material-ui/icons/Error';

import ServiceCostTableHead from './ServiceCostTableHead';
import ServiceCostRow from './ServiceCostRow';

const MIN_HEIGHT = 230;
const LoadingStateContainer = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: MIN_HEIGHT,
  },
})(Box);

function TableSkeleton() {
  return (
    <LoadingStateContainer>
      <Skeleton variant="text" width="50%" height={MIN_HEIGHT * 0.25} />
      <Skeleton variant="rect" width="100%" height={MIN_HEIGHT * 0.75} />
    </LoadingStateContainer>
  );
}

function CostError({ error, onRetry }) {
  const showRetry = typeof onRetry === 'function';

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={1}
      component={LoadingStateContainer}
    >
      <Grid item>
        <ErrorIcon fontSize="large" color="error" />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="error" align="center" gutterBottom>
          {error}
        </Typography>
      </Grid>
      {showRetry && (
        <Grid item component={Button} onClick={onRetry} variant="contained" color="primary">
          Retry
        </Grid>
      )}
    </Grid>
  );
}

CostError.propTypes = { error: PropTypes.node, onRetry: PropTypes.func };
CostError.defaultProps = { error: null, onRetry: undefined };

function CostNoResults() {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={1}
      component={LoadingStateContainer}
    >
      <Grid item>
        <FindInPageIcon fontSize="large" color="action" />
      </Grid>
      <Grid item component={Typography} variant="h4" align="center">
        No service data available for this location
      </Grid>
    </Grid>
  );
}

export default function ServiceCostTable({ npi, location, isDebouncing, analyticsView, ...props }) {
  const dispatch = useDispatch();
  const {
    data: services,
    isLoading,
    isError,
    error,
    fetchProviderCost,
    invalidateData,
  } = useProviderCost(npi, location);
  const { locationId } = location;
  const currentServiceCost = useSelector(
    select.cost.currentServiceByKey(createProviderCostKey({ npi, locationId }))
  );
  const serviceId = useSelector(select.search.serviceId);
  const userHasMetOutOfPocketMaximum = useSelector(select.ui.userHasMetOutOfPocketMaximum);

  const isWaitingToFetch = useMemo(
    () => isDebouncing && !services && !isLoading && !isError,
    [isDebouncing, isError, isLoading, services]
  );

  const sortedArray = useMemo(() => {
    if (currentServiceCost) return currentServiceCost;
    if (!Array.isArray(services)) return [];

    const arrayCopy = [...services];

    return arrayCopy.sort((a, b) => {
      if (a.serviceName < b.serviceName) return -1;
      if (a.serviceName > b.serviceName) return 1;
      return 0;
    });
  }, [services, currentServiceCost]);

  const handleRetry = useCallback(() => {
    invalidateData();
    fetchProviderCost();
  }, [fetchProviderCost, invalidateData]);

  useEffect(() => {
    // Event for viewing or re-viewing cost data
    if ((services && !isLoading) || isError) {
      dispatch(
        _analyticsActions.providerServiceCostView({
          npi,
          data: sortedArray,
          locationId,
          serviceId,
          view: analyticsView,
          isError,
          userHasMetOutOfPocketMaximum,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, isLoading]);

  if (isLoading || isWaitingToFetch) return <TableSkeleton />;
  if (isError) return <CostError error={error} onRetry={handleRetry} />;
  if (!services) return <LoadingStateContainer />;
  if (sortedArray.length === 0) return <CostNoResults />;

  return (
    <TableContainer {...props}>
      {sortedArray.map((service, i) => (
        <React.Fragment key={service.serviceId}>
          <Table>
            <ServiceCostTableHead service={service} />
            <ServiceCostRow service={service} />
          </Table>
          {i < services.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </TableContainer>
  );
}

ServiceCostTable.propTypes = {
  npi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  location: PropTypes.shape({
    locationId: PropTypes.string,
  }).isRequired,
  analyticsView: PropTypes.oneOf(['Modal', 'Accordion']).isRequired,
  isDebouncing: PropTypes.bool,
};

ServiceCostTable.defaultProps = {
  isDebouncing: false,
};
