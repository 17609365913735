import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';
import ProfileBadge from 'components/ModernExperience/Profile/ProfileBadge';

import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridRoot: {
        display: 'inline-flex',
        flexWrap: 'nowrap',
      },
    },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(1.5)}px 0`,
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
}));

export default function HighlyRatedIcons({ pillProps, badgeProps }) {
  const classes = useStyles();

  return (
    <Typography align="center" component="div" data-testid="highly-rated-icons">
      <Grid
        container
        alignItems="center"
        classes={{
          root: classes.gridRoot,
        }}
      >
        <Grid item xs={12} sm={5} classes={{ root: classes.gridItem }}>
          <Pill {...pillProps} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
          or
        </Grid>
        <Grid item xs={12} sm={5} classes={{ root: classes.gridItem }}>
          <ProfileBadge {...badgeProps} />
        </Grid>
      </Grid>
    </Typography>
  );
}

HighlyRatedIcons.propTypes = {
  pillProps: PropTypes.shape({}).isRequired,
  badgeProps: PropTypes.shape({}).isRequired,
};
