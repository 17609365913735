import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import PropTypes, { ChildrenType } from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  p: {
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    fontSize: 16,
  },
}));

function AnchorTag({ ...props }) {
  return <Link target="_blank" rel="noopener noreferrer" {...props} />;
}

function Paragraph({ ...props }) {
  const classes = useStyles();
  return <Typography classes={{ root: classes.p }} {...props} />;
}

function EmboldMarkdown({ children, markdownProps }) {
  const componentOverrides = markdownProps?.components || {};
  const props = {
    ...markdownProps,
    components: {
      a: AnchorTag,
      p: Paragraph,
      ...componentOverrides,
    },
  };
  return <ReactMarkdown {...props}>{children}</ReactMarkdown>;
}

export default EmboldMarkdown;

EmboldMarkdown.propTypes = {
  children: ChildrenType.isRequired,
  markdownProps: PropTypes.shape({
    components: PropTypes.shape({}),
  }),
};

EmboldMarkdown.defaultProps = {
  markdownProps: {},
};
