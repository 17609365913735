import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Typography, Button } from '@material-ui/core';

import { CHAT_BANNER_VARIANT, TEXT_BANNER_VARIANT } from 'store/slices/banner/bannerConstants';

import useChatBanner from 'hooks/useChatBanner';
import { VirtualAssistant } from 'icons/index';
import { select, actions } from 'store/toolkit';
import Banner from './Banner';

function AppBannerVariant({ onChatClose, ...props }) {
  const variant = useSelector(select.banner.variant);
  const text = useSelector(select.banner.text);
  const color = useSelector(select.banner.color);

  const dispatch = useDispatch();

  const handleChatClick = useCallback(() => dispatch(actions.ui.openModal('chat')), [dispatch]);

  switch (variant) {
    case TEXT_BANNER_VARIANT:
      return (
        <Banner color={color} {...props}>
          <Typography variant="h6" component="span">
            {text}
          </Typography>
        </Banner>
      );
    case CHAT_BANNER_VARIANT:
      return (
        <Banner color={color} onClose={onChatClose} {...props}>
          <Button startIcon={<VirtualAssistant />} onClick={handleChatClick}>
            {text}
          </Button>
        </Banner>
      );
    default:
      return null;
  }
}

AppBannerVariant.propTypes = {
  onChatClose: PropTypes.func.isRequired,
};

export default function AppBanner({ showChatButton, ...props }) {
  const showBanner = useSelector(select.banner.showBanner);
  const handleChatClose = useChatBanner(showChatButton);

  return (
    <Collapse in={showBanner} timeout={500} unmountOnExit>
      <AppBannerVariant onChatClose={handleChatClose} {...props} />
    </Collapse>
  );
}

AppBanner.propTypes = {
  showChatButton: PropTypes.bool.isRequired,
};
