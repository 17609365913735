import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useTableValue from 'components/ModernExperience/CompareTable/useTableValue';
import ThemedTableCell from './ThemedTableCell';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    borderBottom: 'none',
  },
  text: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

export default function SubheaderRow({ children, additionalClass }) {
  const classes = useStyles();
  const { columns } = useTableValue();

  return (
    <TableRow>
      <ThemedTableCell
        colSpan={columns}
        className={`${classes.root} ${additionalClass}`}
        TypographyProps={{ classes: { root: classes.text } }}
      >
        {children}
      </ThemedTableCell>
    </TableRow>
  );
}

SubheaderRow.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClass: PropTypes.string,
};

SubheaderRow.defaultProps = {
  additionalClass: '',
};
