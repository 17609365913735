/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, select, thunks } from 'store/toolkit';
import queryParamConfig from 'hooks/queryString/queryParamConfig';
import { useQueryParam, useQueryParams, StringParam } from 'use-query-params';
import { CARE_CATEGORIES, PROVIDER_TYPE } from 'utils/constants';

const useUrlProfileSearch = ({ id, searchType }) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const configIsLoading = useSelector(select.config.isLoading);
  const currentNetworkSlug = useSelector(select.networks.currentSlug);
  const dispatch = useDispatch();

  const [{ location, location_input, city, state, network_slug }] =
    useQueryParams(queryParamConfig);
  const [, setNetworkParam] = useQueryParam('network_slug', StringParam);

  const checkForNetworkMismatch = useCallback(() => {
    if (network_slug !== currentNetworkSlug) {
      dispatch(
        actions.notifications.create({
          message:
            "We've updated this search to use details from your health plan. Some details may look different",
          duration: 5000,
          returnFocusKey: 'logo',
          severity: 'warning',
        })
      );
      setNetworkParam(currentNetworkSlug);
    }
  }, [currentNetworkSlug, dispatch, network_slug, setNetworkParam]);

  useEffect(() => {
    // only call the thunk after config has loaded
    // only call the thunk once per page load (when `fetching` is true)
    if (!configIsLoading && fetching) {
      dispatch(
        thunks.results.fetchProfileFromUrl({
          location,
          location_input,
          city,
          state,
          network_slug,
          entity_id: id,
          care_category:
            searchType === PROVIDER_TYPE
              ? CARE_CATEGORIES.PROVIDER_NAME
              : CARE_CATEGORIES.FACILITY_NAME,
        })
      )
        .unwrap()
        .catch(() => setError(true))
        .finally(() => {
          setFetching(false);
          checkForNetworkMismatch();
        });
    }
  }, [
    city,
    dispatch,
    location,
    location_input,
    network_slug,
    id,
    state,
    configIsLoading,
    searchType,
    fetching,
    checkForNetworkMismatch,
  ]);

  return { fetching, error };
};

export default useUrlProfileSearch;
