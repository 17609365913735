import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { FormHelperText } from '@material-ui/core';

/** This is intended for displaying a formik error for a field that does not already display it's own error */
export default function FieldErrorText({ name, children, ...props }) {
  const { errors } = useFormikContext();
  const error = errors[name];

  const isError = Boolean(error);

  if (!isError) return null;

  return (
    <>
      <FormHelperText role="alert" error {...props}>
        {error}
      </FormHelperText>
      {children}
    </>
  );
}

FieldErrorText.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

FieldErrorText.defaultProps = {
  children: undefined,
};
