import React, { useCallback } from 'react';
import PropTypes from 'propTypes/index';
import { actions } from 'store/toolkit';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import useChatAcknowledgementFormSchema, { SCENARIO_ID } from './useChatAcknowledgementFormSchema';
import ChatAcknowledgementFormFields from './ChatAcknowledgementFormFields';

function ChatAcknowledgementForm({ isError }) {
  const dispatch = useDispatch();

  const { schema, initialValues } = useChatAcknowledgementFormSchema();

  const handleSubmit = useCallback(
    (values, { resetForm }) => {
      const scenarioId = values[SCENARIO_ID];
      dispatch(actions.chat.acceptTerms(scenarioId));
      resetForm();
    },
    [dispatch]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <ChatAcknowledgementFormFields isError={isError} />
    </Formik>
  );
}

export default ChatAcknowledgementForm;

ChatAcknowledgementForm.propTypes = {
  isError: PropTypes.bool,
};
ChatAcknowledgementForm.defaultProps = {
  isError: false,
};
