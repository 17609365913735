import React from 'react';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

import Pill from './Pill';

function PillList({ providerId, pillClassName }) {
  const pillList = useSelector(select.provider(providerId).pillList);

  if (!pillList || !pillList.length) return null;

  return pillList.map((pill) => <Pill {...pill} additionalClass={pillClassName} />);
}

export default React.memo(PillList);

PillList.propTypes = {
  providerId: PropTypes.string.isRequired,
  pillClassName: PropTypes.string,
};

PillList.defaultProps = {
  pillClassName: '',
};
