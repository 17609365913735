import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonRoot: {
    padding: 0,
    margin: '6px 12px',
  },
});

export default function PaginationController({
  index,
  length,
  setIndex,
  className,
  label,
  statusText,
}) {
  const classes = useStyles();
  const prevButton = useRef(null);
  const nextButton = useRef(null);
  const [showAriaStatus, setShowAriaStatus] = useState(false);

  const next = useCallback(() => {
    setShowAriaStatus(true);
    const nextIndex = index + 1;
    if (nextIndex < length) {
      setIndex(nextIndex);
    }
    if (nextIndex === length - 1) {
      prevButton.current?.focus();
    }
  }, [index, length, setIndex, setShowAriaStatus]);

  const previous = useCallback(() => {
    setShowAriaStatus(true);
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      setIndex(previousIndex);
    }
    if (previousIndex === 0) {
      nextButton.current?.focus();
    }
  }, [setIndex, index, setShowAriaStatus]);

  return (
    <div className={clsx(classes.root, { [className]: className })}>
      {showAriaStatus && (
        <Typography variant="srOnly" role="status">
          {statusText || `Showing ${label} ${index + 1} of ${length}`}
        </Typography>
      )}
      <IconButton
        classes={{ root: classes.iconButtonRoot }}
        onClick={previous}
        disabled={index === 0}
        aria-label={`Previous ${label}`}
        ref={prevButton}
      >
        <LeftArrowIcon />
      </IconButton>
      <Typography noWrap component="span">{`${index + 1} of ${length}`}</Typography>
      <IconButton
        classes={{ root: classes.iconButtonRoot }}
        onClick={next}
        disabled={index === length - 1}
        aria-label={`Next ${label}`}
        ref={nextButton}
      >
        <RightArrowIcon />
      </IconButton>
    </div>
  );
}

PaginationController.propTypes = {
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setIndex: PropTypes.func.isRequired,
  statusText: PropTypes.string,
  className: PropTypes.string,
};

PaginationController.defaultProps = {
  className: '',
  statusText: '',
};
