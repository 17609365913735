import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import {
  VIRTUAL_BENEFIT_PRESENTED,
  VIRTUAL_BENEFIT_USE_LIKELYHOOD,
} from 'components/Modals/ChatModal/Feedback/constants';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  buttonSpacer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 5px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 13px 0',
    },
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  ratingSelection: {
    background: theme.palette.grey[50],
    width: 30,
    height: 30,
    borderRadius: '100%',
    lineHeight: 0,
    position: 'relative',
    border: `2px solid ${theme.palette.grey[600]}`,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
    },
    '& span:first-child': {
      position: 'absolute',
      bottom: ' 50%',
      width: '100%',
      textAlign: 'center',
      fontSize: '1rem',
    },
    '@media (forced-colors: active)': {
      '&:focus': {
        borderWidth: 5,
      },
      '&$selected': {
        fontWeight: 900,
      },
    },
  },
  selected: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.common.white,
    },
  },
}));

function FeedbackRating({ name, ratingOptions, question, showLabels }) {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  const handleSelect = (val) => {
    helpers.setValue(val);

    // Always reset the VIRTUAL_BENEFIT_USE_LIKELYHOOD value on VIRTUAL_BENEFIT_PRESENTED change
    if (field.name === VIRTUAL_BENEFIT_PRESENTED) {
      setFieldValue(VIRTUAL_BENEFIT_USE_LIKELYHOOD, null);
    }
  };

  const handleKeyPress = (e, val) => {
    if (e.key === ' ' || e.key === 'Enter') {
      handleSelect(val);
    }
  };

  return (
    <Grid id={field.name}>
      <Typography>{question}</Typography>
      {Boolean(meta.touched && meta.error) && <Typography color="error">{meta.error}</Typography>}
      <Grid container justifyContent="center">
        {ratingOptions.map(({ val, label }, index) => (
          <Grid item key={val} className={classes.buttonSpacer}>
            <div
              role="radio"
              tabIndex="0"
              aria-checked={field.value === val}
              onClick={() => handleSelect(val)}
              onKeyDown={(e) => handleKeyPress(e, val)}
              className={`${classes.ratingSelection} ${field.value === val && classes.selected}`}
            >
              <span>{typeof val === 'boolean' ? label : val}</span>
              <Typography variant="srOnly">{label}</Typography>
            </div>
            {showLabels && index === 0 && <Typography>{ratingOptions[0].label}</Typography>}
            {showLabels && index === ratingOptions.length - 1 && (
              <Typography>{ratingOptions[ratingOptions.length - 1].label}</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

FeedbackRating.propTypes = {
  name: PropTypes.string.isRequired,
  ratingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
  question: PropTypes.string.isRequired,
  showLabels: PropTypes.bool,
};

FeedbackRating.defaultProps = {
  showLabels: false,
};

export default FeedbackRating;
