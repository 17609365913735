import React, { useMemo } from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function SupportTicketError() {
  const classes = useStyles();
  const { values } = useFormikContext();
  const mailbox = useSelector(select.content.zohoMailboxAddress);

  // here we set the email href which includes the subject & description from the form submission
  // this way, if a ticket fails, they can email their ticket without retyping their message
  // *NOTE* if testing on a simulator mailto links will not work as there is no mail app installed
  const href = useMemo(() => {
    const base = `mailto:${mailbox}?`;
    let queryString = `subject=${values.subject}&body=`;

    queryString += `Contact Name: ${values.firstName} ${values.lastName}
Email: ${values.email}
Phone: ${values.phone}
City: ${values.city}
State: ${values.state}
ZIP: ${values.zip}
  
Description:
${values.description}
`;

    return encodeURI(base + queryString);
  }, [values, mailbox]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="strong" gutterBottom color="error">
        Something went wrong
      </Typography>
      <Typography align="center">
        We were unable to submit your ticket to the help desk. Please email{' '}
        <Link href={href}>{mailbox}</Link> to complete your ticket.
      </Typography>
    </div>
  );
}
