export const ARRAY_FILTER_TYPE = 'array';
export const BOOL_FILTER_TYPE = 'boolean';

// filter slider marks need a 'false' value in order to display logarithmically rather than linearly
// ie we want a user to have an easy time selecting between 1 mile and 3 miles as well as 250 miles
// these values get used by the mui slider component for display purposes and then converted to their true value with SLIDER_VALUE_TO_RADIUS
export const SLIDER_MARKS = [
  { value: 0, label: '1' },
  { value: 10, label: '2' },
  { value: 20, label: '3' },
  { value: 30, label: '5' },
  { value: 40, label: '10' },
  { value: 50, label: '25' },
  { value: 60, label: '50' },
  { value: 70, label: '100' },
  { value: 80, label: '250' },
];

export const SLIDER_VALUE_TO_RADIUS = {
  0: 1,
  10: 2,
  20: 3,
  30: 5,
  40: 10,
  50: 25,
  60: 50,
  70: 100,
  80: 250,
};

export const RADIUS_TO_SLIDER_VALUE = {
  1: 0,
  2: 10,
  3: 20,
  5: 30,
  10: 40,
  25: 50,
  50: 60,
  100: 70,
  250: 80,
};

export const RADIUS_VALUES = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 5 },
  { value: 10 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 250 },
];

export const VALID_RADIUS_VALUES = RADIUS_VALUES.map((obj) => obj.value);
export const MAX_RADIUS_VALUE = RADIUS_VALUES[RADIUS_VALUES.length - 1].value;
