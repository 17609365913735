import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import LoadingDots from 'components/LoadingDots';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles(() => ({
  '@keyframes fadeUpAnimation': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
    },
  },
  loadingDotsContainer: {
    width: 80,
    marginTop: 4,
    marginLeft: 16,
    animationName: '$fadeUpAnimation',
    animationDuration: '.4s',
    animationTimingFunction: 'linear',
  },
}));

export default function FadeInLoadingDots({ visible }) {
  const classes = useStyles();
  return visible ? (
    <Grid className={classes.loadingDotsContainer}>
      <LoadingDots />
    </Grid>
  ) : null;
}

FadeInLoadingDots.propTypes = {
  visible: PropTypes.bool.isRequired,
};
