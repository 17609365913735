import React from 'react';
import PropTypes from 'propTypes/index';
import { useDispatch } from 'react-redux';
import { actions } from 'store/toolkit';

import SkipLink from 'components/a11y/SkipLink';
import { APP_MAIN_TAG_ID } from 'utils/constants';

function HeaderSkipLinks({ showChatLink }) {
  const dispatch = useDispatch();
  const openChatModal = () => dispatch(actions.ui.openModal('chat'));
  return (
    <>
      <SkipLink href={`#${APP_MAIN_TAG_ID}`} label="Skip to Main Content" />
      {showChatLink && <SkipLink label="Skip to Chatbot" handleClick={openChatModal} />}
    </>
  );
}

export default HeaderSkipLinks;

HeaderSkipLinks.propTypes = {
  showChatLink: PropTypes.bool.isRequired,
};
