import React from 'react';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import FeaturedBenefit from 'components/ModernExperience/Benefit/FeaturedBenefit';

function PromotionById({ id }) {
  const selectedPromotion = useSelector(select.promotions.byId(id));
  const isSmDown = useSelector(select.ui.isSmDown);
  if (!selectedPromotion) {
    return null;
  }
  // TODO TECH-3480: adjust styling with additionalClass prop as needed when we render this component
  return <FeaturedBenefit isMobile={isSmDown} benefit={selectedPromotion} />;
}

PromotionById.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PromotionById;
