import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

import emboldLogo from 'assets/embold-icon.png';
import { useSelector } from 'react-redux';
import select from 'store/select';

function useStyles(speed) {
  return makeStyles({
    spinner: {
      animation: `$logoSpin infinite ${speed}s linear`,
    },
    '@keyframes logoSpin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  })();
}

export default function EmboldLoadingSpinner({ size, speed, ...props }) {
  const classes = useStyles(speed);
  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);

  return isWhitelabeled ? (
    <CircularProgress size={size} thickness={2} aria-label="Loading" {...props} />
  ) : (
    <img
      src={emboldLogo}
      alt="Loading"
      className={classes.spinner}
      width={size}
      height={size}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

EmboldLoadingSpinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
};

EmboldLoadingSpinner.defaultProps = {
  size: 100,
  speed: 2,
};
