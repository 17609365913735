import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import { select } from 'store/toolkit';

export default function PlacePhoneNumberCell({ placeId }) {
  const dispatch = useDispatch();
  const { phone } = useSelector(select.place(placeId).data) || {};

  const handleLinkClick = useCallback(() => {
    dispatch(_analyticsActions.phoneClickFromCompareModal(placeId));
  }, [dispatch, placeId]);

  if (!phone) return null;

  return (
    <Link onClick={handleLinkClick} href={`tel:${phone}`}>
      {phone}
    </Link>
  );
}

PlacePhoneNumberCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
