import React from 'react';
import PropTypes from 'propTypes';
import clsx from 'clsx';
import { ListItem, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Tooltip, { TooltipPropsShape } from 'components/Tooltip';
import useIconNode from 'hooks/useIconNode';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    '& > img, svg': {
      width: 20,
      height: 20,
    },
  },
  tooltipIcon: {
    margin: 0,
    marginLeft: 10,
  },
}));

export default function Attribute({
  isListItem,
  text,
  icon,
  iconAltText,
  additionalIconClass,
  className,
  TooltipProps,
  TypographyProps,
  ...props
}) {
  const classes = useStyles();

  const { message: tooltipMessage, title: tooltipTitle, link, href } = TooltipProps || {};
  const hasTooltip = Boolean(tooltipMessage);

  const iconEl = useIconNode(icon, iconAltText);

  const Container = isListItem ? ListItem : Box;
  return (
    <Container className={clsx(classes.root, { [className]: className })} {...props}>
      <Tooltip
        deactivated={!hasTooltip}
        title={tooltipTitle || text}
        message={tooltipMessage}
        link={link}
        href={href}
        additionalClasses={{ icon: classes.tooltipIcon }}
      >
        {iconEl && (
          <div
            className={clsx(classes.icon, { [additionalIconClass]: Boolean(additionalIconClass) })}
          >
            {iconEl}
          </div>
        )}
        <Typography {...TypographyProps}>{text}</Typography>
      </Tooltip>
    </Container>
  );
}

Attribute.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  additionalIconClass: PropTypes.string,
  isListItem: PropTypes.bool,
  iconAltText: PropTypes.string,
  TooltipProps: TooltipPropsShape,
  /** MUI Typography Props: https://v4.mui.com/api/typography/#props */
  TypographyProps: PropTypes.shape({}),
};

Attribute.defaultProps = {
  className: '',
  additionalIconClass: undefined,
  isListItem: true,
  iconAltText: '',
  icon: <CheckCircleOutlineIcon color="inherit" />,
  TooltipProps: {},
  TypographyProps: {},
};
