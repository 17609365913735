import { select } from 'store/toolkit';
import sessionEvents from './sessionEvents';
import searchEvents from './searchEvents';
import resultViewEvents from './resultViewEvents';
import conversionEvents from './conversionEvents';
import uiEvents from './uiEvents';

const events = {
  ...sessionEvents,
  ...searchEvents,
  ...resultViewEvents,
  ...conversionEvents,
  ...uiEvents,
};

// add global properties to all event data
for (const key of Object.keys(events)) {
  const event = events[key];
  event.name = key;
  event.reducer = (state, action) => {
    let evtData = {};
    const networkSlug = select.networks.currentSlug(state);
    const locationInput = select.location.locationInput(state);

    if (event.getEventData) {
      evtData = event.getEventData(state, action);
    }
    return {
      ...evtData,
      eventCode: event.code,
      eventCategory: event.category,
      network_slug: networkSlug,
      location_input: locationInput,
    };
  };
}

export default events;
