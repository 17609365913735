import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function ChatDisclaimer() {
  return (
    <>
      <Typography variant="body2" paragraph>
        <strong>
          BY USING THE WEBSITE OR BY CLICKING TO ACCEPT OR AGREE TO THE TERMS OF USE WHEN THIS
          OPTION IS MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS
          OF USE AND OUR PRIVACY STATEMENT, FOUND AT{' '}
          <Link
            target="_blank"
            rel="noreferrer"
            href={window.location.origin.concat('/privacy')}
            style={{ lineBreak: 'anywhere' }}
          >
            {window.location.origin.concat('/privacy')}
          </Link>
          , INCORPORATED HEREIN BY REFERENCE.
        </strong>{' '}
        IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF USE OR THE PRIVACY STATEMENT, YOU MUST NOT
        ACCESS OR USE THE WEBSITE.
      </Typography>
      <Typography variant="body2" paragraph>
        The Website is offered and available to users who 18 years of age or older. By using this
        Website, you represent and warrant that you are of legal age to form a binding contract with
        Embold Health and meet all of the foregoing eligibility requirements. If you do not meet all
        of these requirements, you must not access or use the Website.
      </Typography>
      <Typography variant="body2" paragraph>
        Embold Health reserves the right to refuse service without prior notice to any user for any
        or no reason. Embold Health also reserves the right to suspend any existing user account for
        any or no reason.
      </Typography>
      <Typography variant="h2">Not Medical Advice</Typography>
      <Typography variant="body2" paragraph>
        Please note that Website is not intended to diagnose, treat, cure, or prevent any disease or
        medical condition. The content on the Website are there for informational purposes only and
        cannot replace the services of health professionals or physicians. You should always consult
        a physician before making any changes to your caregiving based on the content of the
        Website, or if you have any questions regarding a medical condition. Never disregard
        professional medical advice or delay in seeking it because of something you have read on or
        through the Website. We are not responsible for any health problems that may result from the
        content you learn about through the Website. If you make any change to your caregiving based
        on the Website, you agree that you do so fully at your own risk.‍ Call 911 for
        life-threatening emergencies.
      </Typography>
    </>
  );
}

export default ChatDisclaimer;
