/* eslint-disable import/prefer-default-export */

/**
 * The shape of location properties on a network config object
 * @typedef {Object} Network
 * @property {string} config.DEFAULT_LOCATION_CITY
 * @property {string} config.DEFAULT_LOCATION_STATE
 * @property {string} config.DEFAULT_LOCATION_LAT
 * @property {string} config.DEFAULT_LOCATION_LONG
 */

/**
 * The shape of a location object as consumed by the app
 * @typedef {Object} FormattedLocation
 * @property {string} city
 * @property {string} state
 * @property {string} lat
 * @property {string} long
 */

/**
 *
 * @param {Network} currentNetwork
 * @param {FormattedLocation} fallbackLocation
 * @returns FormattedLocation
 */
export const getNetworkDefaultLocation = ({ currentNetwork, fallbackLocation }) => {
  const networkConfig = currentNetwork?.config;
  const invalidNetworkDefaultLocation =
    !networkConfig ||
    !networkConfig.DEFAULT_LOCATION_CITY ||
    !networkConfig.DEFAULT_LOCATION_STATE ||
    !networkConfig.DEFAULT_LOCATION_LAT ||
    !networkConfig.DEFAULT_LOCATION_LONG;

  if (invalidNetworkDefaultLocation) {
    return {
      city: fallbackLocation.city,
      state: fallbackLocation.state,
      lat: fallbackLocation.lat,
      long: fallbackLocation.long,
    };
  }

  return {
    city: networkConfig.DEFAULT_LOCATION_CITY,
    state: networkConfig.DEFAULT_LOCATION_STATE,
    lat: networkConfig.DEFAULT_LOCATION_LAT,
    long: networkConfig.DEFAULT_LOCATION_LONG,
  };
};
