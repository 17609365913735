/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { findBestMatchingPromotion } from './promoteUtils';
import { PROMOTIONS_SLICE_NAME } from '../slicesNames';
// @TODO investigate if this dependency cycle can be solved, or if if it's safe to ignore this rule
// eslint-disable-next-line import/no-cycle
import * as selectResults from '../results/selectResults';

// Basic selectors

/** The entire promotions slice */
export const slice = (state) => state[PROMOTIONS_SLICE_NAME];

/** The array of all promotions fetched.
 * @returns {Array} Array of promotion objects */
export const promotions = (state) => state[PROMOTIONS_SLICE_NAME].promotions;

/** The loading state of the fetch call for promotions
 * @returns {boolean} True while the fetch is happening */
export const isLoading = (state) => state[PROMOTIONS_SLICE_NAME].isLoading;

/** The error message related to the promotions fetch call.
 * @returns {boolean|Null} The error message as a string, or null if no error */
export const error = (state) => state[PROMOTIONS_SLICE_NAME].error;

// Advanced Selectors

/** Selects a promotions from the promotions array based on the promotionId field
 * @param {string} id The id field on a promotion to look for.
 * @returns {Object|undefined} Returns the promotion object if found or undefined if not. */
export function byId(id) {
  return createSelector([promotions], (promotionsArray) =>
    promotionsArray.find((p) => p.id === id)
  );
}

/** The promotions whose locations are within the given radius. Relies on the distanceInMiles field to be applied to the locations array on the promotion.
 * @returns {Array} Returns an array of all promotions that have a radius of 0, have no locations, or a location within the radius. */
export const filteredByRadius = createSelector([promotions], (promotions) => {
  const filteredPromotions = promotions.filter((promote) => {
    const { locations = [], radius = Infinity } = promote;

    // if there are no locations listed, include this promotion
    if (locations.length === 0 || radius === 0) return true;

    for (const location of locations) {
      // iterate through locations. If ANY location is withing the radius, include this promote
      if (location.distanceInMiles && location.distanceInMiles < radius) return true;
    }

    // if no location was included in radius, exclude this promote
    return false;
  });

  return filteredPromotions;
});

export const noPromotionsInRadius = createSelector(
  [filteredByRadius, isLoading],
  (promotions, isLoading) => !isLoading && promotions?.length === 0
);

/**
 * @param {number|undefined} specialtyIdOverride Optionally pass a specialtyId to select a promotion from. If no value is passed, it will default to using the specialtyId from the resultsSlice
 * @param {number|undefined} subspecialtyIdOverride Optionally pass a subspecialtyId to select a promotion from. If no value is passed, it will default to using the subspecialtyId from the resultsSlice
 *
 * @returns {Function} A selector function to be passed to useSelector
 *
 * @example
 * // getting a promotion using the resultsSlice specialty & subspecialty
 * const selectedBenefit = useSelector(selectPromotions.fromSpecialtyIds());
 *
 * // or manually pass in a specialtyId/subspecialtyId
 * const mySpecialtyId = 1;
 * const mySubspecialtyId = 2;
 * const mySpecialtyBenefit = useSelector(selectPromotions.fromSpecialtyId(mySpecialtyId, mySubspecialtyId));
 */
export function fromSpecialtyIds(specialtyIdOverride, subspecialtyIdOverride) {
  return createSelector(
    [filteredByRadius, selectResults.specialtyId, selectResults.subspecialtyId],
    (benefits, resultSpecialtyId, resultSubspecialtyId) => {
      // return null to show no promote
      if (benefits.length === 0) return null;

      const specialtyId = specialtyIdOverride || resultSpecialtyId;
      const subspecialtyId = subspecialtyIdOverride || resultSubspecialtyId;

      const options = { specialtyId, subspecialtyId };

      return findBestMatchingPromotion(benefits, options);
    }
  );
}

export const featuredBenefitList = createSelector(
  [
    filteredByRadius,
    selectResults.specialtyId,
    selectResults.subspecialtyId,
    selectResults.highestFilterCountSpecialtyId,
    selectResults.wasServiceSearch,
  ],
  (benefits, resultSpecialtyId, resultSubspecialtyId, filterCountSpecialtyId, wasServiceSearch) => {
    const options = { subspecialtyId: resultSubspecialtyId, specialtyId: undefined }; // initialize the options object with the subspecialtyId from results

    // for service searches, we want to infer the specialty id, based on the specialty with the highest count from filter counts
    if (wasServiceSearch && filterCountSpecialtyId) {
      options.specialtyId = filterCountSpecialtyId;
    } else {
      // if it was not a service search, use the specialtyId from results
      options.specialtyId = resultSpecialtyId;
    }
    const benefitList = []; // initializing this as an array, even though currently we only show 1 benefit. Eventually we will have the ability to show more.

    // in the future, we can refactor here and add logic for running this function multiple times to build a list of multiple benefits order from best matching to least matching
    const bestMatchingBenefit = findBestMatchingPromotion(benefits, options);
    if (bestMatchingBenefit) benefitList.push(bestMatchingBenefit);

    return benefitList;
  }
);
