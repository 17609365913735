import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { useTheme } from '@material-ui/core/styles';

import ProfileBadge from '../../ProfileBadge';

export default function CoverageBadge({ placeId }) {
  const networkContent = useSelector(select.place(placeId).networkContent);
  const { palette } = useTheme();

  if (!networkContent.showNetworkStatus) return null;

  return (
    <ProfileBadge
      icon={networkContent.getIcon(palette)}
      label={networkContent.networkText}
      color="success.dark"
    />
  );
}

CoverageBadge.propTypes = {
  placeId: PropTypes.string.isRequired,
};
