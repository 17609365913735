import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'whatwg-fetch';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './App.scss';
import { FocusAnchorProvider } from 'utils/FocusRefContext';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'azure-maps-control/dist/atlas.min.css';

import LandingPage from 'components/Pages/LandingPage';
import MobileSearchPage from 'components/Pages/MobileSearchPage';
import TermsPage from 'components/Pages/TermsPage';
import PrivacyPage from 'components/Pages/PrivacyPage';
import ProfilePage from 'components/ModernExperience/Profile/ProfilePage';
import DisclaimerPage from 'components/Pages/DisclaimerPage';
import CookieAgreement from 'components/CookieAgreement';
import UpdateRegionModal from 'components/Modals/UpdateRegionModal';
import ProfileModals from 'components/Modals/ProfileModals';
import HighQualityModal from 'components/Modals/HighQualityModal';
import AppErrorModal from 'components/Modals/AppErrorModal';
import ClientConfigErrorModal from 'components/Modals/ClientConfigErrorModal';
import ClientConfigDevToolsModal from 'components/Modals/ClientConfigDevToolsModal';
import TooltipInfo from 'components/TooltipInfo';
import CssCanary from 'components/CssCanary';
import SharePage from 'components/Pages/SharePage/SharePage';
import ContactBCBS from 'components/Pages/ContactBCBS';
import usePromote from 'hooks/usePromote';
import PromotionProfilePage from 'components/Pages/PromotionProfilePage';
import StyleguideApp from 'components/Pages/StyleGuide/StyleguideApp';
import IEErrorModal from 'components/Modals/IEErrorModal';
import ChatModal from 'components/Modals/ChatModal/ChatModal';
import ModalRouter from 'components/Modals/ModalRouter';
import LoginPage from 'components/Pages/LoginPage';
import ResultsPage from 'components/Results/ResultsPage';
import SupportPage from 'components/Pages/SupportPage/SupportPage';
import Notification from 'components/Notification';
import BreakpointWatcher from 'components/BreakpointWatcher';

import { APP_MAIN_TAG_ID } from 'utils/constants';
import { getEnv } from 'utils/utils';

import { _analyticsActions } from 'analytics';
import useInitializeFilters from 'hooks/filters/useInitializeFilters';
import useInitializeLocation from 'hooks/useInitializeLocation';
import useRewards from 'hooks/useRewards';
import useFetchSpecialties from 'hooks/useFetchSpecialties';
import useCostCache from 'hooks/useCostCache';
import AuthProtectedRoute from 'components/Navigation/AuthProtectedRoute';
import RewardsRoutes from 'components/Pages/Rewards/RewardsRoutes';
import ChatButton from 'components/Modals/ChatModal/ChatButton';
import ClientConfigDevTools from 'components/DevTools/ClientConfigDevTools';
import { select } from 'store/toolkit';

import { LOGIN_ROUTE, SUPPORT_ROUTE } from './navigation/navigationConstants';
import AnalyticsPage from './analytics/AnalyticsPage';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTypography-srOnly': {
      whiteSpace: 'nowrap',
    },
  },
  main: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
  },
}));

function App() {
  // styles
  const classes = useStyles();

  // redux
  const dispatch = useDispatch();
  const isMobileLayout = useSelector(select.ui.isMdDown);
  const enableChat = useSelector(select.featureFlags.enableChat);
  const hideSupport = useSelector(select.featureFlags.hideSupport);
  const showDisclaimer = useSelector(select.featureFlags.showDisclaimer);
  const showContactBcbsRoute = useSelector(select.featureFlags.showContactBcbsRoute);
  const enableStyleguide = useSelector(select.featureFlags.enableStyleguide);
  const isLoggedIn = useSelector(select.auth.isLoggedIn);

  // react router
  const location = useLocation();
  usePromote();
  useRewards();
  useInitializeFilters(); // update filters from client config
  useInitializeLocation();
  useFetchSpecialties();
  useCostCache();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(_analyticsActions.returningUserLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup modal routes see https://v5.reactrouter.com/web/example/modal-gallery for example
  // This piece of state is set when one of the
  // links to modals are clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the previous route in the background, behind
  // the modal.
  const background = location.state && location.state.background;

  const isLoginPage = location.pathname === LOGIN_ROUTE;
  const showHeader = !isLoginPage; // show header: all pages except login

  const showChatButton = Boolean(
    enableChat && (location.pathname === '/' || location.pathname.startsWith('/results'))
  );

  const showDesktopChatButton = showChatButton && !isMobileLayout;
  return (
    <FocusAnchorProvider>
      <BreakpointWatcher />
      <CookieAgreement />
      <CssCanary />
      {showHeader && <Header isMobile={isMobileLayout} showChatButton={showChatButton} />}
      <main className={`${classes.main} main`} id={APP_MAIN_TAG_ID}>
        {showDesktopChatButton && <ChatButton />}
        <ClientConfigDevTools />
        <Switch location={background || location}>
          {/* **** PRIVATE ROUTES *** */}
          <AuthProtectedRoute path="/" exact render={(props) => <LandingPage {...props} />} />

          {isMobileLayout ? (
            <AuthProtectedRoute
              path={['/search']}
              exact
              render={(props) => <MobileSearchPage {...props} />}
            />
          ) : null}

          <AuthProtectedRoute
            path={['/results', '/results/compare']}
            exact
            render={(props) => <ResultsPage {...props} />}
          />

          <AuthProtectedRoute
            path={['/profile/:profileType/:id']}
            exact
            render={(props) => <ProfilePage {...props} />}
          />

          <AuthProtectedRoute
            path={['/promotion/:id']}
            exact
            render={(props) => <PromotionProfilePage {...props} />}
          />

          {!hideSupport && (
            <AuthProtectedRoute
              path={SUPPORT_ROUTE}
              render={(props) => <SupportPage {...props} />}
            />
          )}

          <Route path="/rewards" component={RewardsRoutes} />

          {/* *** PUBLIC ROUTES *** */}
          <Route exact path={LOGIN_ROUTE} component={LoginPage} />
          <Route path="/privacy" exact component={PrivacyPage} />
          <Route path="/terms" exact component={TermsPage} />
          <Route path="/share/:id" exact component={SharePage} />
          {showContactBcbsRoute && <Route path="/contactbcbs" exact component={ContactBCBS} />}
          {showDisclaimer && <Route path="/disclaimer" exact component={DisclaimerPage} />}

          {/* *** STYLEGUIDE ROUTES *** */}
          {enableStyleguide && <Route path="/styleguide" component={StyleguideApp} />}
          {enableStyleguide && (
            <AuthProtectedRoute path="/analytics" exact render={() => <AnalyticsPage />} />
          )}

          {/* *** REDIRECT NON-EXISTENT ROUTES *** */}
          <Redirect to="/" />
        </Switch>
      </main>

      <Footer />

      {/* This component is the router for modals rendered as routes */}
      <ModalRouter />

      <TooltipInfo />
      {enableChat && <ChatModal />}

      <IEErrorModal />
      <UpdateRegionModal />
      <HighQualityModal />
      <AppErrorModal />
      {getEnv() !== 'production' && <ClientConfigDevToolsModal />}
      <ClientConfigErrorModal />
      <ProfileModals />
      <Notification />
    </FocusAnchorProvider>
  );
}

export default App;
