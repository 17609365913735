import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/pwrd-emboldhealth-footer.svg';

const useStyles = makeStyles((theme) => ({
  '@media screen and (-ms-high-contrast: black-on-white)': {
    footerLogoWrap: {
      background: theme.palette.primary.main,
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    footerLogoWrap: {
      forcedColorAdjust: 'none',
      background: 'black',
    },
  },
  footerLogoWrap: {
    position: 'absolute',
    top: 30,
    right: 14,
    [theme.breakpoints.up('md')]: {
      top: 22,
      right: 25,
    },
  },
}));

function FooterLogo() {
  const classes = useStyles();
  return (
    <div className={classes.footerLogoWrap}>
      <img className="icon" src={logo} alt="Powered by Embold Health" />
    </div>
  );
}

export default FooterLogo;
