import React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import PropTypes from 'propTypes';

import DirectionsButton from '../DirectionsButton';

function PlaceDirectionsButton({ placeId, additionalClasses }) {
  const placeData = useSelector(select.place(placeId).data);
  const distanceInMiles = useSelector(select.place(placeId).distanceInMiles);

  return (
    <DirectionsButton
      resultId={placeId}
      location={placeData}
      distance={distanceInMiles}
      additionalClasses={additionalClasses}
    />
  );
}

export default React.memo(PlaceDirectionsButton);

PlaceDirectionsButton.propTypes = {
  placeId: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

PlaceDirectionsButton.defaultProps = {
  additionalClasses: '',
};
