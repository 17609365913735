import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Button, Grid, List } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

import LanguageIcon from '@material-ui/icons/Language';

import PhonePicture from 'assets/pg-phone.png';
import { select } from 'store/toolkit';
import {
  SCORE_COLORS_BY_TIER,
  SCORE_TIER_ICON_COMPONENTS,
  STRENGTHS_SECTION,
  SUBSPECIALTY_SECTION,
  HEALTH_AND_WELLNESS,
} from 'utils/constants';

const ScoreTierIcon = SCORE_TIER_ICON_COMPONENTS[1];

function useBadges(palette) {
  const appName = useSelector(select.content.appName);
  const showDrScore = useSelector(select.featureFlags.showDrScore);
  const showEmboldRecommended = useSelector(select.featureFlags.showEmboldRecommended);

  return useMemo(() => {
    const badges = [
      {
        icon: <LanguageIcon color="primary" />,
        heading: 'Quickly find the\nright doctor',
        subHeading:
          'Search by location, name, and specialty. Filter by the score, distance, etc. See how good they are at diagnosing, treatment planning, and outcomes',
      },
      {
        icon: <STRENGTHS_SECTION.IconComponent />,
        heading: `Provider\n${STRENGTHS_SECTION.title}`,
        subHeading:
          'When a provider’s quality in Diagnosing, Treatment Plan, or Patient Outcomes is higher than their peers, it is highlighted as a strength in order to help you choose the best match for your needs.',
      },
      {
        icon: <SUBSPECIALTY_SECTION.IconComponent />,
        heading: 'Provider\n'.concat(SUBSPECIALTY_SECTION.title),
        subHeading: SUBSPECIALTY_SECTION.about,
      },
      {
        icon: <HEALTH_AND_WELLNESS.IconComponent />,
        heading: HEALTH_AND_WELLNESS.title,
        subHeading: HEALTH_AND_WELLNESS.description,
        altText: HEALTH_AND_WELLNESS.altText,
      },
    ];

    if (showDrScore || showEmboldRecommended) {
      badges.splice(1, 0, {
        icon: <ScoreTierIcon htmlColor={get(palette, SCORE_COLORS_BY_TIER[1])} />,
        heading: 'Doctor\nQuality',
        subHeading: `Doctor quality ranking  highlights the best providers in your area and will help you evaluate healthcare providers across all aspects of quality.`,
      });
    }

    return badges;
  }, [showDrScore, appName]);
}

const useStyles = makeStyles((theme) => ({
  rowTwo: {
    padding: theme.spacing(1),
    borderRadius: '15px',
    width: '100%',
  },
  learnMore: {
    marginTop: 15,
    letterSpacing: '.1rem',
    fontWeight: '500',
    minWidth: '200px',
    textAlign: 'center',
    padding: 5,
    borderRadius: '20px',
  },

  badgeHeading: {
    whiteSpace: 'pre-line',
  },
  featureIconList: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      height: '90%',
      maxWidth: '90%',
    },
    '& > svg': {
      fontSize: 90,
    },
    // @TODO TECH-3846 Revisit color here
  },
}));

function AboutProviderGuide() {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const appName = useSelector(select.content.appName);
  const { palette } = useTheme();
  const badges = useBadges(palette);

  return (
    <Grid>
      <Grid container direction="row" className={classes.rowTwo} justifyContent="space-between">
        <Grid item md={5} sm={12}>
          <img width="100%" src={PhonePicture} alt={`a cellphone displaying ${appName}`} />
        </Grid>

        <Grid
          item
          container
          direction="column"
          md={6}
          sm={12}
          justifyContent="center"
          alignItems="center"
        >
          {smDown && <br />}
          <Grid>
            <Typography
              style={{
                fontSize: '1rem',
              }}
            >
              Our {appName} solution gives people a fast, easy way to find a doctor the way a doctor
              would — through thoughtful examination of facts and a focus on patient outcomes.
              Unlike online reviews or word-of-mouth recommendations, which are based on subjective
              opinions, {appName} is based on science.
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Button
              classes={{ root: classes.learnMore }}
              variant="contained"
              color="primary"
              target="_blank"
              rel="noreferrer"
              href="https://emboldstatichosting.blob.core.windows.net/external/EmboldHealth_WhitePaper_MeasureWhatMatter_20220331.pdf"
              aria-label="Learn more about how Embold Health is transforming healthcare by measuring what matters."
            >
              Learn More
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        component={List}
        direction="row"
        justifyContent={smDown ? 'center' : 'space-between'}
        classes={{ root: classes.featureIconList }}
      >
        {badges.map((badge, i) => (
          <Grid
            container
            item
            direction="column"
            component="li"
            sm={7}
            md={2}
            alignItems="center"
            key={badge.heading.concat(i)}
          >
            <div className={classes.iconContainer}>{Boolean(badge.icon) && badge.icon}</div>
            <Typography
              variant="h5"
              component="h3"
              align="center"
              style={{ width: i === 1 ? '55%' : '70%' }}
              className={classes.badgeHeading}
            >
              {badge.heading}
            </Typography>
            <Typography align="center" style={{ paddingTop: 10, fontSize: smDown ? 13 : 11 }}>
              {badge.subHeading}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AboutProviderGuide;
