import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Grid, Button, makeStyles } from '@material-ui/core';
import { _analyticsActions } from 'analytics/index';

import Modal from 'components/Modals/Modal';

import { actions, select } from 'store/toolkit';

const useStyles = makeStyles(() => ({
  modalButton: {
    textAlign: 'center',
    marginTop: 20,
  },
  modalHeader: {
    width: '95%',
    margin: '1rem auto 0',
    textAlign: 'center',
    marginBottom: 10,
  },
}));

function CallModal({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const schedulePhoneNumber = useSelector(select.content.schedulePhoneNumber);
  const scheduleCopy = useSelector(select.content.scheduleCopy);

  const handleBCBSCall = (e) => {
    e.stopPropagation();
    dispatch(
      _analyticsActions.phoneClickFromNeedHelpButton(profileData.entityId || profileData.id)
    );
    document.location.href = `tel:${schedulePhoneNumber}`;
  };

  return (
    <Modal
      handleClose={() => {
        dispatch(actions.ui.closeProfileModal({ promptFeedback: true }));
      }}
      open
      ariaId="call-modal"
    >
      <>
        <Typography
          variant="h2"
          color="primary"
          className={classes.modalHeader}
          id="call-modal-title"
        >
          Call support
        </Typography>

        <Grid item xs={12} container>
          <Grid style={{ textAlign: 'left' }} item id="call-modal-description">
            {scheduleCopy}
          </Grid>

          <Grid item xs={12} className={`${classes.modalButton} profile-modal-button`}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBCBSCall}
              autoFocus
              style={{ marginBottom: 20 }}
              aria-label={`Call support at ${schedulePhoneNumber}`}
            >
              {schedulePhoneNumber}
            </Button>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

CallModal.propTypes = {
  profileData: PropTypes.shape({
    entityId: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default CallModal;
