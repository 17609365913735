import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import { thunks, select } from 'store/toolkit';
import useResultCountQuery from 'hooks/filters/useResultCountQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

function ApplyFiltersButton(props) {
  const dispatch = useDispatch();
  const { count, isLoading } = useSelector(select.filters.predictedResults);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const queryString = useResultCountQuery();

  useEffect(() => {
    dispatch(thunks.filters.fetchPredictedResultCount({ queryString, wasPlaceSearch }));
  }, [dispatch, queryString, wasPlaceSearch]);

  const buttonText = useMemo(() => {
    const defaultText = 'Show Results';
    if (isLoading) return defaultText;
    if (count === 0) return 'No Results';
    if (count > 0) return `Show ${count} ${count === 1 ? 'Result' : 'Results'}`;
    return defaultText;
  }, [count, isLoading]);

  const isDisabled = useMemo(() => count === 0 || isLoading, [count, isLoading]);

  return (
    <>
      {count !== null && (
        <Typography variant="srOnly" role="status">
          Result count with selected filters: {count}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        disabled={isDisabled}
        startIcon={isLoading && <CircularProgress size={16} thickness={2} aria-label="" />}
        {...props}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default React.memo(ApplyFiltersButton);
