import { useEffect, useState } from 'react';
import { ChildrenType } from 'propTypes/index';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

// @TODO: TECH-4023 revisit authorization / authentication handling
export default function AccessCheck({ children }) {
  const configIsLoading = useSelector(select.config.isLoading);
  const isClosedAccess = useSelector(select.config.isClosedAccess);
  const axios = useSelector(select.axios.axiosInstance);

  const [showApp, setShowApp] = useState(!isClosedAccess);

  useEffect(() => {
    if (!configIsLoading) {
      if (isClosedAccess && !window.location.pathname.includes('share')) {
        // use 'init' route to authenticate integration token for closed access clients. the axios instance handles redirects for any invalid tokens
        axios('/init/')
          .then(() => {
            setShowApp(true);
          })
          .catch(() => {});
      } else {
        // open access clients and share routes
        setShowApp(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading]);

  if (showApp) {
    return children;
  }
  return null;
}

AccessCheck.propTypes = {
  children: ChildrenType.isRequired,
};
