import { isAxiosError } from 'axios';

/**
 * @typedef {object} ThunkError
 * @property {string} name This is the instance of Error class that was thrown such as Error (generic javascript error) or AxiosError
 * @property {string} message For generic javascript errors this is the arg passed to the constructor ex. new Error('This is the message')
 * @property {number?} status This is included only on AxiosErrors and it is the status of the response
 * @property {any?} data This is included on AxiosErrors and contains the response data
 */

/**
 * This function is intended to be passed to the serializeError argument of the createAsyncThunk options.
 * Using this serializer allows for the thunk to modify the default error object that is sent to the reducer.
 *
 * When Axios returns a response with a non 200 status code, it throws an AxiosError.
 * The AxiosError has the properties { name: "AxiosError", message: "Request failed with the status code XXX", code: "ERR_BAD_REQUEST" }
 *
 * In the instances where we throw an AxiosError it is more helpful if the response status code and data can be passed to the reducer.
 * This allows the reducer to handle the response status and data in it's own way.
 *
 * For example, if Axios returns a response with a 403 status code, the reducer could handle that specific thunk error with custom user facing error text
 * such as "You need to login to view this content"
 *
 * @param {any} error The error thrown by the async function
 * @returns {ThunkError} A serialized object containing error information. This will be found on the action.error property in the reducer.
 */
export default function thunkErrorSerializer(error) {
  const { name, message } = error; // these two properties are always on the base Javascript Error class;
  const errorPayload = { name, message };

  if (isAxiosError(error)) {
    const { response } = error;

    if (!response) return errorPayload; // no additional information to include if there is no response

    errorPayload.status = response.status;
    errorPayload.data = response.data;
  }

  return errorPayload;
}
