import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

/**
 * This returns a function for getting a location's phone number.
 * This is only used to override the location's phone number if the parent provider is featured,
 * the user's network is a SurgeryPlus network and shows featured providers, and the SURGERY_PLUS_PHONE_NUMBER config value is set.
 * @returns {Function} A function for getting a location's phone number
 */
export default function useLocationPhoneNumberOverride(
  originalPhoneNumber,
  isFeaturedProviderLocation
) {
  const showClientFeatured = useSelector(select.featureFlags.showClientFeatured);
  const isSurgeryPlusNetwork = useSelector(select.featureFlags.isSurgeryPlusNetwork);
  const surgeryPlusPhoneNumber = useSelector(select.content.surgeryPlusPhoneNumber);

  const phoneNumber =
    isFeaturedProviderLocation &&
    showClientFeatured &&
    isSurgeryPlusNetwork &&
    surgeryPlusPhoneNumber
      ? surgeryPlusPhoneNumber
      : originalPhoneNumber;

  return phoneNumber;
}
