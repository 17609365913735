/** the character used to separate npi and locationId */
export const KEY_SEPARATOR = ':';

/**
 * @typedef {object} DestructuredCostKey
 * @property {string} npi
 * @property {string} locationId
 */

/**
 * This is used to create the key for caching cost responses
 * @param {DestructuredCostKey}
 * @returns {string} A string in the format npi:locationId
 */
export function createProviderCostKey({ npi, locationId }) {
  if (!npi) throw new Error('Invalid npi');
  if (!locationId) throw new Error('Invalid locationId');

  return `${npi}${KEY_SEPARATOR}${locationId}`;
}

/**
 *
 * @param {string} key
 * @returns {DestructuredCostKey} An object containing the npi, and locationId property
 */
export function destructureProviderCostKey(key) {
  const i = key.indexOf(KEY_SEPARATOR);
  if (i < 0) throw new Error(`Invalid provider cost key - does not contain ${KEY_SEPARATOR}`);
  const npi = key.slice(0, i);
  const locationId = key.slice(i + 1);

  return { npi, locationId };
}
