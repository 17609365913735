/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import thunkErrorSerializer from 'store/thunkErrorSerializer';
import { ENDPOINTS, PARAMS } from 'store/fusionServices/fusionConstants';
import { axiosInstance as selectAxiosInstance } from '../config/selectAxios';
import { COST_SLICE_NAME } from '../slicesNames';
import { createProviderCostKey } from './costUtils';

export const fetchProviderCost = createAsyncThunk(
  `${COST_SLICE_NAME}/fetchProviderCost`,
  async (args, thunkApi) => {
    const { getState } = thunkApi;
    const { npi, locationId, locationState, productIds, networkSlug, locationTier } = args;

    const state = getState();

    if (!npi) throw new Error('Missing npi');
    if (!locationId) throw new Error('Missing locationId');
    if (!productIds) throw new Error('Missing providerIDs');
    if (!locationState) throw new Error('Missing locationState');
    if (!networkSlug) throw new Error('Missing networkSlug');

    const queryParams = new URLSearchParams();
    queryParams.append(PARAMS.NPI, npi);
    queryParams.append(PARAMS.LOCATION_ID, locationId);
    queryParams.append(PARAMS.PRODUCT_IDS, productIds);
    queryParams.append(PARAMS.NETWORK_SLUG, networkSlug);
    queryParams.append(PARAMS.LOCATION_STATE, locationState);
    if (locationTier) {
      queryParams.append(PARAMS.LOCATION_TIER, locationTier);
    }

    const url = `${ENDPOINTS.PROVIDER_COST}/?${queryParams.toString()}`;

    const axios = selectAxiosInstance(state);

    const response = await axios.get(url);

    return response.data;
  },
  {
    // this condition will check if the key already exists in the cache. if so it will prevent the thunk from being dispatched
    // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
    condition: (args, thunkApi) => {
      const { getState } = thunkApi;
      const { npi, locationId } = args;
      const key = createProviderCostKey({ locationId, npi });
      if (getState()[COST_SLICE_NAME][key]) {
        // if this key already exists, do not fetch again
        return false;
      }
      return true;
    },
    serializeError: thunkErrorSerializer,
  }
);
