import React from 'react';
import PopulateDataToggle from './PopulateDataToggle';

export default function withDataToggle(Component) {
  function ReturnComponent(props) {
    return (
      <>
        <PopulateDataToggle />
        <Component {...props} />
      </>
    );
  }

  return ReturnComponent;
}
