/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { AUTH_SLICE_NAME } from '../slicesNames';
import { currentSlug } from '../config/selectNetworks';

// eslint-disable-next-line import/prefer-default-export
export const isLoggedIn = createSelector(
  [(state) => state[AUTH_SLICE_NAME].isLoggedIn, currentSlug],
  (isLoggedIn, networkSlug) => Boolean(isLoggedIn && networkSlug)
);
