import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { thunks, select } from 'store/toolkit';

/**
 * This hook watches the state of networkSlug and anytime it changes, it attempts to fetch any active reward campaign from the API and load them into Redux.
 *
 * Reward campaigns are only available to closed access clients using SSO logins, so if those conditions are not met, this hook will do nothing.
 */
export default function useRewards() {
  const dispatch = useDispatch();

  const networkSlug = useSelector(select.networks.currentSlug);
  const enableRewards = useSelector(select.featureFlags.enableRewards);

  useEffect(() => {
    if (enableRewards && networkSlug) {
      dispatch(thunks.rewards.fetchCampaign());
    }
  }, [networkSlug, dispatch, enableRewards]);
}
