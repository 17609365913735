import { createContext, useContext } from 'react';

export const TableContext = createContext();

/** This hook can be used to access values provided by the parent table */
export default function useTableValue() {
  const tableContext = useContext(TableContext);

  if (!tableContext) throw new Error('useTableValue must be called inside a Provider');

  return tableContext;
}
