import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { SUBSPECIALTY_SECTION, COST_EFFICIENCY_SECTION } from 'utils/constants';
import { select } from 'store/toolkit';
import ScoreTierCell from 'components/ModernExperience/CompareTable/CellComponents/ScoreTierCell';
import ProviderActionButtonsRow from './ProviderActionButtonsRow';
import NetworkCoverageCell from './CellComponents/NetworkCoverageCell';
import AcceptingNewPatientsCell from './CellComponents/AcceptingNewPatientsCell';
import NearestLocationCell from './CellComponents/NearestLocationCell';
import ProviderPhoneNumberCell from './CellComponents/ProviderPhoneNumberCell';
import NPIcell from './CellComponents/NPIcell';
import DistanceCell from './CellComponents/DistanceCell';
import GenderCell from './CellComponents/GenderCell';
import ScoreCell from './CellComponents/ScoreCell';
import StrengthCell from './CellComponents/StrengthCell';
import FocusAreasCell from './CellComponents/FocusAreasCell';
import CostCell from './CellComponents/CostCell';
import MedicalSchoolCell from './CellComponents/MedicalSchoolCell';
import YearsExperienceCell from './CellComponents/YearsExperienceCell';
import CompareTableHeader from './CompareTableHeader';
import CompareRow from './CompareRow';
import SubheaderRow from './SubheaderRow';
import RemoveButtonRow from './RemoveButtonRow';
import { TableContext } from './useTableValue';

const useStyles = makeStyles(() => ({
  firstSubheaderRow: { paddingTop: 0 },
}));

export default function ProviderCompareTable({
  resultIds = [],
  showRemoveButtons,
  showActionButtons,
}) {
  const classes = useStyles();
  const enableEmboldRecommended = useSelector(select.featureFlags.showEmboldRecommended);
  const enableScores = useSelector(select.featureFlags.showDrScore);
  const enableSubspecialties = useSelector(select.featureFlags.showSubspecialties);
  const enableStrengths = useSelector(select.featureFlags.showProviderStrengths);
  const enableCost = useSelector(select.featureFlags.showCost);
  const locationText = useSelector(select.results.locationInput);

  const tableContext = useMemo(
    () => ({
      // include any values that should be available to table children
      resultIds,
      columns: resultIds.length + 1,
    }),
    [resultIds]
  );

  return (
    <TableContext.Provider value={tableContext}>
      <TableContainer>
        <Table>
          <CompareTableHeader />

          <TableBody>
            {showRemoveButtons && <RemoveButtonRow />}
            {showActionButtons && <ProviderActionButtonsRow />}

            <SubheaderRow additionalClass={classes.firstSubheaderRow}>Overview</SubheaderRow>
            <CompareRow
              label="Network Coverage"
              render={(id) => <NetworkCoverageCell resultId={id} />}
            />
            <CompareRow
              label="Nearest Location"
              render={(id) => <NearestLocationCell providerId={id} />}
            />
            {
              // if there is no location input in results, we should not display a distance as it would not be reliably accurate.
              // legacy share links did NOT include location of the initial search. Because of this we would NOT be able to accurately display a meaningful distance
              Boolean(locationText) && (
                <CompareRow
                  label={`Distance (from ${locationText})`}
                  render={(id) => <DistanceCell providerId={id} />}
                />
              )
            }
            <CompareRow
              label="Phone Number"
              render={(id) => <ProviderPhoneNumberCell providerId={id} />}
            />
            <CompareRow
              label="Accepting New Patients"
              render={(id) => <AcceptingNewPatientsCell providerId={id} />}
            />
            <CompareRow label="Gender" render={(id) => <GenderCell providerId={id} />} />
            <CompareRow label="NPI" render={(id) => <NPIcell providerId={id} />} />
            <SubheaderRow>Embold Measurements</SubheaderRow>
            {enableEmboldRecommended && (
              <CompareRow
                label="Provider Quality"
                render={(id) => <ScoreTierCell providerId={id} />}
              />
            )}

            {enableScores && (
              <CompareRow label="Provider Score" render={(id) => <ScoreCell providerId={id} />} />
            )}

            {enableStrengths && (
              <>
                <CompareRow
                  label="Diagnosing"
                  render={(id) => <StrengthCell providerId={id} strengthKey="diagnosingStrength" />}
                />
                <CompareRow
                  label="Treatment Plan"
                  render={(id) => <StrengthCell providerId={id} strengthKey="treatmentStrength" />}
                />
                <CompareRow
                  label="Patient Outcomes"
                  render={(id) => <StrengthCell providerId={id} strengthKey="outcomesStrength" />}
                />
              </>
            )}

            {enableSubspecialties && (
              <CompareRow
                label={SUBSPECIALTY_SECTION.title}
                render={(id) => <FocusAreasCell providerId={id} />}
              />
            )}

            {enableCost && (
              <CompareRow
                label={COST_EFFICIENCY_SECTION.title}
                render={(id) => <CostCell providerId={id} />}
              />
            )}

            <SubheaderRow>Experience & Credentials</SubheaderRow>
            <CompareRow
              label="Medical School"
              render={(id) => <MedicalSchoolCell providerId={id} />}
            />
            <CompareRow
              label="Years Experience"
              render={(id) => <YearsExperienceCell providerId={id} />}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </TableContext.Provider>
  );
}

ProviderCompareTable.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showRemoveButtons: PropTypes.bool,
  showActionButtons: PropTypes.bool,
};

ProviderCompareTable.defaultProps = {
  showRemoveButtons: false,
  showActionButtons: false,
};
