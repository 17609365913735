import { createTheme, darken, lighten } from '@material-ui/core/styles'; // view colorManipulator functions from MUI here: https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/styles/colorManipulator.js
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';

const iconSizes = {
  small: '.875rem',
  medium: '1.125rem',
  large: '2rem',
};

const {
  palette: defaultPalette,
  spacing,
  shadows,
} = createTheme({
  palette: {
    contrastThreshold: 4.5, // AA a11y standards for text contrast ratio

    /* **** Main MUI Colors **** */
    primary: {
      main: '#00205B',
    },
    secondary: {
      main: '#166FC8',
    },
    info: {
      main: '#166FC8',
    },
    success: {
      light: '#00B407',
      main: '#01801D',
      dark: '#236731',
    },
    warning: {
      main: '#C34515',
    },
    error: {
      main: '#D73737',
    },

    grey,

    /* **** Custom Colors **** */
    tertiary: {
      light: '#f6685e',
      main: '#F25645',
      dark: '#9c4336',
      contrastText: '#FFFFFF',
    },

    focusIndicator: '#166FC8',

    scoreTier: {
      1: '#236731',
      2: '#00205B',
      3: '#C34515',
    },

    pink,
    red,
    orange,
    yellow,
    green,
    teal,
    blue,
    purple,
  },
});

export function getDefaultClassOverrides(palette = defaultPalette) {
  // generate MUI Class overrides based on a given set of palette colors
  return {
    MuiCssBaseline: {
      '@global': {
        '*:focus-visible': {
          outlineColor: palette.focusIndicator, // catch-all for non MUI components ex: buttons used inside azure maps
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
      },
    },
    MuiDrawer: {
      root: {
        zIndex: '1600!important',
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 0px) and (orientation: landscape) and (max-width: 600px)': {
          minHeight: 56,
        },
      },
    },
    MuiLink: {
      root: {
        '&$focusVisible': {
          outlineColor: palette.focusIndicator,
        },
      },
      button: {
        '&$focusVisible': {
          outlineColor: palette.focusIndicator,
        },
      },
    },

    /* ******************** */
    /* ***** Buttons ****** */
    /* ******************** */
    MuiButtonBase: {
      root: {
        fontFamily: 'Lato, Arial, sans-serif',
        overflowY: 'hidden',
        overflowX: 'visible',
        '&$focusVisible': {
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: palette.focusIndicator,
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        borderRadius: 50,
        lineHeight: 'inherit',
      },
      iconSizeSmall: {
        '& > *:first-child': { fontSize: iconSizes.small },
      },
      iconSizeMedium: {
        '& > *:first-child': { fontSize: iconSizes.medium },
      },
      iconSizeLarge: {
        '& > *:first-child': { fontSize: iconSizes.large },
      },
      contained: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        '&$focusVisible': {
          outlineWidth: 3,
          outlineOffset: 0,
          outlineColor: palette.grey[700],
        },
        '&.tertiary': {
          backgroundColor: palette.tertiary.main,
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
          },
          '&:hover': {
            backgroundColor: palette.tertiary.dark,
          },
          '&.Mui-focusVisible': {
            outlineColor: lighten(palette.tertiary.main, 0.5),
            backgroundColor: darken(palette.tertiary.main, 0.3),
          },
        },
      },
      containedPrimary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.primary.main, 0.5),
          backgroundColor: darken(palette.primary.main, 0.3),
        },
      },
      containedSecondary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.secondary.main, 0.5),
          backgroundColor: darken(palette.secondary.main, 0.3),
        },
      },
      outlined: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        '&$focusVisible': {
          outlineColor: palette.grey[700],
        },
        '&.white-background': {
          backgroundColor: palette.common.white,
          '&:hover': {
            backgroundColor: darken(palette.common.white, 0.1),
          },
        },
      },
      outlinedPrimary: {
        borderColor: palette.primary.main, // by default, MUI uses a .light color for the border unless hovered, override that
        '&$focusVisible': {
          outlineColor: palette.primary.main,
        },
      },
      outlinedSecondary: {
        borderColor: palette.secondary.main, // by default, MUI uses a .light color for the border unless hovered, override that
        '&$focusVisible': {
          outlineColor: palette.secondary.main,
        },
      },
    },
    MuiFab: {
      root: {
        '&$focusVisible': {
          outlineOffset: 1,
          outlineColor: palette.grey[700],
        },
      },
      primary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.primary.main, 0.5),
          backgroundColor: darken(palette.primary.main, 0.3),
        },
      },
      secondary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.secondary.main, 0.5),
          backgroundColor: darken(palette.secondary.main, 0.3),
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: -2,
        },
      },
    },
    /* ******************** */
    /* ***** Inputs ****** */
    /* ******************** */
    MuiSelect: {
      outlined: {
        fontSize: '1rem',
        textAlign: 'left',
        padding: '0px 0px 0px 20px',
        border: `2px solid #bdbdbd`,
        borderRadius: 18,
        background: 'white',
        '&:focus': {
          border: '2px solid transparent',
          borderRadius: 18,
          background: 'white',
        },
      },
    },
    MuiDivider: {
      root: {
        margin: '1rem 0',
      },
    },
    MuiPopover: {
      paper: {
        padding: '0.5rem',
      },
    },
    MuiFormLabel: {
      root: {
        margin: '0.5rem 0',
        textTransform: 'uppercase',
        color: '#222',
        fontWeight: 'bold',
      },
    },
    MuiInputBase: {
      input: {
        lineHeight: 2,
        fontSize: '1rem',
      },
    },
    MuiInputLabel: {
      root: {
        margin: 0,
        textTransform: 'none',
        color: '#222',
        fontWeight: 'normal',
      },
      shrink: {
        background: 'white',
        paddingLeft: 2,
        marginTop: 1,
      },
    },
    MuiFormGroup: {
      root: {
        padding: '0.5rem 1rem',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderRadius: 20,
          borderColor: '#616161',
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.focusIndicator,
        },
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 8,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px 8px',
        textAlign: 'center',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 1600,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.tertiary.main,
      },
    },
    MuiMenuItem: {
      root: {
        '&.Mui-focusVisible': {
          outline: `2px solid ${palette.focusIndicator}`,
          background: 'none',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        '&.Mui-focusVisible.Mui-selected': {
          background: '#e0e0e0',
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: { fontSize: iconSizes.small },
      fontSizeLarge: { fontSize: iconSizes.large },
    },
    MuiTab: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: -1 * spacing(0.75),
        },
      },
    },
    MuiRadio: {
      root: {
        '&.MuiButtonBase-root.Mui-focusVisible': {
          outline: 'none',
        },
        '&.Mui-focusVisible > .MuiIconButton-label': {
          outline: `2px solid ${palette.focusIndicator}`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.MuiButtonBase-root.Mui-focusVisible': {
          outline: 'none',
        },
        '&.Mui-focusVisible > .MuiIconButton-label': {
          outline: `2px solid ${palette.focusIndicator}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.common.white,
        color: palette.text.primary,
        fontSize: '.75rem',
        boxShadow: shadows[8],
        padding: spacing(1),
        borderRadius: spacing(1),
      },
      arrow: {
        color: palette.common.white,
      },
    },
  };
}

const defaultTheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiLink: {
      color: 'secondary',
      underline: 'always',
    },
    MuiBadge: {
      overlap: 'rectangular', // resolves deprecation warning
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiSkeleton: {
      animation: 'wave',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
      margin: '0.5rem 0',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 1.15,
      margin: '0.5rem 0',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0.25rem 0',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '.875rem',
    },
    body1: {
      fontSize: '.875rem',
    },
    body2: {
      fontSize: '.875rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '.875rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.25,
      display: 'inline-block',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: defaultPalette,
  overrides: getDefaultClassOverrides(),
  iconSizes,
};

export default defaultTheme;
