import React, { useMemo } from 'react';
import PropTypes, { SubspecialtyDataShape } from 'propTypes';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import FocusAreaTypeIcon from 'components/ModernExperience/FocusAreaTypeIcon';
import AccordionList from 'components/ModernExperience/Profile/AccordionList';
import { SUBSPECIALTY_SECTION } from 'utils/constants';
import useSubspecialtyExplanationText from 'hooks/Provider/useSubspecialtyExplanation';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    '& > :nth-child(2)': { marginTop: theme.spacing(1) },
  },
}));

function FocusAreaContent({ subspecialty }) {
  const classes = useStyles();
  const allSubspecialties = useSelector(select.specialties.providerSubspecialties);

  const description = allSubspecialties[subspecialty.subspecialtyId]?.description;
  const explanationText = useSubspecialtyExplanationText(subspecialty, {
    asComponent: true,
  });

  return (
    <Box className={classes.contentContainer}>
      {explanationText}
      {Boolean(description) && <Typography>{description}</Typography>}
    </Box>
  );
}

function SectionSpecializations({ providerId, isPrintMode }) {
  const subspecialties = useSelector(select.provider(providerId).sortedSubspecialties);

  const accordions = useMemo(
    () =>
      subspecialties.map((subspecialty) => ({
        title: subspecialty.subspecialtyName,
        icon: <FocusAreaTypeIcon subspecialty={subspecialty} size={22} />,
        children: <FocusAreaContent subspecialty={subspecialty} />,
      })),
    [subspecialties]
  );

  if (subspecialties.length === 0) return null;

  return (
    <AccordionList
      TitleProps={{
        title: SUBSPECIALTY_SECTION.title,
        icon: <SUBSPECIALTY_SECTION.IconComponent />,
        TooltipProps: {
          message: SUBSPECIALTY_SECTION.description,
          title: SUBSPECIALTY_SECTION.title,
        },
      }}
      ariaId="provider-specializations"
      isPrintMode={isPrintMode}
      accordions={accordions}
    />
  );
}

export default SectionSpecializations;

FocusAreaContent.propTypes = {
  subspecialty: SubspecialtyDataShape.isRequired,
};

SectionSpecializations.propTypes = {
  providerId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

SectionSpecializations.defaultProps = {
  isPrintMode: false,
};
