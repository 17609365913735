import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path
      d="M22.9993 13.3289C23 13.3696 22.9993 13.411 22.9981 13.4518C22.9449 15.0075 21.6164 16.1966 20.0743 16.2422C19.8949 16.2471 19.7149 16.2428 19.5 16.2428C19.7535 16.5992 19.9341 16.9538 20.0369 17.3479C20.4581 18.9675 19.3408 20.6638 17.6769 20.9272C17.5355 20.9497 17.3946 20.9631 17.252 20.9631C14.3649 20.9637 11.4777 20.9643 8.58997 20.9643C8.27592 20.9643 8.05858 20.8001 7.99063 20.5209C7.93676 20.2977 8.05981 20.0319 8.27592 19.9157C8.4106 19.8434 8.56059 19.8379 8.71058 19.8379C11.52 19.8385 14.3294 19.8294 17.1387 19.8428C18.0405 19.847 18.7556 19.2041 18.938 18.4749C19.1872 17.4793 18.504 16.4338 17.4859 16.2769C17.3242 16.2519 17.1645 16.2428 17.0022 16.2428H6.39401C6.08546 16.2428 5.87303 16.0993 5.80079 15.8456C5.69794 15.485 5.94404 15.1505 6.33769 15.1182C6.4087 15.1121 6.48033 15.1164 6.55196 15.1164C11.0222 15.1164 15.4925 15.1085 19.9628 15.1225C20.8775 15.1255 21.5337 14.5623 21.7609 13.9334C22.1833 12.7633 21.3091 11.5244 20.0528 11.5244C15.959 11.5225 11.8646 11.5244 7.77085 11.5244C6.62236 11.5244 5.47448 11.5244 4.326 11.5237C3.95378 11.5237 3.69727 11.2945 3.69727 10.9642C3.69727 10.6339 3.95194 10.3949 4.32293 10.3949C6.58073 10.3931 8.83914 10.3943 11.0969 10.3937C13.1148 10.3937 15.1326 10.3815 17.1504 10.3998C17.9824 10.4071 18.9043 9.79645 18.9919 8.69681C19.0592 7.85324 18.395 6.99993 17.5532 6.8424C17.4124 6.81625 17.2716 6.80409 17.1283 6.80409C14.0398 6.8053 10.9518 6.80409 7.86329 6.80652C7.60005 6.80652 7.39312 6.72319 7.27313 6.48174C7.09743 6.12715 7.34108 5.71358 7.74514 5.67587C7.8033 5.67039 7.86207 5.67404 7.92023 5.67404C10.9763 5.67404 14.0324 5.67343 17.0885 5.67404C18.6135 5.67404 19.8037 6.63622 20.0816 8.12389C20.2218 8.87623 20.0467 9.57932 19.628 10.2216C19.6035 10.2587 19.5765 10.2952 19.5533 10.3335C19.5471 10.3432 19.5484 10.3572 19.5441 10.384C19.8318 10.4156 20.1208 10.3907 20.4079 10.4223C21.86 10.5816 22.9865 11.8905 22.9993 13.3289Z"
      fill="#4379D3"
    />
    <path
      d="M20.7027 13.326C20.7003 13.6581 20.4548 13.8825 20.0923 13.8825C15.5565 13.8831 11.0208 13.8825 6.48496 13.8831C5.59298 13.8831 4.88772 14.4165 4.66794 15.2522C4.38143 16.3427 5.20607 17.4527 6.34109 17.4703C7.56733 17.4891 8.79418 17.4776 10.0204 17.4776C12.3951 17.4782 14.7705 17.4776 17.1452 17.4782C17.5389 17.4782 17.766 17.6497 17.8174 17.9794C17.8645 18.2816 17.6301 18.5766 17.3234 18.6015C17.2909 18.604 17.2585 18.6034 17.226 18.6034C13.5987 18.6034 9.97144 18.6058 6.34415 18.6009C5.00588 18.5985 3.79801 17.5865 3.54027 16.2825C3.36824 15.4121 3.56292 14.6275 4.08207 13.9068C3.97677 13.8211 3.84637 13.8466 3.73311 13.8199C2.23322 13.4677 1.26166 12.0111 1.42022 10.5915C1.47103 10.139 1.63633 9.69076 1.93569 9.28265C2.52096 8.48651 3.31376 8.07598 4.2994 8.03644C4.69427 8.02063 5.09036 8.03401 5.48584 8.03401C5.51278 7.95798 5.45523 7.92271 5.42524 7.87831C4.62325 6.69779 4.78365 5.12376 5.81643 4.13847C6.38211 3.59899 7.06532 3.31739 7.85078 3.31557C10.0633 3.31009 12.2764 3.31374 14.4889 3.31313C14.7362 3.31313 14.9309 3.40011 15.0496 3.62332C15.1611 3.83254 15.1341 4.03629 14.9958 4.22118C14.8654 4.39452 14.678 4.44683 14.4644 4.44622C12.2715 4.44378 10.078 4.44622 7.88506 4.44378C7.46754 4.44318 7.08981 4.55752 6.76596 4.81418C6.17579 5.28128 5.93275 5.89861 6.09743 6.62845C6.25967 7.34735 6.7329 7.80228 7.45529 7.99265C7.61385 8.03462 7.77486 8.0334 7.9371 8.0334H17.1642C17.5229 8.0334 17.7452 8.19397 17.8094 8.49503C17.8786 8.81859 17.6393 9.13608 17.3056 9.16284C17.2407 9.1677 17.1758 9.16405 17.1109 9.16405H4.39919C3.26478 9.16405 2.44504 10.0502 2.54116 11.1705C2.6134 12.0086 3.40253 12.7458 4.25165 12.7512C5.41177 12.7585 6.5725 12.7525 7.73262 12.7543C8.53583 12.7561 9.33904 12.7628 10.1429 12.7628C10.9467 12.7628 11.7493 12.7549 12.5525 12.7549C13.3557 12.7549 14.2238 12.7634 15.0594 12.764C15.7463 12.7646 16.4326 12.7567 17.1195 12.7555C18.1174 12.7537 19.1159 12.7512 20.1144 12.7567C20.4529 12.7585 20.7058 13.0152 20.7027 13.326Z"
      fill="#4379D3"
    />
    <path
      d="M5.50049 20.5802L6.83026 21.5178C6.96783 21.6147 7.15759 21.5164 7.15759 21.348L7.15759 19.4729C7.15759 19.3046 6.96783 19.2062 6.83026 19.3032L5.50049 20.2408C5.38315 20.3235 5.38315 20.4975 5.50049 20.5802Z"
      fill="#D5EDFF"
    />
    <path
      d="M15.9645 3.98552L17.2712 4.9292C17.4085 5.02839 17.6004 4.93026 17.6004 4.76085L17.6004 2.87348C17.6004 2.70408 17.4085 2.60595 17.2712 2.70513L15.9645 3.64882C15.8498 3.73171 15.8498 3.90262 15.9645 3.98552Z"
      fill="#D5EDFF"
    />
  </>,
  'Colonoscopy'
);
