import { select } from 'store/toolkit';
import { CONVERSION } from './analyticsConstants';
import selectResultAnalyticsAttributes, {
  selectClosestProviderLocationData,
} from './selectAnalytics';
import { getChatProviderAttributes } from './analyticsUtils';

/* ********************************* */
/* *** 300-399 Conversion Events *** */
/* ********************************* */

export default {
  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.directionsOpenedFromResultCard(providerId));
   */
  directionsOpenedFromResultCard: {
    code: 300,
    category: CONVERSION,
    description: 'A user clicked on a directions link on a result card',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Directions Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Directions Click - <Place|Provider>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.directionsOpenedFromProfile(providerId));
   */
  directionsOpenedFromProfile: {
    code: 301,
    category: CONVERSION,
    description: 'A user clicked on a directions link from a profile page',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Directions Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Directions Click - <Place|Provider>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromResultCard(providerId));
   */
  phoneClickFromResultCard: {
    code: 302,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a result card.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Phone Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Phone Click - <Place|Provider>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromProfile(providerId));
   */
  phoneClickFromProfile: {
    code: 303,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a profile.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Phone Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Phone Click - <Place|Provider>',
  },

  /**
   * This action expects an object with the location coordinates object of the chat and the provider object.
   * @param {object} action Expected payload: {coordinates: {latitude: <>, longitude: <>}, providerData: providerObject}
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromChatProfile({coordinates: coords, providerData: provider}));
   */
  phoneClickFromChatProfile: {
    code: 304,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a chat profile card.',
    getEventData: (state, { payload = {} }) => {
      const providerAttributes = getChatProviderAttributes(payload);
      return {
        ...providerAttributes,
        eventAction: `Contact Provider`,
        eventLabel: `Phone Click - Provider`,
      };
    },
    labelDescription: 'Contact Provider',
    actionDescription: 'Phone Click - Provider',
  },

  /**
   * This action expects an object with the location coordinates object of the chat and the provider object.
   * @param {object} action Expected payload: {coordinates: {latitude: <>, longitude: <>}, providerData: providerObject}
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.directionsClickFromChatProfile({coordinates: coords, providerData: provider}));
   */
  directionsClickFromChatProfile: {
    code: 305,
    category: CONVERSION,
    description: 'A user clicked on a directions link from a chat profile location.',
    getEventData: (state, { payload = {} }) => {
      const providerAttributes = getChatProviderAttributes(payload);
      return {
        ...providerAttributes,
        eventAction: `Contact Provider`,
        eventLabel: `Directions Click - Provider`,
      };
    },
    labelDescription: 'Contact Provider',
    actionDescription: 'Directions Click - Provider',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.websiteClickFromResultCard(providerId));
   */
  websiteClickFromResultCard: {
    code: 306,
    category: CONVERSION,
    description: 'A user clicked on a website link from a result card.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Website Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Website Click - <Place|Provider>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.websiteClickFromProfile(providerId));
   */
  websiteClickFromProfile: {
    code: 307,
    category: CONVERSION,
    description: 'A user clicked on a website link from a result profile.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Website Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Website Click - <Place|Provider>',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.websiteClickFromPromotionResultCard(promotion.programName));
   */
  websiteClickFromPromotionResultCard: {
    code: 308,
    category: CONVERSION,
    description: 'A user clicked on a website link from a promotion result card.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Website Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Website Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.websiteClickFromPromotionProfile(promotion.programName));
   */
  websiteClickFromPromotionProfile: {
    code: 309,
    category: CONVERSION,
    description: 'A user clicked on a website link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Website Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Website Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.directionsClickFromPromotionProfile(promotion.programName));
   */
  directionsClickFromPromotionProfile: {
    code: 310,
    category: CONVERSION,
    description: 'A user clicked on a directions link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Directions Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Directions Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromPromotionProfile(promotion.programName));
   */
  phoneClickFromPromotionProfile: {
    code: 311,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Phone Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Phone Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromPromotionResultCard(promotion.programName));
   */
  phoneClickFromPromotionResultCard: {
    code: 312,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a promotion result card.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Phone Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Phone Click - Promote',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromNeedHelpButton(providerId));
   */
  phoneClickFromNeedHelpButton: {
    code: 313,
    category: CONVERSION,
    description:
      'A user clicked on the phone number link in the Call Support modal. This is opened by clicking on the Need Help button in a result profile.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Phone Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Phone Click - <Place|Provider>',
  },

  /**
   * This action expects a provider id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.scheduleClickFromNeedHelpButton(providerId));
   */
  scheduleClickFromNeedHelpButton: {
    code: 314,
    category: CONVERSION,
    description: 'A user clicked on the Schedule button on provider profile.',
    getEventData: (state, { payload = '' }) => {
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      return {
        ...resultAttributes,
        eventAction: `Contact Provider`,
        eventLabel: `Schedule Click - Provider`,
      };
    },
    labelDescription: 'Contact Provider',
    actionDescription: 'Schedule Click - Provider',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.sharedProfile(providerId));
   */
  sharedProfile: {
    code: 315,
    category: CONVERSION,
    description: 'Result profile that was sent in a share.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Share ${resultType}`,
        eventLabel: `Share ${resultType}`,
      };
    },
    labelDescription: 'Share <Provider|Place>',
    actionDescription: 'Share <Provider|Place>',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.accessAppLinkFromPromotionProfile(promotion.programName));
   */
  accessAppLinkFromPromotionProfile: {
    code: 316,
    category: CONVERSION,
    description: 'A user clicked on an access app link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Access App Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Access App Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.accessChatLinkFromPromotionProfile(promotion.programName));
   */
  accessChatLinkFromPromotionProfile: {
    code: 317,
    category: CONVERSION,
    description: 'A user clicked on a access chat link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Access Chat Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Access Chat Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.accessEmailFromPromotionProfile(promotion.programName));
   */
  accessEmailFromPromotionProfile: {
    code: 318,
    category: CONVERSION,
    description: 'A user clicked on a access email link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Access Email Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Access Email Click - Promote',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.accessPDFfromPromotionProfile(promotion.programName));
   */
  accessPDFfromPromotionProfile: {
    code: 319,
    category: CONVERSION,
    description: 'A user clicked on a access pdf link from a promotion profile.',
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `Access PDF Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: 'Access PDF Click - Promote',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.printProfile(providerId));
   */
  printProfile: {
    code: 320,
    category: CONVERSION,
    description: 'A user has clicked on the Print button for a profile.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        eventAction: `Print ${resultType}`,
        eventLabel: `Print ${resultType}`,
      };
    },
    labelDescription: 'Print <Provider|Place>',
    actionDescription: 'Print <Provider|Place>',
  },

  /**
   * This action expects an array of provider or place ids.
   * @param {array} action Expected payload: Array of Provider or Place Ids
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.printCompareTable(providerIds));
   */
  printCompareTable: {
    code: 321,
    category: CONVERSION,
    description: 'A user has clicked on the Print button on a compare table.',
    getEventData: (state, { payload = [''] }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        profileIds: payload.join(','),
        eventAction: `Print Compare Table ${resultType}`,
        eventLabel: `Print Compare Table ${resultType}`,
      };
    },
    labelDescription: 'Print Compare Table <Provider|Place>',
    actionDescription: 'Print Compare Table <Provider|Place>',
  },

  /**
   * This action expects an array of provider or place ids.
   * @param {array} action Expected payload: Array of Provider or Place Ids
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.printShareCompareTable(providerIds));
   */
  printShareCompareTable: {
    code: 322,
    category: CONVERSION,
    description: 'A user has clicked on the Print button on a compare table from a share.',
    getEventData: (state, { payload = [''] }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        profileIds: payload.join(','),
        eventAction: `Print Share Compare Table ${resultType}`,
        eventLabel: `Print Share Compare Table ${resultType}`,
      };
    },
    labelDescription: 'Print Share Compare Table <Provider|Place>',
    actionDescription: 'Print Share Compare Table <Provider|Place>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.phoneClickFromCompareModal(providerId || placeId));
   */
  phoneClickFromCompareModal: {
    code: 323,
    category: CONVERSION,
    description: 'A user clicked on a phone number from a compare modal.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const providerLocationData = !isPlaceSearch
        ? selectClosestProviderLocationData(payload)(state)
        : {};
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        ...providerLocationData,
        eventAction: `Contact ${resultType}`,
        eventLabel: `Phone Click - ${resultType}`,
      };
    },
    labelDescription: 'Contact <Place|Provider>',
    actionDescription: 'Phone Click - <Place|Provider>',
  },

  /**
   * This action expects a provider or place id to collect relevant result attributes.
   * @param {string} action Expected payload: Provider or Place Id
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.addressClickFromCompareModal(providerId || placeId));
   */
  addressClickFromCompareModal: {
    code: 324,
    category: CONVERSION,
    description: 'A user clicked on an address from a compare modal.',
    getEventData: (state, { payload = '' }) => {
      const isPlaceSearch = select.search.isPlaceSearch(state);
      const resultAttributes = selectResultAnalyticsAttributes(payload)(state);
      const providerLocationData = !isPlaceSearch
        ? selectClosestProviderLocationData(payload)(state)
        : {};
      const resultType = isPlaceSearch ? 'Place' : 'Provider';
      return {
        ...resultAttributes,
        ...providerLocationData,
        eventAction: `Address Click ${resultType}`,
        eventLabel: `Address Click - ${resultType}`,
      };
    },
    labelDescription: 'Address Click <Place|Provider>',
    actionDescription: 'Address Click - <Place|Provider>',
  },

  /**
   * This action accept a promotion programName string to send to the event.
   * @param {string} action Expected payload: Promotion programName
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.gettingStartedClickFromPromotionProfile(promotion.programName));
   */
  getStartedClickFromPromotionProfile: {
    code: 325,
    category: CONVERSION,
    description: `A user clicked on the 'Get Started' button from a promotion profile`,
    getEventData: (state, { payload = '' }) => ({
      entityName: payload,
      eventAction: `Contact Promote`,
      eventLabel: `'Get Started' Click - Promote`,
    }),
    labelDescription: 'Contact Promote',
    actionDescription: `'Get Started' Click - Promote`,
  },
};
