import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useContactCardContext from 'hooks/useContactCardContext';
import MiniMap from '../../MiniMap';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    height: 250,
  },
}));

export default function ResultMiniMap() {
  const classes = useStyles();
  const { locations, index, setIndex } = useContactCardContext();

  const handleMarkerClick = useCallback(
    (evt) => {
      const newIndex = evt.shapes?.[0]?.id;
      if (newIndex >= 0 && newIndex < locations.length) {
        setIndex(newIndex);
      }
    },
    [locations.length, setIndex]
  );

  if (!locations.length || !locations[index]) return null;

  return (
    <MiniMap
      locations={locations}
      selectedIndex={index}
      onMarkerClick={handleMarkerClick}
      className={classes.map}
      options={{ interactive: true, zoom: 10 }}
    />
  );
}
