import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

export default function YearsExperienceCell({ providerId }) {
  const { yearsExperience } = useSelector(select.provider(providerId).data) || {};

  // not all networks have this data, but on the backend it will default to 0
  if (!yearsExperience) return <NoDataIcon />;

  return yearsExperience;
}

YearsExperienceCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
