import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { select, actions } from 'store/toolkit';

/**
 * This hook is intended to be mounted in App.jsx
 *
 * It is responsible for  watching the network slug.
 * If the network slug has changed it should clear the costSlice cache.
 * It is not necessary for this effect to fire on initial mount.
 */
export default function useCostCache() {
  const networkSlug = useSelector(select.networks.currentSlug);
  const dispatch = useDispatch();

  const [compareSlug, setCompareSlug] = useState(networkSlug);

  useEffect(() => {
    // the reason for this if block is to not fire this on initial mount
    if (networkSlug !== compareSlug) {
      dispatch(actions.cost.clear());
      setCompareSlug(networkSlug);
    }
  }, [dispatch, networkSlug, setCompareSlug, compareSlug]);
}
