import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  SCORE_COLORS_BY_TIER,
  SCORE_TIER_ICON_ALT_TEXT,
  SCORE_TIER_ICON_COMPONENTS,
  VALID_SCORE_TIERS,
} from 'utils/constants';
import useThemeColor from 'hooks/useThemeColor';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => (props.inverted ? props.color : theme.palette.common.white),
    border: (props) => (props.inverted ? `2px solid ${props.color}` : 'none'),
  },
  icon: {
    width: '125%',
    height: '125%', // MUI Icons have some padding built into the svg, setting this over 100% avoids showing that
    color: (props) => (props.inverted ? theme.palette.common.white : props.color),
  },
}));

export default function ScoreTierIcon({ tier, tierColor, inverted, ...props }) {
  const Icon = SCORE_TIER_ICON_COMPONENTS[tier];
  // use dynamic color if one is passed, default to constant
  const colorName = tierColor || SCORE_COLORS_BY_TIER[tier];
  const alt = SCORE_TIER_ICON_ALT_TEXT[tier];
  const color = useThemeColor(colorName);
  const classes = useStyles({ color, inverted });

  if (!Icon) return null;
  return (
    <Avatar classes={{ root: classes.root }} {...props}>
      <Icon className={classes.icon} titleAccess={alt} />
    </Avatar>
  );
}

ScoreTierIcon.propTypes = {
  tier: PropTypes.oneOf(VALID_SCORE_TIERS).isRequired,
  tierColor: PropTypes.string,
  inverted: PropTypes.bool,
};

ScoreTierIcon.defaultProps = {
  tierColor: undefined,
  inverted: false,
};
