import React, { useState, useEffect, useMemo } from 'react';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import { makeStyles, MenuItem } from '@material-ui/core';
import TextInput from 'components/Forms/TextInput';
import { SCENARIO_ID } from './useChatAcknowledgementFormSchema';

const useStyles = makeStyles((theme) => ({
  dimensions: {
    width: 300,
    marginTop: 10,
  },
  scenarioDropdown: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiSelect-outlined': {
      padding: '4px 14px!important',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

export default function ScenarioDropdown() {
  const classes = useStyles();

  const enableChatScenario = useSelector(select.featureFlags.enableChatScenario);
  const configIsLoading = useSelector(select.config.isLoading);
  const axios = useSelector(select.axios.axiosInstance);
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [loadingScenarios, setLoadingScenarios] = useState(false);

  useEffect(() => {
    if (!configIsLoading && enableChatScenario) {
      setLoadingScenarios(true);
      axios('/chat-scenarios')
        .then((result) => setScenarioOptions(result.data))
        .finally(() => setLoadingScenarios(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading, enableChatScenario]);

  const showScenarioSelect = useMemo(
    () => scenarioOptions.length > 0 && enableChatScenario,
    [enableChatScenario, scenarioOptions.length]
  );

  if (loadingScenarios) {
    return <Skeleton variant="rect" height={40} className={classes.dimensions} animation="wave" />;
  }

  if (!showScenarioSelect) return null;

  return (
    <TextInput
      select
      id="scenario-dropdown"
      variant="outlined"
      name={SCENARIO_ID}
      label="Scenario"
      classes={{ root: `${classes.dimensions} ${classes.scenarioDropdown}` }}
    >
      {scenarioOptions.map((option) => (
        <MenuItem value={option.scenarioId} key={option.scenarioId}>
          {`${option.scenarioId} - ${option.scenarioLabel}`}
        </MenuItem>
      ))}
    </TextInput>
  );
}
ScenarioDropdown.propTypes = {};
ScenarioDropdown.defaultProps = {};
