import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { AzureMapsProvider } from 'react-azure-maps';

import { select } from 'store/toolkit';
import { MapLoadingModal } from 'components/Modals/MapLoadingModal';
import { MAP_LOADING_WRAPPER_Z_INDEX, MAP_LOADING_MODAL_Z_INDEX } from 'utils/constants';
import ResultsMap from './Map/ResultsMap';
import ActiveResultCard from './ResultCards/MapResultCard/ActiveResultCard';
import ClusterSlider from './ResultCards/MapResultCard/ClusterSlider';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 5,
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0px 0px`,
    },
    '&:has(canvas.maplibregl-canvas.atlas-map-canvas:focus-visible)': {
      outline: `2px solid ${theme.palette.focusIndicator}`,
    },
    '& canvas.maplibregl-canvas.atlas-map-canvas:focus-visible': {
      outline: 'none',
    },
  },
  activeResultWrapper: {
    position: 'absolute',
    bottom: 8,
    width: 500,
    maxWidth: 'calc(100% - 16px)',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  loaderWrapper: {
    width: 175,
    height: 100,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: MAP_LOADING_MODAL_Z_INDEX,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
  },

  modalBackground: {
    zIndex: MAP_LOADING_WRAPPER_Z_INDEX,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(0,0,0,0.5)',
  },
}));

function MapLoading() {
  const classes = useStyles();

  return (
    <div className={classes.modalBackground} data-testid="map-loading-spinner">
      <div className={classes.loaderWrapper}>
        <MapLoadingModal open text="Loading Results" />
      </div>
    </div>
  );
}

function MapSection({ className, containerHeight }) {
  const classes = useStyles();
  const isClusterSelected = useSelector(select.results.isClusterSelected);
  const hasActiveMapMarker = useSelector(select.results.hasActiveMapMarker);
  const showMap = useSelector(select.ui.showResultsMap);
  const isMapLoading = useSelector(select.map.isLoading);
  const resultsLoading = useSelector(select.results.isLoading);
  const mapOptions = useSelector(select.map.azureMapOptions); // we don't want to initialize the map until all of our map options are available

  const ProviderMapCard = isClusterSelected ? <ClusterSlider /> : <ActiveResultCard />;

  return (
    <AzureMapsProvider>
      <section
        className={clsx(classes.root, { [className]: className })}
        aria-hidden={!showMap}
        id="map-container"
      >
        {(isMapLoading || resultsLoading) && showMap && <MapLoading />}
        {mapOptions && <ResultsMap containerHeight={containerHeight} />}
        <div data-testid="active-result-wrapper-map" className={classes.activeResultWrapper}>
          {hasActiveMapMarker && ProviderMapCard}
        </div>
      </section>
    </AzureMapsProvider>
  );
}

MapSection.propTypes = {
  className: PropTypes.string,
  containerHeight: PropTypes.number,
};

MapSection.defaultProps = {
  className: undefined,
  containerHeight: undefined,
};

export default React.memo(MapSection);
