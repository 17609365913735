import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { COST_EFFICIENCY_MAP, COST_EFFICIENCY_SECTION } from 'utils/constants';

const sortedCostTiers = Object.keys(COST_EFFICIENCY_MAP)
  .sort()
  .reverse()
  .map((key) => Number(key));

const useStyles = makeStyles((theme) => {
  const mobileOnly = theme.breakpoints.down('xs');

  return {
    root: {
      display: 'flex',
      width: '100%',
      padding: theme.spacing(1),
      minWidth: 100,
      [mobileOnly]: {
        flexDirection: 'column',
      },

      '& > *': {
        flex: 1,
      },
    },
    costStep: {
      padding: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column-reverse',
      [mobileOnly]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    text: { textAlign: 'center', flex: 1 },
    textInactive: { color: theme.palette.grey[700] },
    textActive: { fontWeight: 'bold' },
    colorBar: {
      borderRadius: theme.spacing(1),
      height: theme.spacing(1.5),
      width: '100%',
      [mobileOnly]: {
        height: 75,
        width: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    colorBarInactive: { backgroundColor: theme.palette.grey[500] },
    colorBarTier3: { backgroundColor: theme.palette.success.main },
    colorBarTier2: { backgroundColor: theme.palette.warning.main },
    colorBarTier1: { backgroundColor: theme.palette.error.main },
  };
});

function CostStep({ tier, isActive }) {
  const classes = useStyles();

  const getColorBarClasses = useCallback(
    (tierValue) => {
      const barClasses = [classes.colorBar];

      if (isActive) {
        barClasses.push(classes[`colorBarTier${tierValue}`]);
      } else {
        barClasses.push(classes.colorBarInactive);
      }

      return barClasses.join(' ');
    },
    [classes, isActive]
  );

  const textClasses = clsx(classes.text, {
    [classes.textActive]: isActive,
    [classes.textInactive]: !isActive,
  });

  return (
    <Box className={classes.costStep} aria-hidden={!isActive}>
      <Box className={getColorBarClasses(tier)} />
      <Typography className={textClasses} color={isActive ? 'primary' : 'inherit'}>
        <Typography component="span" variant="srOnly">
          this provider is{' '}
        </Typography>
        {COST_EFFICIENCY_MAP[tier]}
      </Typography>
    </Box>
  );
}

export default function CostStepper({ costTier }) {
  const classes = useStyles();

  const screenReaderText = `${COST_EFFICIENCY_SECTION.title} is measured as ${COST_EFFICIENCY_MAP[3]}, ${COST_EFFICIENCY_MAP[2]} or ${COST_EFFICIENCY_MAP[1]}`;

  return (
    <Box className={classes.root}>
      <Typography variant="srOnly">{screenReaderText}</Typography>
      {sortedCostTiers.map((tier) => (
        <CostStep tier={tier} isActive={tier === costTier} key={`CostStep-${tier}`} />
      ))}
    </Box>
  );
}

CostStep.propTypes = {
  tier: PropTypes.oneOf(sortedCostTiers).isRequired,
  isActive: PropTypes.bool.isRequired,
};

CostStepper.propTypes = {
  costTier: PropTypes.oneOf(sortedCostTiers).isRequired,
};

CostStepper.defaultProps = {};
