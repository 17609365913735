import { NOTIFICATIONS_SLICE_NAME } from '../slicesNames';

/** @returns {String} Message displayed in alert */
export const message = (state) => state[NOTIFICATIONS_SLICE_NAME].message;
/** @returns {String} The variant of alert - success, info, warning or error */
export const severity = (state) => state[NOTIFICATIONS_SLICE_NAME].severity;
/** @returns {Boolean} True if the notification is shown currently */
export const isOpen = (state) => state[NOTIFICATIONS_SLICE_NAME].isOpen;
/** @returns {Number} The duration of the notification in milliseconds before it's auto closed */
export const duration = (state) => state[NOTIFICATIONS_SLICE_NAME].duration;
/** @returns {String} The key of the focusAnchor to return to after close */
export const returnFocusKey = (state) => state[NOTIFICATIONS_SLICE_NAME].returnFocusKey;
