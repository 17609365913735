import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { VALID_HEADING_VARIANTS } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(2),
    margin: 0,
    '& li': {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(0.5),
      listStyleType: 'disc',
    },
    '& li:nth-child(even)': {
      paddingRight: 0,
    },
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

export default function BenefitWhenToUseList({
  headingLevel,
  title,
  list = [],
  isMobile,
  ...props
}) {
  const classes = useStyles();

  if (!Array.isArray(list) || !list.length) return null;

  return (
    <div>
      <Typography variant="h4" component={headingLevel} classes={{ root: classes.heading }}>
        {title}:
      </Typography>
      <Grid component="ul" container classes={{ root: classes.list }} {...props}>
        {list.map((el) => (
          <Grid item component="li" xs={isMobile ? 12 : 6} key={el}>
            {el}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

BenefitWhenToUseList.propTypes = {
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS),
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
};

BenefitWhenToUseList.defaultProps = {
  headingLevel: 'h4',
  title: 'When to use',
  isMobile: false,
};
