import { DEFAULT_LOCATIONS, DOLLAR_GENERAL_SUPPORT, DROPDOWN } from 'utils/constants';
import DollarGeneralLoginLogo from 'assets/bcbst-login-logo.svg';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 1c4d058eb180257d25be814441b1ea788ecc7107',
  staging: 'Token d5b5f37e679ac7d73c61465da2115d084c8e641d',
  prod: 'Token 88a12471a0c85a5a8d5c483a864e710f895345d4',
};

const token = selectToken(apiTokens);

const dollarGeneralSupportConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'bcbst',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'dgbcbst-support',
  EMBOLD_CLIENT: DOLLAR_GENERAL_SUPPORT,
  EMBOLD_CLIENT_SLUG: 'dgbcbst-support',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Goodlettsville },
  LOGIN_NETWORK_NAME: 'dollarGeneralSupportUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['dlgtn|dgiga|dgqnh|degmoks|dlzwi|dlhnj|pdgok|jdldc|dgsfl', 'i'],

  // content
  DISPLAY_NAME: 'Dollar General',
  SCHEDULE_PHONE_NUMBER: '1-800-521-9919',
  HEADER_LOGO: DollarGeneralLoginLogo,
  SUPPORT_LINK: 'https://dgsupport.emboldhealth.com/portal/',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  FORM_VARIANT: DROPDOWN,
  WELCOME_MESSAGE: `Dollar General and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: DollarGeneralLoginLogo,
  NO_WELCOME_LINK: false,
};

export default dollarGeneralSupportConfig;
