import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import useIconNode from 'hooks/useIconNode';

const useStyles = makeStyles((theme) => ({
  iconAvatar: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.35rem',
    background: 'transparent',
    color: theme.palette.primary.main,
    '& img, svg': {
      width: '1rem',
      height: '1rem',
    },
  },
}));

function IconLabel({ img, textClass, children, TypographyProps, ...props }) {
  const classes = useStyles();
  const iconEl = useIconNode(img);

  return (
    <Grid item xs={12} container alignItems="center" {...props}>
      <Grid item>
        <Avatar className={`${classes.iconAvatar} icon-avatar`}>{iconEl}</Avatar>
      </Grid>

      <Grid item style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
        <Typography className={textClass} {...TypographyProps}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
}

IconLabel.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  textClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  TypographyProps: PropTypes.shape({}),
};

IconLabel.defaultProps = {
  textClass: undefined,
  TypographyProps: {},
};

export default IconLabel;
