import { createSelector } from '@reduxjs/toolkit';
import { logDevMessage } from 'utils/utils';

import tooltipSlice from './tooltipSlice';

export const getTitle = (state) => state[tooltipSlice.name].title;
export const getMessage = (state) => state[tooltipSlice.name].message;
export const getLink = (state) => state[tooltipSlice.name].link;
export const getHref = (state) => state[tooltipSlice.name].href;
export const getTooltipState = (state) => state[tooltipSlice.name];
export const isOpen = createSelector([getMessage], (message) => Boolean(message));
export const linkPresent = createSelector(getLink, (link) => link.to && link.label);
export const renderHref = createSelector([getTooltipState], (state) => {
  // if an href and internal link are passed into tooltip, only return href and issue a warning
  if (state.link.to && state.href.to) {
    logDevMessage(
      'Href and link.href props simultaneously given to component. Link.href will be overridden by the Href'
    );
    return state.href;
  }

  return state.href.to && state.href.label ? state.href : null;
});
