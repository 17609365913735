import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';

import { actions, select } from 'store/toolkit';
import PrintButton from 'components/PrintButton';
import CopyTextButton from 'components/CopyTextButton';
import SplitCompareTableForPrint from 'components/ModernExperience/CompareTable/SplitCompareTableForPrint';
import { _analyticsActions } from 'analytics/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `0px auto ${theme.spacing(2)}px`,
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function CompareModalActions({ showPrintButton, title }) {
  const classes = useStyles();
  const [isPrinting, setIsPrinting] = useState(false);

  const dispatch = useDispatch();
  const isProviderCompare = useSelector(select.results.isResultsListProviders);
  const comparedResults = useSelector(select.results.comparedResults);
  const compareIds = useSelector(select.results.compareListIds);
  const searchText = useSelector(select.search.text);
  const compareProvidersString = useSelector(select.toString.providers(compareIds));
  const comparePlacesString = useSelector(select.toString.places(compareIds));
  const showShareButton = useSelector(select.featureFlags.showShare);

  const resultNoun = useMemo(
    () => (isProviderCompare ? 'Providers' : 'Locations'),
    [isProviderCompare]
  );

  const clipboardFullText = useMemo(() => {
    let result = `Compare ${compareIds.length} ${searchText} ${resultNoun}`;
    result += '\n\n';
    result += isProviderCompare ? compareProvidersString : comparePlacesString;
    return result;
  }, [
    resultNoun,
    isProviderCompare,
    compareProvidersString,
    comparePlacesString,
    searchText,
    compareIds,
  ]);

  // props to be added to each button
  const buttonProps = useMemo(
    () => ({
      variant: 'outlined',
      color: 'primary',
    }),
    []
  );

  const shareResults = useCallback(() => {
    dispatch(actions.ui.openProfileModal({ type: 'share', data: comparedResults }));
  }, [dispatch, comparedResults]);

  return (
    <Box className={classes.root}>
      {showShareButton && (
        <Button {...buttonProps} startIcon={<ShareIcon />} onClick={shareResults}>
          Share All {resultNoun}
        </Button>
      )}

      {showPrintButton && (
        <PrintButton
          {...buttonProps}
          startIcon={isPrinting ? <CircularProgress size={20} /> : <PrintIcon />}
          printComponent={
            <SplitCompareTableForPrint
              resultIds={compareIds}
              header={
                <Typography variant="h2" color="primary" align="center">
                  {title}
                </Typography>
              }
            />
          }
          options={{
            onBeforePrint: () => {
              dispatch(_analyticsActions.printCompareTable(compareIds));
              setIsPrinting(true);
            },
            onAfterPrint: () => setIsPrinting(false),
          }}
          includeWatermark
        >
          Print All {resultNoun}
        </PrintButton>
      )}

      <CopyTextButton
        {...buttonProps}
        textToCopy={clipboardFullText}
        buttonText="Copy To Clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
}

CompareModalActions.propTypes = {
  showPrintButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CompareModalActions.defaultProps = {
  showPrintButton: false,
};
