import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import useTableValue from './useTableValue';

const useStyles = makeStyles((theme) => ({
  row: {
    '& > *': {
      padding: theme.spacing(0.5),
      borderBottom: 'none',
      lineHeight: '1rem',
      maxWidth: 180,
      verticalAlign: 'top',
    },
  },
}));
function HeaderCell({ resultId, ...props }) {
  const { name, entityName } = useSelector(select.results.byId(resultId)); // get name for places

  return (
    <TableCell {...props}>
      <strong>{entityName || name}</strong>
    </TableCell>
  );
}

export default function CompareTableHeader() {
  const classes = useStyles();
  const { resultIds } = useTableValue();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell />
        {resultIds.map((id) => (
          <HeaderCell key={`head-${id}`} resultId={id} />
        ))}
      </TableRow>
    </TableHead>
  );
}

HeaderCell.propTypes = {
  resultId: PropTypes.string.isRequired,
};
