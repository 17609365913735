import React from 'react';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Collapse } from '@material-ui/core/';
import {
  VIRTUAL_BENEFIT_PRESENTED,
  VIRTUAL_BENEFIT_USE_LIKELYHOOD,
  YES_NO_OPTIONS,
  RATING_QUESTIONS,
} from 'components/Modals/ChatModal/Feedback/constants';

import TextInput from 'components/Forms/TextInput';
import FeedbackRating from 'components/Modals/ChatModal/Feedback/FeedbackRating';
import SubmitButton from 'components/Forms/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margingLeft: 16,
    marginTop: 16,
    padding: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontWeight: 400,
    maxWidth: '90%',
    margin: '20px auto',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  feedbackText: {
    width: '100%',
    margin: '0 0 1rem',
    '& textarea': {
      lineHeight: 1.5,
    },
  },
  textFieldLabel: {
    display: 'inline-block',
    padding: '13px 0 5px',
  },
  sumbitErrorMessage: {
    marginBottom: 4,
  },
}));

const FEEDBACK_TITLE_ID = 'chat-feedback-description';

function ChatFeedbackForm() {
  const { values, errors, submitCount } = useFormikContext();
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <div role="radiogroup" aria-labelledby={FEEDBACK_TITLE_ID}>
        <Typography id={FEEDBACK_TITLE_ID} variant="h2" className={classes.title}>
          Feedback
        </Typography>

        <Grid container justifyContent="center">
          <div className={classes.innerWrapper}>
            {RATING_QUESTIONS.map((ratingQuestion) => (
              <Grid style={{ marginBottom: 20 }} key={ratingQuestion.name}>
                <FeedbackRating
                  key={ratingQuestion.question}
                  question={ratingQuestion.question}
                  ratingOptions={ratingQuestion.ratingOptions}
                  name={ratingQuestion.name}
                  showLabels={ratingQuestion.ratingOptions.length > 2}
                />
              </Grid>
            ))}
            <Collapse
              in={values[VIRTUAL_BENEFIT_PRESENTED]}
              mountOnEnter
              unmountOnExit
              timeout={600}
            >
              <Grid style={{ marginBottom: 20 }}>
                <FeedbackRating
                  question="Are you more likely to use a virtual care benefit after this conversation?"
                  ratingOptions={YES_NO_OPTIONS}
                  name={VIRTUAL_BENEFIT_USE_LIKELYHOOD}
                />
              </Grid>
            </Collapse>

            <Typography
              className={classes.textFieldLabel}
              component="label"
              htmlFor="feedback-message"
            >
              How can we improve?
            </Typography>

            <TextInput
              id="feedback-message"
              multiline
              minRows="5"
              name="message"
              margin="dense"
              variant="outlined"
              label=""
              className={classes.feedbackText}
              inputProps={{
                maxLength: 1024,
              }}
            />
            <Grid container direction="column" alignItems="center">
              {!isEmpty(errors) && submitCount > 0 && (
                <Typography color="error" className={classes.sumbitErrorMessage}>
                  {errors.message ? 'Invalid message' : 'Please complete all required fields.'}
                </Typography>
              )}
              <SubmitButton />
            </Grid>
          </div>
        </Grid>
      </div>
    </Container>
  );
}

export default ChatFeedbackForm;
