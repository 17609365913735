import React, { useCallback } from 'react';
import PropTypes from 'propTypes';

import { select } from 'store/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import ContactCard from 'components/ModernExperience/ResultCards/ResultLocations/ContactCard';

function PlaceContactCard({ placeId, className, showMap, isPrintMode, isProfile }) {
  const dispatch = useDispatch();
  const placeData = useSelector(select.place(placeId).data);

  const onAddressClick = useCallback(() => {
    if (isProfile) {
      dispatch(_analyticsActions.directionsOpenedFromProfile(placeId));
    } else {
      dispatch(_analyticsActions.directionsOpenedFromResultCard(placeId));
    }
  }, [dispatch, isProfile, placeId]);

  const onPhoneClick = useCallback(() => {
    if (isProfile) {
      dispatch(_analyticsActions.phoneClickFromProfile(placeId));
    } else {
      dispatch(_analyticsActions.phoneClickFromResultCard(placeId));
    }
  }, [dispatch, isProfile, placeId]);

  return (
    <ContactCard
      locations={[placeData]}
      title="Contact Information"
      className={className}
      showMap={showMap}
      showDisclaimer
      onAddressClick={onAddressClick}
      onPhoneClick={onPhoneClick}
      headingLevel="h3"
      isPrintMode={isPrintMode}
    />
  );
}

PlaceContactCard.propTypes = {
  placeId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showMap: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  isProfile: PropTypes.bool,
};

PlaceContactCard.defaultProps = {
  className: undefined,
  showMap: false,
  isPrintMode: false,
  isProfile: false,
};

export default React.memo(PlaceContactCard);
