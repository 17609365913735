import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

import { getValuesFromMapRef } from 'utils/mapUtils';
import { select, actions } from 'store/toolkit';

/** This hook runs multiple effects that allow Redux to control the Azure Map
 * It returns an object containing all of the necessary event handlers for the map
 */
export default function useSyncMapToRedux(mapRef) {
  const pendingCameraUpdates = useSelector(select.map.pendingCameraUpdates);
  const dispatch = useDispatch();

  // this handles the mounting and unmounting of this hook
  useEffect(() => {
    dispatch(actions.map.mounted());
    return () => dispatch(actions.map.unmounted());
  }, [dispatch]);

  // this effect watches pending camera updates and passes those updates to the maps setCamera method
  // this allows us to control the map via Redux
  useEffect(() => {
    if (mapRef && !isEmpty(pendingCameraUpdates)) {
      mapRef.setCamera({
        type: 'ease',
        duration: 400,
        ...pendingCameraUpdates,
      });
    }
  }, [mapRef, pendingCameraUpdates]);

  /* **************************** */
  /* *** Map Event Handlers ***** */
  /* **************************** */

  const handleMapLoad = useCallback(
    (evt) => {
      const { map } = evt;
      const mapValues = getValuesFromMapRef(map);
      dispatch(actions.map.loaded(mapValues));
    },
    [dispatch]
  );

  // we want a debounced function to update Redux, this avoids excessive dispatches to Redux
  // this is just to dispatch the current values of the map to Redux so it's ok if the Redux dispatch lags behind
  const debouncedDispatchMapMoveEnd = useMemo(
    () => debounce((payload) => dispatch(actions.map.moved(payload)), 300),
    [dispatch]
  );

  const handleMapMoveEnd = useCallback(
    (evt) => {
      const { map } = evt;
      const mapValues = getValuesFromMapRef(map);
      debouncedDispatchMapMoveEnd(mapValues);
    },
    [debouncedDispatchMapMoveEnd]
  );

  // sync movedByUser redux action
  const handleMapMovedByUser = useCallback(() => dispatch(actions.map.movedByUser()), [dispatch]);

  const handleZoom = useCallback(
    (e) => {
      const isZoomingOut = e.originalEvent?.deltaY > 0;
      if (isZoomingOut) {
        dispatch(actions.results.clearActiveResults());
      }
    },
    [dispatch]
  );

  const mapEventHandlers = useMemo(
    // example events can be found at https://samples.azuremaps.com/map/map-events
    () => ({
      load: handleMapLoad,
      moveend: handleMapMoveEnd,
      dragend: handleMapMovedByUser,
      wheel: handleMapMovedByUser,
      zoom: handleZoom,
    }),
    [handleMapLoad, handleMapMoveEnd, handleMapMovedByUser, handleZoom]
  );

  return mapEventHandlers;
}
