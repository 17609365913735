import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { select } from 'store/toolkit';
import { useFocusTo } from 'utils/FocusRefContext';
import Modal from 'components/Modals/Modal';
import useStartOver from 'hooks/useStartOver';
import { SUPPORT_ROUTE } from '../../navigation/navigationConstants'; // for some reason eslint can't resolve an absolute path to navigation

function AppErrorModal() {
  const appErrorModalOpen = useSelector(select.ui.appErrorModalOpen);
  const hideSupport = useSelector(select.featureFlags.hideSupport);
  const focusTo = useFocusTo();
  const startOver = useStartOver();

  return (
    <Modal
      open={appErrorModalOpen}
      handleClose={startOver}
      exitFunction={focusTo.locationInput}
      ariaId="app-error-modal"
      title="Unexpected Error"
      errorModal
    >
      <Typography paragraph>
        Sorry, an unexpected error has occurred, please begin a new search.
      </Typography>

      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={startOver}
          style={{ marginBottom: 20 }}
          autoFocus
        >
          Start over
        </Button>
      </div>

      {!hideSupport && (
        <div style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            component={RouterLink}
            to={SUPPORT_ROUTE}
            style={{ marginBottom: 20 }}
          >
            Support
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default AppErrorModal;
