import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import useGetNetworkContent from 'hooks/Provider/useGetNetworkContent';

export default function NetworkCoverageCell({ resultId }) {
  const result = useSelector(select.results.byId(resultId));
  const getNetworkContent = useGetNetworkContent();
  const networkContent = getNetworkContent(result);

  if (!networkContent.networkText) return null;

  return <span>{networkContent.networkText}</span>;
}

NetworkCoverageCell.propTypes = {
  resultId: PropTypes.string.isRequired,
};
