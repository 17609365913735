import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path d="M12 7V3H2V21H22V7H12ZM10 19H4V17H10V19ZM10 15H4V13H10V15ZM10 11H4V9H10V11ZM10 7H4V5H10V7ZM20 19H18V16H14V19H12V9H20V19Z" />
    <path d="M15.3333 10V11.3333H14V12.6667H15.3333V14H16.6667V12.6667H18V11.3333H16.6667V10H15.3333Z" />
  </>,
  'Place'
);
