import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';

import Modal from 'components/Modals/Modal';
import { actions, select } from 'store/toolkit';

const useStyles = makeStyles(() => ({
  modalHeader: {
    width: '95%',
    margin: '10px auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
  },
  modalStyles: {
    '& [data-reach-dialog-content]': {
      padding: '15px 40px',
    },
  },
}));

function InfoModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileModal = useSelector(select.ui.profileModal);

  return (
    <Modal
      handleClose={() => {
        dispatch(actions.ui.closeProfileModal());
      }}
      open
      ariaId="info-modal"
      customStyles={classes.modalStyles}
    >
      <>
        <Typography variant="h2" id="info-modal-title" classes={{ root: classes.modalHeader }}>
          {profileModal.data.text}
        </Typography>

        <Grid item xs={12} container>
          <Grid item id="info-modal-description">
            <Typography paragraph align="left">
              {profileModal.data.modalText}
            </Typography>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default InfoModal;
