import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { uniqueId } from 'lodash';

import { select } from 'store/toolkit';
import SupportPageSection from './SupportPageSection';

const useStyles = makeStyles((theme) => ({
  faq: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  question: {
    marginBottom: theme.spacing(1),
  },
}));

function useFaqContent() {
  const appName = useSelector(select.content.appName);

  return useMemo(
    () => [
      {
        question: `Is ${appName} compatible with all web browsers and mobile Software versions?`,
        answer: `${appName} works best with the latest versions of Google Chrome, Microsoft Edge, Firefox, and Safari. Please keep in mind using a VPN may impact the experience. 
        When using a mobile device, ${appName} works best with Apple iOS (iOS 15 and higher) and Android (Android 10 and higher). Please keep in mind the user experience may vary on different mobile devices, even if the mobile software version is compatible.`,
      },
      {
        question: `What should I do if I am experiencing technical issues with ${appName}?`,
        answer: `If you are experiencing technical issues with ${appName}, first verify that the web browser or mobile software version you are using is compatible with ${appName} and up to date. For technical assistance, submit a ticket to Embold Health Customer Service that includes a detailed description of the problem you are experiencing, any search parameters you are using for your search, and screen shots.`,
      },
      {
        question: `Are all providers included in ${appName}?`,
        answer: `${appName} includes specialties and providers covered under your medical benefits plan. Providers within dental benefits plans and vision benefits plans are not included.`,
      },
      {
        question: `Why don’t all providers have a score?`,
        answer: `If a provider is practicing in one of the evaluated specialties, but does not have a score, it means the provider practices in a geographic region Embold does not evaluate or Embold does not have enough claims data for that provider. This could be due to the provider being new to the area or because they have a sub specialty not covered by our current measures.`,
      },
    ],
    [appName]
  );
}

function FAQ({ question, answer }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.faq}>
      <Typography
        classes={{ root: classes.question }}
        component="strong"
        variant="h4"
        color="primary"
      >
        {question}
      </Typography>
      <Typography>{answer}</Typography>
    </ListItem>
  );
}

FAQ.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default function FrequentlyAskedQuestions() {
  const faqContent = useFaqContent();

  return (
    <SupportPageSection title="Frequently Asked Questions" id="faq">
      <List>
        {faqContent.map((faq) => (
          <FAQ question={faq.question} answer={faq.answer} key={`faq-${uniqueId()}}`} />
        ))}
      </List>
    </SupportPageSection>
  );
}
