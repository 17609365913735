import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

export default function HospitalSafetyGradeCell({ placeId }) {
  const { hospitalGrade, hospitalGradeLastUpdated } = useSelector(select.place(placeId).data) || {};

  if (!hospitalGrade) return <NoDataIcon />;

  let output = hospitalGrade;
  if (hospitalGradeLastUpdated) output += ` (${hospitalGradeLastUpdated})`;
  return output;
}

HospitalSafetyGradeCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
