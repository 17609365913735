import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { validateLatLong, hashForKey, parseJSON } from 'utils/utils';
import { userLoggedIn } from 'store/appThunks';
import { LOCATION_SLICE_NAME } from 'store/slices/slicesNames';
import { updateStoreFromUrl, clearSearchForm, startOver } from 'store/appActions';
import { specialtySearchInPg, goToHomepage } from 'store/slices/chat/chatSlice';
import { executeSearch } from '../results/resultsThunks';
import { geolocateMapCenter } from './locationThunks';

const storedLocationHistory = parseJSON(localStorage.getItem('locationHistory')) || [];

const initialState = {
  latLong: { latitude: null, longitude: null },
  latLongResolved: false, // indicates valid coordinates from browser, autocomplete or history
  locationInput: '', // the actual text value of the location input element
  locationResolved: false,
  locationComponents: {
    city: null,
    zip: null,
    state: null,
  },
  userDeniedLocation: false,
  fetchingGeo: false,
  geolocationResolved: false, // indicates that the locationComponents are valid via either autocomplete, history or react-geolocation fetch
  isLoading: false, // indicates that either the latLong or the locationComponents are being set asynchronously
  error: null,
  locationModalOpen: false,
  hasUpdatedFromURL: null,
  locationHistory: storedLocationHistory,
};

const locationSlice = createSlice({
  name: LOCATION_SLICE_NAME,
  initialState,
  reducers: {
    /* eslint no-param-reassign: 0 */
    /* RTK allows for direct state mutation in slice reducer actions: https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer */
    clear(state) {
      state.latLongResolved = false;
      state.geolocationResolved = false;
    },

    /* Populates with default location values after login is complete */
    setDefaultLocation(state, action) {
      const defaultLocation = action.payload;
      state.latLong.latitude = action.payload.latLong.latitude;
      state.latLong.longitude = action.payload.latLong.longitude;

      state.latLongResolved = defaultLocation.locationResolved || defaultLocation.latLongResolved; // indicates valid coordinates from browser, autocomplete or history
      state.locationInput = defaultLocation.locationInput; // the actual text value of the location input element

      state.locationComponents.city = defaultLocation.locationComponents.city;
      state.locationComponents.zip = defaultLocation.locationComponents.zip;
      state.locationComponents.state = defaultLocation.locationComponents.state;
      state.locationResolved = defaultLocation.locationResolved;
      state.geolocationResolved = defaultLocation.geolocationResolved; // indicates that the locationComponents are valid via either autocomplete, history or react-geolocation fetch
      state.error = defaultLocation.error;
    },

    setLocationHistory(state, action) {
      state.locationHistory = action.payload;
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setLocationInput(state, action) {
      state.locationInput = action.payload;
    },

    /* ************************** */
    /* ** autocomplete actions ** */
    /* ************************** */

    handleAutoCompleteSelected(state, action) {
      state.locationInput = action.payload.label;
      state.latLong = action.payload.latLong;
      state.locationResolved = action.payload.locationResolved;
      state.locationComponents = action.payload.locationComponents;
    },

    handleClearLocation(state) {
      // do not reset the latitude and longitude in this function to null, as it will break the map and any searches
      state.locationInput = '';
      state.locationResolved = false;
      state.geolocationResolved = false;
    },

    handleLocationAutoCompleteChange(state, action) {
      state.locationInput = action.payload;
      state.locationResolved = false;
      state.geolocationResolved = false;
    },

    handleUserTextInput(state, action) {
      // user is typing in autocomplete
      state.locationInput = action.payload;
      state.latLongResolved = false;
      state.latLongResolved = false;
    },

    updateLocationFromAutocompleteSuggestion(state, action) {
      // user has selected an autocomplete suggestion
      const suggestion = action.payload;
      state.locationInput = suggestion.locationInput; // autocomplete drop down
      state.latLong = {
        latitude: suggestion.latLong.latitude,
        longitude: suggestion.latLong.longitude,
      };
      state.locationResolved = true;
      state.latLongResolved = true;
      state.locationComponents = {
        city: suggestion.locationComponents.city,
        state: suggestion.locationComponents.state,
        zip: suggestion.locationComponents.zip || null,
      };
      state.isLoading = false;
      state.error = null;
    },
    updateLocationFromHistory(state, action) {
      // user has selected a location from history
      const selectedHistory = action.payload;

      state.latLong = selectedHistory.latLong;
      state.locationInput = selectedHistory.locationInput;
      state.locationComponents = selectedHistory.locationComponents;
      state.latLongResolved = true;
      state.locationResolved = true;
      state.isLoading = false;
      state.error = null;
    },

    /* ************************** */
    /* ** geolocation actions *** */
    /* ************************** */
    startGetGeolocation(state) {
      state.isLoading = true;
      state.fetchingGeo = true;
      state.error = null;
    },

    closeLocationModal(state) {
      state.locationModalOpen = false;
    },

    handleBrowserSupportError(state, action) {
      state.isLoading = false;
      state.fetchingGeo = false;
      state.error =
        action.payload ||
        'Geolocation is not supported on your browser, please enter city or zipcode in the location field';
    },
    updateLatLongFromBrowserPosition(state, action) {
      const location = action.payload; // the location from navigator.getCurrentPosition()
      const { latitude, longitude } = location.coords;

      if (validateLatLong(latitude, longitude)) {
        state.latLong = { latitude, longitude };
      }
    },
    updateLocationComponentsFromGeocodeResponse(state, action) {
      const { locationInput, locationComponents, latLong } = action.payload;
      state.locationInput = locationInput;
      state.locationComponents.city = locationComponents.city;
      state.locationComponents.zip = locationComponents.zip;
      state.locationComponents.state = locationComponents.state;

      state.latLong = {
        latitude: latLong.latitude,
        longitude: latLong.longitude,
      };

      state.fetchingGeo = false;
      state.userDeniedLocation = false;
      state.geolocationResolved = true;
      state.locationResolved = true;
      state.locationModalOpen = false;
    },
    handleGeocodeError(state, action) {
      const error = action.payload; // error from failed geocode.fromLatLong()
      state.isLoading = false;
      state.fetchingGeo = false;
      state.userDeniedLocation = true;
      state.locationResolved = true;
      state.error = error.message || 'Failed to obtain location components from lat & long';
    },

    /* ************************** */
    /*          login             */
    /* ************************** */
  },
  extraReducers(builder) {
    // execute search
    builder.addCase(executeSearch.fulfilled, (state, action) => {
      const { request } = action.payload;
      state.locationHistory.unshift({
        id: hashForKey(
          JSON.stringify([
            request.location.coordinates.latitude,
            request.location.coordinates.longitude,
          ])
        ),
        locationInput: request.location.input,
        latLong: {
          latitude: request.location.coordinates.latitude,
          longitude: request.location.coordinates.longitude,
        },
        locationComponents: {
          city: request.location.city,
          state: request.location.state,
          zip: request.location.zip || null,
        },
      });
    });

    builder.addCase(clearSearchForm, (state) => {
      state.locationInput = '';
    });

    builder
      .addCase(geolocateMapCenter.pending, (state) => {
        state.isLoading = true;
        state.fetchingGeo = true;
        state.error = null;
      })
      .addCase(geolocateMapCenter.fulfilled, (state, action) => {
        const { locationInput, locationComponents, latLong } = action.payload;
        state.locationInput = locationInput;
        state.locationComponents.city = locationComponents.city;
        state.locationComponents.zip = locationComponents.zip;
        state.locationComponents.state = locationComponents.state;

        state.latLong = {
          latitude: latLong.latitude,
          longitude: latLong.longitude,
        };

        state.locationResolved = true;
        state.geolocationResolved = false;
        state.isLoading = false;
        state.fetchingGeo = false;
        state.error = initialState.error;
      })
      .addCase(geolocateMapCenter.rejected, (state) => {
        state.isLoading = false;
        state.fetchingGeo = false;
      });

    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const queryParams = action.payload || {};

      const { location_input, location, city, state: stateName, zip } = queryParams;

      if (location) {
        // this is nested inside because if we don't have a location param (coordinates), we should not be setting the text input. Otherwise a user would see location text, but under-the-hood there would be no coordinates that the app relies on
        if (location_input) {
          state.locationInput = location_input;
        }

        state.latLong = location;
        state.latLongResolved = true; // because the location param came from useQueryParams, it should have already been validated
      }

      if (city && stateName) {
        state.locationComponents = {
          city: city,
          state: stateName,
          zip: zip || null,
        };
        state.locationResolved = true;
      }

      state.hasUpdatedFromURL = true;
    });

    builder.addCase(userLoggedIn.fulfilled, (state, action) => {
      const { payload } = action;

      // if state has not already been inherited from url query params
      // usually the case for a refresh of closed access clients
      if (!state.hasUpdatedFromURL) {
        state.latLong = {
          latitude: payload.defaultLocation?.lat,
          longitude: payload.defaultLocation?.long,
        };
        state.locationInput = `${payload.defaultLocation?.city}, ${payload.defaultLocation?.state}`;
        state.locationComponents = {
          city: payload.defaultLocation?.city,
          zip: payload.defaultLocation?.zip,
          state: payload.defaultLocation?.state,
        };
      }
    });

    builder.addCase(startOver, (state, action) => {
      const { lastLocation = {} } = action.payload;
      const { locationInput, latLong, locationComponents } = lastLocation;

      if (validateLatLong(latLong?.latitude, latLong?.longitude)) {
        state.latLong = latLong;
        state.latLongResolved = true;

        if (locationComponents?.city && locationComponents?.state) {
          state.locationInput =
            locationInput || `${locationComponents.city}, ${locationComponents.state}`;
          state.locationComponents = locationComponents;
          state.locationResolved = true;
        }
      }
    });

    builder.addMatcher(isAnyOf(specialtySearchInPg, goToHomepage), (state, action) => {
      const { city, stateAbbreviation, location = {} } = action.payload;

      const hasValidLocation = Boolean(
        city && stateAbbreviation && location?.latitude && location?.longitude
      );

      if (hasValidLocation) {
        state.locationInput = `${city}, ${stateAbbreviation}` || '';
        state.latLong = {
          latitude: location.latitude,
          longitude: location.longitude,
        };
        state.locationComponents = {
          zip: null,
          city: city,
          state: stateAbbreviation,
        };
        state.locationResolved = true;
        state.locationModalOpen = false;
      }
    });
  },
});

export const {
  clear,
  setDefaultLocation,
  setLocationHistory,
  setIsLoading,
  setLocationInput,
  handleAutoCompleteSelected,
  handleClearLocation,
  handleLocationAutoCompleteChange,
  handleUserTextInput,
  updateLocationFromAutocompleteSuggestion,
  updateLocationFromHistory,
  startGetGeolocation,
  closeLocationModal,
  handleBrowserSupportError,
  updateLatLongFromBrowserPosition,
  updateLocationComponentsFromGeocodeResponse,
  handleGeocodeError,
  handleUpdateLocationFromMapCenter,
} = locationSlice.actions;
export default locationSlice;
