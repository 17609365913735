import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';

import WarningIcon from '@material-ui/icons/Warning';
import { select, thunks } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: '95%',
    maxWidth: 1000,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.warning.main,
    fontSize: '3rem',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
    '& > button, a': { minWidth: 200 },
  },
}));

export default function NoActiveRewards() {
  const classes = useStyles();
  const isError = Boolean(useSelector(select.rewards.error));
  const dispatch = useDispatch();

  const buttonProps = {
    variant: 'contained',
    color: 'primary',
  };

  const message = isError
    ? 'Looks like there was an issue handling your request. Please refresh your page or try again later.'
    : 'The reward you are looking for may no longer be available. Please check back later to see if a new reward is added.';

  const title = isError ? 'Something went wrong' : 'There are no active rewards available';

  const handleRetry = useCallback(() => dispatch(thunks.rewards.fetchCampaign()), [dispatch]);

  return (
    <Container className={classes.root} maxWidth="md">
      <Paper className={classes.paper} elevation={0}>
        <WarningIcon className={classes.icon} />
        <Typography variant="h1" component="h2" align="center">
          {title}
        </Typography>
        <Typography align="center">{message}</Typography>

        <Box className={classes.buttonContainer}>
          <Button {...buttonProps} component={RouterLink} to="/">
            Go Home
          </Button>
          <Button {...buttonProps} component={RouterLink} to="/support">
            Contact Support
          </Button>
          {isError && (
            <Button {...buttonProps} onClick={handleRetry}>
              Retry
            </Button>
          )}
        </Box>
      </Paper>
    </Container>
  );
}
