import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';

import { select } from 'store/toolkit';
import NetworkSelector from '../NetworkSelects/NetworkSelector';
import FieldErrorText from '../FieldErrorText';

// this component is intended to set the form's networkSlug if it has already been set with SSO
// it hides the network select if that is the case.
export default function SSONetworkController({ name }) {
  const [, , { setValue }] = useField(name); // this is the setValue for the field with the matching name
  const [isSso, setIsSso] = useState(false); // isSso indicates that the networkSlug was set via query params
  const configLoading = useSelector(select.config.isLoading);
  const currentNetworkSlug = useSelector(select.networks.currentSlug);

  useEffect(() => {
    // don't do anything until client config has loaded
    if (!configLoading && currentNetworkSlug) {
      // if loading has completed and we have a current network slug, then it must be valid
      // in configSlice, we check the validity when the config fetch completes
      setValue(currentNetworkSlug); // update our networkSlug in the form
      setIsSso(true); // hide the network selector in the form
    }
  }, [configLoading, currentNetworkSlug, setValue, isSso]);

  if (isSso) {
    // we still want to include the option to show error text, just in case something went wrong and the networkSlug didn't pass validation
    // this shouldn't happen but it would give the user and indication that something was wrong rather than nothing. It also gives them the
    // option to open the network selector
    return (
      <FieldErrorText name={name}>
        {/* Mui links can be used as buttons. See https://v4.mui.com/components/links/#accessibility */}
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <Link component="button" style={{ textAlign: 'left' }} onClick={() => setIsSso(false)}>
          Choose your network
        </Link>
      </FieldErrorText>
    );
  }
  return <NetworkSelector name={name} />;
}

SSONetworkController.propTypes = {
  name: PropTypes.string.isRequired,
};
