import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" />
    <path d="M11 7H13V17H11V7Z" />
    <path d="M15 13H17V17H15V13Z" />
    <path d="M7 10H9V17H7V10Z" />
  </>,
  'Strengths'
);
