import React, { useMemo, useEffect } from 'react';
import PropTypes from 'propTypes/index';
import { useHistory, withRouter, Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Container, Typography, Link } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { thunks, select } from 'store/toolkit';

import { GiftIcon } from 'icons';
import RedeemRewardForm from 'components/Forms/Rewards/RedeemRewardForm';

import PageTemplate from '../PageTemplate';
import RewardLimitDisclaimer from './RewardLimitDisclaimer';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 8,
  },
  disclaimer: {
    marginTop: theme.spacing(8),
    fontWeight: 700,
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 *
 * Page containing the form to receive a reward for booking an appointment with a
 * high performing provider, according to terms defined in a network's reward campaign.
 *
 * A user may visit this route directly or through a provider's profile.
 *
 */
function RedeemRewardPage({ match }) {
  const classes = useStyles();
  const id = parseInt(match.params.id, 10);
  const history = useHistory();

  const providerData = useSelector(select.provider(id).data);
  const providerNameAndTitle = useSelector(select.provider(id).fullNameAndTitle);

  // provider data
  const dispatch = useDispatch();
  const providerDataIsLoading = useSelector(select.results.isLoading);
  const providerDataError = useSelector(select.results.error);
  const showRewards = useMemo(() => providerData?.showRewards, [providerData]);

  // rewards campaign data
  const hasActiveCampaign = useSelector(select.rewards.hasActiveCampaign);
  const userIsEligible = useSelector(select.rewards.userIsEligible);
  const campaignIsLoading = useSelector(select.rewards.isLoading);
  const rewardsError = useSelector(select.rewards.error);

  const isLoading = useMemo(
    () => campaignIsLoading || providerDataIsLoading,
    [campaignIsLoading, providerDataIsLoading]
  );

  const isFetchError = useMemo(
    () => rewardsError || providerDataError,
    [rewardsError, providerDataError]
  );

  const errorContent = useMemo(() => {
    if (isLoading) return '';
    if (isFetchError) return "We've encountered a problem, please refresh or try again later";
    if (!hasActiveCampaign) return 'There are no active reward campaigns for this provider';
    if (!userIsEligible) return 'You have already redeemed your reward';
    if (!showRewards)
      return (
        <>
          This provider does not meet the criteria for this rewards campaign.{' '}
          <Link component={RouterLink} to="/rewards">
            See reward campaign details
          </Link>
        </>
      );
    return '';
  }, [isFetchError, hasActiveCampaign, isLoading, showRewards, userIsEligible]);

  useEffect(() => {
    if (providerData) {
      // do nothing
    } else if (id) {
      dispatch(thunks.results.searchProviderById({ id }));
    } else {
      history.push('/rewards');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Container>
        <Typography variant="h1" color="primary">
          <GiftIcon classes={{ root: classes.icon }} />
          Reward Redemption Form
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Get rewarded for visiting a high-quality provider.
        </Typography>
        <Typography paragraph>
          You’ve found a high-quality doctor! Complete this form to redeem a digital gift card. Once
          the form is submitted, you’ll receive an email with instructions from tangocard.com.
          Follow the link to gain access to your digital gift card.
        </Typography>
        <Typography paragraph>
          Congratulations, you are on your way to better healthcare!
        </Typography>
      </Container>
      {Boolean(errorContent) && (
        <Alert
          severity="error"
          data-testid="rewards-form-error"
          classes={{ root: classes.errorMessage }}
        >
          {errorContent}
        </Alert>
      )}
      <RedeemRewardForm
        providerNameAndTitle={providerNameAndTitle}
        npi={Number(id)}
        disabled={Boolean(errorContent)}
        loading={isLoading}
      />
      <Container>
        <RewardLimitDisclaimer classes={{ root: classes.disclaimer }} />
      </Container>
    </Container>
  );
}

RedeemRewardPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(PageTemplate(RedeemRewardPage, 'Redeem Rewards'));
