import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import { makeStyles } from '@material-ui/styles';

import ResultCardMapViewClusterHeader from '../ResultCardMapViewClusterHeader';
import RoundedWrapper from '../StyledWrappers/RoundedWrapper';
import ResultSlider from './ResultSlider';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    background: 'white',
  },
  cardTransitionWrapper: {
    transition: '.25s linear transform',
    display: 'none',
  },
  activeCard: {
    display: 'block',
  },
}));

export default function ClusterSlider() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const clusterIds = useSelector(select.results.clusterIds);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const handleClose = useCallback(() => dispatch(actions.results.clearActiveResults()), [dispatch]);

  return (
    <RoundedWrapper additionalStyles={classes.card}>
      <ResultCardMapViewClusterHeader
        onClick={handleClose}
        index={selectedIndex}
        setIndex={setSelectedIndex}
        length={clusterIds.length}
      />
      <ResultSlider selectedIndex={selectedIndex} wasPlaceSearch={wasPlaceSearch} />
    </RoundedWrapper>
  );
}
