import { DEFAULT_LOCATIONS, DROPDOWN_AUTOCOMPLETE, WALMART_NATIONAL } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import walmartLogo from 'assets/pg-walmart-header-logo.png';
import walmartLoginLogo from 'assets/wmt.png';
import { getEnv } from 'utils/utils';

const apiTokens = {
  dev: 'Token 2a85debfaa7308c2b1c078e7206eeae31acc46a0',
  staging: 'Token 207ce5d5120dfb074c2f6fb5d5f76ba080412cc6',
  prod: 'Token cc501a49a0e8ad0705b7726061c7cfb1727898d9',
};

const token = selectToken(apiTokens);

const walmartNationalConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: getEnv() === 'production' ? 'walmart' : 'walmart-national',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: WALMART_NATIONAL,
  EMBOLD_CLIENT: WALMART_NATIONAL,
  EMBOLD_CLIENT_SLUG: WALMART_NATIONAL,
  SHOW_BCBS_LINK: true,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  HIGH_QUALITY_INTRO_MODAL: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Bentonville },
  HAS_PORTAL_INTEGRATION: true,
  CLOSED_ACCESS: true,
  LOGIN_NETWORK_NAME: 'walmartNationalLoggedIn',
  LOGIN_COOKIE_AUTH_REGEX: [
    '^(aetnabannerhealthwmt|aetnachoicewmt|unitedhealthcarechoicepluswmt|unitedhealthcaremercyhealthwmt|w2kwmt|wmwwmt|wnkwmt|wojwmt|wpawmt|wpswmt|wtowmt|wvowmt|wyrwmt)$',
  ],

  // content
  DISPLAY_NAME: 'Walmart',
  SCHEDULE_PHONE_NUMBER: null,
  HEADER_LOGO: walmartLogo,
  SUPPORT_LINK: 'https://walmartsupport.emboldhealth.com/portal/home',
  MEMBER_ID_LINK:
    'https://walmartmybenefits.blueadvantagearkansas.net/login/login.aspx?ReturnUrl=%2fsecure%2fid.aspx',
  SHOW_CONTACT_BCBS_ROUTE: true,
  ADD_ICON: true,
  NO_WELCOME_LINK: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Walmart and Embold Health have partnered to bring you Embold’s Provider Guide, your guide
  to quickly and easily finding the doctors in your network and area that have proven to
  provide the best care. Select your region to find Embold Health’s highest quality
  providers in your area that are also in-network within your plan.`,
  LOCAL_LOGO_ASSET: walmartLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
};

export default walmartNationalConfig;
