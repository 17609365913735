import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import FeaturedBenefit from './FeaturedBenefit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& :last-child': {
      marginBottom: 0, // remove marginBottom for the last list item
    },
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
}));

function FeaturedBenefitsList({ additionalClass, listItemProps, ...props }) {
  const classes = useStyles();
  const featuredBenefits = useSelector(select.promotions.featuredBenefitList);
  const isMdDown = useSelector(select.ui.isMdDown);

  if (!featuredBenefits.length) return null;

  return (
    <List classes={{ root: clsx(classes.root, { [additionalClass]: additionalClass }) }} {...props}>
      {featuredBenefits.map((benefit) => (
        <FeaturedBenefit
          benefit={benefit}
          key={benefit.id}
          listItem
          additionalClass={classes.listItem}
          isMobile={isMdDown}
          {...listItemProps}
        />
      ))}
    </List>
  );
}

FeaturedBenefitsList.propTypes = {
  additionalClass: PropTypes.string,
  listItemProps: PropTypes.shape({}),
};

FeaturedBenefitsList.defaultProps = {
  additionalClass: undefined,
  listItemProps: {},
};

export default React.memo(FeaturedBenefitsList);
