import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { select } from 'store/toolkit';
import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';

function PlacePillList({ placeId, ...props }) {
  const clientFeaturedPill = useSelector(select.place(placeId).clientFeaturedPill);
  if (!clientFeaturedPill) return null;

  return (
    <Box {...props}>
      <Pill {...clientFeaturedPill} />
    </Box>
  );
}

PlacePillList.propTypes = {
  placeId: PropTypes.string.isRequired,
};

PlacePillList.defaultProps = {};

export default React.memo(PlacePillList);
