import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClient } from 'utils/utils';
import { Button, IconButton } from '@material-ui/core';
import ConstructionIcon from '@mui/icons-material/Construction';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import { CHAT_BUTTON_Z_INDEX } from 'utils/constants';
import { actions } from 'store/toolkit';

const ICON_WIDTH = 65;
const SPACE_BETWEEN_ICON_AND_BUTTON = 10;
const TEXT_WIDTH = 140;
const BUTTON_PADDING_RIGHT = 22;
const BORDER_WIDTH = 2;

const StyledButton = withStyles((theme) => ({
  root: {
    transition: 'transform 400ms ease',
    paddingLeft: 0,
    paddingRight: BUTTON_PADDING_RIGHT,
    justifyContent: 'flex-start',

    // color
    background: theme.palette.primary.main,
    border: `${BORDER_WIDTH}px solid ${theme.palette.primary.main}`,

    // position
    width: 2 * (ICON_WIDTH + TEXT_WIDTH + SPACE_BETWEEN_ICON_AND_BUTTON), // oversize the button to give it extra space off-screen
    position: 'fixed',
    top: '70%',
    left: `calc(100% - ${ICON_WIDTH}px)`,
    zIndex: CHAT_BUTTON_Z_INDEX,
    '&:hover, &.Mui-focusVisible': {
      transform: `translateX(-${TEXT_WIDTH + BUTTON_PADDING_RIGHT}px)`,
    },

    // border
    borderRadius: theme.shape.borderRadius * 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  label: {
    textAlign: 'left',
    fontSize: theme.typography.h6.fontSize,
    '& :nth-child(2)': {
      // setting a width for the text inside the button label
      width: TEXT_WIDTH,
    },
  },
  startIcon: {
    margin: 0,
    paddingRight: SPACE_BETWEEN_ICON_AND_BUTTON,
    width: ICON_WIDTH,
  },
  iconSizeLarge: {
    '& > *:first-child': { fontSize: '3rem', marginLeft: 'auto' },
  },
}))(Button);

export default function ClientConfigDevTools() {
  const dispatch = useDispatch();
  const [buttonRender, setButtonRender] = useState(true);

  const client = getClient();
  const label = 'Open Client Config Dev Tools';

  const openDevTools = useCallback(
    (e) => {
      dispatch(actions.ui.openModal('ClientConfigDevTools'));
    },
    [dispatch]
  );

  const handleHide = useCallback((e) => {
    e.stopPropagation();
    setButtonRender(false);
  }, []);

  if (client !== 'local' || !buttonRender) {
    return null;
  }

  return (
    <StyledButton
      variant="contained"
      color="secondary"
      disableElevation={false}
      onClick={openDevTools}
      size="large"
      startIcon={<ConstructionIcon />}
    >
      <span>{label}</span>
      <div
        style={{ position: 'relative', left: -15, color: 'white' }}
        id="close-icon"
        aria-label="close"
        onClick={handleHide}
        onKeyUp={handleHide}
        role="button"
        tabIndex={0}
      >
        <CloseIcon />
      </div>
    </StyledButton>
  );
}
