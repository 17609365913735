import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

export default function DistanceCell({ providerId }) {
  const { distance } = useSelector(select.provider(providerId).closestLocation) || {};

  if (typeof distance !== 'number' || Number.isNaN(distance)) return null;

  return `${distance} miles`;
}

DistanceCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
