import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core/';
import { select } from 'store/toolkit';

import { SERVICE_COST_INFO } from 'utils/constants';
import useContactCardContext from 'hooks/useContactCardContext';
import useProviderCost from 'hooks/Provider/useProviderCost';

import ProfileSectionTitle from '../Profile/ProfileSectionTitle';
import OutOfPocketMaximumSwitch from './OutOfPocketMaximumSwitch';
import ServiceCostTable from './ServiceCostTable';
import Accordion from '../Profile/Accordion';

const useStyles = makeStyles((theme) => ({
  serviceCostContainer: {
    marginTop: 50,
    marginBottom: 10,
  },
  dataWrapper: {
    marginTop: theme.spacing(1),
  },
  switchWrapper: { marginLeft: -10, paddingBottom: 10 },
}));

const LOCATION_COST_DEBOUNCE_DELAY = 1000;

export default function ServiceCostWrapper({ npi, isAccordion }) {
  const classes = useStyles();

  const enableServiceCost = useSelector(select.featureFlags.showServiceCost);
  const [isOpen, setIsOpen] = useState(false);

  // get current location data from the parent contact card
  const { currentLocation, index } = useContactCardContext();
  const { locationId } = currentLocation;
  const { fetchProviderCost } = useProviderCost(npi, currentLocation);

  /**
   * Debounce provider-cost network call:
   * - delay call to 'provider-cost' endpoint to prevent excessive network calls
   * - will not perform fetch until user has viewed location for 1000ms
   */
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const createFetchTimeout = useCallback(() => {
    setIsDebouncing(true);
    const id = setTimeout(() => {
      setIsDebouncing(false);
      fetchProviderCost();
    }, LOCATION_COST_DEBOUNCE_DELAY);
    setTimeoutId(id);
  }, [fetchProviderCost, setTimeoutId]);

  const clearTimers = useCallback(() => {
    setTimeoutId(null);
    clearTimeout(timeoutId);
  }, [timeoutId]);

  /**
   * Non-Accordion UI
   * - Auto-fetch function is called when `locationId` changes
   * - Fetch & display first location immediately
   * - Debounce fetch & display for any other location
   * */
  const isInitialLocation = useMemo(() => index === 0, [index]);
  const triggerAutoFetch = useCallback(() => {
    if (isInitialLocation) {
      fetchProviderCost();
    } else {
      createFetchTimeout();
    }
  }, [createFetchTimeout, fetchProviderCost, isInitialLocation]);

  /**
   * Accordion UI
   * - Auto-fetch function is called when `locationId` changes
   * - If accordion is open, trigger a debounced fetch call
   *
   * - Fetch data with no delay when user opens accordion (handleClick)
   * */
  const triggerAutoFetchAccordion = useCallback(() => {
    if (isOpen) createFetchTimeout();
  }, [createFetchTimeout, isOpen]);

  const handleClick = useCallback(() => {
    if (!isOpen) fetchProviderCost();
    setIsOpen((prev) => !prev);
  }, [fetchProviderCost, isOpen]);

  const AccordionProps = useMemo(
    () => ({
      ariaId: 'service-costs',
      iconOnlyTrigger: true,
      plainTextTitle: 'service cost at this location',
      isOpen,
      onClick: handleClick,
    }),
    [handleClick, isOpen]
  );

  // Setup debounce functions
  const autoFetchFunction = useMemo(
    () => (isAccordion ? triggerAutoFetchAccordion : triggerAutoFetch),
    [isAccordion, triggerAutoFetch, triggerAutoFetchAccordion]
  );

  // Clear active timer and re-execute debounce function on location change
  useEffect(() => {
    clearTimers();
    autoFetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  // Clear active timer on component unmount
  useEffect(() => () => clearTimeout(timeoutId), [timeoutId]);

  if (!enableServiceCost) return null;
  if (!locationId) return null;

  return (
    <Grid container className={classes.serviceCostContainer} direction="row">
      <AccordionWrapper
        TitleProps={{
          title: SERVICE_COST_INFO.title,
          icon: <SERVICE_COST_INFO.IconComponent />,
          TooltipProps: {
            message: SERVICE_COST_INFO.description,
            title: SERVICE_COST_INFO.title,
          },
        }}
        isAccordion={isAccordion}
        AccordionProps={AccordionProps}
      >
        <Grid container classes={{ root: classes.dataWrapper }} item justifyContent="flex-start">
          <Grid container item classes={{ root: classes.switchWrapper }}>
            <OutOfPocketMaximumSwitch />
          </Grid>
          <ServiceCostTable
            analyticsView={isAccordion ? 'Accordion' : 'Modal'}
            npi={npi}
            location={currentLocation}
            isDebouncing={isDebouncing}
          />
        </Grid>
      </AccordionWrapper>
    </Grid>
  );
}

ServiceCostWrapper.propTypes = {
  npi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isAccordion: PropTypes.bool,
};

ServiceCostWrapper.defaultProps = {
  isAccordion: false,
};

function AccordionWrapper({ isAccordion, AccordionProps, TitleProps, children }) {
  return isAccordion ? (
    <Accordion
      title={<ProfileSectionTitle {...TitleProps} icon={null} />}
      icon={TitleProps.icon}
      {...AccordionProps}
    >
      {children}
    </Accordion>
  ) : (
    <>
      <ProfileSectionTitle {...TitleProps} />
      {children}
    </>
  );
}

AccordionWrapper.propTypes = {
  isAccordion: PropTypes.bool.isRequired,
  AccordionProps: PropTypes.shape({
    ariaId: PropTypes.string,
    iconOnlyTrigger: PropTypes.bool,
    plainTextTitle: PropTypes.string,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
  }).isRequired,
  TitleProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    TooltipProps: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};
