import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

export default function CostEfficiencyColored(props) {
  const { palette } = useTheme();
  const accentColor = palette.success.main;
  return (
    <SvgIcon {...props}>
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" />
      <path
        d="M10.2496 9.2C10.2496 10.14 11.1096 10.51 12.8896 11.1C15.2896 11.93 15.8996 13.15 15.8896 14.54C15.8896 17.17 13.3896 17.67 12.8696 17.76V19H11.1196V17.71C10.3796 17.53 8.72961 16.94 8.09961 14.75L9.75961 14.08C9.81961 14.3 10.3396 16.17 12.1596 16.17C13.0896 16.17 14.1396 15.69 14.1396 14.56C14.1396 13.6 13.4396 13.09 11.8596 12.53C10.7596 12.13 8.50961 11.49 8.50961 9.22C8.50961 9.11 8.52961 6.82 11.1296 6.26V5H12.8796V6.25C14.7196 6.57 15.3896 8.04 15.5396 8.48L13.9596 9.15C13.8496 8.8 13.3696 7.81 12.0596 7.81C11.3596 7.81 10.2496 8.18 10.2496 9.2Z"
        fill={accentColor}
      />
    </SvgIcon>
  );
}
