import React from 'react';
import PropTypes, { ChildrenType } from 'propTypes/index';
import { TableCell, Typography } from '@material-ui/core';

/**
 *
 * Apply theme colors to table cell text
 */
export default function ThemedTableCell({ children, TypographyProps, ...props }) {
  return (
    <TableCell {...props}>
      <Typography component="span" color="primary" {...TypographyProps}>
        {children}
      </Typography>
    </TableCell>
  );
}
ThemedTableCell.propTypes = {
  children: ChildrenType.isRequired,
  TypographyProps: PropTypes.shape({}),
};
ThemedTableCell.defaultProps = {
  TypographyProps: {},
};
