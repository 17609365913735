import { QUANTUM, ENSEMBLE, ENSEMBLE_PROVIDER_LOOKUP } from 'utils/constants';
import quantumThemeVariant from './quantumThemeVariant';
import testThemeVariant from './testThemeVariant';

export const DEFAULT = 'default';
export const TEST_VARIANT = 'test theme variant';

export const CLIENT_THEME_VARIANT_MAP = {
  [QUANTUM]: {
    name: 'quantum',
    spec: quantumThemeVariant,
  },
  [DEFAULT]: {
    name: DEFAULT,
    spec: {},
  },
  [TEST_VARIANT]: {
    name: TEST_VARIANT,
    spec: testThemeVariant,
  },
  [ENSEMBLE]: {
    name: 'ensemble',
    spec: quantumThemeVariant,
  },
  [ENSEMBLE_PROVIDER_LOOKUP]: {
    name: 'ensemble-providerlookup',
    spec: quantumThemeVariant,
  },
};
