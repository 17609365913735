import React from 'react';
import { useSelector } from 'react-redux';
import CardRibbon from 'components/Results/ResultCard/Ribbons/CardRibbon';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

import { HIGH_PERFORMING_TITLE, HIGH_PERFORMING_TOOLTIP_TEXT } from 'utils/constants';
import { select } from 'store/toolkit';
/** @deprecated */
function HighPerformingRibbon({ result }) {
  const showDrScore = useSelector(select.featureFlags.showDrScore);
  const { highPerforming } = result;

  if (!highPerforming) return null;

  return (
    <CardRibbon variant="blue" floatRight={showDrScore}>
      {HIGH_PERFORMING_TITLE}
      <Tooltip title={HIGH_PERFORMING_TITLE} message={HIGH_PERFORMING_TOOLTIP_TEXT} />
    </CardRibbon>
  );
}

HighPerformingRibbon.propTypes = {
  result: PropTypes.shape({
    highPerforming: PropTypes.bool,
  }).isRequired,
};

export default HighPerformingRibbon;
