import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

export default function NPIcell({ providerId }) {
  const { npi } = useSelector(select.provider(providerId).data) || {};

  if (!npi) return null;

  return npi;
}

NPIcell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
