import { createTheme } from '@material-ui/core/styles';

import { logDevMessage } from 'utils/utils';
import defaultTheme from 'themes/getTheme/defaultTheme';
import { CLIENT_THEME_VARIANT_MAP, DEFAULT } from 'themes/getTheme/variants/index';

/**
 *
 * @param {string} [clientName] this parameters should always be included, but the function will still work without it
 * @returns {Object} MuiTheme object
 */
function getTheme(clientName) {
  const themeVariant = CLIENT_THEME_VARIANT_MAP[clientName] || CLIENT_THEME_VARIANT_MAP[DEFAULT];
  logDevMessage(`🧑‍🎨 Using "${themeVariant.name}" theme variant 🧑‍🎨`, 'info');

  const { props, typography, overrides, palette } = themeVariant.spec;

  return createTheme({
    variantName: themeVariant.name,
    props: Object.assign(defaultTheme.props, props || {}),
    typography: Object.assign(defaultTheme.typography, typography || {}),
    overrides: Object.assign(defaultTheme.overrides, overrides || {}),
    palette: Object.assign(defaultTheme.palette, palette || {}),
    breakpoints: defaultTheme.breakpoints,
    iconSizes: defaultTheme.iconSizes,
  });
}

export default getTheme;
