export const EASE_OF_USE = 'easeOfUseBool';
export const RECOMMENDATION_RELEVANCY = 'recommendationRelevancyBool';
export const ADAPTATION_PERFORMANCE = 'adaptationPerformanceBool';
export const LOCATION_ACCURACY = 'locationAccuracy';
export const SPECIALIZATION_RELEVANCY = 'specialtyRelevancyBool';
export const OVERALL_SATISFACTION = 'overallSatisfaction';
export const VIRTUAL_BENEFIT_PRESENTED = 'virtualBenefitPresented';
export const VIRTUAL_BENEFIT_USE_LIKELYHOOD = 'virtualBenefitUseLikelyhood';
export const EMERGENCY_RECOMMENDATION_OUTCOME = 'emergencyRecommendationOutcome';

export const YES_NO_OPTIONS = [
  {
    val: false,
    label: 'No',
  },
  {
    val: true,
    label: 'Yes',
  },
];

const createOptions = (negativeLabel, positiveLabel) => [
  {
    val: 1,
    label: `${negativeLabel}`,
  },
  {
    val: 2,
    label: `Somewhat ${negativeLabel}`,
  },
  {
    val: 3,
    label: 'Neutral',
  },
  {
    val: 4,
    label: `Somewhat ${positiveLabel}`,
  },
  {
    val: 5,
    label: `${positiveLabel}`,
  },
];

export const RATING_QUESTIONS = [
  {
    question: 'Was the chat interface easy to use?',
    ratingOptions: YES_NO_OPTIONS,
    name: EASE_OF_USE,
  },
  {
    question: 'Did the chat end with the recommendation to seek emergency care?',
    ratingOptions: YES_NO_OPTIONS,
    name: EMERGENCY_RECOMMENDATION_OUTCOME,
  },
  {
    question: 'If applicable, were the provider recommendations relevant to your conversation?',
    ratingOptions: YES_NO_OPTIONS,
    name: RECOMMENDATION_RELEVANCY,
  },
  {
    question:
      'Did the chat adapt to changes in symptoms, diagnosis, or preferences in your conversation?',
    ratingOptions: YES_NO_OPTIONS,
    name: ADAPTATION_PERFORMANCE,
  },
  {
    question: 'If applicable, was the chat able to accurately gain your location?',
    ratingOptions: YES_NO_OPTIONS,
    name: LOCATION_ACCURACY,
  },
  {
    question: 'If applicable, were the recommended specializations relevant to your conversation?',
    ratingOptions: YES_NO_OPTIONS,
    name: SPECIALIZATION_RELEVANCY,
  },
  {
    question: 'Rate your overall satisfaction with the application’s performance and usefulness.',
    ratingOptions: createOptions('Unsatisfied', 'Satisfied'),
    name: OVERALL_SATISFACTION,
  },
  {
    question: 'Were you presented with any virtual care benefit recommendations?',
    ratingOptions: YES_NO_OPTIONS,
    name: VIRTUAL_BENEFIT_PRESENTED,
  },
];
