import React from 'react';
import PropTypes from 'propTypes';

import {
  PROVIDER_CARD_VIEW_OPTIONS,
  MOBILE_EXPANDED_VIEW,
  MOBILE_CONDENSED_VIEW,
} from 'store/slices/ui/uiConstants';

import { ResultCardSkeletonMobile, ResultCardSkeletonDesktop } from './ResultCardSkeletons';

function ResultCardSkeleton({ view }) {
  const isMobileView = view === MOBILE_CONDENSED_VIEW || view === MOBILE_EXPANDED_VIEW;

  return isMobileView ? <ResultCardSkeletonMobile /> : <ResultCardSkeletonDesktop view={view} />;
}

export default ResultCardSkeleton;

ResultCardSkeleton.propTypes = {
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};
