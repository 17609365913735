import React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import PropTypes from 'propTypes';

import DirectionsButton from '../DirectionsButton';

function ProviderDirectionsButton({ providerId, additionalClasses }) {
  const closestLocation = useSelector(select.provider(providerId).closestLocation);

  return (
    <DirectionsButton
      resultId={providerId}
      location={closestLocation}
      distance={closestLocation.distance}
      additionalClasses={additionalClasses}
    />
  );
}

export default React.memo(ProviderDirectionsButton);

ProviderDirectionsButton.propTypes = {
  providerId: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

ProviderDirectionsButton.defaultProps = {
  additionalClasses: '',
};
