import React from 'react';
import { Typography, Container } from '@material-ui/core';

import PageTemplate from 'components/Pages/PageTemplate';

function Disclaimer() {
  return (
    <Container>
      <Typography variant="h1" className="disclaimer-title">
        Disclaimer
      </Typography>

      <Typography variant="body2" paragraph>
        Embold Health is providing, and is exclusively responsible for, this site and all content
        herein, including but not limited to provider contact and practice information, and ratings
        or other qualitative data. Microsoft did not create and does not verify or guarantee the
        accuracy of any such content or information, nor does Microsoft endorse any provider ratings
        or other qualitative information provided or made available through this site. The site and
        its contents are being provided solely for your information. Microsoft does not recommend or
        endorse any health care providers or provider systems.
      </Typography>
    </Container>
  );
}

export default PageTemplate(Disclaimer, 'Disclaimer');
