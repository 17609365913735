/** @typedef {number} Latitude */
/** @typedef {number} Longitude */
/** @typedef {number} Zoom */

import { logDevMessage } from './utils';

/** An object containing latitude and longitude
 * @typedef {Object} Coordinates
 * @property {Latitude} latitude
 * @property {Longitude} longitude
 */

/**
 * @typedef {Object} BoundingBox
 * @property {Coordinates} sw The southwest coordinate
 * @property {Coordinates} ne The northeast coordinate
 */

/** An object containing the sw, ne & center coordinates as well as the zoom
 * @typedef {Object} MapValues
 * @property {Coordinates} sw The southwest coordinate
 * @property {Coordinates} ne The northeast coordinate
 * @property {Coordinates} center The center coordinate
 * @property {Zoom} zoom The map's zoom level
 */

/**
 *
 * @param {AzureMapsRef} mapRef The ref that is returned in the AzureMaps context, or passed in a map event
 * @returns {MapValues} Returns the coordinates for sw, ne, center amd the zoom level.
 */
// eslint-disable-next-line
export function getValuesFromMapRef(mapRef) {
  const mapValues = {
    center: { latitude: undefined, longitude: undefined },
    sw: { latitude: undefined, longitude: undefined },
    ne: { latitude: undefined, longitude: undefined },
    zoom: undefined,
  };
  try {
    const { map } = mapRef;
    const bounds = map.getBounds();
    const [swLng, swLat] = bounds.getSouthWest().toArray();
    const [neLng, neLat] = bounds.getNorthEast().toArray();
    const [centerLng, centerLat] = bounds.getCenter().toArray();
    const zoom = map.getZoom();

    mapValues.center = { latitude: centerLat, longitude: centerLng };
    mapValues.sw = { latitude: swLat, longitude: swLng };
    mapValues.ne = { latitude: neLat, longitude: neLng };
    mapValues.zoom = zoom;
  } catch (e) {
    console.error(e);
  }
  return mapValues;
}

/**
 * This is used as a converter function. Legacy code in getBounds() always returned bounds in the
 * Azure maps format [lng1, lat1, lng2, lat2]. However, our state uses an array of objects shaped as
 * [{ latitude: 123, longitude: 456 }, {latitude: 321, longitude: 654 }];
 * @param {number[]} boundsArray An array in format [lng1, lat1, lng2, lat2] returned from getBounds()
 * @returns {Coordinates[]} An array of two coordinates objects
 */
export function formatBoundsAsObject(boundsArray) {
  const [lng1, lat1, lng2, lat2] = boundsArray;
  return [
    { latitude: lat1, longitude: lng1 },
    { latitude: lat2, longitude: lng2 },
  ];
}

/**
 * Check's if the latitude and longitude passed are valid
 * @param {Latitude} latitude
 * @param {Longitude} longitude
 * @returns {bool}
 */
export function isValidCoords(latitude, longitude) {
  if (typeof latitude !== 'number' || typeof longitude !== 'number') {
    return false;
  }

  return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
}

/**
 * This function accepts
 * @param {Coordinates[]} coordsList A list of coordinates with shape { latitude: 123, longitude: 456 }
 * @returns {BoundingBox|null}
 */
export function getBoundingBoxFromCoordinatesList(coordsList = []) {
  if (!Array.isArray(coordsList)) {
    logDevMessage('getBoundsFromCoordinatesList expects an array of coordinates');
    return null;
  }

  if (!coordsList.length) {
    logDevMessage('getBoundsFromCoordinatesList requires at least 1 coordinate');
    return null;
  }

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // iterate over coordinates to set the max and min lat/lng
  for (const coord of coordsList) {
    // max and min lat
    if (coord.latitude < minLat) minLat = coord.latitude;
    if (coord.latitude > maxLat) maxLat = coord.latitude;
    // max and min lng
    if (coord.longitude < minLng) minLng = coord.longitude;
    if (coord.longitude > maxLng) maxLng = coord.longitude;
  }

  // return the sw corner and ne corner bounds
  return {
    sw: {
      latitude: minLat,
      longitude: minLng,
    },
    ne: {
      latitude: maxLat,
      longitude: maxLng,
    },
  };
}
