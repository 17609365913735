import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import { select } from 'store/toolkit';
import ProfileBadge from 'components/ModernExperience/Profile/ProfileBadge';
import HospitalSafetyGradeBadge from './PlaceBadges/HospitalSafetyGradeBadge';
import CoverageBadge from './PlaceBadges/CoverageBadge';

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
    '& > *': {
      flex: 1,
      minWidth: 160,
      [theme.breakpoints.up('sm')]: { maxWidth: '50%' },
    },
  },
}));

export default function PlaceBadges({ placeId }) {
  const classes = useStyles();
  const clientFeaturedBadge = useSelector(select.place(placeId).clientFeaturedPill);

  return (
    <Grid container className={classes.root}>
      <HospitalSafetyGradeBadge placeId={placeId} />
      <CoverageBadge placeId={placeId} />
      {Boolean(clientFeaturedBadge) && <ProfileBadge {...clientFeaturedBadge} />}
    </Grid>
  );
}

PlaceBadges.propTypes = {
  placeId: PropTypes.string.isRequired,
};
