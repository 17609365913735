/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function ChatTerms() {
  return (
    <>
      <Typography variant="h3">
        <strong>Embold Beta Terms and Conditions</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        BY CHECKING A BOX INDICATING YOUR ACCEPTANCE, CLICKING “I ACCEPT” OR “I AGREE” OR OTHERWISE
        INDICATING YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS AND REQUESTING BETA ACCESS, OR BY
        ACCESSING OR USING THE BETA SERVICE YOU:
      </Typography>
      <ul>
        <li>
          EXPLICITLY AGREE TO THE THESE BETA TERMS AND CONDITIONS (“BETA TERMS”) ON BEHALF OF
          YOURSELF AND THE ENTITY WITH WHICH YOU ARE EMPLOYED, AFFILIATED OR ASSOCIATED; AND
        </li>
        <li>
          REPRESENT AND WARANTY THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THESE TERMS.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE BETA TERMS, YOU MUST
        NOT ACCEPT THIS AGREEMENT AND MAY NOT ACCESS OR USE THE BETA SERVICE. ALL REFERENCES TO
        “YOU” AND “YOUR” IN THIS AGREEMENT MEAN THE PERSON ACCEPTING THESE TERMS AS AN INDIVIDUAL OR
        THE LEGAL ENTITY FOR WHICH THE REPRESENTATIVE IS ACTING, AS THE CASE MAY BE.
      </Typography>
      <Typography variant="body2" paragraph>
        These Beta Terms are effective between You and Embold Health, Inc. ("
        <strong>Embold</strong>
        ”) as of the date of your acceptance of this these Beta Terms. The Privacy Statement
        available at emboldhealth.com/privacy-statement is incorporated herein by reference.
      </Typography>
      <ol>
        <li>
          <Typography variant="h3">
            <strong>Embold Beta Terms and Conditions</strong>
          </Typography>
          <ol type="a">
            <li>
              <Typography variant="body2" paragraph>
                <strong>Use of the Beta Service</strong>. From time to time, Embold may make certain
                services, features or functionality available to You, at no charge, which may be
                designated by Embold as a Beta, pilot, limited release, developer preview,
                non-production, evaluation, or by a similar description, to be used in conjunction
                with or separate from the Services, as applicable (each, a “
                <strong>Beta Service</strong>”). During the Term (defined below), Embold grants You
                a limited, non-exclusive, non-transferable, royalty-free, revocable right to access
                and use the Beta Service solely for the purpose of evaluating and testing the Beta
                Service and provide Feedback to Embold in. You will allow access only to those
                individuals approved by Embold (“<strong>Authorized Users</strong>”); and be
                responsible for any breach of these Beta Terms by any Authorized User. You and Your
                Authorized Users will use the Beta Service provided by Embold solely in the United
                States.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Restrictions</strong>. You and Your Authorized Users will not, nor will they
                assist or encourage anyone else to: (i) sell, license, distribute, publicly perform
                or display, transmit, edit, adapt, modify, copy, translate or make derivative works
                based on the Embold Intellectual Property or the Beta Service; (ii) disassemble,
                reverse engineer, or decompile any of the Embold Intellectual Property or Beta
                Service; or (iii) create Internet “links” to or from the Beta Service, or “frame” or
                “mirror” any of Embold’s content which forms part of the Beta Service; (iv) send
                unsolicited communications, spam, or any form of duplicative communications; (v)
                violate any law, rule or regulation; (vi) transmit through or post on the Beta
                Service or Embold’s website(s) unlawful, immoral, libelous, tortious, infringing,
                defamatory, threatening, vulgar, or obscene material or material harmful to minors;
                (vii) transmit material containing software viruses or other harmful or deleterious
                computer code, files, scripts, agents, or programs; (viii) interfere with or disrupt
                the integrity or performance of the Beta Service or the data contained therein; (ix)
                attempt to gain unauthorized access to the Beta Service, computer systems or
                networks used to host or provide access to the Beta Service; (x) harass or interfere
                with another Embold client’s use and enjoyment of the Beta Service; (xi) remove,
                delete, alter, or obscure any trademarks or any copyright, trademark, patent, or
                other intellectual property or proprietary rights notices from the Beta Service;
                (xii) use or access the Beta Service or any other products or services contemplated
                by these Beta Terms in order to build a competitive product or service; (xiii) copy
                any features, functions or graphics of the Beta Service or any other products or
                services contemplated by these Beta Terms; or (xiv) exploit the Beta Service or any
                of their parts for any commercial purpose.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Termination and Suspension</strong>. In addition to any other remedies
                Embold may have, Embold reserves the right to terminate or suspend any or all of the
                logins provided to You or any Authorized User immediately if Embold determines that
                You or any Authorized User is violating any of the foregoing guidelines. In
                addition, Embold may suspend Your access to the Beta Service immediately if it
                determines in its sole discretion that You or any Authorized User creates or has
                created a security risk to the Beta Service.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Microsoft Terms</strong>. Your use of the Beta Services is subject to, and
                you shall comply with, the terms and conditions applicable to the Azure OpenAI
                Service set forth at{' '}
                <Link
                  rel="noreferrer"
                  href="https://www.microsoft.com/licensing/terms/productoffering/MicrosoftAzure/MCA"
                  style={{ wordBreak: 'break-word' }}
                >
                  https://www.microsoft.com/licensing/terms/productoffering/MicrosoftAzure/MCA
                </Link>
                .
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Not Medical Advice</strong>. Please note that Website is not intended to
                diagnose, treat, cure, or prevent any disease or medical condition. The content on
                the Website are there for informational purposes only and cannot replace the
                services of health professionals or physicians. You should always consult a
                physician before making any changes to your caregiving based on the content of the
                Website, or if you have any questions regarding a medical condition. Never disregard
                professional medical advice or delay in seeking it because of something you have
                read on or through the Website. We are not responsible for any health problems that
                may result from the content you learn about through the Website. If you make any
                change to your caregiving based on the Website, you agree that you do so fully at
                your own risk.‍
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant="h3">
            <strong>Data</strong>
          </Typography>
          <ol type="a">
            <li>
              <Typography variant="body2" paragraph>
                <strong>Customer Data</strong>. You hereby grants Embold the right and license to
                access, use and disclose any data or content submitted by You or Authorized Users to
                the Beta Service (“<strong>Customer Data</strong>”) (i) as necessary to provide the
                Beta Services, and (ii) to improve the Beta Service, and to grant Embold’s
                third-party licensors the right and license to access, use and disclose Customer
                Data to do the same. You represents and warrants that you have procured all required
                consents, authorizations or permissions from all individuals to whom Customer Data
                relates that are required by applicable law.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Usage Data</strong>. Embold exclusively owns all anonymous and/or aggregated
                data derived from Your or Your Authorized Users’ use of the Beta Service (“
                <strong>Usage Data</strong>”). You hereby assigns to Embold all right, title and
                interest in and to Usage Data and all intellectual property rights therein. Embold
                may use Usage Data for any purpose in compliance with applicable laws.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Personal Information and PHI</strong>. You and Your Authorized Users will
                not under any circumstances enter any “personal information”, “personal data” or
                “personally identifiable information” (as such terms are defined under any
                applicable data protection law) or “protected health information” (as defined under
                the Health Insurance Portability and Accountability Act) or any other electronic
                data, information or material relating to an actual person into the Beta Service.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Removal</strong>. Notwithstanding the foregoing, Embold has no obligation to
                review any content. Embold may, without liability or notice to You, remove or delete
                any data, information or material entered into the Beta Service by You and/or any
                Authorized Users at any time.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant="h3">
            <strong>Beta Evaluation.</strong>
          </Typography>
          <ol type="a">
            <li>
              <Typography variant="body2" paragraph>
                <strong>Feedback and Suggestions</strong> In order to assess the results and
                efficacy of the Beta Service, and in consideration of Your receipt of access to the
                Beta Service, You agree to complete any and all surveys administered or provided by
                Embold and You will provide Embold with feedback and information regarding any
                errors, difficulties, bugs, or issues, and make suggestions as to changes or
                modifications to, the Beta Service (“<strong>Feedback</strong>”). You will be asked
                to provide feedback during the Term (to the extent requested by Embold), which may
                take the form of questionnaires, surveys, emails or conversations. You will respond
                within a reasonable amount of time to any such requests and make Authorized Users
                reasonably available during Your normal business hours for discussions. Embold may
                also monitor how You uses the Beta Service and use that information to improve the
                Beta Service, and such information will constitute Feedback. All Feedback will be
                the Confidential Information (as defined in Section 5) of Embold and Embold will
                have the unrestricted right to use and disclose the Feedback for any purpose. You
                hereby irrevocably assign all of Your rights, title and interests, including without
                limitation all intellectual property rights, in and to the Feedback, to Embold. To
                the extent You are unable to assign any of Your rights in the Feedback to Embold,
                You hereby grant to Embold a perpetual, irrevocable, worldwide, fully paid-up
                license to sell, offer to sell, make, have made, import, use, disclose, copy,
                distribute, publicly perform, publicly display, modify, create derivative works of
                and otherwise fully exploit the Feedback and all intellectual property rights
                therein for any purpose.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Generally Available Product</strong>. If and when Embold elects in its sole
                discretion to make a generally commercially available version of the Beta Service
                available, and if Embold and You then wish for You to license and subscribe to that
                product, You may be required at that time to assent to different terms and
                conditions for the licensing and support of such product (including additional
                fees). You acknowledge that there may be a break in access to the Beta Service in
                connection with release of the Beta Service as a generally available product.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Risks</strong>. You acknowledge that the Beta Service is in a Beta testing
                phase and is not a final and finished product. Accordingly, You accept all risks of
                participating as a Beta user of the Beta Service, including:
              </Typography>
              <ol type="i">
                <li>
                  that the Beta Service may contain bugs or other errors, including ones which may
                  limit functionality, produce erroneous results, render part or all of the Beta
                  Service unavailable or inoperable, produce incorrect records, data or content, or
                  cause records, data or content to be corrupted or lost;
                </li>
                <li>
                  functionality may be withdrawn from the Beta Service, or testing suspended, or the
                  Beta Service abandoned, at any time with or without prior notice in Embold’s sole
                  discretion;
                </li>
                <li>
                  additional functionality may be added, or error corrections, patches, updates or
                  upgrades may be applied, at any time with or without notice in Embold’s sole
                  discretion;
                </li>
                <li>
                  Embold will not have any obligation to maintain, restore or return any records,
                  data or content entered into or created using the Beta Service. Records, data or
                  content may be intentionally or inadvertently deleted by Embold or may not
                  otherwise be available for Your use or retrieval (including after an expiration or
                  termination for any reason of the Agreement);
                </li>
                <li>
                  no service levels will apply to the Beta Service or Embold’s services rendered in
                  relation to it, and any training or support that Embold may elect in its sole
                  discretion to provide will be provided only as set forth in these Beta Terms; and
                </li>
                <li>
                  either party may terminate the Agreement, and, as a result, Your access to and use
                  of the Beta Service, at any time pursuant to Section 4 below.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Term and Termination</strong>. The term (“<strong>Term</strong>”) of these Beta
            Terms commences as of the date of You first access or use a Beta Service and continues
            until the earlier of (a) if term is specified in the web sign up page for the Beta
            Service, upon the expiration of such term, (b) upon the conclusion of the Beta Service,
            (c) Embold’s release of the applicable Beta Service as a commercially generally
            available version or (d) termination by a party as set forth herein. Either party may
            terminate these Beta Terms at any time, with or without cause, upon delivery of written
            notice to the other party. Upon any termination of these Beta Terms, all rights granted
            herein to use and access the Beta Service terminate and You and Your end users shall
            immediately: (i) cease use of all Beta Service and (ii) return or destroy all of
            Embold’s Confidential Information and, if requested in writing by Embold, provide Embold
            with written certification of the same.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Confidential Information</strong>. During the Term, a party may disclose (“
            <strong>Disclosing Party</strong>”) Confidential Information (as defined below),
            directly or indirectly, to the other party (“<strong>Receiving Party</strong>”). The
            Receiving Party will not, without the prior written consent of the Disclosing Party,
            disclose the Confidential Information of the Disclosing Party. The Receiving Party will
            use Confidential Information solely for such party’s performance under these Beta Terms
            and the exercise of its rights hereunder. The Receiving Party will take all reasonable
            precautions to protect the Disclosing Party’s Confidential Information, using at least
            the same standard of care as it uses to maintain the confidentiality of its own
            Confidential Information of similar nature but in no event less than reasonable care.
            Notwithstanding the foregoing, the Receiving Party may disclose Confidential
            Information: (i) to any consultants, contractors, and counsel who have a need to know in
            connection with these Beta Terms and have executed a reasonably protective
            non-disclosure agreement with the Receiving Party, or (ii) pursuant to legal process;
            provided that, the Receiving Party will, unless legally prohibited, provide the
            Disclosing Party with reasonable prior written notice sufficient to permit it an
            opportunity to contest such disclosure. “<strong>Confidential Information</strong>”
            means all information furnished by the Disclosing Party to the Receiving Party, that is
            conspicuously designated as “Confidential” or “Proprietary” or similar designation or by
            its nature would be understood by a reasonable person to be confidential or proprietary.
            All information relating to the Beta Service furnished to, accessed by or otherwise
            learned by Embold constitutes Confidential Information of Embold. Confidential
            Information will not include information which the Receiving Party can demonstrate: (w)
            is or becomes public knowledge through no breach of these Beta Terms by the Receiving
            Party or a third party acting on its behalf, (x) is received by the Receiving Party from
            a third party not under a duty of confidence, (y) is already known or is independently
            developed by the Receiving Party without use of or reference to the Confidential
            Information, or (z) is any data stored by You in the Beta Service. The Receiving Party
            will promptly notify the Disclosing Party in writing in the event that the Receiving
            Party becomes aware of any actual or suspected unauthorized use or disclosure of the
            Disclosing Party’s Confidential Information. In the event of a breach of this provision,
            damages may not be an adequate remedy and each party will be entitled to seek injunctive
            relief to restrain any such breach, threatened or actual, without the necessity of
            posting a bond or other security.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Ownership</strong>. Embold owns and shall retain all right, title and interest
            in and to the Beta Service and any products, services and materials that are part of the
            Beta Service, and any modifications, customizations or derivations thereto, and any and
            all intellectual property rights therein. As between Embold and You, You own all right,
            title and interest in and to Customer Data.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Disclaimers</strong>. THE Beta SERVICE, AND ANY RELATED SOFTWARE AND OTHER
            MATERIALS OR PRODUCTS PROVIDED BY EMBOLD IN CONNECTION WITH THE Beta PRODUCT, ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND WITHOUT ANY SERVICE LEVELS OR
            WARRANTIES OR REPRESENTATIONS OF ANY KIND, WHETHER STATUTORY OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO, WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, ACCURACY, COMPLETENESS OR ANY RESULTS TO BE ACHIEVED HEREFROM.
          </Typography>
        </li>
        <li>
          <Typography variant="h3">
            <strong>Limitation of Liability.</strong>
          </Typography>
          <ol type="a">
            <li>
              <Typography variant="body2" paragraph>
                <strong>Limitation of Liability</strong>. EMBOLD’S AND ITS’, AFFILIATES’,
                DIRECTORS’, OFFICERS’, EMPLOYEES’, SUPPLIERS’, LICENSORS’ AND AGENTS’ AGGREGATE
                LIABILITY ARISING OUT OF OR RELATED TO THE AGREEMENT, WHETHER IN CONTRACT, TORT
                (INCLUDING NEGLIGENCE) OR UNDER ANY OTHER THEORY OF LIABILITY, WILL NOT EXCEED AN
                AGGREGATE AMOUNT OF ONE HUNDRED DOLLARS ($100.00).
              </Typography>
            </li>
            <li>
              <Typography variant="body2" paragraph>
                <strong>Exclusion of Consequential and Related Damages</strong>. IN NO EVENT WILL
                EMBOLD OR ITS PRESENT AND FORMER SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS,
                EMPLOYEES, SUPPLIERS, LICENSORS, OR AGENTS HAVE ANY LIABILITY TO YOU, YOUR
                AUTHORIZED USERS OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOSS OF DATA, LOSS OF USE,
                COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES HOWEVER CAUSED AND, WHETHER IN
                CONTRACT, TORT (INCLUDING NEGLIGENCE) OR UNDER ANY OTHER THEORY OF LIABILITY WHETHER
                OR NOT EMBOLD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Indemnification</strong>. You will indemnify and hold harmless Embold and
            Embold’s Affiliates and each of their respective officers, directors, employees and
            agents, from and against any and all damages, liabilities, penalties, interest, fines,
            losses, costs and expenses (including reasonable attorneys’ fees and expenses), arising,
            directly or indirectly, out of or relating to any claim or allegation based on (a) Your
            breach of these Beta Terms, (b) Your use or misuse (or unauthorized use) of the Beta
            Service by You and/or the Authorized Users, or (c) any violation of law, rule or
            regulation in connection with the Beta Service.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" paragraph>
            <strong>Miscellaneous Terms</strong>. Embold may give notice by (i) electronic mail to
            Your e- mail address on record information, (ii) written communication sent by letter
            delivered by a nationally recognized overnight delivery service, or (iii) first-class
            postage prepaid mail to Your address on record. You may give notice to Embold (such
            notice shall be deemed given when received by Embold) by (x) letter delivered by a
            nationally recognized overnight delivery service or first-class postage prepaid mail to
            Embold at Embold Health, Inc. 1801 West End Avenue, Suite 800 Nashville, TN 37203,
            Attention of Embold Health Legal Department. The parties do not intend for these Beta
            Terms to create an express or implicit agency relationship in accordance with federal or
            state common law of agency. Each party is intended to be an independent contractor and
            no agency relationship is created under these Beta Terms. These Beta Terms do not and
            are not intended to confer any enforceable rights or remedies upon any person other than
            Embold and You. You may not assign Your rights or transfer any of Your obligations under
            these Beta Terms without the express written consent of Embold and such consent may be
            granted or withheld in Embold’s sole discretion. Embold may assign its rights or
            transfer any or all of its obligations under these Beta Terms at any time, without prior
            notice to You. Any purported assignment or transfer in violation of this section is null
            and void. These Beta Terms binding upon, and inures to the benefit of, the parties and
            their respective permitted successors and assigns. Embold reserves the right to modify
            the terms and conditions of these Beta Terms from time to time, and Embold will use
            commercially reasonable efforts to communicate any material change to You. Use of the
            Beta Service may require acceptance of new, modified, or additional terms of use from
            time-to-time by and through You, Your administrator, or other account owner. By
            continuing to use the Beta Service or participate in the Beta Service and not issuing a
            notice of termination of these Beta Terms, unless expressly prohibited by law, You agree
            that such actions will be deemed acceptance of, and Your agreement to be bound by, any
            such new, modified, or additional terms. The parties intend for these Beta Terms to
            constitute the final agreement between the parties, and that it is the complete and
            exclusive expression of the parties’ agreement on the matters contained herein. All
            prior or contemporaneous writings, negotiations, and discussions between the parties
            with respect to its subject matter are expressly merged and superseded by these Beta
            Terms. In entering into these Beta Terms, neither party has relied upon any statement,
            representation, warranty, nor agreement of the other party except to the extent
            expressly contained in these Beta Terms. In the event of a conflict between any other
            agreement between the parties and these Beta Terms with respect to the subject matter of
            these Beta Terms, the terms of these Beta Terms will control. You acknowledge that no
            text or information set forth on any purchase order, preprinted form, or other document
            can add to or vary the terms and conditions of these Beta Terms. If any provision of
            these Beta Terms are determined to be invalid, illegal, or unenforceable, the parties do
            not intend for this determination to affect or impair the validity, legality, and
            enforceability of the remaining provisions of these Beta Terms in any way. All sections
            of these Beta Terms which, by their nature should survive termination will survive
            termination, including, without limitation, sections pertaining to confidential
            information, ownership of intellectual property, any payment obligations, warranty
            disclaimers, indemnification, arbitration, and the limitation on liability. Any legal
            suit, action or proceeding arising out of, or related to, these Beta Terms or the Beta
            Service shall be instituted exclusively in the federal courts of the United States or
            the courts of the State of Tennessee in each case located in the City of Nashville
            although Embold retains the right to bring any suit, action or proceeding against you
            for breach of these Beta Terms in Your country of residence or any other relevant
            country. You waive any and all objections to the exercise of jurisdiction over you by
            such courts and to venue in such courts. The parties intend that any ambiguity in these
            Beta Terms will be resolved and interpreted as closely as possible to meet the intent of
            the parties. The captions and headings appearing in these Beta Terms are for reference
            only and are not intended to be considered in construing these Beta Terms.
          </Typography>
        </li>
      </ol>
    </>
  );
}

export default ChatTerms;
