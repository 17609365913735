import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShareIcon from '@material-ui/icons/Share';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Grid, Grow, Typography, Box, Collapse, IconButton } from '@material-ui/core/';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { useProviderScore } from 'hooks/Provider/';
import useReturnFocus from 'hooks/useReturnFocus';
import useFocusAnchors from 'utils/FocusRefContext';

import ScoreIcon from 'components/ScoreIcon';
import HighPerformingBadge from 'components/Profile/Badges/HighPerformingBadge';
import ProviderCardLarge from './ProviderCardLarge';
import ChatScoreTierPill from './ChatScoreTierPill';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    padding: `${theme.spacing(1)}px`,
    cursor: 'pointer',
  },
  cardTitle: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(1),
      flex: 1,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        display: 'block',
        marginTop: 0,
      },
    },
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: 6,
    color: theme.palette.primary.main,
  },
  scoreTierPill: { marginBottom: 4 },
}));

function ProviderCardSmall({
  provider,
  selectedProvider,
  setSelectedProvider,
  index,
  coords,
  queryParams,
}) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [showDetails, setShowDetails] = useState(false);
  const isSurgeryPlusNetwork = useSelector(select.featureFlags.isSurgeryPlusNetwork);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);
  const showShare = useSelector(select.featureFlags.showShare);

  const { entityId, entityName, title, specialty, highPerforming, schedulingUrl } = provider;
  const score = useProviderScore(provider);
  const isSelectedProvider = selectedProvider.entityId === entityId;

  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();

  const handleShareClick = (e) => {
    e?.stopPropagation();
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [provider] }));
  };

  const handleScheduleClick = (e) => {
    e?.stopPropagation();
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: provider }));
  };

  return (
    <Grid item xs={12} sm={4} md={4} lg={4}>
      <Grow in timeout={index * 500}>
        <Grid
          style={{
            boxShadow: !smDown && isSelectedProvider ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
          }}
          className={classes.container}
          onClick={() => !smDown && setSelectedProvider(provider)}
        >
          <Grid className={classes.scoreTierPill}>
            <ChatScoreTierPill provider={provider} />
          </Grid>
          <Grid className={classes.titleRow} onClick={() => smDown && setShowDetails(!showDetails)}>
            {score && (
              <div>
                <Typography variant="srOnly">{score.name}</Typography>
                <ScoreIcon score={score.value} coloredText emboldRecommendedSize={30} />
              </div>
            )}

            <Box>
              <Typography
                variant="h3"
                classes={{
                  root: classes.cardTitle,
                }}
              >
                {entityName}
                {Boolean(title) && ` ${title}`}
              </Typography>
              {specialty && (
                <Typography style={{ fontSize: '.9rem', marginTop: '-8px' }}>
                  {specialty}
                </Typography>
              )}
            </Box>
            <Grid style={{ marginRight: 4 }}>
              <HighPerformingBadge isHighPerforming={highPerforming} />
            </Grid>
            {smDown && (
              <Grid className={classes.buttonSection}>
                {showShare && (
                  <IconButton className={classes.button} onClick={handleShareClick}>
                    <ShareIcon ref={shareButtonRef} />
                  </IconButton>
                )}
                {showScheduleButton &&
                  !isSurgeryPlusNetwork &&
                  (schedulingUrl ? (
                    <IconButton
                      className={classes.button}
                      href={schedulingUrl}
                      color="primary"
                      variant="outlined"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <CalendarMonthIcon ref={callButtonRef} />
                    </IconButton>
                  ) : (
                    <IconButton className={classes.button} onClick={handleScheduleClick}>
                      <HelpOutlineIcon ref={callButtonRef} />
                    </IconButton>
                  ))}
                <ExpandMoreIcon
                  tabIndex="0"
                  aria-label={`expand details for ${entityName} ${title} ${specialty}`}
                  className={classes.expandMoreIcon}
                  style={{ transform: showDetails ? 'rotate(180deg)' : '' }}
                />
              </Grid>
            )}
          </Grid>
          {smDown && (
            <Grid>
              <Collapse in={showDetails}>
                <ProviderCardLarge provider={provider} coords={coords} queryParams={queryParams} />
              </Collapse>
            </Grid>
          )}
        </Grid>
      </Grow>
    </Grid>
  );
}

ProviderCardSmall.propTypes = {
  provider: PropTypes.shape({
    entityId: PropTypes.string,
    entityName: PropTypes.string,
    title: PropTypes.string,
    specialty: PropTypes.string,
    highPerforming: PropTypes.bool,
    schedulingUrl: PropTypes.string,
  }).isRequired,
  selectedProvider: PropTypes.shape({ entityId: PropTypes.string }).isRequired,
  setSelectedProvider: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  queryParams: PropTypes.shape({
    specialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
      })
    ),
    subspecialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
        subspecialtyId: PropTypes.number,
        subspecialtyName: PropTypes.string,
      })
    ),
    radius: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    lgbtqCompetent: PropTypes.bool,
    lookingForDiagnosis: PropTypes.bool,
    lookingForTreatment: PropTypes.bool,
    gender: PropTypes.string,
    languagesSpoken: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    stateAbbreviation: PropTypes.string,
  }).isRequired,
};

export default ProviderCardSmall;
