import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'propTypes/index';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { _analyticsActions } from 'analytics/index';

import { MOBILE_CONDENSED_VIEW } from 'store/slices/ui/uiConstants';
import { select } from 'store/toolkit';
import { ProviderResultCardMobileContent } from '../ProviderCard/ProviderResultCard';
import { PlaceResultCardMobileContent } from '../PlaceCard/PlaceResultCard';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
  },
  slidingContainer: {
    display: 'flex',
    transition: 'all .3s ease',
  },
  cardWrap: {
    flexGrow: 0,
    flexShrink: 0,
    opacity: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  selected: {
    opacity: 1,
  },
  fullWidth: {
    width: '100%',
  },
}));

export default function ResultSlider({ selectedIndex, wasPlaceSearch }) {
  const classes = useStyles();
  const [sliderWidth, setSliderWidth] = useState(null);
  const containerRef = useRef(null);
  const clusterIds = useSelector(select.results.clusterIds);
  const dispatch = useDispatch();

  const updateSliderWidth = useCallback(() => {
    if (containerRef?.current?.clientWidth > 0) {
      setSliderWidth(containerRef.current.clientWidth);
    }
  }, [setSliderWidth]);

  useEffect(() => {
    dispatch(_analyticsActions.mapMarkerView(clusterIds[selectedIndex]));
  }, [selectedIndex, dispatch, clusterIds]);

  useEffect(() => {
    // set initial slider width
    updateSliderWidth();
    // reset on window resize
    window.addEventListener('resize', updateSliderWidth);
    return () => {
      // remove event listener when component unmounts
      window.removeEventListener('resize', updateSliderWidth);
    };
  }, [updateSliderWidth]);

  return (
    <div className={classes.container} ref={containerRef}>
      <div
        className={classes.slidingContainer}
        style={{ transform: `translateX(-${sliderWidth * selectedIndex}px)` }}
      >
        {clusterIds.map((id, i) => {
          const a11yProps = {
            ...(selectedIndex !== i && {
              inert: 'true'.toString(),
              'aria-hidden': 'true',
            }),
          };
          return (
            <div
              key={id}
              className={clsx(classes.cardWrap, {
                [classes.selected]: selectedIndex === i,
              })}
              style={{ flexBasis: sliderWidth }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...a11yProps}
            >
              {/* wrap results in a div to insulate them from being direct children of a flex container */}
              <div className={classes.fullWidth}>
                {wasPlaceSearch ? (
                  <PlaceResultCardMobileContent view={MOBILE_CONDENSED_VIEW} placeId={id} />
                ) : (
                  <ProviderResultCardMobileContent view={MOBILE_CONDENSED_VIEW} providerId={id} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

ResultSlider.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  wasPlaceSearch: PropTypes.bool.isRequired,
};
