import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import NoDataIcon from 'components/ModernExperience/CompareTable/CellComponents/NoDataIcon';
import { select } from 'store/toolkit';

export default function PlaceDistanceCell({ placeId }) {
  const distanceInMiles = useSelector(select.place(placeId).distanceInMiles);

  // distanceInMiles would return null if there is no location in the resultsSlice to compare to
  if (!distanceInMiles) return <NoDataIcon />;

  return `${distanceInMiles} miles`;
}

PlaceDistanceCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
