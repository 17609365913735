import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { Snackbar, IconButton, Slide } from '@material-ui/core';
import { Pause, Close } from '@material-ui/icons';

import { select, actions } from 'store/toolkit';
import useReturnFocus from 'hooks/useReturnFocus';
import { useFocusTo } from 'utils/FocusRefContext';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius * 3,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  action: {
    '& svg': { color: theme.palette.grey[700] }, // accessible color for all severities
  },
}));

function TransitionDown(props) {
  const dispatch = useDispatch();

  const handleExit = useCallback(() => {
    dispatch(actions.notifications.clear());
  }, [dispatch]);

  return <Slide {...props} onExited={handleExit} direction="down" />;
}

function Notification() {
  const classes = useStyles();
  // redux state and dispatch
  const message = useSelector(select.notifications.message);
  const isOpen = useSelector(select.notifications.isOpen);
  const severity = useSelector(select.notifications.severity);
  const duration = useSelector(select.notifications.duration);
  const returnFocusKey = useSelector(select.notifications.returnFocusKey);
  const dispatch = useDispatch();
  // global focus anchors
  const focusTo = useFocusTo();
  // refs and focus control
  const { ref: pauseRef, returnFocus: focusPause } = useReturnFocus();
  const { ref: closeRef, returnFocus: focusClose } = useReturnFocus();

  useEffect(() => {
    // when opened, focus the correct button
    if (isOpen) {
      if (duration) focusPause();
      else focusClose();
    }
  }, [isOpen, duration, focusClose, focusPause]);

  const handlePause = useCallback(() => {
    dispatch(actions.notifications.pause());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(actions.notifications.hide());

    if (returnFocusKey) {
      /* If a returnFocusKey was passed when notification was created,
       * focus should be returned to that global focus anchor when notification
       * is closed.
       */
      focusTo.key(returnFocusKey);
    }
  }, [dispatch, focusTo, returnFocusKey]);

  if (!isOpen) return null;

  return (
    <Snackbar
      open
      autoHideDuration={duration}
      TransitionComponent={TransitionDown}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        classes={classes}
        action={
          <>
            {
              // if there is a duration, then autoClose is running and we show pause button
              duration && (
                <IconButton aria-label="Pause notification" ref={pauseRef} onClick={handlePause}>
                  <Pause />
                </IconButton>
              )
            }
            <IconButton aria-label="Dismiss notification" ref={closeRef} onClick={handleClose}>
              <Close />
            </IconButton>
          </>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default React.memo(Notification);
