import atlas from 'azure-maps-control';

// In this file, we can add custom icon templates to the azure map namespace. This allows us to use custom icons as sprites on SymbolLayers within a map.

// example
// https://learn.microsoft.com/en-us/azure/azure-maps/how-to-use-image-templates-web-sdk#create-custom-reusable-templates
// https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/main/Samples/Map/Add%20custom%20icon%20template%20to%20atlas%20namespace/Add%20custom%20icon%20template%20to%20atlas%20namespace.html

// first define custom svg templates as a string, using {scale} and {color} for placeholder values
// https://learn.microsoft.com/en-us/javascript/api/azure-maps-control/atlas?view=azure-maps-typescript-latest#azure-maps-control-atlas-addimagetemplate

const placeIconString = `<svg width="calc(37px * {scale})" height="calc(44px * {scale})" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_337_18728)">
  <path d="M16.5 6H4.5V30.5H32.5V12.5H16.5V6Z" fill="{secondaryColor}" stroke="black"/>
  <path d="M18.5002 10.7917V4.625H3.0835V32.375H33.9168V10.7917H18.5002ZM15.4168 29.2917H6.16683V26.2083H15.4168V29.2917ZM15.4168 23.125H6.16683V20.0417H15.4168V23.125ZM15.4168 16.9583H6.16683V13.875H15.4168V16.9583ZM15.4168 10.7917H6.16683V7.70833H15.4168V10.7917ZM30.8335 29.2917H18.5002V13.875H30.8335V29.2917Z" fill="{color}"/>
  <path d="M23.6391 15.4167V17.4722H21.5835V19.5278H23.6391V21.5833H25.6946V19.5278H27.7502V17.4722H25.6946V15.4167H23.6391Z" fill="{color}"/>
  <rect x="21.5835" y="24.6667" width="6.16667" height="4.625" fill="{color}"/>
  <path d="M10.7915 35.4583L18.4998 43.1667L26.2082 35.4583H10.7915Z" fill="{color}"/>
  </g>
  <defs>
  <clipPath id="clip0_337_18728">
  <rect width="37" height="43.1667" fill="{secondaryColor}"/>
  </clipPath>
  </defs>
  </svg>
  `;

// eslint-disable-next-line import/prefer-default-export
export const PLACE_TEMPLATE_NAME = 'place';

// add each template to the atlas namespace
atlas.addImageTemplate(PLACE_TEMPLATE_NAME, placeIconString);
