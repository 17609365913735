import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  API_CONTENT_ERROR_MESSAGE,
  RETRY,
  START_OVER,
  TRY_AGAIN,
  ASSISTANT,
  RESTART,
  DISMISS_EMERGENCY,
  CHAT_CONTENT_ERROR_MESSAGE,
  CHAT_GENERIC_ERROR_MESSAGE,
  SEARCH_IN_PG,
  CHAT_MAX_MESSAGES,
  MAX_CHAT_LENGTH_MESSAGE,
} from 'store/slices/chat/chatConstants';
import { CHAT_SLICE_NAME } from 'store/slices/slicesNames';
import { parseSearchResponseData } from 'store/slices/results/resultUtils';
import {
  askEva,
  analyzeChat,
  providerSearch,
  performAllSearches,
  expandProviderSearch,
} from './chatThunks';
import { formatChatResponseParameters, formatProviderPlaces } from './chatUtils';

const getInitialState = () => ({
  chatKey: uuidv4(),

  // loading state
  sendingChatMessage: false,
  sendingChatOverview: false,
  isPerformingAllSearches: false,

  // terms
  scenarioId: null,
  termsAccepted: false,

  errorMessage: null,

  // chat thread
  conversation: [],
  endChat: false,
  actionButtonKeys: [],

  // feedback form
  feedbackSuccess: false,

  // chat search
  searchParameters: {},
  requests: {},

  // search results
  results: {},
  promotionId: null,

  // override emergency
  dismissEmergency: false,

  devTools: process.env.NODE_ENV !== 'production',
});

function appendMessage(state, message) {
  state.conversation = [...state.conversation, message];
}

function handleError(state, action) {
  // don't show error error UI if the request was aborted
  const { aborted } = action.meta;
  if (aborted) return;
  // error message passed as string
  const errorMessageText = action.payload;
  const isContentError = errorMessageText === API_CONTENT_ERROR_MESSAGE;
  const actionButtonKeys = [START_OVER, TRY_AGAIN, ...(isContentError ? [] : [RETRY])];
  state.errorMessage = {
    role: ASSISTANT,
    content: isContentError ? CHAT_CONTENT_ERROR_MESSAGE : CHAT_GENERIC_ERROR_MESSAGE,
  };
  state.actionButtonKeys = actionButtonKeys;
}

function handleEmergency(state, chatResponse) {
  state.errorMessage = chatResponse;
  state.endChat = true;
  state.actionButtonKeys = [RESTART, DISMISS_EMERGENCY];
}

function handleMaxMessages(state) {
  // end the chat and show the user action buttons
  appendMessage(state, MAX_CHAT_LENGTH_MESSAGE);
  state.actionButtonKeys = [RESTART, SEARCH_IN_PG];
  state.endChat = true;
}

const chatSlice = createSlice({
  name: CHAT_SLICE_NAME,
  initialState: getInitialState(),
  reducers: {
    resetChat() {
      return getInitialState();
    },
    acceptTerms(state, { payload }) {
      if (payload) state.scenarioId = payload;
      state.termsAccepted = true;
    },
    feedbackSuccessfullySubmitted(state) {
      state.feedbackSuccess = true;
    },
    addMessageToConversation(state, action) {
      state.conversation = [...state.conversation, action.payload];
    },
    removeMessageFromConversation(state) {
      state.conversation = state.conversation.slice(0, -1);
    },
    resetErrorState(state) {
      state.errorMessage = null;
      state.actionButtonKeys = [];
    },
    setScenarioId(state, action) {
      state.scenarioId = action.payload;
    },
    specialtySearchInPg: (state) => state,
    goToHomepage: (state) => state,
    setDismissEmergency(state, action) {
      state.dismissEmergency = action.payload;

      // add error to conversation so that discussion can continue in case of dismiss emergency
      state.conversation = [...state.conversation, state.errorMessage];
      state.endChat = false;
      state.actionButtonKeys = [];
      state.errorMessage = null;
    },
    toggleDevTools(state) {
      state.devTools = !state.devTools;
    },
    overrideRequest(state, action) {
      const { requestKey, values = {} } = action.payload;

      if (!state.requests[requestKey]) throw new Error(`No request with id ${requestKey}`);

      state.requests[requestKey] = { ...state.requests[requestKey], ...values };
    },
  },
  extraReducers(builder) {
    /* ********* Ask Eva ********* */
    builder
      .addCase(askEva.pending, (state) => {
        state.sendingChatMessage = true;
        state.actionButtonsKeys = [];
      })
      .addCase(askEva.fulfilled, (state, action) => {
        state.sendingChatMessage = false;
        const { chatResponse, end, isEmergency /* , promotionId */ } = action.payload;
        // @TODO: handle promotionId response TECH-3480

        if (isEmergency && !state.dismissEmergency) {
          handleEmergency(state, chatResponse);
        } else if (state.conversation.length >= CHAT_MAX_MESSAGES) {
          handleMaxMessages(state);
        } else if (!end) {
          // continue the conversation
          appendMessage(state, chatResponse);
        }
      })
      .addCase(askEva.rejected, (state, action) => {
        handleError(state, action);
        state.sendingChatMessage = false;
      });

    /* ********* Analyze Chat ********* */
    builder
      .addCase(analyzeChat.pending, (state) => {
        state.sendingChatOverview = true;
        state.actionButtonsKeys = [];
      })
      .addCase(analyzeChat.fulfilled, (state, action) => {
        state.sendingChatOverview = false;
        const { chatResponse, end, isEmergency, parameters } = action.payload;

        if (isEmergency && !state.dismissEmergency) {
          handleEmergency(state, chatResponse);
        } else if (end) {
          appendMessage(state, chatResponse);
          state.endChat = true;
          state.searchParameters = formatChatResponseParameters(parameters);
        } else if (state.conversation.length >= CHAT_MAX_MESSAGES) {
          handleMaxMessages(state);
        } else {
          // continue the conversation
          appendMessage(state, chatResponse);
        }
      })
      .addCase(analyzeChat.rejected, (state, action) => {
        handleError(state, action);
        state.sendingChatOverview = false;
      });

    /* ********* Provider Search ********* */
    builder
      .addCase(providerSearch.pending, (state, action) => {
        const { requestId: requestKey, arg: searchParams } = action.meta;

        // create a new request object, indexed by the requestKey
        state.requests[requestKey] = {
          ...searchParams,
          label: searchParams.subspecialtyName || searchParams.specialtyName,
          requestKey,
          error: null,
          isLoading: true,
          isExpanded: false,
          resultList: [],
        };
      })
      .addCase(providerSearch.fulfilled, (state, action) => {
        // identify the request to be updated
        const { requestId: requestKey } = action.meta;
        const request = state.requests[requestKey];

        // get response data
        const responseData = action.payload;

        // add location properties to each result
        responseData.results = responseData.results.map((result) =>
          formatProviderPlaces(result, request.location)
        );

        // normalize responseData
        const { results, resultIdList } = parseSearchResponseData(responseData);

        // add providers to results object
        state.results = { ...state.results, ...results };

        // update this search's state
        request.isLoading = false;
        request.resultList = resultIdList;
      })
      .addCase(providerSearch.rejected, (state, action) => {
        // identify the request to be updated
        const { requestId: requestKey } = action.meta;
        const search = state.requests[requestKey];

        // update this request's state
        search.isLoading = false;
        search.error = true;
      });

    /* ********* Expand Search ********* */
    builder
      .addCase(expandProviderSearch.pending, (state, action) => {
        // identify the request to be updated
        const { arg: initialSearchAction } = action.meta;
        const { requestId: requestKey } = initialSearchAction.meta;
        const request = state.requests[requestKey];

        // update the state of this request
        request.isLoading = true;
        request.isExpanded = true;
        request.error = null;
      })
      .addCase(expandProviderSearch.fulfilled, (state, action) => {
        const { requestKey, newRadius, ...responseData } = action.payload;

        // identify the request to be updated
        const request = state.requests[requestKey];

        // add location properties to each result
        responseData.results = responseData.results.map((result) =>
          formatProviderPlaces(result, request.location)
        );

        // normalize response
        const { results, resultIdList } = parseSearchResponseData(responseData);

        // add providers to results object
        state.results = { ...state.results, ...results };

        // update the state of this request
        request.isLoading = false;
        request.radius = newRadius;
        request.resultList = resultIdList;
      })
      .addCase(expandProviderSearch.rejected, (state, action) => {
        // identify the request to be updated
        const { arg: initialSearchAction } = action.meta;
        const { requestId: requestKey } = initialSearchAction.meta;
        const request = state.requests[requestKey];

        // update the state of this request
        request.isLoading = false;
        request.error = true;
      });

    /* ********* Perform All Searches ********* */
    builder
      .addCase(performAllSearches.pending, (state) => {
        state.isPerformingAllSearches = true;
      })
      .addCase(performAllSearches.fulfilled, (state) => {
        state.isPerformingAllSearches = false;
      })
      .addCase(performAllSearches.rejected, (state) => {
        state.isPerformingAllSearches = false;
      });
  },
});

export const {
  resetChat,
  addMessageToConversation,
  removeMessageFromConversation,
  resetErrorState,
  acceptTerms,
  feedbackSuccessfullySubmitted,
  setScenarioId,
  specialtySearchInPg,
  goToHomepage,
  setDismissEmergency,
  toggleDevTools,
} = chatSlice.actions;
export default chatSlice;
