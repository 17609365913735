import React, { useCallback, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { Typography, Button, Container, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import hipaaWaveTablet from 'assets/hipaa-wave-tablet.jpg';
import hipaaWave from 'assets/hipaa-wave.jpg';
import { useFocusTo } from 'utils/FocusRefContext';
import { select } from 'store/toolkit';
import Modal from 'components/Modals/Modal';

const useStyles = makeStyles((theme) => {
  const mobileOnly = theme.breakpoints.down('xs');
  const smUp = theme.breakpoints.up('sm');

  return {
    waveBackgroundDesktop: {
      '& [data-reach-dialog-content]': {
        backgroundImage: `url(${hipaaWave})`,
        backgroundSize: 'cover',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
      },
    },
    waveBackgroundTablet: {
      '& [data-reach-dialog-content]': {
        backgroundImage: `url(${hipaaWaveTablet})`,
        backgroundSize: '100% auto',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      },
    },
    title: {
      textAlign: 'center',
    },
    container: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [mobileOnly]: {
        height: '100vh',
      },
    },
    copyContainer: {
      [mobileOnly]: {
        flex: `1 1 0px`,
      },
      [smUp]: {
        maxHeight: '50vh',
      },
      height: 'auto',
      overflowY: 'auto',
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(1),
      },
    },
    checkbox: {
      margin: theme.spacing(2),
    },
  };
});

function HipaaCopy() {
  const appName = useSelector(select.content.appName);
  return (
    <>
      <Typography>
        By accessing &quot;{appName}&quot;, I will have the opportunity to view certain quality and
        rating information for health care providers in my geographic area, as determined, compiled,
        and provided by Embold Health (“Embold”).
      </Typography>

      <Typography>
        I hereby authorize Embold, Premera, and/or other vendors of the Corporation Welfare Plan
        (the “Plan”) to collect and analyze data regarding my use of the platform, including my Plan
        member identification number and other data as detailed in Embold’s{' '}
        <Link to="/privacy" component={RouterLink}>
          Privacy Statement
        </Link>
        , as well as any and all of my health care provider claims or other encounter information
        under the Plan, for purposes of analyzing and evaluating the platform’s utilization and
        impact on health care provider selection, and publishing the results of such analyses and
        evaluations in research publications.
      </Typography>

      <Typography>
        I understand that I am not required to access &quot;{appName}&quot; as a condition of my
        employment (or the employment of the individual through whom my enrollment in the Plan is
        based). I may refuse to execute this authorization, and such refusal will not in any way
        affect my eligibility for treatment, payment, or enrollment in any other benefits offered by
        this Corporation or its affiliates.
      </Typography>

      <Typography>
        I understand that the data collected and analyzed in accordance with this authorization may
        be subject to redisclosure by the recipient, and not protected by federal or state law.
      </Typography>

      <Typography>
        I understand that I may revoke this authorization in writing, at any time, by notifying{' '}
        <Link href="mailto:privacy@emboldhealth.com">privacy@emboldhealth.com</Link>, provided that
        this will not affect any actions taken prior to such revocation. This authorization will
        remain in effect until termination of my enrollment in the Corporation Welfare Plan, unless
        and until I revoke it sooner.
      </Typography>

      <Typography style={{ fontWeight: 'bold' }}>
        By checking the box below, I attest I have read, accept, and hereby electronically sign the
        Authorization for Use or Disclosure of Health Information, effective as of today’s date. I
        hereby consent to the use of my electronic signature for this purpose, and understand that I
        may obtain a paper version of this document free of charge, or withdraw my consent to the
        use of my electronic signature for this purpose, by contacting{' '}
        <Link href="mailto:privacy@emboldhealth.com">privacy@emboldhealth.com</Link>.
      </Typography>
    </>
  );
}

export default function HipaaModal({ fieldName }) {
  const classes = useStyles();
  const isXs = useSelector(select.ui.isXs);

  const [checked, setIsChecked] = useState(false);
  const [field, , helpers] = useField(fieldName);

  const handleAgree = useCallback(() => helpers.setValue(checked), [helpers, checked]);

  const checkboxLabel = useMemo(() => {
    const now = new Date();
    const dateToday = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;

    return `I accept these terms on ${dateToday}.`;
  }, []);

  const focusTo = useFocusTo();

  return (
    <Modal
      showX={false}
      allowBackdropClick={false}
      open={!field.value}
      maxWidth="md"
      fullWidth
      fullScreen={isXs}
      exitFunction={focusTo.prefixField}
      silenceDialogRole={isXs}
      ariaId="hipaa-modal"
      customStyles={classes.waveBackgroundDesktop}
    >
      <Container className={classes.container}>
        <Typography variant="h2" component="h1" id="hipaa-modal-title" className={classes.title}>
          Understanding your HIPAA rights
        </Typography>

        <Container className={classes.copyContainer}>
          <HipaaCopy />
        </Container>

        <FormControlLabel
          control={<Checkbox checked={checked} />}
          value={checked}
          label={checkboxLabel}
          onChange={(evt, val) => setIsChecked(val)}
          className={classes.checkbox}
        />

        <Button onClick={handleAgree} disabled={!checked} variant="contained" color="primary">
          I Acknowledge
        </Button>
      </Container>
    </Modal>
  );
}

HipaaModal.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
