import React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import ProviderContactCard from 'components/ModernExperience/ResultCards/ProviderCard/ProviderContactCard';
import ServiceCostWrapper from 'components/ModernExperience/ServiceCost/ServiceCostWrapper';
import ResultMiniMap from 'components/ModernExperience/ResultCards/ResultLocations/ResultMiniMap';
import ProfileSectionModal from './ProfileSectionModal';

export default function ServiceCostModal(props) {
  const service = useSelector(select.services.current);
  const { data: profileModal } = useSelector(select.ui.profileModal);
  const smDown = useSelector(select.ui.isXsDown);

  const { id: npi } = profileModal || {};

  if (!npi) return null;
  if (!service) return null;

  return (
    <ProfileSectionModal title={`${service.serviceName} Cost`} {...props}>
      <ProviderContactCard providerId={npi} showMap={!smDown}>
        <ServiceCostWrapper npi={npi} />
        {smDown && <ResultMiniMap />}
      </ProviderContactCard>
    </ProfileSectionModal>
  );
}
