import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import { determineResultType } from 'utils/utils';
import ProviderProfile from 'components/ModernExperience/Profile/ProviderProfile/ProviderProfile';
import PlaceProfile from 'components/ModernExperience/Profile/PlaceProfile/PlaceProfile';

/** This is a generic profile component that can be used to render the profile for an unknown result type.
 * Id's that return places will render a PlaceProfile
 * Id's that return providers will render a ProviderProfile
 * If no id is passed or the result type is not determined, it will return null
 */
export default function ResultProfile({ id, ...props }) {
  const resultData = useSelector(select.results.byId(id));

  if (!resultData) return null;

  const resultType = determineResultType(resultData);

  if (resultType === 'provider') return <ProviderProfile providerId={id} {...props} />;
  if (resultType === 'place') return <PlaceProfile placeId={id} />;

  return null;
}

ResultProfile.propTypes = {
  id: PropTypes.string.isRequired,
};
