import React from 'react';
import PropTypes from 'propTypes/index';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import FadeInLoadingDots from './FadeInLoadingDots';
import EmboldFactsCarousel from './EmboldFactsCarousel';
import EmboldAnalysisStepper from './EmboldAnalysisStepper';

export default function ChatLoadingState({ scrollToBottom }) {
  const sendingChatMessage = useSelector(select.chat.sendingChatMessage);
  const sendingChatOverview = useSelector(select.chat.sendingChatOverview);

  return (
    <>
      <div role="status">
        <FadeInLoadingDots visible={sendingChatMessage} />
        <EmboldAnalysisStepper
          scrollToBottom={scrollToBottom}
          sendingChatOverview={sendingChatOverview}
        />
      </div>
      <div role="status" style={{ marginTop: 'auto' }}>
        <EmboldFactsCarousel
          scrollToBottom={scrollToBottom}
          sendingChatMessage={sendingChatMessage}
        />
      </div>
    </>
  );
}

ChatLoadingState.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
};
