import React, { useMemo } from 'react';

/**
 * This hook is used to accept either a string, or a node prop and return the correct React node.
 * If a string is passed, it will return an <img> with the string as the src.
 * If a node such as <MuiIcon /> is passed, it will return that node directly.
 * @param {string|JSX.Element} icon
 * @param {string=} altText
 * @returns {JSX.Element} Either a <img /> tag or the input node directly
 */
export default function useIconNode(icon, altText = '') {
  return useMemo(() => {
    if (!icon) return null;

    // strings type icon for img tag
    if (typeof icon === 'string') {
      return <img src={icon} alt={altText} />;
    }

    // node type icons
    if (React.isValidElement(icon)) {
      return icon;
    }

    return null;
  }, [icon, altText]);
}
