import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PROVIDER_RESULT_TYPE } from 'utils/constants';
import { determineResultType } from 'utils/utils';
import { select } from 'store/toolkit';

/**
 * @deprecated This hook should be replaced by the "calculateScoreFunction" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This returns a function for getting a providers score.
 * If you are just needing the value of the score and not the function for getting the value,
 * you should use the useProviderScore hook instead.
 * @returns {Function} A function for getting the provider score
 */
export function useGetProviderScore() {
  const showDrScore = useSelector(select.featureFlags.showDrScore);

  return useCallback(
    (provider) => {
      const resultType = determineResultType(provider);

      // only provider results should show scores.
      // some clients don't show scores based on showDrScore.
      // some providers may not have a scoreOverallQuality
      if (resultType !== PROVIDER_RESULT_TYPE || !showDrScore || !provider.scoreOverallQuality)
        return null;

      return {
        name: 'Doctor Quality',
        value: provider.scoreOverallQuality,
        id: 'doctor-quality',
      };
    },
    [showDrScore]
  );
}

/**
 *  * @deprecated This hook should be replaced by the "score" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This calculates the displayed score based on the search type. For subspecialty searches
 * with a scored subspecialty, it returns the subspecialty score. For other searches
 * it returns the Overall Quality Score.
 * @param {Object} provider A provider object returned from the fusion api
 * @param {Object[]} provider.subspecialties An array of subspecialties a provider contains
 * @returns {(Object|null)} A display score for a provider of either overall quality or subspecialty. Or null if conditions are not met.
 */
export default function useProviderScore(provider) {
  const getProviderScore = useGetProviderScore();

  const score = useMemo(() => getProviderScore(provider), [getProviderScore, provider]);
  return score;
}

/**
 *  * @deprecated This hook should be replaced by the "score" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This hook is intended for use in the Compare Table, where the score needs to be calculated for a
 * dynamic number of providers.
 * @param {Object[]} providers A provider object returned from the fusion api
 * @param {Object[]} providers.subspecialties An array of subspecialties a provider contains
 * @returns {(Object|null)} An object with a score name, and an array of scores, or null if no score name is found
 */
export function useMultipleProviderScores(providers = []) {
  const getProviderScore = useGetProviderScore();

  return useMemo(() => {
    if (!providers.length) return null;

    // scoreName will represent the name of all of the scores being represented
    // for example, "Overall Quality" or "Hepatology Quality" (a subspecialty)
    let scoreName = null;
    // scores is the array of score objects returned by getProviderScore
    const scores = [];

    for (let i = 0; i < providers.length; i += 1) {
      // get the score using our hook
      const score = getProviderScore(providers[i]);

      if (score) scoreName = score.name;
      scores.push(score);
    }

    // if there was no scoreName, that would mean that all provider scores returned null
    if (!scoreName) return null;

    // return our final object
    return { name: scoreName, values: scores };
  }, [getProviderScore, providers]);
}
