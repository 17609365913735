import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import StarsIcon from '@material-ui/icons/Stars';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';

import ProviderName from 'icons/ProviderName';
import Place from 'icons/Place';
import Service from 'icons/Service';
import CostEfficiencyColored from 'icons/colored/CostEfficiencyColored';
import FocusAreasColored from 'icons/colored/FocusAreasColored';
import StrengthsColored from 'icons/colored/StrengthsColored';
import HealthAndWellness from 'icons/colored/HealthAndWellness';

// Embold clients
export const WALMART = 'walmart';
export const WALMART_NATIONAL = 'walmartnational';
export const MICROSOFT = 'microsoft';
export const POLK = 'polk';
export const ACME = 'acme';
export const ICUBA = 'icuba';
export const DOLLAR_GENERAL = 'dollarGeneral';
export const DOLLAR_GENERAL_SUPPORT = 'dollarGeneralSupport';
export const EMSANA_CARE = 'emsanaCare';

export const DEMO = 'demo';
export const EMBOLD = 'embold';
export const SURGERY_PLUS = 'surgeryPlus';
export const BNSF = 'bnsf';
export const JBHUNT = 'jbhunt';
export const SOCT = 'soct';
export const SOCT_PROVIDER_LOOKUP = 'soctproviderlookup';
export const CONTINENTAL_RESOURCES = 'clr';
export const QUANTUM = 'quantum';
export const ENSEMBLE = 'ensemble';
export const ENSEMBLE_PROVIDER_LOOKUP = 'ensembleproviderlookup';

export const FREEFORM_SEARCH_TYPE = 'freeform';
export const AUTOCOMPLETE_SEARCH_TYPE = 'autocomplete';

// Autocomplete search types
export const PROMOTION_TYPE = 'promotion';
export const SPECIALTY_TYPE = 'specialty';
export const NEW_SPECIALTY_TYPE = 'new_specialty';
export const PROVIDER_TYPE = 'provider';
export const PLACE_TYPE = 'place';
export const COMMON_SEARCHES_TYPE = 'common-searches';
export const SERVICE_TYPE = 'service';

export const PROVIDER_RESULT_TYPE = 'provider';
export const PLACE_RESULT_TYPE = 'place';

export const SPECIALISTS_TAB = 'specialists';
export const NAMES_TAB = 'names';
export const FACILITIES_TAB = 'facilities';

export const DEFAULT_RADIUS = 50;
export const AUTOCOMPLETE_RADIUS_3 = 3;
export const AUTOCOMPLETE_RADIUS_4 = 4;
export const AUTOCOMPLETE_RADIUS_5 = 5;

export const HEALTH_SAVINGS_PLAN_PREFIX = 'MSJ';
export const HEALTH_CONNECT_PLAN_PREFIX = 'YMJ';

// Config
export const SCHEDULE_PHONE_NUMBER = 'SCHEDULE_PHONE_NUMBER';
export const SCHEDULE_COPY = 'SCHEDULE_COPY';

export const SHOW_TELEHEALTH = 'SHOW_TELEHEALTH';
export const SHOW_BOARD_CERTIFIED = 'SHOW_BOARD_CERTIFIED';
export const SHOW_WHEELCHAIR_ACCESSIBLE = 'SHOW_WHEELCHAIR_ACCESSIBLE';
export const SHOW_SERVICE_COST = 'SHOW_SERVICE_COST';
export const MEMBER_ID_LINK = 'MEMBER_ID_LINK';
export const SHOW_BCBS_LINK = 'SHOW_BCBS_LINK';
export const SHOW_PHONE_NUMBER = 'SHOW_PHONE_NUMBER';
export const IS_SURGERY_PLUS = 'IS_SURGERY_PLUS';
export const SURGERY_PLUS_PHONE_NUMBER = 'SURGERY_PLUS_PHONE_NUMBER';
export const SHOW_CLIENT_FEATURED = 'SHOW_CLIENT_FEATURED';
export const LOCAL_STORAGE_VERSION_NUMBER = 1;
export const INTRO_MODAL_VERSION = 1;
export const LOGIN_NETWORK_NAME_VERSION = 1;
export const ZOHO_MAILBOX_ADDRESS = 'ZOHO_MAILBOX_ADDRESS';
export const ZOHO_DEPARTMENT_ID = 'ZOHO_DEPARTMENT_ID';
export const SUPPORT_NAME = 'SUPPORT_NAME';
export const SUPPORT_PHONE_NUMBER = 'SUPPORT_PHONE_NUMBER';
export const SHOW_CHAT = 'SHOW_CHAT';
export const SHOW_CHAT_SCENARIO = 'SHOW_CHAT_SCENARIO';
export const SHOW_CHAT_FEEDBACK = 'SHOW_CHAT_FEEDBACK';

export const LOGIN_IMAGE = 'LOGIN_IMAGE';
export const HEADER_IMAGE = 'HEADER_IMAGE';

export const DEFAULT_SPECIALTY_SUGGESTIONS = [
  { specialtyName: 'Cardiology', specialtyId: 2 },
  { specialtyName: 'Endocrinology', specialtyId: 8 },
  { specialtyName: 'Gastroenterology', specialtyId: 6 },
  { specialtyName: 'Obstetrics', specialtyId: 1 },
  { specialtyName: 'Orthopedics', specialtyId: 3 },
  { specialtyName: 'Primary Care', specialtyId: 5 },
  { specialtyName: 'Pulmonology', specialtyId: 7 },
  { specialtyName: 'Spine', specialtyId: 4 },
  { specialtyName: 'Pediatrics', specialtyId: 10 },
];

export const DEFAULT_PLACE_SUGGESTIONS = ['Urgent Care', 'Hospital', 'Clinic'];

export const COMMON_SEARCHES = [
  { name: 'Primary Care', specialtyId: 5 },
  { name: 'Urgent Care' },
  { name: 'Pediatrics', specialtyId: 10 },
  { name: 'Colonoscopy', serviceId: 51 },
  { name: 'Cardiology', specialtyId: 2 },
  { name: 'Allergy test', serviceId: 12 },
];

export const SORT_LABEL_MAPPING = {
  best_match: 'Best Match',
  '-score_care_journey_cost,-scoring_order,distance': 'Cost Efficiency',
  distance: 'Distance',
  'first_name,last_name': 'A-Z (First Name)',
  '-first_name,-last_name': 'Z-A (First Name)',
  'last_name,first_name': 'A-Z (Last Name)',
  '-last_name,-first_name': 'Z-A (Last Name)',
  name: 'Alphabetical',
  '-name': 'Alphabelical (Z-A)',
  'hospital_grade,distance': 'Hospital Safety Grade',
  'client_featured,distance': 'Best Match',
};

export const VALID_SORT_ORDERS = Object.keys(SORT_LABEL_MAPPING);

export const SORT_OPTIONS = {
  [PROVIDER_RESULT_TYPE]: {
    'Best Match': {
      name: 'sort by best match',
      value: 'best_match',
      label: 'Best Match',
    },
    Distance: {
      name: 'sort by distance',
      value: 'distance',
      label: 'Distance',
    },
    'Last Name (A-Z)': {
      name: 'sort by last name',
      value: 'last_name,first_name',
      reverseValue: '-last_name,-first_name',
      label: 'Last Name (A-Z)',
    },
    'Last Name (Z-A)': {
      name: 'sort by last name',
      value: '-last_name,-first_name',
      reverseValue: 'last_name,first_name',
      label: 'Last Name (Z-A)',
    },
  },
  [PLACE_RESULT_TYPE]: {
    Distance: {
      name: 'sort by distance',
      value: 'distance',
      label: 'Distance',
    },
    Alphabetical: {
      name: 'sort alphabetically',
      value: 'name',
      reverseValue: '-name',
      label: 'Alphabetical',
    },
    HospitalSafetyGrade: {
      name: 'sort by hospital safety grade',
      value: 'hospital_grade,distance',
      label: 'Hospital Safety Grade',
    },
  },
};

export const PROVIDER_SORT_OPTION_VALUES = {
  BEST_MATCH: 'best_match',
  COST_EFFICIENCY: '-score_care_journey_cost,-scoring_order,distance',
  DISTANCE: 'distance',
  LAST_NAME_AZ: 'last_name,first_name',
  LAST_NAME_ZA: '-last_name,-first_name',
};
export const DEFAULT_PROVIDER_SORT = PROVIDER_SORT_OPTION_VALUES.BEST_MATCH;
export const PLACE_SORT_OPTION_VALUES = {
  DISTANCE: 'distance',
  NAME: 'name',
  NAME_REVERSE: '-name',
  HOSPITAL_GRADE: 'hospital_grade,distance',
};
export const DEFAULT_PLACE_SORT = PLACE_SORT_OPTION_VALUES.DISTANCE;
// Unfortunately needing to basically duplicate this object in order
// to add Cost Efficiency with the correct ordering. Remove once Cost
// is turned on for all clients
export const SORT_OPTIONS_WITH_COST = {
  [PROVIDER_RESULT_TYPE]: {
    'Best Match': {
      name: 'sort by best match',
      value: PROVIDER_SORT_OPTION_VALUES.BEST_MATCH,
      label: 'Best Match',
    },
    'Cost Efficiency': {
      name: 'sort by cost efficiency',
      value: PROVIDER_SORT_OPTION_VALUES.COST_EFFICIENCY,
      label: 'Cost Efficiency',
    },
    Distance: {
      name: 'sort by distance',
      value: PROVIDER_SORT_OPTION_VALUES.DISTANCE,
      label: 'Distance',
    },
    'Last Name (A-Z)': {
      name: 'sort by last name',
      value: PROVIDER_SORT_OPTION_VALUES.LAST_NAME_AZ,
      reverseValue: PROVIDER_SORT_OPTION_VALUES.LAST_NAME_ZA,
      label: 'Last Name (A-Z)',
    },
    'Last Name (Z-A)': {
      name: 'sort by last name',
      value: PROVIDER_SORT_OPTION_VALUES.LAST_NAME_ZA,
      reverseValue: PROVIDER_SORT_OPTION_VALUES.LAST_NAME_AZ,
      label: 'Last Name (Z-A)',
    },
  },
  [PLACE_RESULT_TYPE]: {
    Distance: {
      name: 'sort by distance',
      value: PLACE_SORT_OPTION_VALUES.DISTANCE,
      label: 'Distance',
    },
    Alphabetical: {
      name: 'sort alphabetically',
      value: PLACE_SORT_OPTION_VALUES.NAME,
      reverseValue: PLACE_SORT_OPTION_VALUES.NAME_REVERSE,
      label: 'Alphabetical',
    },
    HospitalSafetyGrade: {
      name: 'sort by hospital safety grade',
      value: PLACE_SORT_OPTION_VALUES.HOSPITAL_GRADE,
      label: 'Hospital Safety Grade',
    },
  },
};

export const PLACE_BEST_MATCH_SORT = {
  name: 'sort by best match',
  value: 'client_featured,distance',
  label: 'Best Match',
};

export const SUBSPECIALTY_MAPPING = {
  // OBGYN
  1: [
    'Urogynecology​',
    'Pregnancy and Delivery',
    'Reproductive Endocrinology and Infertility​',
    'General Gynecology​',
  ],
  // Cardiology
  2: [
    'Electrophysiology​',
    'Interventional​',
    'Heart Failure​',
    'Pediatric​s',
    'Adult Congenital​',
  ],
  // Orthopedics
  3: ['Knee​', 'Hip​', 'Foot and Ankle​', 'Shoulder and Elbow', 'Hand​ and Wrist', 'Pediatrics'],
  // Spine
  4: ['Neck​', 'Low Back​', 'Pediatrics'],
  // Primary Care
  5: [
    'Diabetes and High Blood Pressure​',
    'Anxiety and Depression',
    'Geriatrics​',
    'Pediatrics',
    "Women's Health",
  ],
  // Gastroenterology
  6: ['Endoscopy​', 'Hepatology​', 'Pediatric​s', 'Inflammatory Bowel Disease​'],
  // Pulmonology
  7: ['Pediatric​s', 'COPD​', 'Asthma​', 'Sleep Medicine​', 'Interventional Pulmonology​'],
  // Endocrinology
  8: ['Diabetes​', 'Thyroid Disease​', 'Pediatric​s'],
  // Pediatrics
  10: ['Adolescent Medicine​', 'Developmental Pediatrics​'],
};

export const DEFAULT_LOCATIONS = {
  Bentonville: {
    lat: 36.3729,
    long: -94.2088,
    city: 'Bentonville',
    state: 'AR',
  },
  Dallas: {
    lat: 32.7774686,
    long: -96.8042882,
    city: 'Dallas',
    state: 'TX',
  },
  Seattle: {
    lat: 47.6062095,
    long: -122.3320708,
    city: 'Seattle',
    state: 'WA',
  },
  Orlando: {
    lat: 28.4814029,
    long: -81.483093,
    city: 'Orlando',
    state: 'FL',
  },
  Lakeland: {
    lat: 28.0394654,
    long: -81.9498042,
    city: 'Lakeland',
    state: 'FL',
  },
  Nashville: {
    lat: 36.174465,
    long: -86.76796,
    city: 'Nashville',
    state: 'TN',
  },
  Bethesda: {
    lat: 38.9816714,
    long: -77.1535963,
    city: 'Bethesda',
    state: 'MD',
  },
  JunoBeach: {
    lat: 26.88387,
    long: -80.05972,
    city: 'Juno Beach',
    state: 'FL',
  },
  WestPalmBeach: {
    lat: 26.709723,
    long: -80.064163,
    city: 'West Palm Beach',
    state: 'FL',
  },
  Goodlettsville: {
    lat: 36.3231,
    long: -86.7133,
    city: 'Goodlettsville',
    state: 'TN',
  },
  Mesa: {
    lat: 33.4152,
    long: -111.8315,
    city: 'Mesa',
    state: 'AZ',
  },
  FortWorth: {
    lat: 32.749904,
    long: -97.330339,
    city: 'Fort Worth',
    state: 'TX',
  },
  Lowell: {
    lat: 36.25535,
    long: -94.13076,
    city: 'Lowell',
    state: 'AR',
  },
  Hartford: {
    lat: 41.765775,
    long: -72.673356,
    city: 'Hartford',
    state: 'CT',
  },
  OklahomaCity: {
    lat: 35.468494,
    long: -97.521264,
    city: 'Oklahoma City',
    state: 'OK',
  },
  Cincinnati: {
    lat: 39.103697,
    long: -84.513613,
    city: 'Cincinnati',
    state: 'OH',
  },
};

// Login Form Variations
export const DROPDOWN = 'dropdown';
export const DROPDOWN_AUTOCOMPLETE = 'dropdown_autocomplete';
export const PASSWORD = 'password';
export const CHECKMARK = 'checkmark';
export const MEMBER_CREDENTIALS = 'member_credentials';
export const ENSEMBLE_FORM = 'ensemble_form';

export const APP_MAIN_TAG_ID = 'main';

export const PERCENTILE_THRESHOLD = 0.75;

// search constants
export const CARE_CATEGORIES = {
  PROVIDER_SPECIALTY: 'specialty',
  PROVIDER_NAME: 'provider',
  FACILITY_TYPE: 'place_specialty',
  FACILITY_NAME: 'place',
  SERVICE: 'service',
};

export const PROVIDERS_BY_IDS = 'providers_by_ids';
export const PLACES_BY_IDS = 'places_by_ids';

export const COMMON_SEARCH_NAMES = {
  PRIMARY_CARE: 'Primary Care',
  CARDIOLOGY: 'Cardiology',
  PEDIATRICS: 'Pediatrics',
  DERMATOLOGY: 'Dermatology',
  ORTHO_JOINT: 'Ortho-Joint',
  GASTROENTEROLOGY: 'Gastroenterology',
  PHYSICAL_THERAPY: 'Physical Therapy',
  URGENT_CARE: 'Urgent Care',
  HOSPITAL: 'Hospital',
  IMAGING: 'Imaging',
  LAB: 'Lab',
  AMBULATORY_SURGICAL_CENTER: 'Ambulatory Surgical Center',
  COLONOSCOPY: 'Colonoscopy',
  ALLERGY_TEST: 'Allergy Test',
  GASTRIC_BYPASS: 'Gastric Bypass',
  CARDIAC_STRESS_TEST: 'Cardiac Stress Test',
  ACL_RECONSTRUCTION: 'ACL Reconstruction',
  SKIN_BIOPSY: 'Skin Biopsy',
};

export const CARE_CATEGORY_OPTIONS = {
  [CARE_CATEGORIES.PROVIDER_SPECIALTY]: {
    label: 'Doctor by Specialty',
    value: CARE_CATEGORIES.PROVIDER_SPECIALTY,
    IconComponent: LocalHospitalOutlinedIcon,
    shortName: 'Specialty',
    searchText: 'e.g. Cardiology, Pediatrics...',
    accessibleLabel: 'Search for doctor by specialty',
    commonSearches: [
      { name: COMMON_SEARCH_NAMES.PRIMARY_CARE, specialtyId: 5 },
      { name: COMMON_SEARCH_NAMES.CARDIOLOGY, specialtyId: 2 },
      { name: COMMON_SEARCH_NAMES.PEDIATRICS, specialtyId: 10 },
      { name: COMMON_SEARCH_NAMES.DERMATOLOGY, specialtyId: 11 },
      { name: COMMON_SEARCH_NAMES.ORTHO_JOINT, specialtyId: 3 },
      { name: COMMON_SEARCH_NAMES.GASTROENTEROLOGY, specialtyId: 6 },
      { name: COMMON_SEARCH_NAMES.PHYSICAL_THERAPY, specialtyId: 2041 },
    ],
    requiresSelection: true,
    analyticsCategory: 'specialty search',
  },
  [CARE_CATEGORIES.PROVIDER_NAME]: {
    label: 'Doctor by Name/NPI',
    value: CARE_CATEGORIES.PROVIDER_NAME,
    IconComponent: ProviderName,
    shortName: 'Provider',
    searchText: 'Search for a doctor by name or NPI',
    accessibleLabel: null, // searchText already provides sufficient screen-reader context
    commonSearches: [],
    requiresSelection: false,
    analyticsCategory: 'name search',
  },
  // TODO: Uncomment when facility specialization search is upgraded in fusion
  [CARE_CATEGORIES.FACILITY_TYPE]: {
    label: 'Place by Type',
    value: CARE_CATEGORIES.FACILITY_TYPE,
    IconComponent: Place,
    shortName: 'Facility',
    searchText: 'e.g. Hospital, Urgent Care...',
    accessibleLabel: 'Search for a place by type e.g. Hospital, Urgent Care...',
    commonSearches: [
      {
        name: COMMON_SEARCH_NAMES.URGENT_CARE,
        subspecialtyId: 406,
        specialtyId: 300,
      },
      {
        name: COMMON_SEARCH_NAMES.HOSPITAL,
        specialtyId: 302,
      },
      {
        name: COMMON_SEARCH_NAMES.IMAGING,
        subspecialtyId: 405,
        specialtyId: 300,
      },
      {
        name: COMMON_SEARCH_NAMES.LAB,
        subspecialtyId: 460,
        specialtyId: 303,
      },
      {
        name: COMMON_SEARCH_NAMES.AMBULATORY_SURGICAL_CENTER,
        subspecialtyId: 403,
        specialtyId: 300,
      },
    ],
    requiresSelection: true,
    analyticsCategory: 'facility search',
  },
  [CARE_CATEGORIES.FACILITY_NAME]: {
    label: 'Place by Name',
    value: CARE_CATEGORIES.FACILITY_NAME,
    IconComponent: Place,
    shortName: 'Facility',
    searchText: 'Search for a place by name',
    accessibleLabel: null, // searchText already provides sufficient screen-reader context
    commonSearches: [],
    requiresSelection: false,
    analyticsCategory: 'facility name search',
  },
  [CARE_CATEGORIES.SERVICE]: {
    label: 'Service',
    value: CARE_CATEGORIES.SERVICE,
    IconComponent: Service,
    shortName: 'Service',
    searchText: 'e.g. Allergy Test, Colonoscopy...',
    accessibleLabel: 'Search for a service',
    commonSearches: [
      { name: COMMON_SEARCH_NAMES.COLONOSCOPY, serviceId: 51, serviceType: 'provider' },
      { name: COMMON_SEARCH_NAMES.ALLERGY_TEST, serviceId: 12, serviceType: 'provider' },
      { name: COMMON_SEARCH_NAMES.GASTRIC_BYPASS, serviceId: 84, serviceType: 'provider' },
      { name: COMMON_SEARCH_NAMES.CARDIAC_STRESS_TEST, serviceId: 37, serviceType: 'provider' },
      { name: COMMON_SEARCH_NAMES.ACL_RECONSTRUCTION, serviceId: 6, serviceType: 'provider' },
      { name: COMMON_SEARCH_NAMES.SKIN_BIOPSY, serviceId: 199, serviceType: 'provider' },
    ],
    requiresSelection: true,
    analyticsCategory: 'service search',
  },
};

// Threshold for showing red score
export const QUALITY_COLOR_THRESHOLD = 75;
// Threshold for showing embold recommended logo
export const EMBOLD_RECOMMENDED_THRESHOLD = 87;
export const EMBOLD_RECOMMENDED = 'Exceeds Standards';

export const HIGH_PERFORMING_TITLE = 'Highly Rated';
export const HIGH_PERFORMING_TOOLTIP_TEXT =
  'This provider has been reviewed by medical experts and has proven to consistently deliver care that is among the best in the area in this specialty.';

export const FEATURED_PROVIDER_LINK_LABEL = 'Learn More Here';

export const DEFAULT_SUPPORT_MAILBOX = 'support@emboldhealth.com';
export const DEFAULT_SUPPORT_PHONE = '877-362-6531';

export const SCORE_TIER_HIGH = 1;
export const SCORE_TIER_MIDDLE = 2;
export const SCORE_TIER_LOW = 3;
export const SCORE_TIER_UNSCORED = 0;
export const VALID_SCORE_TIERS = [
  SCORE_TIER_HIGH,
  SCORE_TIER_MIDDLE,
  SCORE_TIER_LOW,
  SCORE_TIER_UNSCORED,
];

export const SCORE_TIER_ICON_COMPONENTS = {
  [SCORE_TIER_HIGH]: StarsIcon,
  [SCORE_TIER_MIDDLE]: CheckCircleIcon,
  [SCORE_TIER_LOW]: ErrorIcon,
};

export const SCORE_TIER_LANGUAGE = {
  [SCORE_TIER_HIGH]: 'Exceeds Standards',
  [SCORE_TIER_MIDDLE]: 'Meets Standards',
  [SCORE_TIER_LOW]: 'Below Standards',
};

export const SCORE_TIER_ICON_ALT_TEXT = {
  // this will be used as the titleAccess prop on the score tier icon
  ...SCORE_TIER_LANGUAGE,
};

export const SCORE_COLORS_BY_TIER = {
  [SCORE_TIER_HIGH]: 'scoreTier.1',
  [SCORE_TIER_MIDDLE]: 'scoreTier.2',
  [SCORE_TIER_LOW]: 'scoreTier.3',
};

export const SUBSPECIALTY_SECTION = {
  title: 'Focus Areas',
  IconComponent: FocusAreasColored,
  description:
    'The following highlights the demonstrated capabilities of a healthcare provider, determined by the quality of their practice according to standards identified by medical experts, the volume of their cases, or the areas of expertise they have self-identified.',
  about:
    'Focus Areas are calculated by Embold Health based on how frequently providers practice in that area, so you can be confident your provider has the experience you need.',
};

export const PRIMARY_AFFILIATION_METRIC = {
  title: 'Primary Affiliation',
  description:
    'Primary Affiliation refers to the hospital or group where a provider is most closely associated, based on services provided and number of patients seen.',
};

// TODO: Add wording to description and about
export const SERVICE_COST_INFO = {
  title: 'Services at this location',
  IconComponent: Service,
  description:
    'The price displayed is an estimate of this service only. In some cases additional services may be provided and these will change the total price. Always check with your provider and health plan for more detailed information.',
};

export const HEALTH_AND_WELLNESS = {
  title: 'Health &\nWellness Perks',
  IconComponent: HealthAndWellness,
  description:
    "When they relate to your search, your employer's benefits display above the results, reminding you of effective, low-cost options for specific medical needs.",
  altText: 'Example benefit rating showing a score of 3 out of 4 stars',
};

export const STRENGTHS_SECTION = {
  title: 'Strengths',
  IconComponent: StrengthsColored,
  description:
    "Provider Strengths highlight a doctor's areas of exceptional care in comparison to their peers.",
};

export const SUBSPECIALTY_TYPES = {
  SCORED: 'scored',
  HIGH_VOLUME: 'high_volume',
  SELF_IDENTIFIED: 'self_identified',
};

export const COST_EFFICIENCY_SECTION = {
  title: 'Cost Efficiency',
  IconComponent: CostEfficiencyColored,
  description:
    'Embold recommends that you first look for a high-quality provider and then consider their Cost Efficiency. High-quality and highly cost-efficient providers deliver the best care while using the least resources.',
};
export const SERVICE_COST_CONTENT = {
  buttonText: 'See estimate >',
  IconComponent: CostEfficiencyColored,
  description: "Service costs may change per provider's location",
};
export const CHOOSE_NETWORK_TEXT = 'Choose Your Network';

export const COST_EFFICIENCY_MAP = {
  1: 'Less Efficient',
  2: 'Average',
  3: 'More Efficient',
};

export const CHAT_BUTTON_Z_INDEX = 8;
export const MAP_LOADING_WRAPPER_Z_INDEX = CHAT_BUTTON_Z_INDEX - 2;
export const MAP_LOADING_MODAL_Z_INDEX = CHAT_BUTTON_Z_INDEX - 1;

export const LIGHT_BLUE = 'lightBlue';
export const DARK_GREEN = 'darkGreen';
export const APPLE_RED = 'appleRed';
